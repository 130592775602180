import { FullPage } from '@/components/fullpage';
import { AppInterface } from '@/interface';
import clipboard from 'clipboard';
import { Component, CreateElement, Prop, VueComponent } from "internal";
import { clipboardCopy, isAndroid } from "../clipboard";
import { DownloadPage } from "../../download/download-comp";
import { DownloadConfig, getDownLoadConfig } from "../../download/config"

import "./index.scss";

export type BasePageProps = {
  code?: string;
  activeId: string;
  userId?: string;
};

@Component
export class InventActComp extends VueComponent<BasePageProps>{

  @Prop()
  private code: string;
  @Prop()
  private userId: string;
  @Prop()
  private activeId: string;

  private list: any = {};
  $refs: { number: any; code: any, fullpage: FullPage, child_scroll: any, father_srcoll: any }

  get isProd() {
    return location.hostname == "m.fireweed.cn"
  }

  private get userHead() {
    if (!this.list.head) {
      return require('@/assets/default.png');
    }
    if (this.isProd) {
      return `http://media.fireweed.cn/${this.list.head}`
    }
    return `https://jiangruihao-pub.oss-cn-hangzhou.aliyuncs.com/${this.list.head}`
  }

  private get userName() {
    if (this.list) {
      return this.list.name ? this.list.name : "";
    }
    return "";
  }

  private get inviteCode() {
    if (this.list && this.list.inviteCode) {
      return this.list.inviteCode;
    }
    return "";
  }

  private get inviteUrl() {
    if (!this.list || !this.list.url) {
      return require('@/assets/default.png');
    }
    if (this.isProd) {
      return `http://media.fireweed.cn/${this.list.url}`
    }

    return `https://jiangruihao-pub.oss-cn-hangzhou.aliyuncs.com/${this.list.url}`;;
  }

  render(h: CreateElement) {
    return <div class="fw-inventAct-page" >
      {/* <div class="invent-bg"> */}
      <div class="invent-top">
        <div class="invent-top-user">
          <span>
            <img src={this.userHead} alt="" class="user-head" />
          </span>
          <div class="user-con-name"> {this.userName}</div>
        </div>
        <div class="act-con">
          <div>{this.list.content} </div>
          <div>去APP帮我助力吧</div>
          <div>
            <span>
              <img src={this.inviteUrl} alt="" class="act-url" />
            </span>

          </div>
          <div class="point-number">新用户助力成功将获得{this.list.registerGetPointNumber}燃豆奖励 </div>
        </div>
        <div class="user-con">
          <div class="user-con-buttom"
            onClick={() => this.downBtn(".user-con-buttom")}
            data-clipboard-action="copy" data-clipboard-target="#downCode">
            <span>点击去APP助力</span>
            <div >
            </div>
          </div>
        </div>
      </div>
      {/* 点下载复制内容 */}
      <span class="hidden" id="downCode">
        {/* code:{this.list.inviteCode}&&avtivityId:{this.$route.query.activeId} */}
        {location.origin}/i/{this.inviteCode}/{this.activeId} # [燃草] {this.list.content},来燃草和我一起学习吧~
        </span>
      <div class="download-share" v-show={this.shareImg} onClick={() => this.shareImg = false}>
        <img src={require('@/assets/invent-img/share.png')}></img>
      </div>

      <div v-show={this.tipPopup} class="download-share">
        {/* <div class="download-share"> */}
        <div class="tip-popup-all">
          <div class="tip-popup">
            <div class="tip-popup-title">分享链接复制失败</div>
            {/* <div class="copy-con" id="copy-con" onClick={this.selectText} textIsSelectable={true}> */}
            <div class="copy-con">
              {location.origin}/i/{this.inviteCode}/{this.activeId} # [燃草] {this.list.content},来燃草和我一起学习吧~【双击复制】打开燃草
            </div>
            <div class="to-help" onClick={this.toHelp}>去app助力</div>
          </div>
          <div>
            <img class="tip-close" src={require('@/assets/close.png')} onClick={() => this.tipPopup = false}></img>
          </div>
        </div>
      </div>
    </div>
  }
  // private selectText() {
  //   var text: any = document.getElementById("copy-con");
  //   var selection = window.getSelection();
  //   var range: any = document.createRange();
  //   range.selectNodeContents(text);
  //   selection!.removeAllRanges();
  //   selection!.addRange(range);
  // }



  private selectText() {
    // var doc = document as any,
    //   text = doc.getElementById("copy-con"),
    //   range,
    //   selection;
    // if (doc.body.createTextRange) {
    //   range = doc.body.createTextRange();
    //   range.moveToElementText(text);
    //   range.select();
    // } else if (window.getSelection) {
    //   selection = window.getSelection();
    //   range = document.createRange();
    //   range.selectNodeContents(text!);
    //   selection!.removeAllRanges();
    //   selection!.addRange(range);
    // } else {
    //   alert("当前浏览器不支持点击复制功能");
    // }
  }

  private async LoadList() {
    let res = await AppInterface.User.GetUserIntiveInfoById({
      searchId: this.userId as any,
      avtivityId: this.activeId as any,
      inviteCode: this.code
      // searchId: "648991831832395776",
      // avtivityId: 15
    })
    this.list = res.data;
  }
  private get isWeiXin() {
    var u = navigator.userAgent;
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('micromessenger') !== -1 || (ua.indexOf('qq') !== -1 && u.indexOf('MQQBrowser') == -1) || ua.indexOf('dingtalk') !== -1) {
      return true;
    }
    return false;
  }
  private shareImg: boolean = false;
  private tipPopup: boolean = false;

  private config: DownloadConfig = {} as any;
  private async downBtn(codeClassName: string) {
    if (this.isWeiXin) {
      this.shareImg = true
    } else {
      let success = await clipboardCopy(codeClassName)
      if (success) {
        this.toHelp()
      } else {
        this.tipPopup = true
      }
    }
  }
  private toHelp() {
    if (isAndroid()) {
      this.downLoadAndroid()
    } else {
      this.downLoadIos()
    }
  }

  private downLoadIos() {
    window.location.href = this.config.ios.scheme;
    setTimeout(() => {
      let hidden = window.document.hidden
      if (typeof hidden == "undefined" || hidden == false) {
        //App store下载地址
        window.location.href = this.config.ios.url
      }
    }, 1000);
  }
  private downLoadAndroid() {
    let con = `${location.origin}/i/${this.inviteCode}/${this.activeId} # [燃草]`
    window.location.href = this.config.android.scheme + `?content=${encodeURIComponent(con)}`;
    setTimeout(() => {
      let hidden = window.document.hidden
      if (typeof hidden == "undefined" || hidden == false) {
        //App store下载地址
        window.location.href = this.config.android.url
      }
    }, 1000);
  }
  private async downVersion() {
    this.config = await getDownLoadConfig();
  }

  private mounted() { }
  private created() {
    this.LoadList();
    this.downVersion();
  }
}