import "internal/styles/base.scss";
import Vue from "vue";
import { AppRoutes } from "./router"
import { InstallFireWeedCommon } from "internal"
import VueRouter from "vue-router";
import { AppKeel } from "./pages/keel";
import clipboard from 'clipboard';
const MetaInfo = require('vue-meta-info')
Vue.prototype.clipboard = clipboard;
Vue.use(MetaInfo);

// AppRoutes.push({
//   name: "页面未找到！",
//   component: NotFoundPage,
//   path: "*"
// })

const AppRouter = new VueRouter({
  routes: AppRoutes,
  mode: "history"
})

InstallFireWeedCommon(Vue);
const App = new Vue({
  router: AppRouter,
  render(h) {
    return h(AppKeel)
  }
})

App.$mount("#app")

