import { VueComponent, Component, CreateElement } from "internal";

export type AppKeelProps = {};

@Component
export class AppKeel extends VueComponent<AppKeelProps>{

  private render(h: CreateElement) {
    return <router-view id="app"></router-view>
  }

  private mounted() {
    try {
      document.dispatchEvent(new Event('render-event'))
    } catch (e) { }
  }

  private created() { }

}