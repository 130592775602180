import Vue from "vue";
import { createDecorator } from "vue-class-component";
import { Component, Mixins } from "vue-property-decorator";
import { Route } from "vue-router";
import { Dictionary, RawLocation } from "vue-router/types/router";
import utils from "../utils";
import { Arrays } from '../utils/arrays';
import { pure } from "../utils/data";

const pathEqual = (target: string, source: string) => {
  return (target + "/").replace(/\/\/$/g, "/") == (source + "/").replace(/\/\/$/g, "/")
}
/**
 * 定制化路由
 */
export interface PageRoute<T extends Dictionary<string>> extends Route {
  query: T;
  // params: T;
}
@Component
export class BasePagesMixins extends Vue {

  loaded() {
    this.$emit("loaded")
    try {
      document.dispatchEvent(new Event('render-event'))
    } catch (e) { }
  }

  /**
   * 退出当前页面
   * @param count
   */
  ExitWithOutSave(count: number = 0) {
    if (count < 0) {
      this.$router.go(count);
    } else {
      let matched = this.$router.currentRoute.matched
      if (matched.length > 1) {
        let parent = matched[matched.length - 2];
        let ParentIndex = Arrays.FindIndex(BasePageCacher.history, i => {
          return pathEqual(i.path, parent.path)
        });
        if (ParentIndex != -1 && ParentIndex < BasePageCacher.history.length - 2) {
          this.$router.go(ParentIndex - BasePageCacher.history.length + 1)
        } else {
          this.$router.replace({
            path: (parent.path + "/").replace(/\/\/$/g, "/"),
            query: {}
          });
        }
      } else {
        this.$router.back();
      }
    }
  }

  /**
   * 返回并刷新上级的BasePage页面
   * @param force
   */
  ExitAndRefreshParent(force: boolean = false) {
    let parent = this.$parent;
    while (parent) {
      if (!parent.$vnode.componentOptions) {
        parent = parent.$parent;
        return;
      }
      let Super = utils.vue.getStaticClass<BasePage>(parent, "_brandName");
      if (Super && Super == "BasePage") {
        // @ts-ignore
        if (parent.PageRefresh) parent.PageRefresh(force);
        // @ts-ignore
        parent = null;
        break;
      }
      parent = parent.$parent;
    }
    this.ExitWithOutSave();
  }
  /**
     * 返回并刷新父页面
     * @param force
     */
  ExitAndRefresh(force: boolean = false) {
    let parent = this.$parent;
    while (parent) {
      if (!parent.$vnode.componentOptions) {
        parent = parent.$parent;
        return;
      }
      let Super = utils.vue.getStaticClass<BasePage>(parent, "_brandName");
      if (Super && (Super == "BasePage" || Super == "PopUpPage")) {
        // @ts-ignore
        if (parent.PageRefresh) parent.PageRefresh(force);
        // @ts-ignore
        parent = null;
        break;
      }
      parent = parent.$parent;
    }
    this.ExitWithOutSave();
  }
}

const PageRefresh = createDecorator((options, key, index) => {
  (options.mixins || (options.mixins = [])).push({
    methods: {
      PageRefresh() {
        // @ts-ignore
        this[key](...arguments)
      }
    }
  })
})

const BasePageCacher = {
  history: [] as Route[]
}
export const MirrorRouteList = createDecorator((options, key, index) => {
  (options.mixins || (options.mixins = [])).push({
    created(this: Vue) {
      // BasePageCacher.history.push(this.$route)
      BasePageCacher.history.push({
        path: this.$route.path,
        name: this.$route.name,
        hash: this.$route.hash,
        query: pure(this.$route.query),
        params: pure(this.$route.params),
        fullPath: this.$route.fullPath
      } as any)
      this.$router.beforeEach((
        to: Route,
        from: Route,
        next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
      ) => {
        let IndexOfFrom = Arrays.FindIndex(BasePageCacher.history, item => item.path == from.path)
        let IndexOfTo = Arrays.FindIndex(BasePageCacher.history, item => item.path == to.path)
        if (IndexOfTo != -1 && IndexOfTo < IndexOfFrom) {
          // back
          BasePageCacher.history = BasePageCacher.history.splice(0, IndexOfTo + 1)
        } else {
          BasePageCacher.history.push({
            path: to.path,
            name: to.name,
            hash: to.hash,
            query: pure(to.query),
            params: pure(to.params),
            fullPath: to.fullPath
          } as any)
        }
        next();
      })
    }
  })
})
export type BasePageRef<T> = T
/**
 * 基础页面 继承此类 有PageRefresh，子页面才可以刷新
 */
export abstract class BasePage<Query extends Dictionary<string> = {}> extends Mixins(BasePagesMixins) {
  static _brandName: string = "BasePage"
  static PageRefresh = PageRefresh;
  $route: PageRoute<Query>
  // abstract PageRefresh?(force?: boolean): void
}
// export function BasePageMixins<A>(CtorA: VueClass<A>) {
//   return class extends Mixins(CtorA, BasePagesMixins){

//   } as VueClass<A & BasePagesMixins>
// }
// export function ExtendsBasePage<Base,Query extends Dictionary<string> = {}> extends BasePage<Query>{

// }

export interface BasePage<Query extends Dictionary<string> = {}> {
  $route: PageRoute<Query>;
}