import { pure } from "./data";


export namespace Trees {
  /**
   * 获取所需层级下所有分类
   * @param tree        树形列表
   * @param transfer    转换方法
   * @param getChild    获取子
   * @param setChild    设置转换后的子
   * @param depth       深度
   * @param current     可以不设置
   */
  export function transform<T, R>(
    tree: T[],
    transfer: (item: T, index: number) => R,
    getChild: (item: T, index: number) => T[],
    setChild: (item: R, children: R[]) => void,
    depth: number = -1,
    current: number = 0
  ): R[] {
    if (depth != -1 && current >= depth) return [];
    return tree.map((item, i) => {
      let obj: R = transfer(item, i);
      let children = getChild(item, i);
      if ((depth == -1 || current < depth - 1) && children.length > 0) {
        let childs = transform(children, transfer, getChild, setChild, depth, current + 1);
        setChild(obj, childs);
      }
      return obj;
    })
  }


  export function findByStep<T, V>(
    tree: T[],
    values: V[],
    equal: (item: T, value: V) => boolean,
    getChild: (item: T) => T[],
  ) {
    let result: T[] = [];
    let currentOptions = pure(tree);
    let vals = pure(values);
    while (vals.length) {
      currentOptions.forEach(item => {
        if (equal(item, vals[0])) {
          currentOptions = getChild(item);
          result.push(item);
        }
      })
      vals.shift();
    }
    if (result.length != values.length) {
      return [];
    }
    return result;
  }
}