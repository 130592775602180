//// <reference path="../../../node_modules/@types/node/index.d.ts"/>
/*
 * @Author: zhoupeng
 * @Date:   2017-11-14 16:03:29
 * @Last Modified by: zhoupeng
 * @Last Modified time: 2018-04-09 19:07:23
 */
// @ts-ignore
import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EventEmitter as events } from 'events';
import Object2Formdata from "object-to-formdata";
import { AjaxGet, AjaxPost, AjaxUpload } from '.';
import AxiosConfig from './config';
import { createDecorator } from "vue-class-component";
import { BasePage } from "../pages/base";
import { debounce } from "../utils/debounce";
import utils from "../utils";

const URI = function (name: string, ...props: any[]) {
  let url = name;
  if (props.length > 0) {
    url = url.replace(/\{(.*?)\}/gi, function (whole: string) {
      if (props.length > 0) {
        return props.shift()
      } else {
        return whole
      }
    })
  }
  return url
}

const parseUrl = function (Url: string | any[]) {
  let url = typeof Url === 'string' ? [Url] : Url;
  let name = url.shift()
  return URI(name, ...url)
}

class AJAXFilterd {
  private beforeAjaxConfig: { [id: string]: { ins: any, key: string } } = {};
  BeforeAjax() {
    return createDecorator((options, key, index) => {
      let _ajax = this;
      let _ajaxConfigId = utils.data.random();
      (options.mixins || (options.mixins = [])).push({
        created(this: BasePage) {
          // @ts-ignore
          this._ajaxConfigId = _ajaxConfigId;
          _ajax.addAjaxEnhance(_ajaxConfigId, this, key)
        },
        destroyed() {
          _ajax.removeAjaxEnhance(_ajaxConfigId)
        }
      });
    })
  }

  private addAjaxEnhance(_ajaxConfigId: string, ins: any, key: string) {
    this.beforeAjaxConfig[_ajaxConfigId] = { ins, key };
  }

  private removeAjaxEnhance(_ajaxConfigId: string) {
    delete this.beforeAjaxConfig[_ajaxConfigId];
  }

  protected invokeBefore(options: any) {
    Object.keys(this.beforeAjaxConfig).forEach(key => {
      let c = this.beforeAjaxConfig[key];
      c.ins[c.key](options);
    })
  }
}
class AJAXResolvers extends AJAXFilterd {
  private ErrorCodeRs: { [key: number]: any[] } = {};
  protected ErrorRs: { [key: number]: Function } = {};
  ErrorCodeResolver(code: number) {
    return createDecorator((options, key, index) => {
      let _ajax = this;
      (options.mixins || (options.mixins = [])).push({
        created(this: BasePage) {
          // @ts-ignore
          this.$on(`ajax-error-${code}`, this[key]);
          _ajax.addResolver(code, this)
        },
        destroyed() {
          // @ts-ignore
          this.$off(`ajax-error-${code}`, this[key])
        }
      });
    })
  }

  private addResolver(code: number, instance: BasePage) {
    if (!this.ErrorCodeRs[code]) {
      this.ErrorCodeRs[code] = [];
    }
    this.ErrorCodeRs[code].push(instance);
    if (!this.ErrorRs[code]) {
      this.ErrorRs[code] = debounce((error: any) => {
        this.ErrorCodeRs[code].forEach(v => {
          v.$emit(`ajax-error-${code}`, error)
        })
      }, 200)
    }
  }
}
export class AJAX extends AJAXResolvers {

  private request<T>(axiosInstance: AxiosPromise<T>) {
    return new Promise<T>((resolve, reject) => {
      axiosInstance.then((d: AxiosResponse<T>) => {
        const {
          data
        } = d;
        // let hasResolved = afterAjax(data as any);
        resolve(data)
      }).catch((error: AxiosError) => {
        let status = error.response ? error.response.status : 200;
        if (this.ErrorRs[status]) {
          this.ErrorRs[status](error)
        } else {
          reject({
            resolved: false,
            error
          })
        }

      })
    })
  }


  get<Response, Data>(Url: AjaxGet<Response, Data>, data?: Data) {
    const options: AxiosRequestConfig = {
      headers: {},
      params: data
    }
    this.invokeBefore(options)
    const instance = axios.get<Response>(Url, options)
    return this.request(instance)
  }

  post<Response, Data>(Url: AjaxPost<Response, Data>, data?: Data) {
    const options = {
      headers: {}
    }
    this.invokeBefore(options)
    const instance = axios.post<Response>(Url, data, options)
    return this.request(instance)
  }


  upload<Response, Data>(Url: AjaxUpload<Response, Data>, _data?: Data) {
    let data = Object2Formdata(_data) as any
    let progress = new events();
    let onProgress = function (cb: (progressEvent: ProgressEvent) => void) {
      progress.on('progress', cb)
    }
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
          progress.emit('progress', progressEvent)
        }
      }
    }
    this.invokeBefore(options)
    const instance = axios.post<Response>(Url, data, options)
    return {
      instance: this.request(instance),
      onProgress
    }
  }

}

export const Ajax = new AJAX();

/*

const request = function <T>(axiosInstance: AxiosPromise<T>) {
  return new Promise<T>(function (resolve, reject) {
    axiosInstance.then(function (d: AxiosResponse<T>) {
      const {
        data
      } = d;
      let hasResolved = afterAjax(data as any);
      if (hasResolved) {
        reject(data)
      } else {
        resolve(data)
      }
    }).catch(function (error: AxiosError) {
      console.info(error)
      let resolved = AxiosConfig.onRequestError.some(s => s(error))
      reject({
        resolved,
        error
      })
    })
  })
}

export namespace Ajax1 {
  export function getWithFormat<Response, Data, Format>(Url: AjaxItem<Response, Data, Format>, format: Format, data?: Data) {
    let url = parseUrl([Url, format])
    const options: AxiosRequestConfig = {
      headers: {},
      params: data
    }
    if (!validateAjax) return Promise.reject(null);
    beforeAjax(options)
    const instance = axios.get<Response>(url, options)
    return request(instance)
  }

  export const get = PromiseSingleton(function <Response, Data>(Url: AjaxGet<Response, Data>, data?: Data) {
    const options: AxiosRequestConfig = {
      headers: {},
      params: data
    }
    if (!validateAjax) return Promise.reject(null);
    beforeAjax(options)
    const instance = axios.get<Response>(Url, options)
    return request(instance)
  })


  export function postWithFormat<Response, Data, Format>(Url: AjaxPost<Response, Data, Format>, format: Format, data?: Data) {
    let url = parseUrl([Url, format])
    const options = {
      headers: {}
    }
    if (!validateAjax) return Promise.reject(null);
    beforeAjax(options)
    const instance = axios.post<Response>(url, data, options)
    return request(instance)
  }
  export function post<Response, Data>(Url: AjaxPost<Response, Data>, data?: Data) {
    const options = {
      headers: {}
    }
    if (!validateAjax) return Promise.reject(null);
    beforeAjax(options)
    const instance = axios.post<Response>(Url, data, options)
    return request(instance)
  }

  export function uploadWithFormat<Response, Data, Format>(Url: AjaxPost<Response, Data, Format>, format: Format, data?: Data) {
    let url = parseUrl([Url, format])
    let progress = new events();
    let onProgress = function (cb: (progressEvent: ProgressEvent) => void) {
      progress.on('progress', cb)
    }
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: data,
      onUploadProgress(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
          progress.emit('progress', progressEvent)
        }
      }
    }
    if (!validateAjax) return {
      instance: Promise.reject(null),
      onProgress
    };
    beforeAjax(options)
    const instance = axios.post<Response>(url, data, options)
    return {
      instance: request(instance),
      onProgress
    }
  }
  export function upload<Response, Data>(Url: AjaxUpload<Response, Data>, _data?: Data) {
    let data = Object2Formdata(_data) as any
    let progress = new events();
    let onProgress = function (cb: (progressEvent: ProgressEvent) => void) {
      progress.on('progress', cb)
    }
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress(progressEvent: ProgressEvent) {
        if (progressEvent.lengthComputable) {
          progress.emit('progress', progressEvent)
        }
      }
    }
    if (!validateAjax) return {
      instance: Promise.reject(null),
      onProgress
    };
    beforeAjax(options)
    const instance = axios.post<Response>(Url, data, options)
    return {
      instance: request(instance),
      onProgress
    }
  }
  export function Upload<Response, Data>(Url: AjaxUpload<Response, Data>, data?: Data) {
    return upload(Url, data)
  }
} */



