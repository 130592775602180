import { VueComponent, Component, CreateElement } from "internal";
import { DownloadPage } from "./download/download-comp";
// import "./sdk.js"


export type IndexPageProps = {};

/**
 * @title 燃草-高效铸就非凡
 * @meta {title:"燃草-高效铸就非凡",meta:[{keywords:"时间规划, 计时软件, 新年倒计时, 倒计时, 时间计时, 高考倒计时, 番茄钟, 学习计时, 番茄计时, 高效todo, 锁机, 专注学习, timing, 纪念日, 正计时, 学霸模式, 学习锁屏, 目标管理, 待办事项, 自习室"},{description:"如何做好时间管理？怎样成为一个高效率的人？燃草是一款全工具免费的时间管理app，以目标管理为核心，完善学习时间安排。页面风格简约，功能强大，不仅可以能让你高效利用时间，而且还能在燃草圈子进行学习交流，讨论生活中遇到的问题，规划你的生活、学习。软件特色：燃草日历，每日一句经典励志名言，配上原创励志插画为你助燃每一天；计时工具，包含正计时，倒计时，以及专注模式，瞬间开启学霸模式，让你时刻保持学习专注度；周计划列表，相比日计划代办而言，有更多发挥的空间，每周末还可填写周总结。时间日志，首页开启目标管理，清晰查看目标日期的剩余时间和坚持天数，记录你的努力过程，也可以为你的目标制定计划。如果你想分享你的经历，也可以公开日志，让大家给你助燃鼓励。待办清单，可以帮你制定项目计划、设置会议提醒、 安排行程规划、保持工作专注，还能用于记录备忘、整理购物清单。燃草装备，提供各类优质的正品文具用品，满足您在学习生活中需求，评价还能获得燃豆奖励哦~燃草，高效铸就非凡"}]}
 */
@Component
export class IndexPage extends VueComponent<IndexPageProps>{

  private render(h: CreateElement) {
    return <div class="total">
      <DownloadPage />
    </div>
  }

  private mounted() { }

  private created() { }

}