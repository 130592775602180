import { BasePage, Component, CreateElement, Ajax } from "internal";
import "../invent/base.scss";
import { DownloadPage } from '@/pages/download/download-comp';

export type BasePageProps = {
    userId: ''
};

/**
 * @title 邀请用户赢燃豆
 * @path /common/invent/receive.html
 */
@Component


export class Receive extends BasePage<BasePageProps>{
    private iosDownUrl: any = ''
    private androidDownUrl: any = ''
    private async downVersion() {
        let res;
        if (location.hostname == "m.fireweed.cn") {
            res = await Ajax.get("https://download.fireweed.fun/appVersion/config.json?time=" + Date.now());
        } else {
            res = await Ajax.get("https://download.fireweed.fun/devAppVersion/config.json?time=" + Date.now());
        }
        let con: any
        con = res
        this.iosDownUrl = "itms-services://?action=download-manifest&url=" + encodeURIComponent("https://download.fireweed.fun/" + con!.ios[0].url);
        this.androidDownUrl = "http://download.fireweed.fun/" + con!.android[0].url
    }

    $refs: { number: any; code: any }
    private render(h: CreateElement) {
        return <div class="fw-base-con">
            <div class="pic-bg total">
                <img class="pic-receive" src={require('@/assets/invent-img/receive.png')}></img>
                <div class="use-points">快去使用你的燃豆吧</div>
                <div class="pic-receive-mid">
                    <img src={require('@/assets/invent-img/pic-receive.png')}></img>
                </div>
                <DownloadPage isSmall />
            </div>
        </div>
    }

    private mounted() {
        document.body.className += " total"
    }

    private created() {
        this.downVersion()
    }

}