import Vue from "vue";
/**
 * @example
 * class Base extends Vue{
 *  static _brandName = "my_brandName"
 * }
 * getStaticClass(Base,"_brandName") => "my_brandName"
 */
export function getStaticClass<T>(vm: Vue, staticName: string) {
  if (!vm.$vnode.componentOptions) {
    return undefined;
  }
  if (!vm.$vnode.componentOptions.Ctor) {
    return undefined;
  }
  // @ts-ignore
  if (!vm.$vnode.componentOptions.Ctor.super) {
    return undefined;
  }
  let Super = (vm.$vnode.componentOptions.Ctor as any).super as any;
  return Super[staticName];
  // return // @ts-ignore
  // parent.$vnode.componentOptions.Ctor.super &&
  //   // @ts-ignore
  //   parent.$vnode.componentOptions.Ctor.super._brandName == "BasePage"
}