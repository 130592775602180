import Vue from "vue";
import { Store } from "vuex";
import { Mixins } from "vue-property-decorator";
import { VueDefaultJSXElementAttributes } from "..";


/**
 * 全局mixins
 */
const BaseComponent = {
  metaInfo(this: Vue) {
    if (this.$route && this.$route.meta) {
      return {
        title: this.$route.meta.title || document.title,
        meta: this.$route.meta.meta || []
      };
    }
  }
}

/**
 * VueComponent
 */
export interface VueComponent<Props = {}> extends Vue {
  $props: Props & VueDefaultJSXElementAttributes
}
/**
 * VueComponent 所有的自定义组件只要继承这个类就可以使用类型推导了
 */
export class VueComponent<Props = {}> extends Mixins(BaseComponent as any) {
  loaded() {
    this.$emit("loaded")
    try {
      document.dispatchEvent(new Event('render-event'))
    } catch (e) { }
  }
}