
import clipboard from 'clipboard';

export function clipboardCopy(codeClassName: string): Promise<boolean> {
  return new Clipboard(codeClassName).copy();
}


export function isAndroid() {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  return isAndroid;
}

class Clipboard {
  listener: (this: Document, ev: ClipboardEvent) => any;
  constructor(private codeClassName: string) {
    this.listener = (event) => {
      this.isEventEmitted = true;
      document.removeEventListener("copy", this.listener)
      this.check();
    };
    document.addEventListener('copy', this.listener);
  }

  private isClipBoardFinish = false;
  private isEventEmitted = false;
  private clipboardFinish() {
    if (isAndroid()) {
      this.isClipBoardFinish = true;
      this.check();
    } else {
      if (this.callBack) {
        this.callBack(true);
        this.callBack == undefined;
        document.removeEventListener("copy", this.listener)
      }
    }
  }

  private check() {
    if (this.isEventEmitted && this.isClipBoardFinish) {
      if (this.callBack) {
        this.callBack(true);
        this.callBack = undefined;
        document.removeEventListener("copy", this.listener)
      }
    }
  }

  private fail() {
    if (this.callBack) {
      this.callBack(false);
      this.callBack = undefined;
      document.removeEventListener("copy", this.listener)
    }
  }

  private callBack?: (success: boolean) => void
  async copy(): Promise<boolean> {
    setTimeout(() => {
      this.fail();
    }, 200);
    return new Promise(async (resolve, reject) => {
      this.callBack = resolve;
      let Clipboard = new clipboard(this.codeClassName)
      Clipboard.on('success', (event) => {
        // this.$toast("复制成功")
        Clipboard.destroy();
        this.clipboardFinish();
        // resolve(true);
      })
      Clipboard.on("error", e => {
        // 不支持复制
        // console.log("该浏览器不支持自动复制");
        // 释放内存
        Clipboard.destroy();
        this.fail();
        // resolve(false);
      });
    })
  }




}