import { FullPage, FullPageItem } from '@/components/fullpage';
import { AppInterface } from '@/interface';
import { BasePage, Component, CreateElement, Ajax, VueComponent, Prop } from "internal";
import "./index.scss";
import BScroll from '@better-scroll/core';
import clipboard from 'clipboard';

export type BasePageProps = {
  code?: string;
  userId?: string;
};

@Component
export class InventComp extends VueComponent<BasePageProps>{
  private list: any = {};

  @Prop()
  private code: string;
  @Prop()
  private userId: string;

  $refs: { number: any; code: any, fullpage: FullPage, child_scroll: any, father_srcoll: any }

  get isProd() {
    return location.hostname == "m.fireweed.cn"
  }

  private get userHead() {
    if (!this.list.head) {
      return require('@/assets/default.png');
    }
    if (this.isProd) {
      return `http://media.fireweed.cn/${this.list.head}`
    }
    return `https://jiangruihao-pub.oss-cn-hangzhou.aliyuncs.com/${this.list.head}`
  }

  private get userName() {
    if (this.list) {
      return this.list.name ? this.list.name : "";
    }
    return "";
  }

  private get inviteCode() {
    if (this.list && this.list.inviteCode) {
      return this.list.inviteCode;
    }
    return "";
  }
  private copyTip: Boolean = false
  private render(h: CreateElement) {
    return <div class="fw-invent-page" >
      {/* <div class="invent-bg"> */}
      {/* 点下载复制内容 */}
      <span class="hidden" id="downCode">
        {/* code:{this.list.inviteCode} */}
        {location.origin}/i/{this.inviteCode} # [燃草] 来燃草和我一起学习吧~
        </span>
      <div class="invent-top">
        <div class="invent-top-user">
          <span>
            <img src={this.userHead} alt="" class="user-head" />
          </span>
          <div>
            <div class="user-con-name"> {this.userName}</div>
            <div class="user-con-sec">邀请你加入燃草</div>
          </div>
        </div>
        <div class="invent-top-code">
          <div>
            填写邀请码：
          <span class="invent-top-codeNum">
              {this.inviteCode}
            </span>
          </div>
          <div class="invent-top-codeCopy"
            onclick={() => this.doCopy(".invent-top-codeCopy")}
            data-clipboard-action="copy"
            data-clipboard-target="#downCode">
            复制
          </div>
        </div>
        <div class="father-srcoll" ref="father_srcoll">
          <div class="child-scroll" ref="child_scroll">
            <img src={require('@/assets/invent-img/bg1.png')}></img>
            <img src={require('@/assets/invent-img/bg2.png')}></img>
            <img src={require('@/assets/invent-img/bg3.png')}></img>
          </div>
        </div>
        <div class="point-info">
          <div class="point-info-register">新用户注册可获得{this.list.registerGetPointNumber}燃豆 </div>
          <div>成功绑定邀请人可再获得{this.list.intiveRegisterGetPointNumber}燃豆</div>
        </div>
        <div class="user-con">
          <div class="user-con-buttom" onClick={() => this.downBtn(".user-con-buttom")} data-clipboard-action="copy" data-clipboard-target="#downCode">
            <span>立即下载</span>
            <div >
            </div>
          </div>
        </div>
        {/* <div class=${hidden copy-tip> */}
        <div class={`${this.copyTip ? "copy-tipInfo" : ""} hidden`}>
          {/* ${!this.inset ? "enabled" : "" */}
          <span>复制成功</span>
        </div>
      </div>
    </div>
  }


  private async LoadList() {
    let res = await AppInterface.User.GetUserIntiveInfoById({
      searchId: this.userId as any,
      inviteCode: this.code
      // searchId: "648991831832395776"
    } as any)
    this.list = res.data;
  }

  private downBtn(codeClassName: string) {
    this.doCopy(codeClassName)
    setTimeout(() => {
      (this.$router.push({
        path: this.$AppRoutes.Receive,
      }), 100)
    })
  }




  private doCopy(codeClassName: string, popup?: boolean) {
    // try {
    //   if (document.execCommand) {
    //     console.log(document)
    //     console.log(document.execCommand)
    //     const input = document.createElement('input');
    //     document.body.appendChild(input);
    //     input.setAttribute('value', code);
    //     input.select();
    //     if (document.execCommand('copy')) {
    //       document.execCommand('copy');
    //       if (popup) {
    //         alert('复制成功');
    //       }
    //     }
    //     input.remove()
    //   } else {
    //     if (popup) {
    //       alert('复制失败');
    //     }
    //   }
    // } catch (error) {
    //   if (popup) {
    //     alert('复制失败');
    //   }
    // }

    let Clipboard = new clipboard(codeClassName)
    Clipboard.on('success', function (e) {
      // this.$toast("复制成功")
      Clipboard.destroy();
    })
    Clipboard.on("error", e => {
      // 不支持复制
      // console.log("该浏览器不支持自动复制");
      // 释放内存
      Clipboard.destroy();
    });
    this.copyTip = true;
    setTimeout(() => { this.copyTip = false }, 3000);
  }


  private width: number = 0
  private GetNum() {
    this.width = 15;
    return this.width;
  }

  private scroll: any
  personScroll() {
    this.$refs.child_scroll.style.width = this.GetNum() + "rem";
    this.$nextTick(() => {
      if (!this.scroll) {
        this.scroll = new BScroll(this.$refs.father_srcoll, {
          startX: 0,
          click: true,
          scrollX: true,//
          scrollY: false,
          eventPassthrough: "vertical",
        });
      } else {
        this.scroll.refresh();
      }
    });
  }//横向滚动

  private mounted() { }
  private created() {
    this.LoadList();
    this.$nextTick(() => {//回调横向滚动方法
      this.personScroll();
    })
  }
}