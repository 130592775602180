
/**
 * 生成随机数
 */
export const random = (length: number = 6) => {
  let result = Math.random().toString(36).substr(2).replace(/\d/g, '');
  while (result.length < length) {
    result += Math.random().toString(36).substr(2).replace(/\d/g, '');
  }
  return result.substr(0, length)
}
/**
 * Enhance Version of `Array.indexOf`
 */
export const FindIndex = function <T>(data: T[], equal: (item: T, index: number) => boolean): number {
  let index = -1;
  let current = 0, length = data.length;
  while (index == -1 && current < length) {
    if (equal(data[current], current)) {
      index = current;
    }
    current++;
  }
  return index;
}

/**
 * remove keys in `data` who's value is in `values`
 */
export const FilterValue = <T>(data: T, values: any[] = ["", null]): Partial<T> => {
  let _result: Partial<T> = {};
  Object.keys(data).forEach(key => {
    let val = (<any>data)[key];
    if (typeof val == "object") {
      let _val = FilterValue(val, values);
      if (Object.keys(_val).length > 0) {
        (<any>_result)[key] = _val;
      }
    } else {
      if (values.every(i => i != val)) {
        (<any>_result)[key] = val;
      }
    }
  })
  return _result;
}

/**
 * make vue binded data static
 */
export const pure = function <T>(data: T): T {
  return JSON.parse(JSON.stringify(data))
}

export function clone<T>(obj: T): T {
  let o: any;
  if (typeof obj == "object") {
    if (obj === null) {
      o = null;
    } else {
      if (obj instanceof Array) {
        o = [];
        for (var i = 0, len = obj.length; i < len; i++) {
          o.push(clone(obj[i]));
        }
      } else {
        o = {};
        for (var j in obj) {
          o[j] = clone(obj[j]);
        }
      }
    }
  } else {
    o = obj;
  }
  return o;
}

export function weekEqual(origin: any, current: any): boolean {
  var o1 = origin instanceof Object;
  var o2 = current instanceof Object;
  // 判断是不是对象
  if (!o1 || !o2) {
    return origin === current;
  }

  //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,
  //例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  if (Object.keys(origin).length !== Object.keys(current).length) {
    return false;
  }

  for (var o in origin) {
    var t1 = origin[o] instanceof Object;
    var t2 = current[o] instanceof Object;
    if (t1 && t2) {
      return weekEqual(origin[o], current[o]);
    } else if (origin[o] !== current[o]) {
      return false;
    }
  }
  return true;

}

export function price(price: number | string | undefined | null, fixed: number = 2) {
  if (price == undefined || price == null) return 0.00;
  if (typeof price == "string") {
    if (isNaN(parseInt(price))) {
      return 0.00
    }
    price = parseInt(price)
  }
  return parseFloat(price.toString()).toFixed(fixed)
}
class Cacher {
  private _cache: { [x: string]: Promise<any> | any } = {}
  private _cacheTime: { [x: string]: number } = {}
  constructor(private max: number = 1000) { }

  cache(instance: any, args: any[]) {
    let key = this.parse(args);
    this._cache[key] = instance;
    if (instance.then) {
      (instance as Promise<any>)
        .then(() => {
          setTimeout(() => {
            delete this._cache[key]
          }, this.max);
        })
        .catch(() => delete this._cache[key])
    } else {
      setTimeout(() => {
        delete this._cache[key]
      }, this.max);
    }
  }

  cached(...args: any[]): any {
    let key = this.parse(args);
    // console.info(key)
    let cache = this._cache[key];
    return cache;
  }

  parse(arg: any, index: number = 0): string {
    if (Array.isArray(arg)) {
      return arg.map(a => this.parse(a, index + 1)).join("&")
    } else if (typeof arg == "object") {
      let keys = Object.keys(arg).sort();
      return keys.map(key => {
        return `${key}.${index}=${this.parse(arg[key], index + 1)}`
      }).join("&")
    } else {
      return arg.toString()
    }
  }
}
export const PromiseSingleton = <TFunction extends Function>(Func: TFunction): TFunction => {
  const cache = new Cacher();
  return function (...args: any[]) {
    let instance = cache.cached(...args);
    if (instance) return instance;
    let result = Func(...args);
    cache.cache(result, args);
    return result;
  } as any

}