import Vue from "vue";
// import './hack'
import CahceManager from './cache/index'
import eventCenter from './event-center'
import utils from './utils/plugin-install'
import ResizeHandler from './resize'
import AjaxPlugin from './ajax'
export { Ajax } from "./ajax/ajax"
import { AjaxGet, AjaxPost, AjaxUpload, AjaxForm } from "./ajax"

export type AjaxForm<Response = any, Data = any, Format = any> = AjaxForm<Response, Data, Format>;
export type AjaxGet<Response = any, Data = any, Format = any> = AjaxGet<Response, Data, Format>;
export type AjaxUpload<Response = any, Data = any, Format = any> = AjaxUpload<Response, Data, Format>;
export type AjaxPost<Response = any, Data = any, Format = any> = AjaxPost<Response, Data, Format>;

export const InstallFireWeedCommon = (vue: typeof Vue) => {
  vue.use(eventCenter)
  vue.use(utils)
  vue.use(CahceManager)
  vue.use(ResizeHandler)
  vue.use(AjaxPlugin);
}
// export * from './element';

export * from "./internal"

export * from "./pages/componnet"
export * from "./pages/base"
// export * from "./pages/popup-page"