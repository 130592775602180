

export namespace Arrays {
  export type TreedItem<T> = T & {
    children?: TreedItem<T>[]
  }

  export function loopListInStep<T, I, R>(
    list: T[], step: number,
    itemCall: (item: T, index: number) => I,
    stepCall: (stepItems: I[], index: number) => R,
    emptyCall?: (index: number) => I,
  ): R[] {
    let currentIndex = 0;
    let items: I[] = [];
    let results: R[] = []
    while (currentIndex < list.length) {
      items.push(itemCall(list[currentIndex], currentIndex))
      currentIndex++;
      if (currentIndex % step == 0) {
        results.push(stepCall(items, currentIndex))
        items = [];
      }
    }
    if (currentIndex % step != 0) {
      let index = 0;
      while (currentIndex % step != 0) {
        if (emptyCall) {
          items.push(emptyCall(index));
        } else {
          items.push(itemCall(undefined as any, index))
        }

        index++;
        currentIndex++;
      }
      results.push(stepCall(items, currentIndex))
    }
    return results;

  }

  export function loopListByStep<T, I, R>(
    list: T[], step: number,
    itemCall: (item: T, index: number) => I[],
    stepCall: (stepItems: I[], index: number) => R,
    emptyCall?: (index: number) => I[],
  ): R[] {
    let currentIndex = 0;
    let items: I[] = [];
    let results: R[] = []
    while (currentIndex < list.length) {
      items = items.concat(itemCall(list[currentIndex], currentIndex))
      currentIndex++;
      if (currentIndex % step == 0) {
        results.push(stepCall(items, currentIndex))
        items = [];
      }
    }
    if (currentIndex % step != 0) {
      let index = 0;
      while (currentIndex % step != 0) {
        if (emptyCall) {
          items = items.concat(emptyCall(index));
        } else {
          items = items.concat(itemCall(undefined as any, index))
        }

        index++;
        currentIndex++;
      }
      results.push(stepCall(items, currentIndex))
    }
    return results;

  }

  export function replace<T>(list: T[], index: number, item: T) {
    list.splice(index, 1, item)
  }

  export function move<T>(list: T[], currentIndex: number, toIndex: number) {
    if (currentIndex > list.length - 1 || currentIndex < 0) {
      return currentIndex;
    }
    if (toIndex > list.length - 1 || toIndex < 0) {
      return currentIndex;
    }
    let item = list.splice(currentIndex, 1)[0];
    list.splice(toIndex, 0, item)
    return toIndex;
  }

  export function remove<T>(list: T[], indexOrItem: number | T) {
    if (typeof indexOrItem == "number") {
      list.splice(indexOrItem, 1)
    } else if (HasItem(list, indexOrItem)) {
      list.splice(FindIndex(list, indexOrItem as any), 1)
    }
  }

  /**
 * Enhance Version of `Array.indexOf`
 */
  export const FindIndex = function <T>(
    data: T[],
    equal: (item: T, index: number) => boolean
  ): number {
    let index = -1;
    let current = 0, length = data.length;
    while (index == -1 && current < length) {
      if (equal(data[current], current)) {
        index = current;
        break;
      }
      current++;
    }
    return index;
  }
  /**
   * Enhance Version of `Array.indexOf`
   */
  export const HasItem = function <T>(
    data: T[],
    equal: ((item: T, index: number) => boolean) | T
  ): boolean {
    let index = -1;
    let current = 0, length = data.length;
    let eq: ((item: T, index: number) => boolean)
    if (typeof equal == "function") {
      eq = equal as any;
    } else {
      eq = (item: T, index: number) => equal == item;
    }
    while (index == -1 && current < length) {
      if (eq(data[current], current)) {
        index = current;
      }
      current++;
    }
    return index != -1;
  }
  /**
   * find items in neasted list
   * @example
   *  let items = [{name:"1",children:[{name:"2"},{name:"3"}]},{name:"4"}]
   *  let item = FindUntil(items,t=>t.children,t=>t.name=="3")
   *
   */
  export function FindUntil<T>(
    items: T[],
    equal: (item: T) => boolean,
    lop: (item: T) => T[] = (t: any) => t.children,
  ): T | null {
    let result: T | null = null;
    let max = items.length;
    let i = 0;
    while (result == null && i < max) {
      let current = items[i];
      if (equal(current)) {
        result = current
      } else {
        let childrens = lop(current) || [];
        result = FindUntil(childrens, equal, lop);
      }
      i++;
    }
    return result;
  }

  /**
 * transfer list to tree
 */
  export function transferList2Tree<T>(
    list: T[],
    isChild: (parent: T | undefined, item: T) => boolean,
    options: { maxdeep: number } = { maxdeep: 2 },
    parent?: T
  ): TreedItem<T>[] {
    return list.filter(item => isChild(parent, item)).map((item, index) => {
      let result: TreedItem<T> = item;
      let children = transferList2Tree(list, isChild, options, item);
      if (children.length) result.children = children;
      return item;
    })
  }

}
