/*
* @Author: zhoupeng
* @Date:   2017-11-15 18:05:52
 * @Last Modified by: zhoupeng
 * @Last Modified time: 2019-11-26 14:33:04
*/
import * as common from './common';
import * as data from './data';
import * as date from './date';
import { debounce, throttle } from './debounce';
import * as dom from './dom';
import env from './enviroment';
import * as filter from './filter';
import win from './window';
import * as vue from "./vue"
import { Arrays } from "./arrays"
import { Trees } from "./tree"
const utils = {
  filter,
  win,
  env,
  ...date,
  ...dom,
  ...common,
  data,
  debounce,
  throttle,
  vue,
  Arrays,
  Trees
}

export default utils;


