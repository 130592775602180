import { AjaxGet, AjaxPost, AjaxUpload, AjaxForm, Ajax } from "internal"

/**
 * 返回类
 */
export type AppResponse<T = any> = {
  /**
   * 返回数据对象
   */
  data: T;
  /**
   * 备注原因
   */
  msg: string;
  /**
   * 状态码: 200 成功, 201 失败, 202 需要登陆
   */
  rescode: number;
  /**
   * 状态: ok 成功, fail 失败
   */
  result: string;
};
/**
 * 数据列表
 */
export interface AppListResponse<T = any> {
  /**
   * 返回数据对象
   */
  dataList: T[];
}
/**
 * 分页数据列表
 */
export interface AppPageListResponse<T = any> extends AppListResponse<T> {
  /**
   * 当前页码
   */
  pageNum: number;
  /**
   * 分页参数
   */
  pageSize: number;
  /**
   * 总条数
   */
  totalCount: number;
}
/**
 * 分页数据列表
 */
export interface AppMultiplePageListResponse<T = any> extends AppListResponse<T> {
  /**
   * 当前页码
   */
  pageNum: number;
  /**
   * 分页参数
   */
  pageSize: number;
  /**
   * 总条数
   */
  totalCount: number;
}
export interface AppScrollPageListResponse<T = any> extends AppPageListResponse<T> {
  /**
   * 滚动Id
   */
  scrollId: string;
}
export namespace AppInterface {
  /**
   * 商品分类树形查询
   */
  export type ShopGoodsClassTreeVo = {
    id: number;
    name: string;
    typeId?: number;
    children: ShopGoodsClassTreeVo[];
  }
}



export namespace AppInterface {
    /**
     * 日记和鸡汤接口
     */
    export namespace EpilogueAndChicken {
        /**
         * OK
         */
        export type AddEpilogueResponse = AppResponse<EfficiencyDailyNotesVo>;
        /**
         * form
         */
        export type AddEpilogueData = EfficiencyDailyNotesCreateForm;
        const AddEpilogueUrl: AjaxPost<AddEpilogueResponse, AddEpilogueData> = "/EpilogueAndChicken/addEpilogue";
        /**
         * 新增每日一记
         */
        export const AddEpilogue = (data: AddEpilogueData) => Ajax.post(AddEpilogueUrl, data);
        /**
         * OK
         */
        export type QuerCalendarResponse = AppResponse<EfficiencyDailyDateVo>;
        /**
         * form
         */
        export type QuerCalendarData = EfficiencyChickenSoupQueryForm;
        const QuerCalendarUrl: AjaxPost<QuerCalendarResponse, QuerCalendarData> = "/EpilogueAndChicken/querCalendar";
        /**
         * 查询日历
         */
        export const QuerCalendar = (data: QuerCalendarData) => Ajax.post(QuerCalendarUrl, data);
        /**
         * OK
         */
        export type QueryChickenSoupResponse = AppResponse<AppListResponse<EfficiencyChickenSoupVo>>;
        /**
         * form
         */
        export type QueryChickenSoupData = EfficiencyChickenSoupQueryForm;
        const QueryChickenSoupUrl: AjaxPost<QueryChickenSoupResponse, QueryChickenSoupData> = "/EpilogueAndChicken/queryChickenSoup";
        /**
         * 查询系统鸡汤表列表
         */
        export const QueryChickenSoup = (data: QueryChickenSoupData) => Ajax.post(QueryChickenSoupUrl, data);
        /**
         * OK
         */
        export type QueryChickenSoupByOneDayResponse = AppResponse<EfficiencyChickenSoupVo>;
        /**
         * form
         */
        export type QueryChickenSoupByOneDayData = EfficiencyChickenSoupQueryForm;
        const QueryChickenSoupByOneDayUrl: AjaxPost<QueryChickenSoupByOneDayResponse, QueryChickenSoupByOneDayData> = "/EpilogueAndChicken/queryChickenSoupByOneDay";
        /**
         * 查询单日系统鸡汤
         */
        export const QueryChickenSoupByOneDay = (data: QueryChickenSoupByOneDayData) => Ajax.post(QueryChickenSoupByOneDayUrl, data);
        /**
         * OK
         */
        export type QueryDailyByIdResponse = AppResponse<EfficiencyDailyNotesVo>;
        export type QueryDailyByIdData = {
            /**
             * 日记id
             */
            id: number;
        };
        const QueryDailyByIdUrl: AjaxGet<QueryDailyByIdResponse, QueryDailyByIdData> = "/EpilogueAndChicken/queryDailyById";
        /**
         * 查询每日一记表
         */
        export const QueryDailyById = (data: QueryDailyByIdData) => Ajax.get(QueryDailyByIdUrl, data);
        /**
         * OK
         */
        export type QueryUserDailyByDateResponse = AppResponse<EfficiencyDailyNotesVo>;
        export type QueryUserDailyByDateData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * datetime
             */
            datetime: string;
        };
        const QueryUserDailyByDateUrl: AjaxGet<QueryUserDailyByDateResponse, QueryUserDailyByDateData> = "/EpilogueAndChicken/queryUserDailyByDate";
        /**
         * 根据日期查询每日一记
         */
        export const QueryUserDailyByDate = (data: QueryUserDailyByDateData) => Ajax.get(QueryUserDailyByDateUrl, data);
        /**
         * OK
         */
        export type UpdateDelResponse = AppResponse;
        /**
         * form
         */
        export type UpdateDelData = EfficiencyDailyNotesUpdateForm;
        const UpdateDelUrl: AjaxPost<UpdateDelResponse, UpdateDelData> = "/EpilogueAndChicken/updateDel";
        /**
         * 逻辑删除每日一记
         */
        export const UpdateDel = (data: UpdateDelData) => Ajax.post(UpdateDelUrl, data);
        /**
         * OK
         */
        export type UpdateEpilogueResponse = AppResponse;
        /**
         * form
         */
        export type UpdateEpilogueData = EfficiencyDailyNotesUpdateForm;
        const UpdateEpilogueUrl: AjaxPost<UpdateEpilogueResponse, UpdateEpilogueData> = "/EpilogueAndChicken/updateEpilogue";
        /**
         * 修改每日一记表
         */
        export const UpdateEpilogue = (data: UpdateEpilogueData) => Ajax.post(UpdateEpilogueUrl, data);
    }
    /**
     * app轮播图
     */
    export namespace ShopBannerImage {
        /**
         * OK
         */
        export type QueryResponse = AppResponse<ShopBannerImageVo>;
        export type QueryData = {
            /**
             * id
             */
            id: number;
        };
        const QueryUrl: AjaxGet<QueryResponse, QueryData> = "/app/banner/image/query";
        /**
         * 查询app轮播图
         */
        export const Query = (data: QueryData) => Ajax.get(QueryUrl, data);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<ShopBannerImageVo>>;
        export type QueryListData = {
            /**
             * 列表
             */
            idList?: number[];
            typeList?: string[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 0:失效，1：生效
             */
            activite?: number;
            /**
             * 描述
             */
            description?: string;
            id?: number;
            /**
             * 图片链接
             */
            image?: string;
            /**
             * 跳转参数
             */
            jumpArguments?: string;
            /**
             * 跳转类型
             */
            jumpType?: string;
            /**
             * 排序
             */
            sort?: number;
            /**
             * 'shop','cycle'
             */
            type?: string;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/app/banner/image/queryList";
        /**
         * 查询app轮播图列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
    }
    /**
     * Shop App Update Controller
     */
    export namespace ShopAppUpdate {
        /**
         * OK
         */
        export type QueryNewVersionResponse = AppResponse<ShopAppVersionUpdateVo>;
        export type QueryNewVersionData = {
            /**
             * 当前版本号
             */
            version: string;
            /**
             * app类型 1安卓 2ios
             */
            type: number;
        };
        const QueryNewVersionUrl: AjaxGet<QueryNewVersionResponse, QueryNewVersionData> = "/app/update/queryNewVersion";
        /**
         * 查询是否需要版本更新
         */
        export const QueryNewVersion = (data: QueryNewVersionData) => Ajax.get(QueryNewVersionUrl, data);
    }
    /**
     * 地区接口
     */
    export namespace ShopArea {
        /**
         * OK
         */
        export type QueryAllCityListResponse = AppResponse<AppListResponse<ShopAreaVo>>;
        const QueryAllCityListUrl: AjaxGet<QueryAllCityListResponse, never> = "/area/queryAllCityList";
        /**
         * 查询城市列表 包含直辖市
         */
        export const QueryAllCityList = () => Ajax.get(QueryAllCityListUrl);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<ShopAreaVo>>;
        export type QueryListData = {
            /**
             * 索引ID列表
             */
            areaIdList?: string[];
            /**
             * 地区父ID列表
             */
            areaParentIdList?: string[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 地区深度，从1开始
             */
            areaDeep?: number;
            /**
             * 索引ID
             */
            areaId?: string;
            /**
             * 地区首字母
             */
            areaIndexWord?: string;
            /**
             * 地区名称
             */
            areaName?: string;
            /**
             * 地区父ID
             */
            areaParentId?: string;
            /**
             * 排序
             */
            areaSort?: number;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/area/queryList";
        /**
         * 查询地区表列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
        /**
         * OK
         */
        export type VerifyCityResponse = AppResponse<ShopAreaVo>;
        /**
         * form
         */
        export type VerifyCityData = ShopAreaQueryForm;
        const VerifyCityUrl: AjaxPost<VerifyCityResponse, VerifyCityData> = "/area/verifyCity";
        /**
         * 验证市地址是否正确
         */
        export const VerifyCity = (data: VerifyCityData) => Ajax.post(VerifyCityUrl, data);
    }
    /**
     * 数据接口
     */
    export namespace Data {
        /**
         * OK
         */
        export type AddMaidianDataResponse = AppResponse;
        /**
         * form
         */
        export type AddMaidianDataData = MaidianCreateForm;
        const AddMaidianDataUrl: AjaxPost<AddMaidianDataResponse, AddMaidianDataData> = "/data/addMaidianData";
        /**
         * 新增埋点数据
         */
        export const AddMaidianData = (data: AddMaidianDataData) => Ajax.post(AddMaidianDataUrl, data);
        /**
         * OK
         */
        export type AppIsAliveResponse = AppResponse;
        const AppIsAliveUrl: AjaxGet<AppIsAliveResponse, never> = "/data/appIsAlive";
        /**
         * 验证APP是否存活
         */
        export const AppIsAlive = () => Ajax.get(AppIsAliveUrl);
        /**
         * OK
         */
        export type GetHotSearchWordResponse = AppResponse<HotDataVo>;
        const GetHotSearchWordUrl: AjaxGet<GetHotSearchWordResponse, never> = "/data/getHotSearchWord";
        /**
         * 获取热门搜索数据
         */
        export const GetHotSearchWord = () => Ajax.get(GetHotSearchWordUrl);
    }
    /**
     * 效率接口
     */
    export namespace Efficiency {
        /**
         * OK
         */
        export type AddAbsorbedNumResponse = AppResponse;
        const AddAbsorbedNumUrl: AjaxPost<AddAbsorbedNumResponse, never> = "/efficiency/addAbsorbedNum";
        /**
         * 增加同地区专注人数
         */
        export const AddAbsorbedNum = () => Ajax.post(AddAbsorbedNumUrl);
        /**
         * OK
         */
        export type AddBackgrounmdImageResponse = AppResponse<EfficiencyBackgroundImageVo>;
        /**
         * form
         */
        export type AddBackgrounmdImageData = EfficiencyBackgroundImageCreateForm;
        const AddBackgrounmdImageUrl: AjaxPost<AddBackgrounmdImageResponse, AddBackgrounmdImageData> = "/efficiency/addBackgrounmdImage";
        /**
         * 新增项目背景图片
         */
        export const AddBackgrounmdImage = (data: AddBackgrounmdImageData) => Ajax.post(AddBackgrounmdImageUrl, data);
        /**
         * OK
         */
        export type AddEfficiencyEpilogueResponse = AppResponse<EfficiencyEpilogueVo>;
        /**
         * form
         */
        export type AddEfficiencyEpilogueData = EfficiencyEpilogueCreateForm;
        const AddEfficiencyEpilogueUrl: AjaxPost<AddEfficiencyEpilogueResponse, AddEfficiencyEpilogueData> = "/efficiency/addEfficiencyEpilogue";
        /**
         * 新增后记表
         */
        export const AddEfficiencyEpilogue = (data: AddEfficiencyEpilogueData) => Ajax.post(AddEfficiencyEpilogueUrl, data);
        /**
         * OK
         */
        export type AddEpilogueResponse = AppResponse<EfficiencyDailyNotesVo>;
        /**
         * form
         */
        export type AddEpilogueData = EfficiencyDailyNotesCreateForm;
        const AddEpilogueUrl: AjaxPost<AddEpilogueResponse, AddEpilogueData> = "/efficiency/addEpilogue";
        /**
         * 新增每日一记
         */
        export const AddEpilogue = (data: AddEpilogueData) => Ajax.post(AddEpilogueUrl, data);
        /**
         * OK
         */
        export type AddGoPlanResponse = AppResponse<EfficiencyGoalPlanVo>;
        /**
         * form
         */
        export type AddGoPlanData = EfficiencyGoalPlanCreateForm;
        const AddGoPlanUrl: AjaxPost<AddGoPlanResponse, AddGoPlanData> = "/efficiency/addGoPlan";
        /**
         * 新增目标计划表
         */
        export const AddGoPlan = (data: AddGoPlanData) => Ajax.post(AddGoPlanUrl, data);
        /**
         * OK
         */
        export type AddHabitMergerResponse = AppResponse;
        /**
         * form
         */
        export type AddHabitMergerData = EfficiencyHabitMergerCreateForm;
        const AddHabitMergerUrl: AjaxPost<AddHabitMergerResponse, AddHabitMergerData> = "/efficiency/addHabitMerger";
        /**
         * 新增一条合并记录
         */
        export const AddHabitMerger = (data: AddHabitMergerData) => Ajax.post(AddHabitMergerUrl, data);
        /**
         * OK
         */
        export type AddHabitsListByUserResponse = AppResponse<EfficiencyUserHabitsVo>;
        /**
         * form
         */
        export type AddHabitsListByUserData = EfficiencyUserHabitsCreateForm;
        const AddHabitsListByUserUrl: AjaxPost<AddHabitsListByUserResponse, AddHabitsListByUserData> = "/efficiency/addHabitsListByUser";
        /**
         * 用户自己批量新增习惯
         */
        export const AddHabitsListByUser = (data: AddHabitsListByUserData) => Ajax.post(AddHabitsListByUserUrl, data);
        /**
         * OK
         */
        export type AddJournalResponse = AppResponse<EfficiencyTargetJournalVo>;
        /**
         * form
         */
        export type AddJournalData = EfficiencyTargetJournalCreateForm;
        const AddJournalUrl: AjaxPost<AddJournalResponse, AddJournalData> = "/efficiency/addJournal";
        /**
         * 新增日志表
         */
        export const AddJournal = (data: AddJournalData) => Ajax.post(AddJournalUrl, data);
        /**
         * OK
         */
        export type AddJournalCommentResponse = AppResponse;
        /**
         * form
         */
        export type AddJournalCommentData = EfficiencyCommentJournalCreateForm;
        const AddJournalCommentUrl: AjaxPost<AddJournalCommentResponse, AddJournalCommentData> = "/efficiency/addJournalComment";
        /**
         * 新增日志评论
         */
        export const AddJournalComment = (data: AddJournalCommentData) => Ajax.post(AddJournalCommentUrl, data);
        /**
         * OK
         */
        export type AddNeedHandleResponse = AppResponse<EfficiencyNeedhandleVo>;
        /**
         * form
         */
        export type AddNeedHandleData = EfficiencyNeedhandleCreateForm;
        const AddNeedHandleUrl: AjaxPost<AddNeedHandleResponse, AddNeedHandleData> = "/efficiency/addNeedHandle";
        /**
         * 新增待办表2.0
         */
        export const AddNeedHandle = (data: AddNeedHandleData) => Ajax.post(AddNeedHandleUrl, data);
        /**
         * OK
         */
        export type AddNeedhandleResponse = AppResponse<EfficiencyNeedhandleVo>;
        /**
         * form
         */
        export type AddNeedhandleData = EfficiencyNeedhandleCreateForm;
        const AddNeedhandleUrl: AjaxPost<AddNeedhandleResponse, AddNeedhandleData> = "/efficiency/addNeedhandle";
        /**
         * 新增待办表
         */
        export const AddNeedhandle = (data: AddNeedhandleData) => Ajax.post(AddNeedhandleUrl, data);
        /**
         * OK
         */
        export type AddRecordingTimeResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type AddRecordingTimeData = EfficiencyRecordingTimeCreateForm;
        const AddRecordingTimeUrl: AjaxPost<AddRecordingTimeResponse, AddRecordingTimeData> = "/efficiency/addRecordingTime";
        /**
         * 新增计时表
         */
        export const AddRecordingTime = (data: AddRecordingTimeData) => Ajax.post(AddRecordingTimeUrl, data);
        /**
         * OK
         */
        export type AddTargetResponse = AppResponse<EfficiencyTargetVo>;
        /**
         * form
         */
        export type AddTargetData = EfficiencyTargetCreateForm;
        const AddTargetUrl: AjaxPost<AddTargetResponse, AddTargetData> = "/efficiency/addTarget";
        /**
         * 新增目标表
         */
        export const AddTarget = (data: AddTargetData) => Ajax.post(AddTargetUrl, data);
        /**
         * OK
         */
        export type AddWeekPlanResponse = AppResponse<EfficiencyWeekPlanVo>;
        /**
         * form
         */
        export type AddWeekPlanData = EfficiencyWeekPlanCreateForm;
        const AddWeekPlanUrl: AjaxPost<AddWeekPlanResponse, AddWeekPlanData> = "/efficiency/addWeekPlan";
        /**
         * 新增周计划表
         */
        export const AddWeekPlan = (data: AddWeekPlanData) => Ajax.post(AddWeekPlanUrl, data);
        /**
         * OK
         */
        export type DelJournalCommentResponse = AppResponse;
        /**
         * form
         */
        export type DelJournalCommentData = EfficiencyCommentDelCondition;
        const DelJournalCommentUrl: AjaxPost<DelJournalCommentResponse, DelJournalCommentData> = "/efficiency/delJournalComment";
        /**
         * 新增日志评论
         */
        export const DelJournalComment = (data: DelJournalCommentData) => Ajax.post(DelJournalCommentUrl, data);
        /**
         * OK
         */
        export type DeleteBackgroundImageResponse = AppResponse;
        /**
         * form
         */
        export type DeleteBackgroundImageData = NeedhandleDeleteForm;
        const DeleteBackgroundImageUrl: AjaxPost<DeleteBackgroundImageResponse, DeleteBackgroundImageData> = "/efficiency/deleteBackgroundImage";
        /**
         * 删除项目背景图片
         */
        export const DeleteBackgroundImage = (data: DeleteBackgroundImageData) => Ajax.post(DeleteBackgroundImageUrl, data);
        /**
         * OK
         */
        export type DeleteJournalResponse = AppResponse;
        /**
         * needhandleDeleteForm
         */
        export type DeleteJournalData = NeedhandleDeleteForm;
        const DeleteJournalUrl: AjaxPost<DeleteJournalResponse, DeleteJournalData> = "/efficiency/deleteJournal";
        /**
         * 删除日志表
         */
        export const DeleteJournal = (data: DeleteJournalData) => Ajax.post(DeleteJournalUrl, data);
        /**
         * OK
         */
        export type DeleteNeedhandleResponse = AppResponse;
        /**
         * needhandleDeleteForm
         */
        export type DeleteNeedhandleData = NeedhandleDeleteForm;
        const DeleteNeedhandleUrl: AjaxPost<DeleteNeedhandleResponse, DeleteNeedhandleData> = "/efficiency/deleteNeedhandle";
        /**
         * 逻辑删除删除待办
         */
        export const DeleteNeedhandle = (data: DeleteNeedhandleData) => Ajax.post(DeleteNeedhandleUrl, data);
        /**
         * OK
         */
        export type DeleteNeedhandleThreeResponse = AppResponse;
        /**
         * efficiencyNeedhandleDeleteForm
         */
        export type DeleteNeedhandleThreeData = EfficiencyNeedhandleDeleteForm;
        const DeleteNeedhandleThreeUrl: AjaxPost<DeleteNeedhandleThreeResponse, DeleteNeedhandleThreeData> = "/efficiency/deleteNeedhandleThree";
        /**
         * 逻辑删除删除待办3.0
         */
        export const DeleteNeedhandleThree = (data: DeleteNeedhandleThreeData) => Ajax.post(DeleteNeedhandleThreeUrl, data);
        /**
         * OK
         */
        export type DeleteNeedhandleTwoResponse = AppResponse;
        /**
         * needhandleDeleteForm
         */
        export type DeleteNeedhandleTwoData = NeedhandleDeleteForm;
        const DeleteNeedhandleTwoUrl: AjaxPost<DeleteNeedhandleTwoResponse, DeleteNeedhandleTwoData> = "/efficiency/deleteNeedhandleTwo";
        /**
         * 逻辑删除删除待办2.0
         */
        export const DeleteNeedhandleTwo = (data: DeleteNeedhandleTwoData) => Ajax.post(DeleteNeedhandleTwoUrl, data);
        /**
         * OK
         */
        export type DeleteTargetResponse = AppResponse;
        /**
         * form
         */
        export type DeleteTargetData = EfficiencyTargetUpdateForm;
        const DeleteTargetUrl: AjaxPost<DeleteTargetResponse, DeleteTargetData> = "/efficiency/deleteTarget";
        /**
         * 删除目标表
         */
        export const DeleteTarget = (data: DeleteTargetData) => Ajax.post(DeleteTargetUrl, data);
        /**
         * OK
         */
        export type DeleteUserHabitsResponse = AppResponse;
        /**
         * form
         */
        export type DeleteUserHabitsData = EfficiencyUserHabitsUpdateForm;
        const DeleteUserHabitsUrl: AjaxPost<DeleteUserHabitsResponse, DeleteUserHabitsData> = "/efficiency/deleteUserHabits";
        /**
         * 删除用户习惯
         */
        export const DeleteUserHabits = (data: DeleteUserHabitsData) => Ajax.post(DeleteUserHabitsUrl, data);
        /**
         * OK
         */
        export type DiyAddUserHabitResponse = AppResponse<EfficiencyUserHabitsVo>;
        /**
         * form
         */
        export type DiyAddUserHabitData = EfficiencyUserHabitsCreateForm;
        const DiyAddUserHabitUrl: AjaxPost<DiyAddUserHabitResponse, DiyAddUserHabitData> = "/efficiency/diyAddUserHabit";
        /**
         * 自定义新增用户习惯表
         */
        export const DiyAddUserHabit = (data: DiyAddUserHabitData) => Ajax.post(DiyAddUserHabitUrl, data);
        /**
         * OK
         */
        export type DragSortNeedHandleResponse = AppResponse;
        /**
         * form
         */
        export type DragSortNeedHandleData = EfficiencyNeedhandleUpdateForm;
        const DragSortNeedHandleUrl: AjaxPost<DragSortNeedHandleResponse, DragSortNeedHandleData> = "/efficiency/dragSortNeedHandle";
        /**
         * 待办拖动排序
         */
        export const DragSortNeedHandle = (data: DragSortNeedHandleData) => Ajax.post(DragSortNeedHandleUrl, data);
        /**
         * OK
         */
        export type GetHabitsByHNameResponse = AppResponse<AppListResponse<EfficiencySysHabitsVo>>;
        /**
         * form
         */
        export type GetHabitsByHNameData = EfficiencyUserHabitsCreateForm;
        const GetHabitsByHNameUrl: AjaxPost<GetHabitsByHNameResponse, GetHabitsByHNameData> = "/efficiency/getHabitsByHName";
        /**
         * 根据习惯名称模糊查询系统习惯表
         */
        export const GetHabitsByHName = (data: GetHabitsByHNameData) => Ajax.post(GetHabitsByHNameUrl, data);
        /**
         * OK
         */
        export type GetLunarCalendarByDateResponse = AppResponse<AppListResponse<EfficiencyNeedhandleCalendarListVo>>;
        export type GetLunarCalendarByDateData = {
            /**
             * 指定日期
             */
            time: string;
        };
        const GetLunarCalendarByDateUrl: AjaxGet<GetLunarCalendarByDateResponse, GetLunarCalendarByDateData> = "/efficiency/getLunarCalendarByDate";
        /**
         * 根据指定日期获取阴历的日子
         */
        export const GetLunarCalendarByDate = (data: GetLunarCalendarByDateData) => Ajax.get(GetLunarCalendarByDateUrl, data);
        /**
         * OK
         */
        export type GetTargetInfoResponse = AppResponse<AppListResponse<EfficiencyTargetContent>>;
        export type GetTargetInfoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const GetTargetInfoUrl: AjaxGet<GetTargetInfoResponse, GetTargetInfoData> = "/efficiency/getTargetInfo";
        /**
         * 查看目标中各类内容
         */
        export const GetTargetInfo = (data: GetTargetInfoData) => Ajax.get(GetTargetInfoUrl, data);
        /**
         * OK
         */
        export type GiveTheThumbsUpJournalResponse = AppResponse;
        /**
         * form
         */
        export type GiveTheThumbsUpJournalData = PraiseInformationForm;
        const GiveTheThumbsUpJournalUrl: AjaxPost<GiveTheThumbsUpJournalResponse, GiveTheThumbsUpJournalData> = "/efficiency/giveTheThumbsUpJournal";
        /**
         * 点赞日志/取消
         */
        export const GiveTheThumbsUpJournal = (data: GiveTheThumbsUpJournalData) => Ajax.post(GiveTheThumbsUpJournalUrl, data);
        /**
         * OK
         */
        export type JournalFeedPointResponse = AppResponse;
        /**
         * form
         */
        export type JournalFeedPointData = DynamicFeedPointForm;
        const JournalFeedPointUrl: AjaxPost<JournalFeedPointResponse, JournalFeedPointData> = "/efficiency/journalFeedPoint";
        /**
         * 日志喂豆
         */
        export const JournalFeedPoint = (data: JournalFeedPointData) => Ajax.post(JournalFeedPointUrl, data);
        /**
         * OK
         */
        export type KuaiupdateNeedhandleResponse = AppResponse;
        /**
         * form
         */
        export type KuaiupdateNeedhandleData = EfficiencyNeedhandleUpdateForm;
        const KuaiupdateNeedhandleUrl: AjaxPost<KuaiupdateNeedhandleResponse, KuaiupdateNeedhandleData> = "/efficiency/kuaiupdateNeedhandle";
        /**
         * 编辑待办
         */
        export const KuaiupdateNeedhandle = (data: KuaiupdateNeedhandleData) => Ajax.post(KuaiupdateNeedhandleUrl, data);
        /**
         * OK
         */
        export type OverFocusStateResponse = AppResponse;
        export type OverFocusStateData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const OverFocusStateUrl: AjaxGet<OverFocusStateResponse, OverFocusStateData> = "/efficiency/overFocusState";
        /**
         * 结束用户专注计时状态
         */
        export const OverFocusState = (data: OverFocusStateData) => Ajax.get(OverFocusStateUrl, data);
        /**
         * OK
         */
        export type ProjectArchiveResponse = AppResponse;
        /**
         * form
         */
        export type ProjectArchiveData = EfficiencyTargetUpdateForm;
        const ProjectArchiveUrl: AjaxPost<ProjectArchiveResponse, ProjectArchiveData> = "/efficiency/projectArchive";
        /**
         * 项目归档
         */
        export const ProjectArchive = (data: ProjectArchiveData) => Ajax.post(ProjectArchiveUrl, data);
        /**
         * OK
         */
        export type QuerCalendarResponse = AppResponse<EfficiencyDailyDateVo>;
        /**
         * form
         */
        export type QuerCalendarData = EfficiencyChickenSoupQueryForm;
        const QuerCalendarUrl: AjaxPost<QuerCalendarResponse, QuerCalendarData> = "/efficiency/querCalendar";
        /**
         * 查询日历
         */
        export const QuerCalendar = (data: QuerCalendarData) => Ajax.post(QuerCalendarUrl, data);
        /**
         * OK
         */
        export type QuerTwoLevelyListResponse = AppResponse<EfficiencyJournalCommentVo>;
        export type QuerTwoLevelyListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            dynamicId?: string;
            fromDate?: string;
            orderBy?: string;
            pageNum?: number;
            pageSize?: number;
            searchNameValue?: string;
            toDate?: string;
            totalCount?: number;
            type?: number;
            userId?: string;
        };
        const QuerTwoLevelyListUrl: AjaxGet<QuerTwoLevelyListResponse, QuerTwoLevelyListData> = "/efficiency/querTwoLevelyList";
        /**
         * 查询二级评论列表
         */
        export const QuerTwoLevelyList = (data: QuerTwoLevelyListData) => Ajax.get(QuerTwoLevelyListUrl, data);
        /**
         * OK
         */
        export type QueryAbsorbedNumResponse = AppResponse<number>;
        export type QueryAbsorbedNumData = {
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 地区id
             */
            cityId?: number;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 0榜上 1榜下
             */
            isup?: number;
            /**
             * 榜单类型 1总榜 2日榜 3周榜 4月榜
             */
            listType?: number;
            /**
             * 待办id
             */
            nhId?: number;
            /**
             * 待办类型 0普通待办 1重复待办
             */
            nhType?: number;
            /**
             * 用户ID
             */
            uid?: string;
        };
        const QueryAbsorbedNumUrl: AjaxGet<QueryAbsorbedNumResponse, QueryAbsorbedNumData> = "/efficiency/queryAbsorbedNum";
        /**
         * 查询同地区专注人数
         */
        export const QueryAbsorbedNum = (data: QueryAbsorbedNumData) => Ajax.get(QueryAbsorbedNumUrl, data);
        /**
         * OK
         */
        export type QueryAbsorbedStateByUserResponse = AppResponse<number>;
        export type QueryAbsorbedStateByUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryAbsorbedStateByUserUrl: AjaxGet<QueryAbsorbedStateByUserResponse, QueryAbsorbedStateByUserData> = "/efficiency/queryAbsorbedStateByUser";
        /**
         * 查询某用户是否专注状态
         */
        export const QueryAbsorbedStateByUser = (data: QueryAbsorbedStateByUserData) => Ajax.get(QueryAbsorbedStateByUserUrl, data);
        /**
         * OK
         */
        export type QueryByFollowUserRecordSortResponse = AppResponse<AppPageListResponse<EfficiencyRecordingTimeVo>>;
        export type QueryByFollowUserRecordSortData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 排行榜类型 1总榜 2日榜3周榜 4月榜
             */
            listType?: number;
            /**
             * 页码
             */
            pageNum?: number;
            /**
             * 页尺寸
             */
            pageSize?: number;
        };
        const QueryByFollowUserRecordSortUrl: AjaxGet<QueryByFollowUserRecordSortResponse, QueryByFollowUserRecordSortData> = "/efficiency/queryByFollowUserRecordSort";
        /**
         * 查询计时专注排行
         */
        export const QueryByFollowUserRecordSort = (data: QueryByFollowUserRecordSortData) => Ajax.get(QueryByFollowUserRecordSortUrl, data);
        /**
         * OK
         */
        export type QueryByIdAndTypeResponse = AppResponse<EfficiencyNeedhandleVo>;
        export type QueryByIdAndTypeData = {
            /**
             * 所属时间
             */
            dateOfOwnership?: string;
            /**
             * 待办id
             */
            id?: number;
            /**
             * 父级待办id
             */
            nhId?: number;
            orderBy?: string;
            pageNum?: number;
            pageSize?: number;
            /**
             * 待办类型
             */
            type?: number;
        };
        const QueryByIdAndTypeUrl: AjaxGet<QueryByIdAndTypeResponse, QueryByIdAndTypeData> = "/efficiency/queryByIdAndType";
        /**
         * 根据id和类型查询单个待办
         */
        export const QueryByIdAndType = (data: QueryByIdAndTypeData) => Ajax.get(QueryByIdAndTypeUrl, data);
        /**
         * OK
         */
        export type QueryByIdAndTypeTwoResponse = AppResponse<EfficiencyNeedhandleVo>;
        export type QueryByIdAndTypeTwoData = {
            /**
             * 所属时间
             */
            dateOfOwnership?: string;
            /**
             * 待办id
             */
            id?: number;
            /**
             * 父级待办id
             */
            nhId?: number;
            orderBy?: string;
            pageNum?: number;
            pageSize?: number;
            /**
             * 待办类型
             */
            type?: number;
        };
        const QueryByIdAndTypeTwoUrl: AjaxGet<QueryByIdAndTypeTwoResponse, QueryByIdAndTypeTwoData> = "/efficiency/queryByIdAndTypeTwo";
        /**
         * 根据id和类型查询单个待办2.0
         */
        export const QueryByIdAndTypeTwo = (data: QueryByIdAndTypeTwoData) => Ajax.get(QueryByIdAndTypeTwoUrl, data);
        /**
         * OK
         */
        export type QueryByJournalIdResponse = AppResponse<EfficiencyTargetJournalVo>;
        export type QueryByJournalIdData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * es中日志id
             */
            efficiencyJournalId?: string;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 目标id
             */
            targetId?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryByJournalIdUrl: AjaxGet<QueryByJournalIdResponse, QueryByJournalIdData> = "/efficiency/queryByJournalId";
        /**
         * 查询日志表
         */
        export const QueryByJournalId = (data: QueryByJournalIdData) => Ajax.get(QueryByJournalIdUrl, data);
        /**
         * OK
         */
        export type QueryChickenSoupResponse = AppResponse<AppListResponse<EfficiencyChickenSoupVo>>;
        /**
         * form
         */
        export type QueryChickenSoupData = EfficiencyChickenSoupQueryForm;
        const QueryChickenSoupUrl: AjaxPost<QueryChickenSoupResponse, QueryChickenSoupData> = "/efficiency/queryChickenSoup";
        /**
         * 查询系统鸡汤表列表
         */
        export const QueryChickenSoup = (data: QueryChickenSoupData) => Ajax.post(QueryChickenSoupUrl, data);
        /**
         * OK
         */
        export type QueryChickenSoupByOneDayResponse = AppResponse<EfficiencyChickenSoupVo>;
        /**
         * form
         */
        export type QueryChickenSoupByOneDayData = EfficiencyChickenSoupQueryForm;
        const QueryChickenSoupByOneDayUrl: AjaxPost<QueryChickenSoupByOneDayResponse, QueryChickenSoupByOneDayData> = "/efficiency/queryChickenSoupByOneDay";
        /**
         * 查询单日系统鸡汤
         */
        export const QueryChickenSoupByOneDay = (data: QueryChickenSoupByOneDayData) => Ajax.post(QueryChickenSoupByOneDayUrl, data);
        /**
         * OK
         */
        export type QueryDailyByIdResponse = AppResponse<EfficiencyDailyNotesVo>;
        export type QueryDailyByIdData = {
            /**
             * 日记id
             */
            id: number;
        };
        const QueryDailyByIdUrl: AjaxGet<QueryDailyByIdResponse, QueryDailyByIdData> = "/efficiency/queryDailyById";
        /**
         * 查询每日一记表
         */
        export const QueryDailyById = (data: QueryDailyByIdData) => Ajax.get(QueryDailyByIdUrl, data);
        /**
         * OK
         */
        export type QueryFocusOnTimeWeeklyListResponse = AppResponse<AppPageListResponse<EfficiencyRecordingTimeVo>>;
        /**
         * form
         */
        export type QueryFocusOnTimeWeeklyListData = EfficiencyRecordingTimeQueryForm;
        const QueryFocusOnTimeWeeklyListUrl: AjaxPost<QueryFocusOnTimeWeeklyListResponse, QueryFocusOnTimeWeeklyListData> = "/efficiency/queryFocusOnTimeWeeklyList";
        /**
         * 查询计时排行周榜
         */
        export const QueryFocusOnTimeWeeklyList = (data: QueryFocusOnTimeWeeklyListData) => Ajax.post(QueryFocusOnTimeWeeklyListUrl, data);
        /**
         * OK
         */
        export type QueryHomePageInfoResponse = AppResponse<EfficiencyHomePageVO>;
        export type QueryHomePageInfoData = {
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 城市id
             */
            cityId?: number;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryHomePageInfoUrl: AjaxGet<QueryHomePageInfoResponse, QueryHomePageInfoData> = "/efficiency/queryHomePageInfo";
        /**
         * 效率首页
         */
        export const QueryHomePageInfo = (data: QueryHomePageInfoData) => Ajax.get(QueryHomePageInfoUrl, data);
        /**
         * OK
         */
        export type QueryHomePageUserInfoResponse = AppResponse<EfficiencyHomePageVO>;
        export type QueryHomePageUserInfoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryHomePageUserInfoUrl: AjaxGet<QueryHomePageUserInfoResponse, QueryHomePageUserInfoData> = "/efficiency/queryHomePageUserInfo";
        /**
         * 效率首页待办数+日志目标数+日志目标列表+周总结状态
         */
        export const QueryHomePageUserInfo = (data: QueryHomePageUserInfoData) => Ajax.get(QueryHomePageUserInfoUrl, data);
        /**
         * OK
         */
        export type QueryHotUserHabitsResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        const QueryHotUserHabitsUrl: AjaxGet<QueryHotUserHabitsResponse, never> = "/efficiency/queryHotUserHabits";
        /**
         * 查询热门习惯
         */
        export const QueryHotUserHabits = () => Ajax.get(QueryHotUserHabitsUrl);
        /**
         * OK
         */
        export type QueryIsFileResponse = AppResponse<EfficiencyTargetVo>;
        /**
         * form
         */
        export type QueryIsFileData = EfficiencyTargetQueryForm;
        const QueryIsFileUrl: AjaxPost<QueryIsFileResponse, QueryIsFileData> = "/efficiency/queryIsFile";
        /**
         * 查询目标表
         */
        export const QueryIsFile = (data: QueryIsFileData) => Ajax.post(QueryIsFileUrl, data);
        /**
         * OK
         */
        export type QueryIsOPenTargetListResponse = AppResponse<AppListResponse<EfficiencyTargetVo>>;
        export type QueryIsOPenTargetListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryIsOPenTargetListUrl: AjaxGet<QueryIsOPenTargetListResponse, QueryIsOPenTargetListData> = "/efficiency/queryIsOPenTargetList";
        /**
         * 查询有公开日志的目标表(分页)
         */
        export const QueryIsOPenTargetList = (data: QueryIsOPenTargetListData) => Ajax.get(QueryIsOPenTargetListUrl, data);
        /**
         * OK
         */
        export type QueryJournalCommentResponse = AppResponse<AppPageListResponse<EfficiencyJournalCommentVo>>;
        export type QueryJournalCommentData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            dynamicId?: string;
            fromDate?: string;
            orderBy?: string;
            pageNum?: number;
            pageSize?: number;
            searchNameValue?: string;
            toDate?: string;
            totalCount?: number;
            type?: number;
            userId?: string;
        };
        const QueryJournalCommentUrl: AjaxGet<QueryJournalCommentResponse, QueryJournalCommentData> = "/efficiency/queryJournalComment";
        /**
         * 查询日志评论(带分页)
         */
        export const QueryJournalComment = (data: QueryJournalCommentData) => Ajax.get(QueryJournalCommentUrl, data);
        /**
         * OK
         */
        export type QueryLastIncompleteResponse = AppResponse<EfficiencyNeedhandleVo>;
        export type QueryLastIncompleteData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryLastIncompleteUrl: AjaxGet<QueryLastIncompleteResponse, QueryLastIncompleteData> = "/efficiency/queryLastIncomplete";
        /**
         * 查询是否有上一次操作未完成的待办
         */
        export const QueryLastIncomplete = (data: QueryLastIncompleteData) => Ajax.get(QueryLastIncompleteUrl, data);
        /**
         * OK
         */
        export type QueryListByDayResponse = AppResponse<AppListResponse<EfficiencyNeedhandleVo>>;
        export type QueryListByDayData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 范围类型 1.周范围 2.月范围 3.月视图
             */
            rangeType?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryListByDayUrl: AjaxGet<QueryListByDayResponse, QueryListByDayData> = "/efficiency/queryListByDay";
        /**
         * 查询某日待办
         */
        export const QueryListByDay = (data: QueryListByDayData) => Ajax.get(QueryListByDayUrl, data);
        /**
         * OK
         */
        export type QueryListByDayTwoResponse = AppResponse<AppListResponse<EfficiencyNeedhandleVo>>;
        export type QueryListByDayTwoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 范围类型 1.周范围 2.月范围 3.月视图
             */
            rangeType?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryListByDayTwoUrl: AjaxGet<QueryListByDayTwoResponse, QueryListByDayTwoData> = "/efficiency/queryListByDayTwo";
        /**
         * 查询某日待办2.0
         */
        export const QueryListByDayTwo = (data: QueryListByDayTwoData) => Ajax.get(QueryListByDayTwoUrl, data);
        /**
         * OK
         */
        export type QueryListByUserIdAndDayResponse = AppResponse<EfficiencyWeekPlanVo>;
        export type QueryListByUserIdAndDayData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期,格式为:yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 0为计划 1为总结
             */
            type?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryListByUserIdAndDayUrl: AjaxGet<QueryListByUserIdAndDayResponse, QueryListByUserIdAndDayData> = "/efficiency/queryListByUserIdAndDay";
        /**
         * 根据日期查询周计划
         */
        export const QueryListByUserIdAndDay = (data: QueryListByUserIdAndDayData) => Ajax.get(QueryListByUserIdAndDayUrl, data);
        /**
         * OK
         */
        export type QueryListForBakcGroundImageResponse = AppResponse<AppPageListResponse<EfficiencyBackgroundImageVo>>;
        /**
         * form
         */
        export type QueryListForBakcGroundImageData = EfficiencyBackgroundImageQueryForm;
        const QueryListForBakcGroundImageUrl: AjaxPost<QueryListForBakcGroundImageResponse, QueryListForBakcGroundImageData> = "/efficiency/queryListForBakcGroundImage";
        /**
         * 查询项目背景图片列表(分页)
         */
        export const QueryListForBakcGroundImage = (data: QueryListForBakcGroundImageData) => Ajax.post(QueryListForBakcGroundImageUrl, data);
        /**
         * OK
         */
        export type QueryListForUserResponse = AppResponse<EfficiencyHabitsIconVo>;
        export type QueryListForUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryListForUserUrl: AjaxGet<QueryListForUserResponse, QueryListForUserData> = "/efficiency/queryListForUser";
        /**
         * 查询列表贡用户自定义习惯使用
         */
        export const QueryListForUser = (data: QueryListForUserData) => Ajax.get(QueryListForUserUrl, data);
        /**
         * OK
         */
        export type QueryListRecentlyUsedResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        export type QueryListRecentlyUsedData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryListRecentlyUsedUrl: AjaxGet<QueryListRecentlyUsedResponse, QueryListRecentlyUsedData> = "/efficiency/queryListRecentlyUsed";
        /**
         * 查询用户最近使用习惯
         */
        export const QueryListRecentlyUsed = (data: QueryListRecentlyUsedData) => Ajax.get(QueryListRecentlyUsedUrl, data);
        /**
         * OK
         */
        export type QueryMyJournalResponse = AppResponse<AppListResponse<EfficiencyTargetVo>>;
        export type QueryMyJournalData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * es中日志id
             */
            efficiencyJournalId?: string;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 目标id
             */
            targetId?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryMyJournalUrl: AjaxGet<QueryMyJournalResponse, QueryMyJournalData> = "/efficiency/queryMyJournal";
        /**
         * 查看 我的-日志
         */
        export const QueryMyJournal = (data: QueryMyJournalData) => Ajax.get(QueryMyJournalUrl, data);
        /**
         * OK
         */
        export type QueryNeedByRangeResponse = AppResponse<AppListResponse<EfficiencyNeedhandleVo>>;
        export type QueryNeedByRangeData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 范围类型 1.周范围 2.月范围 3.月视图
             */
            rangeType?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryNeedByRangeUrl: AjaxGet<QueryNeedByRangeResponse, QueryNeedByRangeData> = "/efficiency/queryNeedByRange";
        /**
         * 根据时间范围查询待办情况
         */
        export const QueryNeedByRange = (data: QueryNeedByRangeData) => Ajax.get(QueryNeedByRangeUrl, data);
        /**
         * OK
         */
        export type QueryNeedByRangeTwoResponse = AppResponse<AppListResponse<EfficiencyNeedhandleVo>>;
        export type QueryNeedByRangeTwoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 范围类型 1.周范围 2.月范围 3.月视图
             */
            rangeType?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryNeedByRangeTwoUrl: AjaxGet<QueryNeedByRangeTwoResponse, QueryNeedByRangeTwoData> = "/efficiency/queryNeedByRangeTwo";
        /**
         * 根据时间范围查询待办情况2.0
         */
        export const QueryNeedByRangeTwo = (data: QueryNeedByRangeTwoData) => Ajax.get(QueryNeedByRangeTwoUrl, data);
        /**
         * OK
         */
        export type QueryPageListForEfficiencyEpilogueResponse = AppResponse<AppPageListResponse<EfficiencyEpilogueVo>>;
        export type QueryPageListForEfficiencyEpilogueData = {
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 日志id
             */
            journalId?: number;
        };
        const QueryPageListForEfficiencyEpilogueUrl: AjaxGet<QueryPageListForEfficiencyEpilogueResponse, QueryPageListForEfficiencyEpilogueData> = "/efficiency/queryPageListForEfficiencyEpilogue";
        /**
         * 查询后记表列表(带分页)
         */
        export const QueryPageListForEfficiencyEpilogue = (data: QueryPageListForEfficiencyEpilogueData) => Ajax.get(QueryPageListForEfficiencyEpilogueUrl, data);
        /**
         * OK
         */
        export type QuerySysHabitsListResponse = AppResponse<AppListResponse<EfficiencySysHabitsVo>>;
        const QuerySysHabitsListUrl: AjaxGet<QuerySysHabitsListResponse, never> = "/efficiency/querySysHabitsList";
        /**
         * 查询系统习惯表列表
         */
        export const QuerySysHabitsList = () => Ajax.get(QuerySysHabitsListUrl);
        /**
         * OK
         */
        export type QueryTargetResponse = AppResponse<EfficiencyTargetVo>;
        export type QueryTargetData = {
            /**
             * 主键id
             */
            id: number;
        };
        const QueryTargetUrl: AjaxGet<QueryTargetResponse, QueryTargetData> = "/efficiency/queryTarget";
        /**
         * 查询单个目标详情
         */
        export const QueryTarget = (data: QueryTargetData) => Ajax.get(QueryTargetUrl, data);
        /**
         * OK
         */
        export type QueryTargetIsOpenJournalResponse = AppResponse<EfficiencyTargetVo>;
        export type QueryTargetIsOpenJournalData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryTargetIsOpenJournalUrl: AjaxGet<QueryTargetIsOpenJournalResponse, QueryTargetIsOpenJournalData> = "/efficiency/queryTargetIsOpenJournal";
        /**
         * 查询公开目标内容(圈子内使用)
         */
        export const QueryTargetIsOpenJournal = (data: QueryTargetIsOpenJournalData) => Ajax.get(QueryTargetIsOpenJournalUrl, data);
        /**
         * OK
         */
        export type QueryTargetIsOpenJournalnewResponse = AppResponse<EfficiencyTargetVo>;
        /**
         * form
         */
        export type QueryTargetIsOpenJournalnewData = EfficiencyTargetQueryForm;
        const QueryTargetIsOpenJournalnewUrl: AjaxPost<QueryTargetIsOpenJournalnewResponse, QueryTargetIsOpenJournalnewData> = "/efficiency/queryTargetIsOpenJournalnew";
        /**
         * 查询公开目标内容新版本(圈子内使用)
         */
        export const QueryTargetIsOpenJournalnew = (data: QueryTargetIsOpenJournalnewData) => Ajax.post(QueryTargetIsOpenJournalnewUrl, data);
        /**
         * OK
         */
        export type QueryTargetProgressResponse = AppResponse<EfficiencyTargetVo>;
        export type QueryTargetProgressData = {
            /**
             * targetId
             */
            targetId: number;
        };
        const QueryTargetProgressUrl: AjaxGet<QueryTargetProgressResponse, QueryTargetProgressData> = "/efficiency/queryTargetProgress";
        /**
         * 查询目标进度
         */
        export const QueryTargetProgress = (data: QueryTargetProgressData) => Ajax.get(QueryTargetProgressUrl, data);
        /**
         * OK
         */
        export type QueryTimeLogResponse = AppResponse<EfficiencyTargetVo>;
        export type QueryTimeLogData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryTimeLogUrl: AjaxGet<QueryTimeLogResponse, QueryTimeLogData> = "/efficiency/queryTimeLog";
        /**
         * 查询目标列表(时间日志)
         */
        export const QueryTimeLog = (data: QueryTimeLogData) => Ajax.get(QueryTimeLogUrl, data);
        /**
         * OK
         */
        export type QueryUserDailyByDateResponse = AppResponse<EfficiencyDailyNotesVo>;
        export type QueryUserDailyByDateData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * datetime
             */
            datetime: string;
        };
        const QueryUserDailyByDateUrl: AjaxGet<QueryUserDailyByDateResponse, QueryUserDailyByDateData> = "/efficiency/queryUserDailyByDate";
        /**
         * 根据日期查询每日一记
         */
        export const QueryUserDailyByDate = (data: QueryUserDailyByDateData) => Ajax.get(QueryUserDailyByDateUrl, data);
        /**
         * OK
         */
        export type QueryUserHabitsListResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        export type QueryUserHabitsListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 习惯名称
             */
            customaryName?: string;
            /**
             * 习惯类型 1.学习 2.工作 3.健康 4.生活
             */
            customaryType?: number;
            /**
             * 是否禁用 0否1是
             */
            disable?: number;
            /**
             * 使用时长
             */
            durationOfUse?: number;
            /**
             * 专注时长(秒)
             */
            focusTime?: number;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 图标url
             */
            imageUrl?: string;
            /**
             * 排序
             */
            sort?: number;
            /**
             * 系统习惯id
             */
            systemHabitsId?: number;
            /**
             * 用户id
             */
            uid?: string;
            /**
             * 使用次数
             */
            useFrequency?: number;
        };
        const QueryUserHabitsListUrl: AjaxGet<QueryUserHabitsListResponse, QueryUserHabitsListData> = "/efficiency/queryUserHabitsList";
        /**
         * 查询用户习惯表
         */
        export const QueryUserHabitsList = (data: QueryUserHabitsListData) => Ajax.get(QueryUserHabitsListUrl, data);
        /**
         * OK
         */
        export type QueryUserWeekPlanHistoryResponse = AppResponse<AppPageListResponse<EfficiencyWeekPlanOverviewVo>>;
        /**
         * efficiencyWeekPlanQueryForm
         */
        export type QueryUserWeekPlanHistoryData = EfficiencyWeekPlanQueryForm;
        const QueryUserWeekPlanHistoryUrl: AjaxPost<QueryUserWeekPlanHistoryResponse, QueryUserWeekPlanHistoryData> = "/efficiency/queryUserWeekPlanHistory";
        /**
         * 修改周计划完成状态
         */
        export const QueryUserWeekPlanHistory = (data: QueryUserWeekPlanHistoryData) => Ajax.post(QueryUserWeekPlanHistoryUrl, data);
        /**
         * OK
         */
        export type UpdateResponse = AppResponse;
        /**
         * form
         */
        export type UpdateData = EfficiencyEpilogueUpdateForm;
        const UpdateUrl: AjaxPost<UpdateResponse, UpdateData> = "/efficiency/update";
        /**
         * 修改后记表
         */
        export const Update = (data: UpdateData) => Ajax.post(UpdateUrl, data);
        /**
         * OK
         */
        export type UpdateBackgroundResponse = AppResponse;
        /**
         * form
         */
        export type UpdateBackgroundData = EfficiencyBackgroundImageUpdateForm;
        const UpdateBackgroundUrl: AjaxPost<UpdateBackgroundResponse, UpdateBackgroundData> = "/efficiency/updateBackground";
        /**
         * 修改项目背景图片
         */
        export const UpdateBackground = (data: UpdateBackgroundData) => Ajax.post(UpdateBackgroundUrl, data);
        /**
         * OK
         */
        export type UpdateCompleteIncompleteResponse = AppResponse;
        /**
         * form
         */
        export type UpdateCompleteIncompleteData = EfficiencyNeedhandleUpdateForm;
        const UpdateCompleteIncompleteUrl: AjaxPost<UpdateCompleteIncompleteResponse, UpdateCompleteIncompleteData> = "/efficiency/updateCompleteIncomplete";
        /**
         * 完成待办/未完成
         */
        export const UpdateCompleteIncomplete = (data: UpdateCompleteIncompleteData) => Ajax.post(UpdateCompleteIncompleteUrl, data);
        /**
         * OK
         */
        export type UpdateCompleteIncompleteTwoResponse = AppResponse;
        /**
         * form
         */
        export type UpdateCompleteIncompleteTwoData = EfficiencyNeedhandleUpdateForm;
        const UpdateCompleteIncompleteTwoUrl: AjaxPost<UpdateCompleteIncompleteTwoResponse, UpdateCompleteIncompleteTwoData> = "/efficiency/updateCompleteIncompleteTwo";
        /**
         * 完成待办/未完成2.0
         */
        export const UpdateCompleteIncompleteTwo = (data: UpdateCompleteIncompleteTwoData) => Ajax.post(UpdateCompleteIncompleteTwoUrl, data);
        /**
         * OK
         */
        export type UpdateDelResponse = AppResponse;
        /**
         * form
         */
        export type UpdateDelData = EfficiencyDailyNotesUpdateForm;
        const UpdateDelUrl: AjaxPost<UpdateDelResponse, UpdateDelData> = "/efficiency/updateDel";
        /**
         * 逻辑删除每日一记
         */
        export const UpdateDel = (data: UpdateDelData) => Ajax.post(UpdateDelUrl, data);
        /**
         * OK
         */
        export type UpdateDisableResponse = AppResponse;
        /**
         * form
         */
        export type UpdateDisableData = EfficiencyEpilogueUpdateForm;
        const UpdateDisableUrl: AjaxPost<UpdateDisableResponse, UpdateDisableData> = "/efficiency/updateDisable";
        /**
         * 逻辑删除后记
         */
        export const UpdateDisable = (data: UpdateDisableData) => Ajax.post(UpdateDisableUrl, data);
        /**
         * OK
         */
        export type UpdateDisableWeekPlanResponse = AppResponse;
        /**
         * form
         */
        export type UpdateDisableWeekPlanData = EfficiencyWeekPlanUpdateForm;
        const UpdateDisableWeekPlanUrl: AjaxPost<UpdateDisableWeekPlanResponse, UpdateDisableWeekPlanData> = "/efficiency/updateDisableWeekPlan";
        /**
         * 逻辑删除周计划
         */
        export const UpdateDisableWeekPlan = (data: UpdateDisableWeekPlanData) => Ajax.post(UpdateDisableWeekPlanUrl, data);
        /**
         * OK
         */
        export type UpdateEpilogueResponse = AppResponse;
        /**
         * form
         */
        export type UpdateEpilogueData = EfficiencyDailyNotesUpdateForm;
        const UpdateEpilogueUrl: AjaxPost<UpdateEpilogueResponse, UpdateEpilogueData> = "/efficiency/updateEpilogue";
        /**
         * 修改每日一记表
         */
        export const UpdateEpilogue = (data: UpdateEpilogueData) => Ajax.post(UpdateEpilogueUrl, data);
        /**
         * OK
         */
        export type UpdateGoPlanResponse = AppResponse;
        /**
         * form
         */
        export type UpdateGoPlanData = EfficiencyGoalPlanUpdateForm;
        const UpdateGoPlanUrl: AjaxPost<UpdateGoPlanResponse, UpdateGoPlanData> = "/efficiency/updateGoPlan";
        /**
         * 修改目标计划表
         */
        export const UpdateGoPlan = (data: UpdateGoPlanData) => Ajax.post(UpdateGoPlanUrl, data);
        /**
         * OK
         */
        export type UpdateGoPlanDisbaleResponse = AppResponse;
        /**
         * form
         */
        export type UpdateGoPlanDisbaleData = EfficiencyGoalPlanUpdateForm;
        const UpdateGoPlanDisbaleUrl: AjaxPost<UpdateGoPlanDisbaleResponse, UpdateGoPlanDisbaleData> = "/efficiency/updateGoPlanDisbale";
        /**
         * 逻辑删除目标计划
         */
        export const UpdateGoPlanDisbale = (data: UpdateGoPlanDisbaleData) => Ajax.post(UpdateGoPlanDisbaleUrl, data);
        /**
         * OK
         */
        export type UpdateJournalResponse = AppResponse;
        /**
         * form
         */
        export type UpdateJournalData = EfficiencyTargetJournalUpdateForm;
        const UpdateJournalUrl: AjaxPost<UpdateJournalResponse, UpdateJournalData> = "/efficiency/updateJournal";
        /**
         * 修改日志表
         */
        export const UpdateJournal = (data: UpdateJournalData) => Ajax.post(UpdateJournalUrl, data);
        /**
         * OK
         */
        export type UpdateNeedhandleResponse = AppResponse;
        /**
         * form
         */
        export type UpdateNeedhandleData = EfficiencyNeedhandleUpdateForm;
        const UpdateNeedhandleUrl: AjaxPost<UpdateNeedhandleResponse, UpdateNeedhandleData> = "/efficiency/updateNeedhandle";
        /**
         * 编辑待办
         */
        export const UpdateNeedhandle = (data: UpdateNeedhandleData) => Ajax.post(UpdateNeedhandleUrl, data);
        /**
         * OK
         */
        export type UpdateSortByDragResponse = AppResponse;
        /**
         * form
         */
        export type UpdateSortByDragData = EfficiencyUserHabitsUpdateForm;
        const UpdateSortByDragUrl: AjaxPost<UpdateSortByDragResponse, UpdateSortByDragData> = "/efficiency/updateSortByDrag";
        /**
         * 用户习惯拖动排序
         */
        export const UpdateSortByDrag = (data: UpdateSortByDragData) => Ajax.post(UpdateSortByDragUrl, data);
        /**
         * OK
         */
        export type UpdateTargetResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetData = EfficiencyTargetUpdateForm;
        const UpdateTargetUrl: AjaxPost<UpdateTargetResponse, UpdateTargetData> = "/efficiency/updateTarget";
        /**
         * 修改目标表
         */
        export const UpdateTarget = (data: UpdateTargetData) => Ajax.post(UpdateTargetUrl, data);
        /**
         * OK
         */
        export type UpdateTargetImageResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetImageData = EfficiencyTargetUpdateForm;
        const UpdateTargetImageUrl: AjaxPost<UpdateTargetImageResponse, UpdateTargetImageData> = "/efficiency/updateTargetImage";
        /**
         * 修改目标背景图
         */
        export const UpdateTargetImage = (data: UpdateTargetImageData) => Ajax.post(UpdateTargetImageUrl, data);
        /**
         * OK
         */
        export type UpdateTargetOpenResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetOpenData = EfficiencyTargetUpdateForm;
        const UpdateTargetOpenUrl: AjaxPost<UpdateTargetOpenResponse, UpdateTargetOpenData> = "/efficiency/updateTargetOpen";
        /**
         * 目标的二次公开
         */
        export const UpdateTargetOpen = (data: UpdateTargetOpenData) => Ajax.post(UpdateTargetOpenUrl, data);
        /**
         * OK
         */
        export type UpdateToDoTwoResponse = AppResponse<EfficiencyNeedhandleVo>;
        /**
         * form
         */
        export type UpdateToDoTwoData = EfficiencyNeedhandleUpdateForm;
        const UpdateToDoTwoUrl: AjaxPost<UpdateToDoTwoResponse, UpdateToDoTwoData> = "/efficiency/updateToDoTwo";
        /**
         * 编辑待办2.0
         */
        export const UpdateToDoTwo = (data: UpdateToDoTwoData) => Ajax.post(UpdateToDoTwoUrl, data);
        /**
         * OK
         */
        export type UpdateTransferNeedHandleResponse = AppResponse<IntegerVo>;
        const UpdateTransferNeedHandleUrl: AjaxPost<UpdateTransferNeedHandleResponse, never> = "/efficiency/updateTransferNeedHandle";
        /**
         * 将上一次操作未完成的待办转至今日
         */
        export const UpdateTransferNeedHandle = () => Ajax.post(UpdateTransferNeedHandleUrl);
        /**
         * OK
         */
        export type UpdateTransferNeedHandleStateResponse = AppResponse;
        const UpdateTransferNeedHandleStateUrl: AjaxPost<UpdateTransferNeedHandleStateResponse, never> = "/efficiency/updateTransferNeedHandleState";
        /**
         * 关闭将上一次操作未完成的待办转至今日提醒
         */
        export const UpdateTransferNeedHandleState = () => Ajax.post(UpdateTransferNeedHandleStateUrl);
        /**
         * OK
         */
        export type UpdateWeekPlanResponse = AppResponse;
        /**
         * form
         */
        export type UpdateWeekPlanData = EfficiencyWeekPlanUpdateForm;
        const UpdateWeekPlanUrl: AjaxPost<UpdateWeekPlanResponse, UpdateWeekPlanData> = "/efficiency/updateWeekPlan";
        /**
         * 修改周计划表
         */
        export const UpdateWeekPlan = (data: UpdateWeekPlanData) => Ajax.post(UpdateWeekPlanUrl, data);
        /**
         * OK
         */
        export type UpdateWeekSummaryStateResponse = AppResponse;
        export type UpdateWeekSummaryStateData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const UpdateWeekSummaryStateUrl: AjaxGet<UpdateWeekSummaryStateResponse, UpdateWeekSummaryStateData> = "/efficiency/updateWeekSummaryState";
        /**
         * 关闭周总结提示
         */
        export const UpdateWeekSummaryState = (data: UpdateWeekSummaryStateData) => Ajax.get(UpdateWeekSummaryStateUrl, data);
    }
    /**
     * 新目标接口
     */
    export namespace EfficiencyTarget {
        /**
         * OK
         */
        export type AddCommonPracticeSortResponse = AppResponse;
        /**
         * form
         */
        export type AddCommonPracticeSortData = EfficiencyTargetPracticeSortListUpdateForm;
        const AddCommonPracticeSortUrl: AjaxPost<AddCommonPracticeSortResponse, AddCommonPracticeSortData> = "/efficiency/target/addCommonPracticeSort";
        /**
         * 常用练习排序
         */
        export const AddCommonPracticeSort = (data: AddCommonPracticeSortData) => Ajax.post(AddCommonPracticeSortUrl, data);
        /**
         * OK
         */
        export type AddTargetResponse = AppResponse<EfficiencyTargetVo>;
        /**
         * form
         */
        export type AddTargetData = EfficiencyTargetCreateNewForm;
        const AddTargetUrl: AjaxPost<AddTargetResponse, AddTargetData> = "/efficiency/target/addTarget";
        /**
         * 新增目标表2.0
         */
        export const AddTarget = (data: AddTargetData) => Ajax.post(AddTargetUrl, data);
        /**
         * OK
         */
        export type BuyBackGroundImageResponse = AppResponse;
        /**
         * form
         */
        export type BuyBackGroundImageData = EfficiencyBackgroundImageBuyForm;
        const BuyBackGroundImageUrl: AjaxPost<BuyBackGroundImageResponse, BuyBackGroundImageData> = "/efficiency/target/buyBackGroundImage";
        /**
         * 购买项目背景
         */
        export const BuyBackGroundImage = (data: BuyBackGroundImageData) => Ajax.post(BuyBackGroundImageUrl, data);
        /**
         * OK
         */
        export type DeleteTargetResponse = AppResponse;
        /**
         * deleteForm
         */
        export type DeleteTargetData = EfficiencyTargetDeleteForm;
        const DeleteTargetUrl: AjaxPost<DeleteTargetResponse, DeleteTargetData> = "/efficiency/target/deleteTarget";
        /**
         * 删除目标2.0
         */
        export const DeleteTarget = (data: DeleteTargetData) => Ajax.post(DeleteTargetUrl, data);
        /**
         * OK
         */
        export type QueryAllNumWithUserResponse = AppResponse<EfficiencyTargetIndexVo>;
        export type QueryAllNumWithUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryAllNumWithUserUrl: AjaxGet<QueryAllNumWithUserResponse, QueryAllNumWithUserData> = "/efficiency/target/queryAllNumWithUser";
        /**
         * 查询在燃草开启的目标总数
         */
        export const QueryAllNumWithUser = (data: QueryAllNumWithUserData) => Ajax.get(QueryAllNumWithUserUrl, data);
        /**
         * OK
         */
        export type QueryCommonPracticeByUserIdResponse = AppResponse<AppListResponse<EfficiencyTargetPracticeVo>>;
        const QueryCommonPracticeByUserIdUrl: AjaxPost<QueryCommonPracticeByUserIdResponse, never> = "/efficiency/target/queryCommonPracticeByUserId";
        /**
         * 根据用户id查询首页常用练习
         */
        export const QueryCommonPracticeByUserId = () => Ajax.post(QueryCommonPracticeByUserIdUrl);
        /**
         * OK
         */
        export type QueryHasFinishTargetInfoResponse = AppResponse<EfficiencyTargetDetailWhenTargetIsFinishedVo>;
        export type QueryHasFinishTargetInfoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 目标id
             */
            targetId: number;
        };
        const QueryHasFinishTargetInfoUrl: AjaxGet<QueryHasFinishTargetInfoResponse, QueryHasFinishTargetInfoData> = "/efficiency/target/queryHasFinishTargetInfo";
        /**
         * 目标达成后数据
         */
        export const QueryHasFinishTargetInfo = (data: QueryHasFinishTargetInfoData) => Ajax.get(QueryHasFinishTargetInfoUrl, data);
        /**
         * OK
         */
        export type QueryImageListWithUserResponse = AppResponse<AppListResponse<EfficiencyBackgroundImageVo>>;
        export type QueryImageListWithUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryImageListWithUserUrl: AjaxGet<QueryImageListWithUserResponse, QueryImageListWithUserData> = "/efficiency/target/queryImageListWithUser";
        /**
         * 查询用户项目背景
         */
        export const QueryImageListWithUser = (data: QueryImageListWithUserData) => Ajax.get(QueryImageListWithUserUrl, data);
        /**
         * OK
         */
        export type QueryNoStateTargetListResponse = AppResponse<EfficiencyResponseVo>;
        export type QueryNoStateTargetListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryNoStateTargetListUrl: AjaxGet<QueryNoStateTargetListResponse, QueryNoStateTargetListData> = "/efficiency/target/queryNoStateTargetList";
        /**
         * 查询进行中目标列表
         */
        export const QueryNoStateTargetList = (data: QueryNoStateTargetListData) => Ajax.get(QueryNoStateTargetListUrl, data);
        /**
         * OK
         */
        export type QueryNoStateTargetListWithSimpleResponse = AppResponse<AppPageListResponse<EfficiencySimpleTargetVo>>;
        export type QueryNoStateTargetListWithSimpleData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryNoStateTargetListWithSimpleUrl: AjaxGet<QueryNoStateTargetListWithSimpleResponse, QueryNoStateTargetListWithSimpleData> = "/efficiency/target/queryNoStateTargetListWithSimple";
        /**
         * 查询进行中目标列表-simple
         */
        export const QueryNoStateTargetListWithSimple = (data: QueryNoStateTargetListWithSimpleData) => Ajax.get(QueryNoStateTargetListWithSimpleUrl, data);
        /**
         * OK
         */
        export type QueryTargeWithStatetListResponse = AppResponse<AppPageListResponse<EfficiencyResponseListVo>>;
        export type QueryTargeWithStatetListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 背景图id
             */
            backgroundId?: string;
            /**
             * 背景图url
             */
            backgroundUrl?: string;
            /**
             * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
             */
            createTime?: string;
            /**
             * 0启用 1禁用
             */
            disable?: number;
            /**
             * 共经历天数
             */
            experienceDays?: number;
            /**
             * 共专注时长(秒)
             */
            focusTimeNum?: number;
            /**
             * 区分全年日志和普通目标 0 普通目标 1 全年目标
             */
            identify?: number;
            /**
             * 是否归档 0否 1是
             */
            isFile?: number;
            /**
             * 是否查询所有目标 1是 无值默认查询进行中的目标
             */
            isQueryAll?: number;
            /**
             * 日志数量
             */
            logNumber?: number;
            /**
             * 最新的或者被举报的 0最新 1被举报
             */
            newortip?: number;
            /**
             * 当前年-月
             */
            nowYearMonth?: string;
            /**
             * 上月 -1 /下月1
             */
            num?: number;
            /**
             * 计时方式 0倒计时 1正计时
             */
            recordingTimeType?: number;
            /**
             * 提醒时间
             */
            reminderTime?: string;
            /**
             * 重复日期
             */
            repeatedTime?: string;
            /**
             * 主键id
             */
            targetId?: number;
            /**
             * 目标名称
             */
            targetName?: string;
            /**
             * 共培养习惯
             */
            trainingHabits?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryTargeWithStatetListUrl: AjaxGet<QueryTargeWithStatetListResponse, QueryTargeWithStatetListData> = "/efficiency/target/queryTargeWithStatetList";
        /**
         * 查询有状态目标列表
         */
        export const QueryTargeWithStatetList = (data: QueryTargeWithStatetListData) => Ajax.get(QueryTargeWithStatetListUrl, data);
        /**
         * OK
         */
        export type QueryTargeWithTargetNameResponse = AppResponse<AppPageListResponse<EfficiencyOpenTargetVo>>;
        export type QueryTargeWithTargetNameData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 目标名称-查询字段
             */
            targetName?: string;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryTargeWithTargetNameUrl: AjaxGet<QueryTargeWithTargetNameResponse, QueryTargeWithTargetNameData> = "/efficiency/target/queryTargeWithTargetName";
        /**
         * 搜索目标列表
         */
        export const QueryTargeWithTargetName = (data: QueryTargeWithTargetNameData) => Ajax.get(QueryTargeWithTargetNameUrl, data);
        /**
         * OK
         */
        export type QueryTargetDetailResponse = AppResponse<EfficiencyTargetDetailVo>;
        export type QueryTargetDetailData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 目标id
             */
            targetId: number;
        };
        const QueryTargetDetailUrl: AjaxGet<QueryTargetDetailResponse, QueryTargetDetailData> = "/efficiency/target/queryTargetDetail";
        /**
         * 查询目标详情
         */
        export const QueryTargetDetail = (data: QueryTargetDetailData) => Ajax.get(QueryTargetDetailUrl, data);
        /**
         * OK
         */
        export type QueryTargetIsOpenResponse = AppResponse<EfficiencySimpleTargetVo>;
        export type QueryTargetIsOpenData = {
            /**
             * 目标id
             */
            targetId: number;
        };
        const QueryTargetIsOpenUrl: AjaxGet<QueryTargetIsOpenResponse, QueryTargetIsOpenData> = "/efficiency/target/queryTargetIsOpen";
        /**
         * 发布动态查询目标是否公开
         */
        export const QueryTargetIsOpen = (data: QueryTargetIsOpenData) => Ajax.get(QueryTargetIsOpenUrl, data);
        /**
         * OK
         */
        export type QueryTargetListInUserResponse = AppResponse<AppPageListResponse<EfficiencyOpenTargetVo>>;
        export type QueryTargetListInUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 是否本人 0不是 1是
             */
            isOwner?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryTargetListInUserUrl: AjaxGet<QueryTargetListInUserResponse, QueryTargetListInUserData> = "/efficiency/target/queryTargetListInUser";
        /**
         * 个人资料页目标列表
         */
        export const QueryTargetListInUser = (data: QueryTargetListInUserData) => Ajax.get(QueryTargetListInUserUrl, data);
        /**
         * OK
         */
        export type QueryTargetListWithSimpleResponse = AppResponse<AppPageListResponse<EfficiencySimpleTargetVo>>;
        export type QueryTargetListWithSimpleData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * pageNum
             */
            pageNum: number;
            /**
             * pageSize
             */
            pageSize: number;
        };
        const QueryTargetListWithSimpleUrl: AjaxGet<QueryTargetListWithSimpleResponse, QueryTargetListWithSimpleData> = "/efficiency/target/queryTargetListWithSimple";
        /**
         * 查询目标列表-simple
         */
        export const QueryTargetListWithSimple = (data: QueryTargetListWithSimpleData) => Ajax.get(QueryTargetListWithSimpleUrl, data);
        /**
         * OK
         */
        export type QueryTargetPracticeByUserIdResponse = AppResponse<AppListResponse<EfficiencyTargetPracticeListVo>>;
        export type QueryTargetPracticeByUserIdData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryTargetPracticeByUserIdUrl: AjaxGet<QueryTargetPracticeByUserIdResponse, QueryTargetPracticeByUserIdData> = "/efficiency/target/queryTargetPracticeByUserId";
        /**
         * 根据用户id查询进行中目标列表以及目标对应的练习
         */
        export const QueryTargetPracticeByUserId = (data: QueryTargetPracticeByUserIdData) => Ajax.get(QueryTargetPracticeByUserIdUrl, data);
        /**
         * OK
         */
        export type UpdateTargetResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetData = EfficiencyTargetUpdateNewForm;
        const UpdateTargetUrl: AjaxPost<UpdateTargetResponse, UpdateTargetData> = "/efficiency/target/updateTarget";
        /**
         * 修改目标2.0
         */
        export const UpdateTarget = (data: UpdateTargetData) => Ajax.post(UpdateTargetUrl, data);
        /**
         * OK
         */
        export type UpdateTargetBackGroundImageResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetBackGroundImageData = EfficiencyTargetImageUpdateForm;
        const UpdateTargetBackGroundImageUrl: AjaxPost<UpdateTargetBackGroundImageResponse, UpdateTargetBackGroundImageData> = "/efficiency/target/updateTargetBackGroundImage";
        /**
         * 修改目标背景图片
         */
        export const UpdateTargetBackGroundImage = (data: UpdateTargetBackGroundImageData) => Ajax.post(UpdateTargetBackGroundImageUrl, data);
        /**
         * OK
         */
        export type UpdateTargetSortResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetSortData = EfficiencyTargetSortListUpdateForm;
        const UpdateTargetSortUrl: AjaxPost<UpdateTargetSortResponse, UpdateTargetSortData> = "/efficiency/target/updateTargetSort";
        /**
         * 进行中-目标排序
         */
        export const UpdateTargetSort = (data: UpdateTargetSortData) => Ajax.post(UpdateTargetSortUrl, data);
        /**
         * OK
         */
        export type UpdateTargetStateResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTargetStateData = EfficiencyTargetStateUpdateForm;
        const UpdateTargetStateUrl: AjaxPost<UpdateTargetStateResponse, UpdateTargetStateData> = "/efficiency/target/updateTargetState";
        /**
         * 目标达成/未达成
         */
        export const UpdateTargetState = (data: UpdateTargetStateData) => Ajax.post(UpdateTargetStateUrl, data);
    }
    /**
     * 练习相关接口
     */
    export namespace EfficiencyTargetPractice {
        /**
         * OK
         */
        export type AddResponse = AppResponse<EfficiencyTargetPracticeVo>;
        /**
         * form
         */
        export type AddData = EfficiencyTargetPracticeCreateForm;
        const AddUrl: AjaxPost<AddResponse, AddData> = "/efficiency/target/practice/add";
        /**
         * 新增小练习
         */
        export const Add = (data: AddData) => Ajax.post(AddUrl, data);
        /**
         * OK
         */
        export type ClockInPracticeResponse = AppResponse<EfficiencyTargetPracticeClockVo>;
        /**
         * form
         */
        export type ClockInPracticeData = EfficiencyTargetPracticeUpdateForm;
        const ClockInPracticeUrl: AjaxPost<ClockInPracticeResponse, ClockInPracticeData> = "/efficiency/target/practice/clockInPractice";
        /**
         * 练习打卡
         */
        export const ClockInPractice = (data: ClockInPracticeData) => Ajax.post(ClockInPracticeUrl, data);
        /**
         * OK
         */
        export type DeleteResponse = AppResponse;
        /**
         * form
         */
        export type DeleteData = EfficiencyTargetPracticeDeleteForm;
        const DeleteUrl: AjaxPost<DeleteResponse, DeleteData> = "/efficiency/target/practice/delete";
        /**
         * 删除小练习
         */
        export const Delete = (data: DeleteData) => Ajax.post(DeleteUrl, data);
        /**
         * OK
         */
        export type QueryResponse = AppResponse<EfficiencyTargetPracticeVo>;
        export type QueryData = {
            /**
             * id
             */
            id: number;
        };
        const QueryUrl: AjaxGet<QueryResponse, QueryData> = "/efficiency/target/practice/query";
        /**
         * 查询小练习
         */
        export const Query = (data: QueryData) => Ajax.get(QueryUrl, data);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<EfficiencyTargetPracticeVo>>;
        export type QueryListData = {
            /**
             * 列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 习惯颜色
             */
            habitColor?: string;
            /**
             * 习惯id
             */
            habitId?: number;
            /**
             * 习惯名称
             */
            habitName?: string;
            /**
             * 已完成计时时间
             */
            hasFinishHours?: number;
            /**
             * 已完成次数
             */
            hasFinishTimes?: number;
            id?: number;
            /**
             * 背景图id
             */
            imageId?: number;
            /**
             * 是否删除 0未删除 1已删除
             */
            isDel?: number;
            /**
             * 练习是否完成 0未完成 1已完成
             */
            isFinish?: number;
            /**
             * 练习总时长
             */
            practiceHours?: number;
            /**
             * 练习名称
             */
            practiceName?: string;
            /**
             * 练习次数
             */
            practiceTimes?: number;
            /**
             * 练习类型 1次数 2时长
             */
            practiceType?: number;
            /**
             * 练习排序
             */
            sort?: number;
            /**
             * 所属目标id
             */
            targetId?: number;
            /**
             * 用户id
             */
            userId?: string;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/efficiency/target/practice/queryList";
        /**
         * 查询小练习列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
        /**
         * OK
         */
        export type QueryPageListResponse = AppResponse<AppPageListResponse<EfficiencyTargetPracticeVo>>;
        export type QueryPageListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 习惯颜色
             */
            habitColor?: string;
            /**
             * 习惯id
             */
            habitId?: number;
            /**
             * 习惯名称
             */
            habitName?: string;
            /**
             * 已完成计时时间
             */
            hasFinishHours?: number;
            /**
             * 已完成次数
             */
            hasFinishTimes?: number;
            id?: number;
            /**
             * 背景图id
             */
            imageId?: number;
            /**
             * 是否删除 0未删除 1已删除
             */
            isDel?: number;
            /**
             * 练习是否完成 0未完成 1已完成
             */
            isFinish?: number;
            /**
             * 练习总时长
             */
            practiceHours?: number;
            /**
             * 练习名称
             */
            practiceName?: string;
            /**
             * 练习次数
             */
            practiceTimes?: number;
            /**
             * 练习类型 1次数 2时长
             */
            practiceType?: number;
            /**
             * 练习排序
             */
            sort?: number;
            /**
             * 所属目标id
             */
            targetId?: number;
            /**
             * 用户id
             */
            userId?: string;
        };
        const QueryPageListUrl: AjaxGet<QueryPageListResponse, QueryPageListData> = "/efficiency/target/practice/queryPageList";
        /**
         * 查询小练习列表(带分页)
         */
        export const QueryPageList = (data: QueryPageListData) => Ajax.get(QueryPageListUrl, data);
        /**
         * OK
         */
        export type QueryPracticeImageListResponse = AppResponse<AppListResponse<EfficiencyPracticeImageVo>>;
        const QueryPracticeImageListUrl: AjaxGet<QueryPracticeImageListResponse, never> = "/efficiency/target/practice/queryPracticeImageList";
        /**
         * 查询练习可用背景图列表
         */
        export const QueryPracticeImageList = () => Ajax.get(QueryPracticeImageListUrl);
        /**
         * OK
         */
        export type UpdateResponse = AppResponse;
        /**
         * form
         */
        export type UpdateData = EfficiencyTargetPracticeUpdateForm;
        const UpdateUrl: AjaxPost<UpdateResponse, UpdateData> = "/efficiency/target/practice/update";
        /**
         * 修改小练习
         */
        export const Update = (data: UpdateData) => Ajax.post(UpdateUrl, data);
        /**
         * OK
         */
        export type UpdateSortResponse = AppResponse;
        /**
         * form
         */
        export type UpdateSortData = EfficiencyTargetPracticeSortListUpdateForm;
        const UpdateSortUrl: AjaxPost<UpdateSortResponse, UpdateSortData> = "/efficiency/target/practice/updateSort";
        /**
         * 修改小练习排序
         */
        export const UpdateSort = (data: UpdateSortData) => Ajax.post(UpdateSortUrl, data);
    }
    /**
     * 效率习惯接口
     */
    export namespace EfficiencyHabit {
        /**
         * OK
         */
        export type AddHabitMergerResponse = AppResponse;
        /**
         * form
         */
        export type AddHabitMergerData = EfficiencyHabitMergerCreateForm;
        const AddHabitMergerUrl: AjaxPost<AddHabitMergerResponse, AddHabitMergerData> = "/efficiencyHabits/addHabitMerger";
        /**
         * 新增一条合并记录
         */
        export const AddHabitMerger = (data: AddHabitMergerData) => Ajax.post(AddHabitMergerUrl, data);
        /**
         * OK
         */
        export type AddHabitsListByUserResponse = AppResponse<EfficiencyUserHabitsVo>;
        /**
         * form
         */
        export type AddHabitsListByUserData = EfficiencyUserHabitsCreateForm;
        const AddHabitsListByUserUrl: AjaxPost<AddHabitsListByUserResponse, AddHabitsListByUserData> = "/efficiencyHabits/addHabitsListByUser";
        /**
         * 用户自己批量新增习惯
         */
        export const AddHabitsListByUser = (data: AddHabitsListByUserData) => Ajax.post(AddHabitsListByUserUrl, data);
        /**
         * OK
         */
        export type BatchDeleteResponse = AppResponse;
        /**
         * form
         */
        export type BatchDeleteData = EfficiencyUserHabitsDeleteForm;
        const BatchDeleteUrl: AjaxPost<BatchDeleteResponse, BatchDeleteData> = "/efficiencyHabits/batchDelete";
        /**
         * 批量删除用户习惯表
         */
        export const BatchDelete = (data: BatchDeleteData) => Ajax.post(BatchDeleteUrl, data);
        /**
         * OK
         */
        export type DeleteUserHabitsResponse = AppResponse;
        /**
         * form
         */
        export type DeleteUserHabitsData = EfficiencyUserHabitsUpdateForm;
        const DeleteUserHabitsUrl: AjaxPost<DeleteUserHabitsResponse, DeleteUserHabitsData> = "/efficiencyHabits/deleteUserHabits";
        /**
         * 删除用户习惯
         */
        export const DeleteUserHabits = (data: DeleteUserHabitsData) => Ajax.post(DeleteUserHabitsUrl, data);
        /**
         * OK
         */
        export type DiyAddUserHabitResponse = AppResponse<EfficiencyUserHabitsVo>;
        /**
         * form
         */
        export type DiyAddUserHabitData = EfficiencyUserHabitsCreateForm;
        const DiyAddUserHabitUrl: AjaxPost<DiyAddUserHabitResponse, DiyAddUserHabitData> = "/efficiencyHabits/diyAddUserHabit";
        /**
         * 自定义新增用户习惯表
         */
        export const DiyAddUserHabit = (data: DiyAddUserHabitData) => Ajax.post(DiyAddUserHabitUrl, data);
        /**
         * OK
         */
        export type GetHabitsByHNameResponse = AppResponse<AppListResponse<EfficiencySysHabitsVo>>;
        /**
         * form
         */
        export type GetHabitsByHNameData = EfficiencyUserHabitsCreateForm;
        const GetHabitsByHNameUrl: AjaxPost<GetHabitsByHNameResponse, GetHabitsByHNameData> = "/efficiencyHabits/getHabitsByHName";
        /**
         * 根据习惯名称模糊查询系统习惯表
         */
        export const GetHabitsByHName = (data: GetHabitsByHNameData) => Ajax.post(GetHabitsByHNameUrl, data);
        /**
         * OK
         */
        export type QueryHotUserHabitsResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        const QueryHotUserHabitsUrl: AjaxGet<QueryHotUserHabitsResponse, never> = "/efficiencyHabits/queryHotUserHabits";
        /**
         * 查询热门习惯
         */
        export const QueryHotUserHabits = () => Ajax.get(QueryHotUserHabitsUrl);
        /**
         * OK
         */
        export type QueryListForUserResponse = AppResponse<EfficiencyHabitsIconVo>;
        export type QueryListForUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryListForUserUrl: AjaxGet<QueryListForUserResponse, QueryListForUserData> = "/efficiencyHabits/queryListForUser";
        /**
         * 查询列表贡用户自定义习惯使用
         */
        export const QueryListForUser = (data: QueryListForUserData) => Ajax.get(QueryListForUserUrl, data);
        /**
         * OK
         */
        export type QueryListRecentlyUsedResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        export type QueryListRecentlyUsedData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryListRecentlyUsedUrl: AjaxGet<QueryListRecentlyUsedResponse, QueryListRecentlyUsedData> = "/efficiencyHabits/queryListRecentlyUsed";
        /**
         * 查询用户最近使用习惯
         */
        export const QueryListRecentlyUsed = (data: QueryListRecentlyUsedData) => Ajax.get(QueryListRecentlyUsedUrl, data);
        /**
         * OK
         */
        export type QuerySysHabitsListResponse = AppResponse<AppListResponse<EfficiencySysHabitsVo>>;
        const QuerySysHabitsListUrl: AjaxGet<QuerySysHabitsListResponse, never> = "/efficiencyHabits/querySysHabitsList";
        /**
         * 查询系统习惯表列表
         */
        export const QuerySysHabitsList = () => Ajax.get(QuerySysHabitsListUrl);
        /**
         * OK
         */
        export type QueryUserHabitsListResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        export type QueryUserHabitsListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 习惯名称
             */
            customaryName?: string;
            /**
             * 习惯类型 1.学习 2.工作 3.健康 4.生活
             */
            customaryType?: number;
            /**
             * 是否禁用 0否1是
             */
            disable?: number;
            /**
             * 使用时长
             */
            durationOfUse?: number;
            /**
             * 专注时长(秒)
             */
            focusTime?: number;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 图标url
             */
            imageUrl?: string;
            /**
             * 排序
             */
            sort?: number;
            /**
             * 系统习惯id
             */
            systemHabitsId?: number;
            /**
             * 用户id
             */
            uid?: string;
            /**
             * 使用次数
             */
            useFrequency?: number;
        };
        const QueryUserHabitsListUrl: AjaxGet<QueryUserHabitsListResponse, QueryUserHabitsListData> = "/efficiencyHabits/queryUserHabitsList";
        /**
         * 查询用户习惯表
         */
        export const QueryUserHabitsList = (data: QueryUserHabitsListData) => Ajax.get(QueryUserHabitsListUrl, data);
        /**
         * OK
         */
        export type QueryUserZyResponse = AppResponse<EfficiencyUserHabitsVo>;
        const QueryUserZyUrl: AjaxPost<QueryUserZyResponse, never> = "/efficiencyHabits/queryUserZy";
        /**
         * 查询用户自由计时
         */
        export const QueryUserZy = () => Ajax.post(QueryUserZyUrl);
        /**
         * OK
         */
        export type UpdateSortByDragResponse = AppResponse;
        /**
         * form
         */
        export type UpdateSortByDragData = EfficiencyUserHabitsUpdateForm;
        const UpdateSortByDragUrl: AjaxPost<UpdateSortByDragResponse, UpdateSortByDragData> = "/efficiencyHabits/updateSortByDrag";
        /**
         * 用户习惯拖动排序
         */
        export const UpdateSortByDrag = (data: UpdateSortByDragData) => Ajax.post(UpdateSortByDragUrl, data);
        /**
         * OK
         */
        export type UserhabitAndSysResponse = AppResponse<AppListResponse<EfficiencyUserHabitsVo>>;
        export type UserhabitAndSysData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const UserhabitAndSysUrl: AjaxGet<UserhabitAndSysResponse, UserhabitAndSysData> = "/efficiencyHabits/userhabitAndSys";
        /**
         * 查询用户所有习惯及系统习惯
         */
        export const UserhabitAndSys = (data: UserhabitAndSysData) => Ajax.get(UserhabitAndSysUrl, data);
    }
    /**
     * 效率待办接口
     */
    export namespace Needhandle {
        /**
         * OK
         */
        export type AddNeedhandleResponse = AppResponse<EfficiencyNeedhandleVo>;
        /**
         * form
         */
        export type AddNeedhandleData = EfficiencyNeedhandleCreateForm;
        const AddNeedhandleUrl: AjaxPost<AddNeedhandleResponse, AddNeedhandleData> = "/efficiencyNeedhandle/addNeedhandle";
        /**
         * 新增待办表
         */
        export const AddNeedhandle = (data: AddNeedhandleData) => Ajax.post(AddNeedhandleUrl, data);
        /**
         * OK
         */
        export type DeleteNeedhandleResponse = AppResponse;
        /**
         * needhandleDeleteForm
         */
        export type DeleteNeedhandleData = NeedhandleDeleteForm;
        const DeleteNeedhandleUrl: AjaxPost<DeleteNeedhandleResponse, DeleteNeedhandleData> = "/efficiencyNeedhandle/deleteNeedhandle";
        /**
         * 逻辑删除删除待办
         */
        export const DeleteNeedhandle = (data: DeleteNeedhandleData) => Ajax.post(DeleteNeedhandleUrl, data);
        /**
         * OK
         */
        export type QueryByIdAndTypeResponse = AppResponse<EfficiencyNeedhandleVo>;
        export type QueryByIdAndTypeData = {
            /**
             * 所属时间
             */
            dateOfOwnership?: string;
            /**
             * 待办id
             */
            id?: number;
            /**
             * 父级待办id
             */
            nhId?: number;
            orderBy?: string;
            pageNum?: number;
            pageSize?: number;
            /**
             * 待办类型
             */
            type?: number;
        };
        const QueryByIdAndTypeUrl: AjaxGet<QueryByIdAndTypeResponse, QueryByIdAndTypeData> = "/efficiencyNeedhandle/queryByIdAndType";
        /**
         * 根据id和类型查询单个待办
         */
        export const QueryByIdAndType = (data: QueryByIdAndTypeData) => Ajax.get(QueryByIdAndTypeUrl, data);
        /**
         * OK
         */
        export type QueryLastIncompleteResponse = AppResponse<EfficiencyNeedhandleVo>;
        export type QueryLastIncompleteData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryLastIncompleteUrl: AjaxGet<QueryLastIncompleteResponse, QueryLastIncompleteData> = "/efficiencyNeedhandle/queryLastIncomplete";
        /**
         * 查询是否有上一次操作未完成的待办
         */
        export const QueryLastIncomplete = (data: QueryLastIncompleteData) => Ajax.get(QueryLastIncompleteUrl, data);
        /**
         * OK
         */
        export type QueryListByDayResponse = AppResponse<AppListResponse<EfficiencyNeedhandleVo>>;
        export type QueryListByDayData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 范围类型 1.周范围 2.月范围 3.月视图
             */
            rangeType?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryListByDayUrl: AjaxGet<QueryListByDayResponse, QueryListByDayData> = "/efficiencyNeedhandle/queryListByDay";
        /**
         * 查询某日待办
         */
        export const QueryListByDay = (data: QueryListByDayData) => Ajax.get(QueryListByDayUrl, data);
        /**
         * OK
         */
        export type QueryNeedByRangeResponse = AppResponse<AppListResponse<EfficiencyNeedhandleVo>>;
        export type QueryNeedByRangeData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 范围类型 1.周范围 2.月范围 3.月视图
             */
            rangeType?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryNeedByRangeUrl: AjaxGet<QueryNeedByRangeResponse, QueryNeedByRangeData> = "/efficiencyNeedhandle/queryNeedByRange";
        /**
         * 根据时间范围查询待办情况
         */
        export const QueryNeedByRange = (data: QueryNeedByRangeData) => Ajax.get(QueryNeedByRangeUrl, data);
        /**
         * OK
         */
        export type UpdateCompleteIncompleteResponse = AppResponse;
        /**
         * form
         */
        export type UpdateCompleteIncompleteData = EfficiencyNeedhandleUpdateForm;
        const UpdateCompleteIncompleteUrl: AjaxPost<UpdateCompleteIncompleteResponse, UpdateCompleteIncompleteData> = "/efficiencyNeedhandle/updateCompleteIncomplete";
        /**
         * 完成待办/未完成
         */
        export const UpdateCompleteIncomplete = (data: UpdateCompleteIncompleteData) => Ajax.post(UpdateCompleteIncompleteUrl, data);
        /**
         * OK
         */
        export type UpdateNeedhandleResponse = AppResponse;
        /**
         * form
         */
        export type UpdateNeedhandleData = EfficiencyNeedhandleUpdateForm;
        const UpdateNeedhandleUrl: AjaxPost<UpdateNeedhandleResponse, UpdateNeedhandleData> = "/efficiencyNeedhandle/updateNeedhandle";
        /**
         * 编辑待办
         */
        export const UpdateNeedhandle = (data: UpdateNeedhandleData) => Ajax.post(UpdateNeedhandleUrl, data);
        /**
         * OK
         */
        export type UpdateTransferNeedHandleResponse = AppResponse;
        const UpdateTransferNeedHandleUrl: AjaxPost<UpdateTransferNeedHandleResponse, never> = "/efficiencyNeedhandle/updateTransferNeedHandle";
        /**
         * 将上一次操作未完成的待办转至今日
         */
        export const UpdateTransferNeedHandle = () => Ajax.post(UpdateTransferNeedHandleUrl);
        /**
         * OK
         */
        export type UpdateTransferNeedHandleStateResponse = AppResponse;
        const UpdateTransferNeedHandleStateUrl: AjaxPost<UpdateTransferNeedHandleStateResponse, never> = "/efficiencyNeedhandle/updateTransferNeedHandleState";
        /**
         * 关闭将上一次操作未完成的待办转至今日提醒
         */
        export const UpdateTransferNeedHandleState = () => Ajax.post(UpdateTransferNeedHandleStateUrl);
    }
    /**
     * 效率首页
     */
    export namespace HomePage {
        /**
         * OK
         */
        export type QueryTargetByUserIdResponse = AppResponse<EfficiencyHomePageTargetVo>;
        const QueryTargetByUserIdUrl: AjaxPost<QueryTargetByUserIdResponse, never> = "/efficiencyhomepage/queryTargetByUserId";
        /**
         * 查询首页用户目标列表
         */
        export const QueryTargetByUserId = () => Ajax.post(QueryTargetByUserIdUrl);
        /**
         * OK
         */
        export type QueryallnewHomePageInfoResponse = AppResponse<EfficiencyHomePageVO>;
        /**
         * form
         */
        export type QueryallnewHomePageInfoData = EfficiencyHomePageShareQueryForm;
        const QueryallnewHomePageInfoUrl: AjaxPost<QueryallnewHomePageInfoResponse, QueryallnewHomePageInfoData> = "/efficiencyhomepage/queryallnewHomePageInfo";
        /**
         * 全新首页信息 当前时间+当前城市天气
         */
        export const QueryallnewHomePageInfo = (data: QueryallnewHomePageInfoData) => Ajax.post(QueryallnewHomePageInfoUrl, data);
        /**
         * OK
         */
        export type QueryallnewHomePageInfoTwoResponse = AppResponse<EfficiencyHomePageVO>;
        /**
         * form
         */
        export type QueryallnewHomePageInfoTwoData = EfficiencyHomePageShareQueryForm;
        const QueryallnewHomePageInfoTwoUrl: AjaxPost<QueryallnewHomePageInfoTwoResponse, QueryallnewHomePageInfoTwoData> = "/efficiencyhomepage/queryallnewHomePageInfoTwo";
        /**
         * 全新首页信息 当前时间+当前城市天气 2.0
         */
        export const QueryallnewHomePageInfoTwo = (data: QueryallnewHomePageInfoTwoData) => Ajax.post(QueryallnewHomePageInfoTwoUrl, data);
    }
    /**
     * 效率计时接口
     */
    export namespace EfficiencyRecordingTime {
        /**
         * OK
         */
        export type AddUserRecordingHealthStateResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type AddUserRecordingHealthStateData = EfficiencyRecordingTimeCreateForm;
        const AddUserRecordingHealthStateUrl: AjaxPost<AddUserRecordingHealthStateResponse, AddUserRecordingHealthStateData> = "/efficiencyrecordingtime/addUserRecordingHealthState";
        /**
         * 增加某用户计时记录 计时2.0
         */
        export const AddUserRecordingHealthState = (data: AddUserRecordingHealthStateData) => Ajax.post(AddUserRecordingHealthStateUrl, data);
        /**
         * OK
         */
        export type DropRecordingResponse = AppResponse;
        /**
         * form
         */
        export type DropRecordingData = EfficiencyRecordingDropTimeCreateForm;
        const DropRecordingUrl: AjaxPost<DropRecordingResponse, DropRecordingData> = "/efficiencyrecordingtime/dropRecording";
        /**
         * 放弃计时
         */
        export const DropRecording = (data: DropRecordingData) => Ajax.post(DropRecordingUrl, data);
        /**
         * OK
         */
        export type FinishTimingSubmissionResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type FinishTimingSubmissionData = EfficiencyRecordingTimeCreateForm;
        const FinishTimingSubmissionUrl: AjaxPost<FinishTimingSubmissionResponse, FinishTimingSubmissionData> = "/efficiencyrecordingtime/finishTimingSubmission";
        /**
         * 提交计时2.0加强加密版本
         */
        export const FinishTimingSubmission = (data: FinishTimingSubmissionData) => Ajax.post(FinishTimingSubmissionUrl, data);
        /**
         * OK
         */
        export type FinishTimingSubmissionThreeResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type FinishTimingSubmissionThreeData = EfficiencyRecordingTimeCreateForm;
        const FinishTimingSubmissionThreeUrl: AjaxPost<FinishTimingSubmissionThreeResponse, FinishTimingSubmissionThreeData> = "/efficiencyrecordingtime/finishTimingSubmissionThree";
        /**
         * 提交计时3.0
         */
        export const FinishTimingSubmissionThree = (data: FinishTimingSubmissionThreeData) => Ajax.post(FinishTimingSubmissionThreeUrl, data);
        /**
         * OK
         */
        export type PauseOrEndRecordingResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type PauseOrEndRecordingData = EfficiencyRecordingTimeCreateForm;
        const PauseOrEndRecordingUrl: AjaxPost<PauseOrEndRecordingResponse, PauseOrEndRecordingData> = "/efficiencyrecordingtime/pauseOrEndRecording";
        /**
         * 暂停或结束用户计时 2.0
         */
        export const PauseOrEndRecording = (data: PauseOrEndRecordingData) => Ajax.post(PauseOrEndRecordingUrl, data);
        /**
         * OK
         */
        export type QueryCurrentAbsorbedNumberResponse = AppResponse<IntegerVo>;
        const QueryCurrentAbsorbedNumberUrl: AjaxGet<QueryCurrentAbsorbedNumberResponse, never> = "/efficiencyrecordingtime/queryCurrentAbsorbedNumber";
        /**
         * 查询当前多少人正在专注
         */
        export const QueryCurrentAbsorbedNumber = () => Ajax.get(QueryCurrentAbsorbedNumberUrl);
        /**
         * OK
         */
        export type QueryRecordDetailResponse = AppResponse<EfficiencyRecordingInfoInDynamicVo>;
        export type QueryRecordDetailData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 主键ID
             */
            id: number;
        };
        const QueryRecordDetailUrl: AjaxGet<QueryRecordDetailResponse, QueryRecordDetailData> = "/efficiencyrecordingtime/queryRecordDetail";
        /**
         * 动态详情点击标签查询计时详情
         */
        export const QueryRecordDetail = (data: QueryRecordDetailData) => Ajax.get(QueryRecordDetailUrl, data);
        /**
         * OK
         */
        export type UpdateUserRecordingHealthStateResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type UpdateUserRecordingHealthStateData = EfficiencyRecordingTimeCreateForm;
        const UpdateUserRecordingHealthStateUrl: AjaxPost<UpdateUserRecordingHealthStateResponse, UpdateUserRecordingHealthStateData> = "/efficiencyrecordingtime/updateUserRecordingHealthState";
        /**
         * 计时保活或继续 计时2.0
         */
        export const UpdateUserRecordingHealthState = (data: UpdateUserRecordingHealthStateData) => Ajax.post(UpdateUserRecordingHealthStateUrl, data);
    }
    /**
     * Basic Error Controller
     */
    export namespace BasicError {
        /**
         * OK
         */
        export type ErrorGetResponse = ModelAndView;
        const ErrorGetUrl: AjaxGet<ErrorGetResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorGet = () => Ajax.get(ErrorGetUrl);
        /**
         * OK
         */
        export type ErrorHeadResponse = ModelAndView;
        const ErrorHeadUrl: AjaxGet<ErrorHeadResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorHead = () => Ajax.get(ErrorHeadUrl);
        /**
         * OK
         */
        export type ErrorPostResponse = ModelAndView;
        const ErrorPostUrl: AjaxPost<ErrorPostResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorPost = () => Ajax.post(ErrorPostUrl);
        /**
         * OK
         */
        export type ErrorPutResponse = ModelAndView;
        const ErrorPutUrl: AjaxPost<ErrorPutResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorPut = () => Ajax.post(ErrorPutUrl);
        /**
         * OK
         */
        export type ErrorDeleteResponse = ModelAndView;
        const ErrorDeleteUrl: AjaxPost<ErrorDeleteResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorDelete = () => Ajax.post(ErrorDeleteUrl);
        /**
         * OK
         */
        export type ErrorOptionsResponse = ModelAndView;
        const ErrorOptionsUrl: AjaxPost<ErrorOptionsResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorOptions = () => Ajax.post(ErrorOptionsUrl);
        /**
         * OK
         */
        export type ErrorPatchResponse = ModelAndView;
        const ErrorPatchUrl: AjaxPost<ErrorPatchResponse, never> = "/error";
        /**
         * errorHtml
         */
        export const ErrorPatch = () => Ajax.post(ErrorPatchUrl);
    }
    /**
     * 商品品牌接口
     */
    export namespace ShopBrand {
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<ShopBrandVo>>;
        const QueryListUrl: AjaxGet<QueryListResponse, never> = "/goods/brand/queryList";
        /**
         * 查询品牌列表
         */
        export const QueryList = () => Ajax.get(QueryListUrl);
    }
    /**
     * 商品接口
     */
    export namespace ShopGoods {
        /**
         * OK
         */
        export type CalculateShippingFeeResponse = AppResponse<ShopGoodsShipAndDeliverAddressVo>;
        export type CalculateShippingFeeData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * spuId
             */
            spuId: number;
        };
        const CalculateShippingFeeUrl: AjaxGet<CalculateShippingFeeResponse, CalculateShippingFeeData> = "/goods/calculateShippingFee";
        /**
         * 计算运费
         */
        export const CalculateShippingFee = (data: CalculateShippingFeeData) => Ajax.get(CalculateShippingFeeUrl, data);
        /**
         * OK
         */
        export type GuessUserLikeResponse = AppResponse<ShopUserLikeGoodsVo>;
        export type GuessUserLikeData = {
            /**
             * spuid
             */
            spuId: number;
        };
        const GuessUserLikeUrl: AjaxGet<GuessUserLikeResponse, GuessUserLikeData> = "/goods/guessUserLike";
        /**
         * 猜你喜欢
         */
        export const GuessUserLike = (data: GuessUserLikeData) => Ajax.get(GuessUserLikeUrl, data);
        /**
         * OK
         */
        export type QueryGoodsActivityResponse = AppResponse<UtilVo>;
        export type QueryGoodsActivityData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 商品id
             */
            itemId: number;
        };
        const QueryGoodsActivityUrl: AjaxGet<QueryGoodsActivityResponse, QueryGoodsActivityData> = "/goods/queryGoodsActivity";
        /**
         * 查询换选规格后参与的活动
         */
        export const QueryGoodsActivity = (data: QueryGoodsActivityData) => Ajax.get(QueryGoodsActivityUrl, data);
        /**
         * OK
         */
        export type QueryGoodsListInEsResponse = AppResponse<ShopGoodsCommonViewEsGoodsListVo>;
        export type QueryGoodsListInEsData = {
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 排序字段 1按销量排 2按价格排 3按发布时间排
             */
            sortColumn?: number;
            /**
             * 排序类型 1顺序 2倒序
             */
            sortType?: number;
            /**
             * 品牌id - ,号连接
             */
            brandIdList?: string;
            /**
             * 分类id- ,号连接
             */
            appClassIdList?: string;
            /**
             * 是否二级分类id 0不是 1是
             */
            isParentClassId?: number;
            /**
             * appClass id
             */
            appClassId?: number;
            /**
             * 搜索初始价格
             */
            minPrice?: number;
            /**
             * 搜索结束价格
             */
            maxPrice?: number;
            /**
             * 用户输入内容
             */
            content?: string;
            /**
             * 分词类型 1商品 2品牌 3分类 -- 无类型不传
             */
            type?: number;
        };
        const QueryGoodsListInEsUrl: AjaxGet<QueryGoodsListInEsResponse, QueryGoodsListInEsData> = "/goods/queryGoodsListInEs";
        /**
         * 搜索页-商品详情信息
         */
        export const QueryGoodsListInEs = (data: QueryGoodsListInEsData) => Ajax.get(QueryGoodsListInEsUrl, data);
        /**
         * OK
         */
        export type QueryGoodsSpecResponse = AppResponse<OriginShopGoodsSpecAndItemInfoVo>;
        export type QueryGoodsSpecData = {
            /**
             * 货品id
             */
            goodsCommonId: number;
        };
        const QueryGoodsSpecUrl: AjaxGet<QueryGoodsSpecResponse, QueryGoodsSpecData> = "/goods/queryGoodsSpec";
        /**
         * 查询货品规格
         */
        export const QueryGoodsSpec = (data: QueryGoodsSpecData) => Ajax.get(QueryGoodsSpecUrl, data);
        /**
         * OK
         */
        export type QueryItemDetailResponse = AppResponse<ShopGoodsCommonDetailVo>;
        export type QueryItemDetailData = {
            /**
             * spu id
             */
            goodsCommonId: number;
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryItemDetailUrl: AjaxGet<QueryItemDetailResponse, QueryItemDetailData> = "/goods/queryItemDetail";
        /**
         * 查询spu详情
         */
        export const QueryItemDetail = (data: QueryItemDetailData) => Ajax.get(QueryItemDetailUrl, data);
        /**
         * OK
         */
        export type QueryItemExtraDetailResponse = AppResponse<ShopItemExtraDetailVo>;
        export type QueryItemExtraDetailData = {
            /**
             * spu id
             */
            goodsCommonId: number;
        };
        const QueryItemExtraDetailUrl: AjaxGet<QueryItemExtraDetailResponse, QueryItemExtraDetailData> = "/goods/queryItemExtraDetail";
        /**
         * 查询商品详情--底部样式
         */
        export const QueryItemExtraDetail = (data: QueryItemExtraDetailData) => Ajax.get(QueryItemExtraDetailUrl, data);
        /**
         * OK
         */
        export type QueryNameInEsResponse = AppResponse<AppListResponse<ShopSearchNameListResponse>>;
        export type QueryNameInEsData = {
            /**
             * name
             */
            name: string;
        };
        const QueryNameInEsUrl: AjaxGet<QueryNameInEsResponse, QueryNameInEsData> = "/goods/queryNameInEs";
        /**
         * 搜索页-下拉名称查询
         */
        export const QueryNameInEs = (data: QueryNameInEsData) => Ajax.get(QueryNameInEsUrl, data);
        /**
         * OK
         */
        export type QueryPageListResponse = AppResponse<AppPageListResponse<ShopGoodsCommonViewInAppVo>>;
        export type QueryPageListData = {
            /**
             * 前台分类列表
             */
            appClassIdList?: number[];
            /**
             * 品牌列表
             */
            brandIdList?: number[];
            /**
             * 过滤掉的商品公共表id列表
             */
            excludedGoodsCommonIdList?: number[];
            /**
             * 商品公共表id列表
             */
            goodsCommonidList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 品牌id
             */
            brandId?: number;
            /**
             * 品牌名称
             */
            brandName?: string;
            /**
             * 需要过滤的商品分类id
             */
            excludedGcId?: number;
            /**
             * 商品分类
             */
            gcId?: number;
            /**
             * 商品分类
             */
            gcName?: string;
            /**
             * 商品属性
             */
            goodsAttr?: string;
            /**
             * 商品推荐 1是，0否，默认为0
             */
            goodsCommend?: number;
            /**
             * 商品公共表id
             */
            goodsCommonid?: number;
            /**
             * 成本价
             */
            goodsCostprice?: number;
            /**
             * 商品锁定 0未锁，1已锁
             */
            goodsLock?: number;
            /**
             * 市场价
             */
            goodsMarketprice?: number;
            /**
             * 商品名称
             */
            goodsName?: string;
            /**
             * 商家条形码编号
             */
            goodsSerial?: string;
            /**
             * 商品状态 0下架，1正常
             */
            goodsState?: number;
            /**
             * 店铺分类id
             */
            goodsStcid?: number;
            /**
             * 是否删除0未删除 1已删除
             */
            isDel?: number;
            /**
             * 商品价格(大)
             */
            maxGoodsPrice?: number;
            /**
             * 商品价格(小)
             */
            minGoodsPrice?: number;
            /**
             * app模糊查询内容
             */
            multiName?: string;
            /**
             * 查询类型对应的查询内容--后台
             */
            multiValue?: string;
            /**
             * 查询类型，1商家名称，2商家货号，3平台货号
             */
            queryType?: number;
            /**
             * 规格名称
             */
            specName?: string;
            /**
             * 规格值
             */
            specValue?: string;
            /**
             * 店铺id
             */
            storeId?: number;
            /**
             * 店铺名称
             */
            storeName?: string;
            /**
             * 运费模板Id
             */
            transportId?: number;
            /**
             * 运费模板Id列表
             */
            transportIdList?: number;
            /**
             * 类型id
             */
            typeId?: number;
        };
        const QueryPageListUrl: AjaxGet<QueryPageListResponse, QueryPageListData> = "/goods/queryPageList";
        /**
         * 查询spu列表
         */
        export const QueryPageList = (data: QueryPageListData) => Ajax.get(QueryPageListUrl, data);
        /**
         * OK
         */
        export type QuerySpuActivityResponse = AppResponse<AppListResponse<ShopSpuActivityDetailVo>>;
        export type QuerySpuActivityData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * spu id
             */
            goodsCommonId: number;
            /**
             * sku id
             */
            itemId?: number;
        };
        const QuerySpuActivityUrl: AjaxGet<QuerySpuActivityResponse, QuerySpuActivityData> = "/goods/querySpuActivity";
        /**
         * 查询商品促销活动
         */
        export const QuerySpuActivity = (data: QuerySpuActivityData) => Ajax.get(QuerySpuActivityUrl, data);
        /**
         * OK
         */
        export type SeeAgainAfterConfirmResponse = AppResponse<ShopUserLikeGoodsVo>;
        export type SeeAgainAfterConfirmData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const SeeAgainAfterConfirmUrl: AjaxGet<SeeAgainAfterConfirmResponse, SeeAgainAfterConfirmData> = "/goods/seeAgainAfterConfirm";
        /**
         * 确认收货后-看了又看
         */
        export const SeeAgainAfterConfirm = (data: SeeAgainAfterConfirmData) => Ajax.get(SeeAgainAfterConfirmUrl, data);
        /**
         * OK
         */
        export type StoreItemResponse = AppResponse<ShopFavoritesVo>;
        /**
         * form
         */
        export type StoreItemData = ShopFavoritesCreateForm;
        const StoreItemUrl: AjaxPost<StoreItemResponse, StoreItemData> = "/goods/storeItem";
        /**
         * 收藏货品
         */
        export const StoreItem = (data: StoreItemData) => Ajax.post(StoreItemUrl, data);
    }
    /**
     * 前台分类接口
     */
    export namespace ShopClass {
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<ShopAppClassVo>>;
        export type QueryListData = {
            /**
             * 列表
             */
            idList?: number[];
            /**
             * 上级类目id列表
             */
            parentIdList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 分类图片
             */
            classImage?: string;
            /**
             * 类目名称
             */
            className?: string;
            id?: number;
            /**
             * 上级类目id 顶级默认为0
             */
            parentId?: number;
            /**
             * 是否展示在app首页 0不展示 1展示
             */
            showInIndex?: number;
            /**
             * 排序
             */
            sort?: number;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/goods/class/queryList";
        /**
         * 查询前台分类列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
    }
    /**
     * 首页接口
     */
    export namespace ShopIndex {
        /**
         * OK
         */
        export type QueryDiscountGoodsResponse = AppResponse<ShopDiscountIndexVo>;
        export type QueryDiscountGoodsData = {
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 活动id
             */
            discountId: number;
        };
        const QueryDiscountGoodsUrl: AjaxGet<QueryDiscountGoodsResponse, QueryDiscountGoodsData> = "/goods/index/queryDiscountGoods";
        /**
         * 查询首页折扣活动商品
         */
        export const QueryDiscountGoods = (data: QueryDiscountGoodsData) => Ajax.get(QueryDiscountGoodsUrl, data);
        /**
         * OK
         */
        export type QueryDiscountListResponse = AppResponse<AppListResponse<ShopDiscountIndexVo>>;
        const QueryDiscountListUrl: AjaxGet<QueryDiscountListResponse, never> = "/goods/index/queryDiscountList";
        /**
         * 查询首页显示抢购活动
         */
        export const QueryDiscountList = () => Ajax.get(QueryDiscountListUrl);
        /**
         * OK
         */
        export type QueryIndexClassListResponse = AppResponse<ShopGoodsIndexVo>;
        const QueryIndexClassListUrl: AjaxGet<QueryIndexClassListResponse, never> = "/goods/index/queryIndexClassList";
        /**
         * 首页展示信息列表
         */
        export const QueryIndexClassList = () => Ajax.get(QueryIndexClassListUrl);
    }
    /**
     * 首页活动
     */
    export namespace ShopActivity {
        /**
         * OK
         */
        export type QueryResponse = AppResponse<ShopActivityVo>;
        export type QueryData = {
            /**
             * id
             */
            id: number;
        };
        const QueryUrl: AjaxGet<QueryResponse, QueryData> = "/index/activity/query";
        /**
         * 查询活动表
         */
        export const Query = (data: QueryData) => Ajax.get(QueryUrl, data);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<ShopActivityVo>>;
        export type QueryListData = {
            /**
             * 列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 活动名称
             */
            activityName?: string;
            /**
             * 是否可分享 0:不可以；1：可以
             */
            canShare?: number;
            /**
             * 描述
             */
            description?: string;
            /**
             * html数据
             */
            htmlText?: string;
            /**
             * 分享图标
             */
            iconImage?: string;
            id?: number;
            /**
             * json数据
             */
            jsonText?: string;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/index/activity/queryList";
        /**
         * 查询活动表列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
        /**
         * OK
         */
        export type QueryPageListResponse = AppResponse<AppPageListResponse<ShopActivityVo>>;
        export type QueryPageListData = {
            /**
             * 列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 活动名称
             */
            activityName?: string;
            /**
             * 是否可分享 0:不可以；1：可以
             */
            canShare?: number;
            /**
             * 描述
             */
            description?: string;
            /**
             * html数据
             */
            htmlText?: string;
            /**
             * 分享图标
             */
            iconImage?: string;
            id?: number;
            /**
             * json数据
             */
            jsonText?: string;
        };
        const QueryPageListUrl: AjaxGet<QueryPageListResponse, QueryPageListData> = "/index/activity/queryPageList";
        /**
         * 查询活动表列表(带分页)
         */
        export const QueryPageList = (data: QueryPageListData) => Ajax.get(QueryPageListUrl, data);
    }
    /**
     * 订单接口
     */
    export namespace ShopOrder {
        /**
         * OK
         */
        export type OrderIsPayResponse = AppResponse<ShopOrderVo>;
        export type OrderIsPayData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 订单id
             */
            orderId: number;
        };
        const OrderIsPayUrl: AjaxGet<OrderIsPayResponse, OrderIsPayData> = "/order/OrderIsPay";
        /**
         * 支付后查询订单状态
         */
        export const OrderIsPay = (data: OrderIsPayData) => Ajax.get(OrderIsPayUrl, data);
        /**
         * OK
         */
        export type ApplyReceiptResponse = AppResponse;
        /**
         * form
         */
        export type ApplyReceiptData = OriginShopInvoiceCreateForm;
        const ApplyReceiptUrl: AjaxPost<ApplyReceiptResponse, ApplyReceiptData> = "/order/applyReceipt";
        /**
         * 申请开票
         */
        export const ApplyReceipt = (data: ApplyReceiptData) => Ajax.post(ApplyReceiptUrl, data);
        /**
         * OK
         */
        export type CancelOrderResponse = AppResponse;
        /**
         * form
         */
        export type CancelOrderData = ShopOrderCancelForm;
        const CancelOrderUrl: AjaxPost<CancelOrderResponse, CancelOrderData> = "/order/cancelOrder";
        /**
         * 取消订单
         */
        export const CancelOrder = (data: CancelOrderData) => Ajax.post(CancelOrderUrl, data);
        /**
         * OK
         */
        export type CancelRefundGoodsResponse = AppResponse;
        /**
         * form
         */
        export type CancelRefundGoodsData = ShopRefundCancelForm;
        const CancelRefundGoodsUrl: AjaxPost<CancelRefundGoodsResponse, CancelRefundGoodsData> = "/order/cancelRefundGoods";
        /**
         * 撤销退货申请
         */
        export const CancelRefundGoods = (data: CancelRefundGoodsData) => Ajax.post(CancelRefundGoodsUrl, data);
        /**
         * OK
         */
        export type ConfirmGoodsResponse = AppResponse;
        /**
         * form
         */
        export type ConfirmGoodsData = ShopOrderConfirmGoodsForm;
        const ConfirmGoodsUrl: AjaxPost<ConfirmGoodsResponse, ConfirmGoodsData> = "/order/confirmGoods";
        /**
         * 确认收货
         */
        export const ConfirmGoods = (data: ConfirmGoodsData) => Ajax.post(ConfirmGoodsUrl, data);
        /**
         * OK
         */
        export type ConfirmOrderResponse = AppResponse<ShopOrderAppPayParamsVo>;
        /**
         * form
         */
        export type ConfirmOrderData = ShopOrderAppCreateForm;
        const ConfirmOrderUrl: AjaxPost<ConfirmOrderResponse, ConfirmOrderData> = "/order/confirmOrder";
        /**
         * 提交订单
         */
        export const ConfirmOrder = (data: ConfirmOrderData) => Ajax.post(ConfirmOrderUrl, data);
        /**
         * OK
         */
        export type ContinuePayResponse = AppResponse<ShopOrderAppPayParamsVo>;
        /**
         * form
         */
        export type ContinuePayData = ShopOrderContinuePayForm;
        const ContinuePayUrl: AjaxPost<ContinuePayResponse, ContinuePayData> = "/order/continuePay";
        /**
         * 再支付
         */
        export const ContinuePay = (data: ContinuePayData) => Ajax.post(ContinuePayUrl, data);
        /**
         * OK
         */
        export type CreateOrderResponse = AppResponse<ShopOrderParamVo>;
        /**
         * form
         */
        export type CreateOrderData = ShopOrderCreatePreForm;
        const CreateOrderUrl: AjaxPost<CreateOrderResponse, CreateOrderData> = "/order/createOrder";
        /**
         * 创建订单-参数
         */
        export const CreateOrder = (data: CreateOrderData) => Ajax.post(CreateOrderUrl, data);
        /**
         * OK
         */
        export type CreatePointsOrderResponse = AppResponse<ShopOrderAppPayParamsVo>;
        /**
         * createForm
         */
        export type CreatePointsOrderData = AppShopPointsOrderCreateForm;
        const CreatePointsOrderUrl: AjaxPost<CreatePointsOrderResponse, CreatePointsOrderData> = "/order/createPointsOrder";
        /**
         * 创建燃豆订单
         */
        export const CreatePointsOrder = (data: CreatePointsOrderData) => Ajax.post(CreatePointsOrderUrl, data);
        /**
         * OK
         */
        export type DeleteOrderResponse = AppResponse;
        /**
         * form
         */
        export type DeleteOrderData = ShopOrderDeleteForm;
        const DeleteOrderUrl: AjaxPost<DeleteOrderResponse, DeleteOrderData> = "/order/deleteOrder";
        /**
         * 删除订单
         */
        export const DeleteOrder = (data: DeleteOrderData) => Ajax.post(DeleteOrderUrl, data);
        /**
         * OK
         */
        export type IphoneResponse = AppResponse<ShopCallBackOfIphoneVo>;
        /**
         * certForm
         */
        export type IphoneData = ShopIphoneCertForm;
        const IphoneUrl: AjaxPost<IphoneResponse, IphoneData> = "/order/iphone";
        /**
         * 回调验证iphone支付票据
         */
        export const Iphone = (data: IphoneData) => Ajax.post(IphoneUrl, data);
        /**
         * OK
         */
        export type QueryApplyReceiptPreInfoResponse = AppResponse<ShopApplyInvoicePreVo>;
        export type QueryApplyReceiptPreInfoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 订单id
             */
            orderId: number;
        };
        const QueryApplyReceiptPreInfoUrl: AjaxGet<QueryApplyReceiptPreInfoResponse, QueryApplyReceiptPreInfoData> = "/order/queryApplyReceiptPreInfo";
        /**
         * 开票前置信息查询
         */
        export const QueryApplyReceiptPreInfo = (data: QueryApplyReceiptPreInfoData) => Ajax.get(QueryApplyReceiptPreInfoUrl, data);
        /**
         * OK
         */
        export type QueryCanEvalOrderGoodsListResponse = AppResponse<AppListResponse<ShopCanEvalOrderSpuVo>>;
        export type QueryCanEvalOrderGoodsListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * orderId
             */
            orderId?: number;
        };
        const QueryCanEvalOrderGoodsListUrl: AjaxGet<QueryCanEvalOrderGoodsListResponse, QueryCanEvalOrderGoodsListData> = "/order/queryCanEvalOrderGoodsList";
        /**
         * 查询订单中可评价的商品
         */
        export const QueryCanEvalOrderGoodsList = (data: QueryCanEvalOrderGoodsListData) => Ajax.get(QueryCanEvalOrderGoodsListUrl, data);
        /**
         * OK
         */
        export type QueryDetailResponse = AppResponse<OriginShopOrderVo>;
        export type QueryDetailData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 订单id
             */
            orderId: number;
        };
        const QueryDetailUrl: AjaxGet<QueryDetailResponse, QueryDetailData> = "/order/queryDetail";
        /**
         * 查询订单详情
         */
        export const QueryDetail = (data: QueryDetailData) => Ajax.get(QueryDetailUrl, data);
        /**
         * OK
         */
        export type QueryGoodsCanRefundResponse = AppResponse;
        /**
         * form
         */
        export type QueryGoodsCanRefundData = ShopOrderGoodsCanRefundQueryForm;
        const QueryGoodsCanRefundUrl: AjaxPost<QueryGoodsCanRefundResponse, QueryGoodsCanRefundData> = "/order/queryGoodsCanRefund";
        /**
         * 已发货商品是否可退款
         */
        export const QueryGoodsCanRefund = (data: QueryGoodsCanRefundData) => Ajax.post(QueryGoodsCanRefundUrl, data);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppPageListResponse<OriginShopOrderVo>>;
        export type QueryListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 订单状态：0(已取消)1(默认):未付款;2:已付款;3:已发货;4:已收货;5:超时未付款;6:交易关闭
             */
            orderState?: number;
            /**
             * 退款状态:0是无退款,1退款中，2退款成功，3退款失败
             */
            refundState?: number;
            /**
             * 模糊查询字段
             */
            multiPart?: string;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/order/queryList";
        /**
         * 查询订单列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
        /**
         * OK
         */
        export type QueryOrderExpressResponse = AppResponse<ShopOrderExpressVo>;
        export type QueryOrderExpressData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 订单id
             */
            orderId: number;
        };
        const QueryOrderExpressUrl: AjaxGet<QueryOrderExpressResponse, QueryOrderExpressData> = "/order/queryOrderExpress";
        /**
         * 查看订单物流
         */
        export const QueryOrderExpress = (data: QueryOrderExpressData) => Ajax.get(QueryOrderExpressUrl, data);
        /**
         * OK
         */
        export type QueryReceiptInfoResponse = AppResponse<ShopOrderInvoiceVo>;
        export type QueryReceiptInfoData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 订单id
             */
            orderId: number;
        };
        const QueryReceiptInfoUrl: AjaxGet<QueryReceiptInfoResponse, QueryReceiptInfoData> = "/order/queryReceiptInfo";
        /**
         * 查看订单发票信息
         */
        export const QueryReceiptInfo = (data: QueryReceiptInfoData) => Ajax.get(QueryReceiptInfoUrl, data);
        /**
         * OK
         */
        export type QueryRefundPriceResponse = AppResponse<ShopRefundGoodsPriceVo>;
        /**
         * form
         */
        export type QueryRefundPriceData = ShopRefundPriceQueryForm;
        const QueryRefundPriceUrl: AjaxPost<QueryRefundPriceResponse, QueryRefundPriceData> = "/order/queryRefundPrice";
        /**
         * 商品退款价格计算
         */
        export const QueryRefundPrice = (data: QueryRefundPriceData) => Ajax.post(QueryRefundPriceUrl, data);
        /**
         * OK
         */
        export type QueryRefundReasonListResponse = AppResponse<ShopRefundReasonVo>;
        const QueryRefundReasonListUrl: AjaxPost<QueryRefundReasonListResponse, never> = "/order/queryRefundReasonList";
        /**
         * 退款原因
         */
        export const QueryRefundReasonList = () => Ajax.post(QueryRefundReasonListUrl);
        /**
         * OK
         */
        export type RefundResponse = AppResponse;
        /**
         * applyForm
         */
        export type RefundData = ShopRefundApplyForm;
        const RefundUrl: AjaxPost<RefundResponse, RefundData> = "/order/refund";
        /**
         * 退款申请
         */
        export const Refund = (data: RefundData) => Ajax.post(RefundUrl, data);
        /**
         * OK
         */
        export type RefundDetailResponse = AppResponse<OriginShopRefundVo>;
        /**
         * form
         */
        export type RefundDetailData = ShopRefundCancelForm;
        const RefundDetailUrl: AjaxPost<RefundDetailResponse, RefundDetailData> = "/order/refundDetail";
        /**
         * 退款详情
         */
        export const RefundDetail = (data: RefundDetailData) => Ajax.post(RefundDetailUrl, data);
        /**
         * OK
         */
        export type RefundDetailInExpressResponse = AppResponse<ShopRefundDetailInExpressVo>;
        export type RefundDetailInExpressData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 退款单id
             */
            refundId: number;
        };
        const RefundDetailInExpressUrl: AjaxGet<RefundDetailInExpressResponse, RefundDetailInExpressData> = "/order/refundDetailInExpress";
        /**
         * 退款详情-填写退款物流页面
         */
        export const RefundDetailInExpress = (data: RefundDetailInExpressData) => Ajax.get(RefundDetailInExpressUrl, data);
        /**
         * OK
         */
        export type RefundDetailRouteResponse = AppResponse<ShopRefundDetailTypeVo>;
        export type RefundDetailRouteData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 退款单id
             */
            refundId: number;
        };
        const RefundDetailRouteUrl: AjaxGet<RefundDetailRouteResponse, RefundDetailRouteData> = "/order/refundDetailRoute";
        /**
         * 已发货订单退款-退款中商品跳转详情页面
         */
        export const RefundDetailRoute = (data: RefundDetailRouteData) => Ajax.get(RefundDetailRouteUrl, data);
        /**
         * OK
         */
        export type RefundOrderGoodsResponse = AppResponse;
        /**
         * form
         */
        export type RefundOrderGoodsData = ShopRefundGoodsForm;
        const RefundOrderGoodsUrl: AjaxPost<RefundOrderGoodsResponse, RefundOrderGoodsData> = "/order/refundOrderGoods";
        /**
         * 填写退货物流
         */
        export const RefundOrderGoods = (data: RefundOrderGoodsData) => Ajax.post(RefundOrderGoodsUrl, data);
        /**
         * OK
         */
        export type RegisterVipOrderResponse = AppResponse<ShopOrderAppPayParamsVo>;
        /**
         * createForm
         */
        export type RegisterVipOrderData = ShopVipOrderCreateForm;
        const RegisterVipOrderUrl: AjaxPost<RegisterVipOrderResponse, RegisterVipOrderData> = "/order/registerVipOrder";
        /**
         * 开通会员订单
         */
        export const RegisterVipOrder = (data: RegisterVipOrderData) => Ajax.post(RegisterVipOrderUrl, data);
        /**
         * OK
         */
        export type TestSchduleResponse = AppResponse;
        const TestSchduleUrl: AjaxPost<TestSchduleResponse, never> = "/order/testSchdule";
        /**
         * 定时器测试
         */
        export const TestSchdule = () => Ajax.post(TestSchduleUrl);
        /**
         * OK
         */
        export type UpdateOrderAddressResponse = AppResponse;
        /**
         * form
         */
        export type UpdateOrderAddressData = ShopOrderAddressUpdateForm;
        const UpdateOrderAddressUrl: AjaxPost<UpdateOrderAddressResponse, UpdateOrderAddressData> = "/order/updateOrderAddress";
        /**
         * 修改订单地址
         */
        export const UpdateOrderAddress = (data: UpdateOrderAddressData) => Ajax.post(UpdateOrderAddressUrl, data);
        /**
         * OK
         */
        export type UpdateRefundApplyResponse = AppResponse<ShopRefundVo>;
        /**
         * form
         */
        export type UpdateRefundApplyData = ShopRefundApplyUpdateForm;
        const UpdateRefundApplyUrl: AjaxPost<UpdateRefundApplyResponse, UpdateRefundApplyData> = "/order/updateRefundApply";
        /**
         * 修改退款申请
         */
        export const UpdateRefundApply = (data: UpdateRefundApplyData) => Ajax.post(UpdateRefundApplyUrl, data);
    }
    /**
     * 计时和周计划接口
     */
    export namespace WeekPlan {
        /**
         * OK
         */
        export type AddAbsorbedNumResponse = AppResponse;
        const AddAbsorbedNumUrl: AjaxPost<AddAbsorbedNumResponse, never> = "/recordingtimeAndWeekPlan/addAbsorbedNum";
        /**
         * 增加同地区专注人数
         */
        export const AddAbsorbedNum = () => Ajax.post(AddAbsorbedNumUrl);
        /**
         * OK
         */
        export type AddRecordingTimeResponse = AppResponse<EfficiencyRecordingTimeVo>;
        /**
         * form
         */
        export type AddRecordingTimeData = EfficiencyRecordingTimeCreateForm;
        const AddRecordingTimeUrl: AjaxPost<AddRecordingTimeResponse, AddRecordingTimeData> = "/recordingtimeAndWeekPlan/addRecordingTime";
        /**
         * 新增计时表
         */
        export const AddRecordingTime = (data: AddRecordingTimeData) => Ajax.post(AddRecordingTimeUrl, data);
        /**
         * OK
         */
        export type AddWeekPlanResponse = AppResponse<EfficiencyWeekPlanVo>;
        /**
         * form
         */
        export type AddWeekPlanData = EfficiencyWeekPlanCreateForm;
        const AddWeekPlanUrl: AjaxPost<AddWeekPlanResponse, AddWeekPlanData> = "/recordingtimeAndWeekPlan/addWeekPlan";
        /**
         * 新增周计划表
         */
        export const AddWeekPlan = (data: AddWeekPlanData) => Ajax.post(AddWeekPlanUrl, data);
        /**
         * OK
         */
        export type QueryAbsorbedNumResponse = AppResponse<number>;
        export type QueryAbsorbedNumData = {
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 地区id
             */
            cityId?: number;
            /**
             * 主键ID
             */
            id?: number;
            /**
             * 0榜上 1榜下
             */
            isup?: number;
            /**
             * 榜单类型 1总榜 2日榜 3周榜 4月榜
             */
            listType?: number;
            /**
             * 待办id
             */
            nhId?: number;
            /**
             * 待办类型 0普通待办 1重复待办
             */
            nhType?: number;
            /**
             * 用户ID
             */
            uid?: string;
        };
        const QueryAbsorbedNumUrl: AjaxGet<QueryAbsorbedNumResponse, QueryAbsorbedNumData> = "/recordingtimeAndWeekPlan/queryAbsorbedNum";
        /**
         * 查询同地区专注人数
         */
        export const QueryAbsorbedNum = (data: QueryAbsorbedNumData) => Ajax.get(QueryAbsorbedNumUrl, data);
        /**
         * OK
         */
        export type QueryAbsorbedStateByUserResponse = AppResponse<number>;
        export type QueryAbsorbedStateByUserData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryAbsorbedStateByUserUrl: AjaxGet<QueryAbsorbedStateByUserResponse, QueryAbsorbedStateByUserData> = "/recordingtimeAndWeekPlan/queryAbsorbedStateByUser";
        /**
         * 查询某用户是否专注状态
         */
        export const QueryAbsorbedStateByUser = (data: QueryAbsorbedStateByUserData) => Ajax.get(QueryAbsorbedStateByUserUrl, data);
        /**
         * OK
         */
        export type QueryByFollowUserRecordSortResponse = AppResponse<AppPageListResponse<EfficiencyRecordingTimeVo>>;
        export type QueryByFollowUserRecordSortData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 排行榜类型 1总榜 2日榜3周榜 4月榜
             */
            listType?: number;
            /**
             * 页码
             */
            pageNum?: number;
            /**
             * 页尺寸
             */
            pageSize?: number;
        };
        const QueryByFollowUserRecordSortUrl: AjaxGet<QueryByFollowUserRecordSortResponse, QueryByFollowUserRecordSortData> = "/recordingtimeAndWeekPlan/queryByFollowUserRecordSort";
        /**
         * 查询计时专注排行
         */
        export const QueryByFollowUserRecordSort = (data: QueryByFollowUserRecordSortData) => Ajax.get(QueryByFollowUserRecordSortUrl, data);
        /**
         * OK
         */
        export type QueryListByUserIdAndDayResponse = AppResponse<EfficiencyWeekPlanVo>;
        export type QueryListByUserIdAndDayData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 所属日期,格式为:yyyy-MM-dd
             */
            dateOfOwnership?: string;
            /**
             * 主键id
             */
            id?: number;
            /**
             * 0为计划 1为总结
             */
            type?: number;
            /**
             * 用户id
             */
            uid?: string;
        };
        const QueryListByUserIdAndDayUrl: AjaxGet<QueryListByUserIdAndDayResponse, QueryListByUserIdAndDayData> = "/recordingtimeAndWeekPlan/queryListByUserIdAndDay";
        /**
         * 根据日期查询周计划
         */
        export const QueryListByUserIdAndDay = (data: QueryListByUserIdAndDayData) => Ajax.get(QueryListByUserIdAndDayUrl, data);
        /**
         * OK
         */
        export type QueryUserWeekPlanHistoryResponse = AppResponse<AppPageListResponse<EfficiencyWeekPlanOverviewVo>>;
        /**
         * efficiencyWeekPlanQueryForm
         */
        export type QueryUserWeekPlanHistoryData = EfficiencyWeekPlanQueryForm;
        const QueryUserWeekPlanHistoryUrl: AjaxPost<QueryUserWeekPlanHistoryResponse, QueryUserWeekPlanHistoryData> = "/recordingtimeAndWeekPlan/queryUserWeekPlanHistory";
        /**
         * 修改周计划完成状态
         */
        export const QueryUserWeekPlanHistory = (data: QueryUserWeekPlanHistoryData) => Ajax.post(QueryUserWeekPlanHistoryUrl, data);
        /**
         * OK
         */
        export type UpdateDisableWeekPlanResponse = AppResponse;
        /**
         * form
         */
        export type UpdateDisableWeekPlanData = EfficiencyWeekPlanUpdateForm;
        const UpdateDisableWeekPlanUrl: AjaxPost<UpdateDisableWeekPlanResponse, UpdateDisableWeekPlanData> = "/recordingtimeAndWeekPlan/updateDisableWeekPlan";
        /**
         * 逻辑删除周计划
         */
        export const UpdateDisableWeekPlan = (data: UpdateDisableWeekPlanData) => Ajax.post(UpdateDisableWeekPlanUrl, data);
        /**
         * OK
         */
        export type UpdateWeekPlanResponse = AppResponse;
        /**
         * form
         */
        export type UpdateWeekPlanData = EfficiencyWeekPlanUpdateForm;
        const UpdateWeekPlanUrl: AjaxPost<UpdateWeekPlanResponse, UpdateWeekPlanData> = "/recordingtimeAndWeekPlan/updateWeekPlan";
        /**
         * 修改周计划表
         */
        export const UpdateWeekPlan = (data: UpdateWeekPlanData) => Ajax.post(UpdateWeekPlanUrl, data);
    }
    /**
     * 我的-相关接口
     */
    export namespace ShopMy {
        /**
         * OK
         */
        export type DeleteFavoritesResponse = AppResponse<AppListResponse<ShopMyFavoritesNotEffectGoodsVo>>;
        /**
         * form
         */
        export type DeleteFavoritesData = ShopFavoritesDeleteForm;
        const DeleteFavoritesUrl: AjaxPost<DeleteFavoritesResponse, DeleteFavoritesData> = "/shop/deleteFavorites";
        /**
         * 我的收藏-删除和快速清理
         */
        export const DeleteFavorites = (data: DeleteFavoritesData) => Ajax.post(DeleteFavoritesUrl, data);
        /**
         * OK
         */
        export type MyPointsRecordListResponse = AppResponse<AppPageListResponse<ShopUserPointsVo>>;
        /**
         * form
         */
        export type MyPointsRecordListData = ShopUserPointsQueryForm;
        const MyPointsRecordListUrl: AjaxPost<MyPointsRecordListResponse, MyPointsRecordListData> = "/shop/myPointsRecordList";
        /**
         * 收支明细
         */
        export const MyPointsRecordList = (data: MyPointsRecordListData) => Ajax.post(MyPointsRecordListUrl, data);
        /**
         * OK
         */
        export type MyRecordingTimeStaticResponse = AppResponse<EfficiencyRecordingTimeStaticVo>;
        export type MyRecordingTimeStaticData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 查看统计类型 1日视图 2周视图 3月视图
             */
            type?: number;
            /**
             * 用户id
             */
            userId?: string;
            /**
             * 查看日期-周视图取第一天:yyyy-MM-dd
             */
            viewTime?: string;
        };
        const MyRecordingTimeStaticUrl: AjaxGet<MyRecordingTimeStaticResponse, MyRecordingTimeStaticData> = "/shop/myRecordingTimeStatic";
        /**
         * 我的计时统计
         */
        export const MyRecordingTimeStatic = (data: MyRecordingTimeStaticData) => Ajax.get(MyRecordingTimeStaticUrl, data);
        /**
         * OK
         */
        export type MyRefundListResponse = AppResponse<AppPageListResponse<ShopMyRefundVo>>;
        export type MyRefundListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
        };
        const MyRefundListUrl: AjaxGet<MyRefundListResponse, MyRefundListData> = "/shop/myRefundList";
        /**
         * 我的退款记录
         */
        export const MyRefundList = (data: MyRefundListData) => Ajax.get(MyRefundListUrl, data);
        /**
         * OK
         */
        export type MyShopStaticResponse = AppResponse<ShopIndexMyShopStaticVo>;
        export type MyShopStaticData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const MyShopStaticUrl: AjaxGet<MyShopStaticResponse, MyShopStaticData> = "/shop/myShopStatic";
        /**
         * 我的-商城块-数据聚合
         */
        export const MyShopStatic = (data: MyShopStaticData) => Ajax.get(MyShopStaticUrl, data);
        /**
         * OK
         */
        export type MyStoreListResponse = AppResponse<AppPageListResponse<ShopMyFavoritesListVo>>;
        export type MyStoreListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 查询字段
             */
            multiPart?: string;
        };
        const MyStoreListUrl: AjaxGet<MyStoreListResponse, MyStoreListData> = "/shop/myStoreList";
        /**
         * 我的收藏列表
         */
        export const MyStoreList = (data: MyStoreListData) => Ajax.get(MyStoreListUrl, data);
        /**
         * OK
         */
        export type QueryNotEffectFavoritesResponse = AppResponse<AppListResponse<ShopMyFavoritesNotEffectGoodsVo>>;
        export type QueryNotEffectFavoritesData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryNotEffectFavoritesUrl: AjaxGet<QueryNotEffectFavoritesResponse, QueryNotEffectFavoritesData> = "/shop/queryNotEffectFavorites";
        /**
         * 我的收藏-失效商品列表
         */
        export const QueryNotEffectFavorites = (data: QueryNotEffectFavoritesData) => Ajax.get(QueryNotEffectFavoritesUrl, data);
        /**
         * OK
         */
        export type QueryRecordingTimeInSevenDayResponse = AppResponse<EfficiencyRecordingTimeHistogramVo>;
        export type QueryRecordingTimeInSevenDayData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const QueryRecordingTimeInSevenDayUrl: AjaxGet<QueryRecordingTimeInSevenDayResponse, QueryRecordingTimeInSevenDayData> = "/shop/queryRecordingTimeInSevenDay";
        /**
         * 近7日计时统计柱状图
         */
        export const QueryRecordingTimeInSevenDay = (data: QueryRecordingTimeInSevenDayData) => Ajax.get(QueryRecordingTimeInSevenDayUrl, data);
        /**
         * OK
         */
        export type ShopEveryNoticeListResponse = AppResponse<AppPageListResponse<ShopNoticeVo>>;
        /**
         * shopNoticeListQueryForm
         */
        export type ShopEveryNoticeListData = ShopNoticeListQueryForm;
        const ShopEveryNoticeListUrl: AjaxPost<ShopEveryNoticeListResponse, ShopEveryNoticeListData> = "/shop/shopEveryNoticeList";
        /**
         * 我的-通知-通知详情列表
         */
        export const ShopEveryNoticeList = (data: ShopEveryNoticeListData) => Ajax.post(ShopEveryNoticeListUrl, data);
        /**
         * OK
         */
        export type ShopNoticeAllReadResponse = AppResponse;
        const ShopNoticeAllReadUrl: AjaxPost<ShopNoticeAllReadResponse, never> = "/shop/shopNoticeAllRead";
        /**
         * 我的-通知-全部已读
         */
        export const ShopNoticeAllRead = () => Ajax.post(ShopNoticeAllReadUrl);
        /**
         * OK
         */
        export type ShopRelateToMeResponse = AppResponse<AppListResponse<ShopNoticeEveryCountVo>>;
        export type ShopRelateToMeData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const ShopRelateToMeUrl: AjaxGet<ShopRelateToMeResponse, ShopRelateToMeData> = "/shop/shopRelateToMe";
        /**
         * 我的-通知首页
         */
        export const ShopRelateToMe = (data: ShopRelateToMeData) => Ajax.get(ShopRelateToMeUrl, data);
        /**
         * OK
         */
        export type ShopRelateToMeV2Response = AppResponse<AppListResponse<ShopNoticeEveryCountVo>>;
        export type ShopRelateToMeV2Data = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const ShopRelateToMeV2Url: AjaxGet<ShopRelateToMeV2Response, ShopRelateToMeV2Data> = "/shop/shopRelateToMeV2";
        /**
         * 我的-通知首页
         */
        export const ShopRelateToMeV2 = (data: ShopRelateToMeV2Data) => Ajax.get(ShopRelateToMeV2Url, data);
        /**
         * OK
         */
        export type VipRecordingTimeDistributionResponse = AppResponse<EfficiencyRecordingTimeStaticDistributionVo>;
        export type VipRecordingTimeDistributionData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const VipRecordingTimeDistributionUrl: AjaxGet<VipRecordingTimeDistributionResponse, VipRecordingTimeDistributionData> = "/shop/vipRecordingTimeDistribution";
        /**
         * vip用户计时统计分布图
         */
        export const VipRecordingTimeDistribution = (data: VipRecordingTimeDistributionData) => Ajax.get(VipRecordingTimeDistributionUrl, data);
        /**
         * OK
         */
        export type VipRecordingTimeStaticResponse = AppResponse<EfficiencyRecordingTimeStaticVo>;
        export type VipRecordingTimeStaticData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 结束时间yyyy-MM-dd
             */
            endTime?: string;
            /**
             * 开始时间yyyy-MM-dd
             */
            startTime?: string;
            /**
             * 用户id
             */
            userId?: string;
        };
        const VipRecordingTimeStaticUrl: AjaxGet<VipRecordingTimeStaticResponse, VipRecordingTimeStaticData> = "/shop/vipRecordingTimeStatic";
        /**
         * vip用户计时统计-自定义时间
         */
        export const VipRecordingTimeStatic = (data: VipRecordingTimeStaticData) => Ajax.get(VipRecordingTimeStaticUrl, data);
    }
    /**
     * 商城评价及回复接口
     */
    export namespace ShopEvaluate {
        /**
         * OK
         */
        export type AddResponse = AppResponse<ShopEvaluateGoodsSuccessResponseVo>;
        /**
         * form
         */
        export type AddData = ShopEvaluateCreateForm;
        const AddUrl: AjaxPost<AddResponse, AddData> = "/shop/evaluate/add";
        /**
         * 评价
         */
        export const Add = (data: AddData) => Ajax.post(AddUrl, data);
        /**
         * OK
         */
        export type GiveLikeResponse = AppResponse;
        /**
         * form
         */
        export type GiveLikeData = ShopEvaluateGoodsCreateGiveLikeForm;
        const GiveLikeUrl: AjaxPost<GiveLikeResponse, GiveLikeData> = "/shop/evaluate/giveLike";
        /**
         * 评价点赞
         */
        export const GiveLike = (data: GiveLikeData) => Ajax.post(GiveLikeUrl, data);
        /**
         * OK
         */
        export type QueryCountWithImageResponse = AppResponse<ShopEvalImageNumVo>;
        export type QueryCountWithImageData = {
            /**
             * 商品id
             */
            spuId: number;
        };
        const QueryCountWithImageUrl: AjaxGet<QueryCountWithImageResponse, QueryCountWithImageData> = "/shop/evaluate/queryCountWithImage";
        /**
         * 查询带图的评论的个数
         */
        export const QueryCountWithImage = (data: QueryCountWithImageData) => Ajax.get(QueryCountWithImageUrl, data);
        /**
         * OK
         */
        export type QueryEvalDetailResponse = AppResponse<ShopEvaluateGoodsDetailResponseVo>;
        export type QueryEvalDetailData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 评论id
             */
            UUID: string;
        };
        const QueryEvalDetailUrl: AjaxGet<QueryEvalDetailResponse, QueryEvalDetailData> = "/shop/evaluate/queryEvalDetail";
        /**
         * 评价详情
         */
        export const QueryEvalDetail = (data: QueryEvalDetailData) => Ajax.get(QueryEvalDetailUrl, data);
        /**
         * OK
         */
        export type QueryEvalListResponse = AppResponse<AppPageListResponse<ShopEvaluateGoodsPageListResponseVo>>;
        export type QueryEvalListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * spu id
             */
            goodsCommonId?: number;
            /**
             * 商品id
             */
            goodsId?: number;
            /**
             * 排序类型 0全部 1最新 2有图 默认为0
             */
            type?: number;
            /**
             * 用户id
             */
            userId?: string;
        };
        const QueryEvalListUrl: AjaxGet<QueryEvalListResponse, QueryEvalListData> = "/shop/evaluate/queryEvalList";
        /**
         * 评价列表-分页
         */
        export const QueryEvalList = (data: QueryEvalListData) => Ajax.get(QueryEvalListUrl, data);
        /**
         * OK
         */
        export type QueryGoodsListWhenEvalSuccessResponse = AppResponse<ShopGoodsListWhenEvelSuccessResponseVo>;
        export type QueryGoodsListWhenEvalSuccessData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 商品id
             */
            goodsId?: number;
        };
        const QueryGoodsListWhenEvalSuccessUrl: AjaxGet<QueryGoodsListWhenEvalSuccessResponse, QueryGoodsListWhenEvalSuccessData> = "/shop/evaluate/queryGoodsListWhenEvalSuccess";
        /**
         * 评价成功后展示的商品列表
         */
        export const QueryGoodsListWhenEvalSuccess = (data: QueryGoodsListWhenEvalSuccessData) => Ajax.get(QueryGoodsListWhenEvalSuccessUrl, data);
        /**
         * OK
         */
        export type ReplyResponse = AppResponse;
        /**
         * form
         */
        export type ReplyData = ShopEvaluateReplyForm;
        const ReplyUrl: AjaxPost<ReplyResponse, ReplyData> = "/shop/evaluate/reply";
        /**
         * 评价回复
         */
        export const Reply = (data: ReplyData) => Ajax.post(ReplyUrl, data);
        /**
         * OK
         */
        export type ViewResponse = AppResponse;
        /**
         * form
         */
        export type ViewData = ShopEvaluateGoodsViewForm;
        const ViewUrl: AjaxPost<ViewResponse, ViewData> = "/shop/evaluate/view";
        /**
         * 评价浏览
         */
        export const View = (data: ViewData) => Ajax.post(ViewUrl, data);
    }
    /**
     * 用户地址相关接口
     */
    export namespace UserAddress {
        /**
         * OK
         */
        export type AddResponse = AppResponse<ShopAddressVo>;
        /**
         * form
         */
        export type AddData = OriginShopAddressCreateForm;
        const AddUrl: AjaxPost<AddResponse, AddData> = "/user/address/add";
        /**
         * 新增买家地址
         */
        export const Add = (data: AddData) => Ajax.post(AddUrl, data);
        /**
         * OK
         */
        export type AddTestResponse = AppResponse<ShopAddressVo>;
        /**
         * shopAddressCreateForm
         */
        export type AddTestData = ShopAddressCreateForm;
        const AddTestUrl: AjaxPost<AddTestResponse, AddTestData> = "/user/address/addTest";
        /**
         * 新增买家地址-test
         */
        export const AddTest = (data: AddTestData) => Ajax.post(AddTestUrl, data);
        /**
         * OK
         */
        export type DeleteResponse = AppResponse;
        /**
         * form
         */
        export type DeleteData = ShopAddressDeleteForm;
        const DeleteUrl: AjaxPost<DeleteResponse, DeleteData> = "/user/address/delete";
        /**
         * 删除买家地址信息表
         */
        export const Delete = (data: DeleteData) => Ajax.post(DeleteUrl, data);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<OriginShopAddressVo>>;
        export type QueryListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 地址ID列表
             */
            addressIdList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 地址
             */
            address?: string;
            /**
             * 地址ID
             */
            addressId?: number;
            /**
             * 地址标签 1家 2学校 3公司
             */
            addressTag?: number;
            /**
             * 区ID
             */
            cityId?: string;
            /**
             * 1默认收货地址
             */
            isDefault?: string;
            /**
             * 会员ID
             */
            memberId?: string;
            /**
             * 手机电话
             */
            mobPhone?: string;
            /**
             * 省ID
             */
            provinceId?: string;
            /**
             * 地区内容
             */
            regioId?: string;
            /**
             * 街道id
             */
            rowId?: string;
            /**
             * 座机电话
             */
            telPhone?: string;
            /**
             * 会员姓名
             */
            trueName?: string;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/user/address/queryList";
        /**
         * 查询买家地址信息表列表
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
        /**
         * OK
         */
        export type UpdateResponse = AppResponse;
        /**
         * form
         */
        export type UpdateData = OriginShopAddressUpdateForm;
        const UpdateUrl: AjaxPost<UpdateResponse, UpdateData> = "/user/address/update";
        /**
         * 修改买家地址信息表
         */
        export const Update = (data: UpdateData) => Ajax.post(UpdateUrl, data);
    }
    /**
     * 用户购物车相关接口
     */
    export namespace UserCart {
        /**
         * OK
         */
        export type AddResponse = AppResponse;
        /**
         * form
         */
        export type AddData = OriginShopCartCreateForm;
        const AddUrl: AjaxPost<AddResponse, AddData> = "/user/cart/add";
        /**
         * 新增购物车
         */
        export const Add = (data: AddData) => Ajax.post(AddUrl, data);
        /**
         * OK
         */
        export type AllSelectResponse = AppResponse<AppListResponse<number>>;
        const AllSelectUrl: AjaxPost<AllSelectResponse, never> = "/user/cart/allSelect";
        /**
         * 全选--改状态
         */
        export const AllSelect = () => Ajax.post(AllSelectUrl);
        /**
         * OK
         */
        export type CollectResponse = AppResponse;
        /**
         * createForm
         */
        export type CollectData = ShopCartCollectCreateForm;
        const CollectUrl: AjaxPost<CollectResponse, CollectData> = "/user/cart/collect";
        /**
         * 移入种草单
         */
        export const Collect = (data: CollectData) => Ajax.post(CollectUrl, data);
        /**
         * OK
         */
        export type DeleteResponse = AppResponse;
        /**
         * form
         */
        export type DeleteData = ShopCartDeleteForm;
        const DeleteUrl: AjaxPost<DeleteResponse, DeleteData> = "/user/cart/delete";
        /**
         * 删除购物车
         */
        export const Delete = (data: DeleteData) => Ajax.post(DeleteUrl, data);
        /**
         * OK
         */
        export type DeleteAllDownGoodsResponse = AppResponse;
        const DeleteAllDownGoodsUrl: AjaxPost<DeleteAllDownGoodsResponse, never> = "/user/cart/deleteAllDownGoods";
        /**
         * 清空失效商品
         */
        export const DeleteAllDownGoods = () => Ajax.post(DeleteAllDownGoodsUrl);
        /**
         * OK
         */
        export type GetTotalPriceResponse = AppResponse<ShopCartPriceVo>;
        export type GetTotalPriceData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 购物车id-按逗号,分割
             */
            cartIdList?: string;
        };
        const GetTotalPriceUrl: AjaxGet<GetTotalPriceResponse, GetTotalPriceData> = "/user/cart/getTotalPrice";
        /**
         * 购物车总价计算接口
         */
        export const GetTotalPrice = (data: GetTotalPriceData) => Ajax.get(GetTotalPriceUrl, data);
        /**
         * OK
         */
        export type QueryListResponse = AppResponse<AppListResponse<ShopCartSpuVo>>;
        export type QueryListData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 购物车id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 组合套装ID
             */
            blId?: number;
            /**
             * 买家id
             */
            buyerId?: string;
            /**
             * 货品id
             */
            goodsCommonId?: number;
            /**
             * 商品id
             */
            goodsId?: number;
            /**
             * 商品图片
             */
            goodsImage?: string;
            /**
             * 商品名称
             */
            goodsName?: string;
            /**
             * 购买商品数量
             */
            goodsNum?: number;
            /**
             * 商品价格
             */
            goodsPrice?: number;
            /**
             * 购物车id
             */
            id?: number;
        };
        const QueryListUrl: AjaxGet<QueryListResponse, QueryListData> = "/user/cart/queryList";
        /**
         * 购物车列表-首次
         */
        export const QueryList = (data: QueryListData) => Ajax.get(QueryListUrl, data);
        /**
         * OK
         */
        export type QueryListDetailResponse = AppResponse<ShopCartGoodsVo>;
        export type QueryListDetailData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 购物车id列表
             */
            idList?: number[];
            pageNum?: number;
            pageSize?: number;
            orderBy?: string;
            /**
             * 组合套装ID
             */
            blId?: number;
            /**
             * 买家id
             */
            buyerId?: string;
            /**
             * 货品id
             */
            goodsCommonId?: number;
            /**
             * 商品id
             */
            goodsId?: number;
            /**
             * 商品图片
             */
            goodsImage?: string;
            /**
             * 商品名称
             */
            goodsName?: string;
            /**
             * 购买商品数量
             */
            goodsNum?: number;
            /**
             * 商品价格
             */
            goodsPrice?: number;
            /**
             * 购物车id
             */
            id?: number;
        };
        const QueryListDetailUrl: AjaxGet<QueryListDetailResponse, QueryListDetailData> = "/user/cart/queryListDetail";
        /**
         * 购物车列表-详情
         */
        export const QueryListDetail = (data: QueryListDetailData) => Ajax.get(QueryListDetailUrl, data);
        /**
         * OK
         */
        export type UpdateResponse = AppResponse<ShopCartUpdateVo>;
        /**
         * updateForm
         */
        export type UpdateData = OriginShopCartUpdateForm;
        const UpdateUrl: AjaxPost<UpdateResponse, UpdateData> = "/user/cart/update";
        /**
         * 修改购物车
         */
        export const Update = (data: UpdateData) => Ajax.post(UpdateUrl, data);
    }
    /**
     * 个性装扮
     */
    export namespace UserDressup {
        /**
         * OK
         */
        export type CustomFocusSceneDressupResponse = AppResponse<UserFocussceneVo>;
        /**
         * form
         */
        export type CustomFocusSceneDressupData = UserFocussceneCreateForm;
        const CustomFocusSceneDressupUrl: AjaxPost<CustomFocusSceneDressupResponse, CustomFocusSceneDressupData> = "/userDressup/customFocusSceneDressup";
        /**
         * 新增/修改用户自定义专注场景的个性装扮
         */
        export const CustomFocusSceneDressup = (data: CustomFocusSceneDressupData) => Ajax.post(CustomFocusSceneDressupUrl, data);
        /**
         * OK
         */
        export type ExchangeDressupResponse = AppResponse;
        /**
         * form
         */
        export type ExchangeDressupData = UserDressupExchangeForm;
        const ExchangeDressupUrl: AjaxPost<ExchangeDressupResponse, ExchangeDressupData> = "/userDressup/exchangeDressup";
        /**
         * 燃豆兑换个性装扮
         */
        export const ExchangeDressup = (data: ExchangeDressupData) => Ajax.post(ExchangeDressupUrl, data);
        /**
         * OK
         */
        export type GetBindShowInfoResponse = AppResponse<BindShowVo>;
        /**
         * form
         */
        export type GetBindShowInfoData = BindShowQueryForm;
        const GetBindShowInfoUrl: AjaxPost<GetBindShowInfoResponse, GetBindShowInfoData> = "/userDressup/getBindShowInfo";
        /**
         * 获取绑定信息
         */
        export const GetBindShowInfo = (data: GetBindShowInfoData) => Ajax.post(GetBindShowInfoUrl, data);
        /**
         * OK
         */
        export type IncreaseCombustNumberResponse = AppResponse<EfficiencyCombustUserHeadVo>;
        /**
         * form
         */
        export type IncreaseCombustNumberData = EfficiencyAbsorbedSceneForm;
        const IncreaseCombustNumberUrl: AjaxPost<IncreaseCombustNumberResponse, IncreaseCombustNumberData> = "/userDressup/increaseCombustNumber";
        /**
         * 助燃他人专注场景
         */
        export const IncreaseCombustNumber = (data: IncreaseCombustNumberData) => Ajax.post(IncreaseCombustNumberUrl, data);
        /**
         * OK
         */
        export type PossessDressupResponse = AppResponse<AppListResponse<UserDressupVo>>;
        /**
         * form
         */
        export type PossessDressupData = UserDressupExchangeForm;
        const PossessDressupUrl: AjaxPost<PossessDressupResponse, PossessDressupData> = "/userDressup/possessDressup";
        /**
         * 查询用户已拥有的个性装扮
         */
        export const PossessDressup = (data: PossessDressupData) => Ajax.post(PossessDressupUrl, data);
        /**
         * OK
         */
        export type QueryDressupListByModelTypeResponse = AppResponse<AppListResponse<UserDressupVo>>;
        /**
         * form
         */
        export type QueryDressupListByModelTypeData = UserDressupExchangeForm;
        const QueryDressupListByModelTypeUrl: AjaxPost<QueryDressupListByModelTypeResponse, QueryDressupListByModelTypeData> = "/userDressup/queryDressupListByModelType";
        /**
         * 根据模块类型查询个性装扮列表
         */
        export const QueryDressupListByModelType = (data: QueryDressupListByModelTypeData) => Ajax.post(QueryDressupListByModelTypeUrl, data);
        /**
         * OK
         */
        export type QueryIconByParentIdResponse = AppResponse<UserDressupIconVo>;
        export type QueryIconByParentIdData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
            /**
             * 图标父id
             */
            parentId: number;
        };
        const QueryIconByParentIdUrl: AjaxGet<QueryIconByParentIdResponse, QueryIconByParentIdData> = "/userDressup/queryIconByParentId";
        /**
         * 根据图标父id查询图标详情
         */
        export const QueryIconByParentId = (data: QueryIconByParentIdData) => Ajax.get(QueryIconByParentIdUrl, data);
        /**
         * OK
         */
        export type QueryOtherAbsorbedSceneResponse = AppResponse<EfficiencyAbsorbedSceneVo>;
        /**
         * form
         */
        export type QueryOtherAbsorbedSceneData = EfficiencyAbsorbedSceneForm;
        const QueryOtherAbsorbedSceneUrl: AjaxPost<QueryOtherAbsorbedSceneResponse, QueryOtherAbsorbedSceneData> = "/userDressup/queryOtherAbsorbedScene";
        /**
         * 查询他人专注场景
         */
        export const QueryOtherAbsorbedScene = (data: QueryOtherAbsorbedSceneData) => Ajax.post(QueryOtherAbsorbedSceneUrl, data);
        /**
         * OK
         */
        export type QueryUseDressupResponse = AppResponse<AppListResponse<UserDressupVo>>;
        /**
         * form
         */
        export type QueryUseDressupData = UserUseRecordQueryForm;
        const QueryUseDressupUrl: AjaxPost<QueryUseDressupResponse, QueryUseDressupData> = "/userDressup/queryUseDressup";
        /**
         * 查询用户使用中的头像挂件,白噪音,专注场景,表情包
         */
        export const QueryUseDressup = (data: QueryUseDressupData) => Ajax.post(QueryUseDressupUrl, data);
        /**
         * OK
         */
        export type QueryUseUserDressupResponse = AppResponse<UserDressupVo>;
        /**
         * form
         */
        export type QueryUseUserDressupData = UserDressupExchangeForm;
        const QueryUseUserDressupUrl: AjaxPost<QueryUseUserDressupResponse, QueryUseUserDressupData> = "/userDressup/queryUseUserDressup";
        /**
         * 查询用户使用中的个性装扮
         */
        export const QueryUseUserDressup = (data: QueryUseUserDressupData) => Ajax.post(QueryUseUserDressupUrl, data);
        /**
         * OK
         */
        export type RemoveDressupResponse = AppResponse;
        /**
         * form
         */
        export type RemoveDressupData = UserDressupExchangeForm;
        const RemoveDressupUrl: AjaxPost<RemoveDressupResponse, RemoveDressupData> = "/userDressup/removeDressup";
        /**
         * 卸下个性装扮
         */
        export const RemoveDressup = (data: RemoveDressupData) => Ajax.post(RemoveDressupUrl, data);
        /**
         * OK
         */
        export type UseDressupResponse = AppResponse;
        /**
         * form
         */
        export type UseDressupData = UserDressupExchangeForm;
        const UseDressupUrl: AjaxPost<UseDressupResponse, UseDressupData> = "/userDressup/useDressup";
        /**
         * 新增/修改用户使用中的个性装扮
         */
        export const UseDressup = (data: UseDressupData) => Ajax.post(UseDressupUrl, data);
    }
    /**
     * IM相关接口
     */
    export namespace UserIm {
        /**
         * OK
         */
        export type AddImChatFeedBackResponse = AppResponse;
        /**
         * form
         */
        export type AddImChatFeedBackData = ImFeedbackCreateForm;
        const AddImChatFeedBackUrl: AjaxPost<AddImChatFeedBackResponse, AddImChatFeedBackData> = "/userIm/addImChatFeedBack";
        /**
         * 新增举报聊天
         */
        export const AddImChatFeedBack = (data: AddImChatFeedBackData) => Ajax.post(AddImChatFeedBackUrl, data);
        /**
         * OK
         */
        export type AddImChatNumberRecordResponse = AppResponse<IMChatNumberVo>;
        /**
         * form
         */
        export type AddImChatNumberRecordData = ImChatNumberCreateForm;
        const AddImChatNumberRecordUrl: AjaxPost<AddImChatNumberRecordResponse, AddImChatNumberRecordData> = "/userIm/addImChatNumberRecord";
        /**
         * 非聊天好友新增聊天消息个数记录
         */
        export const AddImChatNumberRecord = (data: AddImChatNumberRecordData) => Ajax.post(AddImChatNumberRecordUrl, data);
        /**
         * OK
         */
        export type DelImChatNumberRecordResponse = AppResponse;
        /**
         * form
         */
        export type DelImChatNumberRecordData = ImChatNumberCreateForm;
        const DelImChatNumberRecordUrl: AjaxPost<DelImChatNumberRecordResponse, DelImChatNumberRecordData> = "/userIm/delImChatNumberRecord";
        /**
         * 删除聊天消息个数记录
         */
        export const DelImChatNumberRecord = (data: DelImChatNumberRecordData) => Ajax.post(DelImChatNumberRecordUrl, data);
        /**
         * OK
         */
        export type GetImMsgDisturbingOrBeDisturbByUserIdResponse = AppResponse<ListStringVo>;
        export type GetImMsgDisturbingOrBeDisturbByUserIdData = {
            /**
             * 这个接口需要登录，这只是一个标识，不需要传，在上面swagger页面点击Authorize按钮点击
             */
            authorization?: string;
        };
        const GetImMsgDisturbingOrBeDisturbByUserIdUrl: AjaxGet<GetImMsgDisturbingOrBeDisturbByUserIdResponse, GetImMsgDisturbingOrBeDisturbByUserIdData> = "/userIm/getImMsgDisturbingOrBeDisturbByUserId";
        /**
         * 获取用户免打扰列表信息
         */
        export const GetImMsgDisturbingOrBeDisturbByUserId = (data: GetImMsgDisturbingOrBeDisturbByUserIdData) => Ajax.get(GetImMsgDisturbingOrBeDisturbByUserIdUrl, data);
        /**
         * OK
         */
        export type SetImMsgDisturbingResponse = AppResponse;
        /**
         * form
         */
        export type SetImMsgDisturbingData = ImDisturbingCreateForm;
        const SetImMsgDisturbingUrl: AjaxPost<SetImMsgDisturbingResponse, SetImMsgDisturbingData> = "/userIm/setImMsgDisturbing";
        /**
         * 设置用户IM消息是否打扰
         */
        export const SetImMsgDisturbing = (data: SetImMsgDisturbingData) => Ajax.post(SetImMsgDisturbingUrl, data);
    }
    /**
     * 会员相关接口
     */
    export namespace UserMember {
        /**
         * OK
         */
        export type DelMemberExpireInfoResponse = AppResponse;
        /**
         * form
         */
        export type DelMemberExpireInfoData = UserMemberExpireTipQueryForm;
        const DelMemberExpireInfoUrl: AjaxPost<DelMemberExpireInfoResponse, DelMemberExpireInfoData> = "/userMember/delMemberExpireInfo";
        /**
         * 删除会员到期情况
         */
        export const DelMemberExpireInfo = (data: DelMemberExpireInfoData) => Ajax.post(DelMemberExpireInfoUrl, data);
        /**
         * OK
         */
        export type GetMemberCenterDataResponse = AppResponse<UserMemberPackageAndPrivilegeListVo>;
        const GetMemberCenterDataUrl: AjaxPost<GetMemberCenterDataResponse, never> = "/userMember/getMemberCenterData";
        /**
         * 会员中心(用户信息/会员套餐/会员特权)
         */
        export const GetMemberCenterData = () => Ajax.post(GetMemberCenterDataUrl);
        /**
         * OK
         */
        export type GetOngoingActivitiesAndUserInfoResponse = AppResponse<UserMemberOngoingActivitiesVo>;
        const GetOngoingActivitiesAndUserInfoUrl: AjaxPost<GetOngoingActivitiesAndUserInfoResponse, never> = "/userMember/getOngoingActivitiesAndUserInfo";
        /**
         * 邀请好友界面(用户信息/进行中的活动)
         */
        export const GetOngoingActivitiesAndUserInfo = () => Ajax.post(GetOngoingActivitiesAndUserInfoUrl);
        /**
         * OK
         */
        export type GetUserInUseDressUpInfoResponse = AppResponse<UserOwnDressUpVo>;
        /**
         * form
         */
        export type GetUserInUseDressUpInfoData = UserSearchByIdForm;
        const GetUserInUseDressUpInfoUrl: AjaxPost<GetUserInUseDressUpInfoResponse, GetUserInUseDressUpInfoData> = "/userMember/getUserInUseDressUpInfo";
        /**
         * 获取个人信息/正在使用中的个性装扮信息
         */
        export const GetUserInUseDressUpInfo = (data: GetUserInUseDressUpInfoData) => Ajax.post(GetUserInUseDressUpInfoUrl, data);
        /**
         * OK
         */
        export type QueryMemberExpireInfoResponse = AppResponse<UserExpireTipBeanVo>;
        /**
         * form
         */
        export type QueryMemberExpireInfoData = UserMemberExpireTipQueryForm;
        const QueryMemberExpireInfoUrl: AjaxPost<QueryMemberExpireInfoResponse, QueryMemberExpireInfoData> = "/userMember/queryMemberExpireInfo";
        /**
         * 查询用户会员到期情况
         */
        export const QueryMemberExpireInfo = (data: QueryMemberExpireInfoData) => Ajax.post(QueryMemberExpireInfoUrl, data);
        /**
         * OK
         */
        export type QueryUnlockActivityPageListResponse = AppResponse<AppPageListResponse<UserDressupVo>>;
        /**
         * form
         */
        export type QueryUnlockActivityPageListData = UserEmoticonQueryForm;
        const QueryUnlockActivityPageListUrl: AjaxPost<QueryUnlockActivityPageListResponse, QueryUnlockActivityPageListData> = "/userMember/queryUnlockActivityPageList";
        /**
         * 查询用户解锁记录列表
         */
        export const QueryUnlockActivityPageList = (data: QueryUnlockActivityPageListData) => Ajax.post(QueryUnlockActivityPageListUrl, data);
        /**
         * OK
         */
        export type QueryUserMemberRecordListResponse = AppResponse<AppPageListResponse<UserMemberRecordVo>>;
        /**
         * form
         */
        export type QueryUserMemberRecordListData = UserMemberRecordQueryForm;
        const QueryUserMemberRecordListUrl: AjaxPost<QueryUserMemberRecordListResponse, QueryUserMemberRecordListData> = "/userMember/queryUserMemberRecordList";
        /**
         * 获取会员交易记录
         */
        export const QueryUserMemberRecordList = (data: QueryUserMemberRecordListData) => Ajax.post(QueryUserMemberRecordListUrl, data);
        /**
         * OK
         */
        export type SetInviteCodeResponse = AppResponse;
        /**
         * form
         */
        export type SetInviteCodeData = UserSetInviteCodeForm;
        const SetInviteCodeUrl: AjaxPost<SetInviteCodeResponse, SetInviteCodeData> = "/userMember/setInviteCode";
        /**
         * 设置会员立即到期
         */
        export const SetInviteCode = (data: SetInviteCodeData) => Ajax.post(SetInviteCodeUrl, data);
        /**
         * OK
         */
        export type SetUserMemberExpireNowResponse = AppResponse;
        /**
         * form
         */
        export type SetUserMemberExpireNowData = BaseStringForm;
        const SetUserMemberExpireNowUrl: AjaxPost<SetUserMemberExpireNowResponse, SetUserMemberExpireNowData> = "/userMember/setUserMemberExpireNow";
        /**
         * 设置会员立即到期
         */
        export const SetUserMemberExpireNow = (data: SetUserMemberExpireNowData) => Ajax.post(SetUserMemberExpireNowUrl, data);
    }
    /**
     * 用户自定义tabBar模块表
     */
    export namespace UserCustomTabbar {
        /**
         * OK
         */
        export type QueryUseTabbarByUserIdResponse = AppResponse<AppListResponse<UserCustomTabbarVo>>;
        /**
         * form
         */
        export type QueryUseTabbarByUserIdData = UserCustomTabbarQueryForm;
        const QueryUseTabbarByUserIdUrl: AjaxPost<QueryUseTabbarByUserIdResponse, QueryUseTabbarByUserIdData> = "/usercustomtabbar/queryUseTabbarByUserId";
        /**
         * 查询用户使用中的tabbar
         */
        export const QueryUseTabbarByUserId = (data: QueryUseTabbarByUserIdData) => Ajax.post(QueryUseTabbarByUserIdUrl, data);
        /**
         * OK
         */
        export type UpdateTabbarResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTabbarData = UserUseCustomTabbarForm;
        const UpdateTabbarUrl: AjaxPost<UpdateTabbarResponse, UpdateTabbarData> = "/usercustomtabbar/updateTabbar";
        /**
         * 新增/修改用户使用中的tabbar
         */
        export const UpdateTabbar = (data: UpdateTabbarData) => Ajax.post(UpdateTabbarUrl, data);
    }
    /**
     * 用户接口
     */
    export namespace User {
        /**
         * OK
         */
        export type AddFeedBackResponse = AppResponse<UserFeedbackVo>;
        /**
         * form
         */
        export type AddFeedBackData = UserFeedbackCreateForm;
        const AddFeedBackUrl: AjaxPost<AddFeedBackResponse, AddFeedBackData> = "/users/addFeedBack";
        /**
         * 意见反馈
         */
        export const AddFeedBack = (data: AddFeedBackData) => Ajax.post(AddFeedBackUrl, data);
        /**
         * OK
         */
        export type AddInvoiceResponse = AppResponse<UserInvoiceVo>;
        /**
         * form
         */
        export type AddInvoiceData = UserInvoiceCreateForm;
        const AddInvoiceUrl: AjaxPost<AddInvoiceResponse, AddInvoiceData> = "/users/addInvoice";
        /**
         * 添加增票资质
         */
        export const AddInvoice = (data: AddInvoiceData) => Ajax.post(AddInvoiceUrl, data);
        /**
         * OK
         */
        export type BindAccountResponse = AppResponse;
        /**
         * form
         */
        export type BindAccountData = UserBindAccountForm;
        const BindAccountUrl: AjaxPost<BindAccountResponse, BindAccountData> = "/users/bindAccount";
        /**
         * 绑定账号
         */
        export const BindAccount = (data: BindAccountData) => Ajax.post(BindAccountUrl, data);
        /**
         * OK
         */
        export type CancelUserResponse = AppResponse;
        const CancelUserUrl: AjaxPost<CancelUserResponse, never> = "/users/cancelUser";
        /**
         * 注销账号
         */
        export const CancelUser = () => Ajax.post(CancelUserUrl);
        /**
         * OK
         */
        export type ChangPhoneResponse = AppResponse;
        /**
         * form
         */
        export type ChangPhoneData = UserChangePhoneForm;
        const ChangPhoneUrl: AjaxPost<ChangPhoneResponse, ChangPhoneData> = "/users/changPhone";
        /**
         * 更换绑定手机号
         */
        export const ChangPhone = (data: ChangPhoneData) => Ajax.post(ChangPhoneUrl, data);
        /**
         * OK
         */
        export type CheckIsCancleUserResponse = AppResponse<BolleanVo>;
        const CheckIsCancleUserUrl: AjaxPost<CheckIsCancleUserResponse, never> = "/users/checkIsCancleUser";
        /**
         * 校验用户是不是注销用户
         */
        export const CheckIsCancleUser = () => Ajax.post(CheckIsCancleUserUrl);
        /**
         * OK
         */
        export type CreateAppScoreResponse = AppResponse;
        /**
         * form
         */
        export type CreateAppScoreData = UserAppScoreForm;
        const CreateAppScoreUrl: AjaxPost<CreateAppScoreResponse, CreateAppScoreData> = "/users/createAppScore";
        /**
         * APP评分
         */
        export const CreateAppScore = (data: CreateAppScoreData) => Ajax.post(CreateAppScoreUrl, data);
        /**
         * OK
         */
        export type DelFeedBackInfoResponse = AppResponse;
        /**
         * form
         */
        export type DelFeedBackInfoData = DynamicDelForm;
        const DelFeedBackInfoUrl: AjaxPost<DelFeedBackInfoResponse, DelFeedBackInfoData> = "/users/delFeedBackInfo";
        /**
         * 删除意见反馈相关
         */
        export const DelFeedBackInfo = (data: DelFeedBackInfoData) => Ajax.post(DelFeedBackInfoUrl, data);
        /**
         * OK
         */
        export type DelRecordingTimeInfoResponse = AppResponse;
        /**
         * form
         */
        export type DelRecordingTimeInfoData = EfficiencyRecordingTimeDelForm;
        const DelRecordingTimeInfoUrl: AjaxPost<DelRecordingTimeInfoResponse, DelRecordingTimeInfoData> = "/users/delRecordingTimeInfo";
        /**
         * 删除个人时间轴
         */
        export const DelRecordingTimeInfo = (data: DelRecordingTimeInfoData) => Ajax.post(DelRecordingTimeInfoUrl, data);
        /**
         * OK
         */
        export type DelUserFriendRecordResponse = AppResponse;
        /**
         * form
         */
        export type DelUserFriendRecordData = BaseStringForm;
        const DelUserFriendRecordUrl: AjaxPost<DelUserFriendRecordResponse, DelUserFriendRecordData> = "/users/delUserFriendRecord";
        /**
         * 删除好友消息记录
         */
        export const DelUserFriendRecord = (data: DelUserFriendRecordData) => Ajax.post(DelUserFriendRecordUrl, data);
        /**
         * OK
         */
        export type DeleteUserInvoiceResponse = AppResponse;
        /**
         * form
         */
        export type DeleteUserInvoiceData = UserInvoiceDeleteForm;
        const DeleteUserInvoiceUrl: AjaxPost<DeleteUserInvoiceResponse, DeleteUserInvoiceData> = "/users/deleteUserInvoice";
        /**
         * 删除增票资质
         */
        export const DeleteUserInvoice = (data: DeleteUserInvoiceData) => Ajax.post(DeleteUserInvoiceUrl, data);
        /**
         * OK
         */
        export type FeedBackCommentResponse = AppResponse<UserFeedbackVo>;
        /**
         * form
         */
        export type FeedBackCommentData = FeedBackCommentCreateForm;
        const FeedBackCommentUrl: AjaxPost<FeedBackCommentResponse, FeedBackCommentData> = "/users/feedBackComment";
        /**
         * 意见反馈评论
         */
        export const FeedBackComment = (data: FeedBackCommentData) => Ajax.post(FeedBackCommentUrl, data);
        /**
         * OK
         */
        export type FollowOrNotUserResponse = AppResponse<UserFollowInfoBeanVo>;
        /**
         * form
         */
        export type FollowOrNotUserData = UserFollowsForm;
        const FollowOrNotUserUrl: AjaxPost<FollowOrNotUserResponse, FollowOrNotUserData> = "/users/followOrNotUser";
        /**
         * 关注/取消关注用户
         */
        export const FollowOrNotUser = (data: FollowOrNotUserData) => Ajax.post(FollowOrNotUserUrl, data);
        /**
         * OK
         */
        export type ForgetPasswordResponse = AppResponse<UserVo>;
        /**
         * form
         */
        export type ForgetPasswordData = UserForgetPasswordForm;
        const ForgetPasswordUrl: AjaxPost<ForgetPasswordResponse, ForgetPasswordData> = "/users/forgetPassword";
        /**
         * 忘记密码
         */
        export const ForgetPassword = (data: ForgetPasswordData) => Ajax.post(ForgetPasswordUrl, data);
        /**
         * OK
         */
        export type GetBaseUserInfoResponse = AppResponse<UserInfoVo>;
        const GetBaseUserInfoUrl: AjaxPost<GetBaseUserInfoResponse, never> = "/users/getBaseUserInfo";
        /**
         * 用户登录后补充基本信息
         */
        export const GetBaseUserInfo = () => Ajax.post(GetBaseUserInfoUrl);
        /**
         * OK
         */
        export type GetDynamicUserInfoResponse = AppResponse<UserInfoDynamicVo>;
        const GetDynamicUserInfoUrl: AjaxPost<GetDynamicUserInfoResponse, never> = "/users/getDynamicUserInfo";
        /**
         * 获取用户动态信息
         */
        export const GetDynamicUserInfo = () => Ajax.post(GetDynamicUserInfoUrl);
        /**
         * OK
         */
        export type GetMsgCodeResponse = AppResponse;
        export type GetMsgCodeData = {
            /**
             * 手机号
             */
            phone: string;
            /**
             * 短信验证码的类型:(1.注册；2登录；3.忘记密码；4.绑定手机号；5.解除绑定手机号; 6.修改已绑定手机 旧手机号; 7.修改已绑定手机 新手机号; 8.注销账号)
             */
            msgCodeType: string;
        };
        const GetMsgCodeUrl: AjaxGet<GetMsgCodeResponse, GetMsgCodeData> = "/users/getMsgCode";
        /**
         * 获取短信验证码
         */
        export const GetMsgCode = (data: GetMsgCodeData) => Ajax.get(GetMsgCodeUrl, data);
        /**
         * OK
         */
        export type GetMsgCodeBindPhoneResponse = AppResponse;
        export type GetMsgCodeBindPhoneData = {
            /**
             * 手机号
             */
            phone: string;
            /**
             * 短信验证码的类型:(1.注册；2登录；3.忘记密码；4.绑定手机号；5.解除绑定手机号; 6.修改已绑定手机 旧手机号; 7.修改已绑定手机 新手机号; 8.注销账号)
             */
            msgCodeType: string;
            /**
             * 三方账户类型(三方账户绑定手机号的时候传 1.QQ;2.wx;3.wb;)
             */
            ownerType?: string;
        };
        const GetMsgCodeBindPhoneUrl: AjaxGet<GetMsgCodeBindPhoneResponse, GetMsgCodeBindPhoneData> = "/users/getMsgCodeBindPhone";
        /**
         * 绑定手机号获取短信验证码
         */
        export const GetMsgCodeBindPhone = (data: GetMsgCodeBindPhoneData) => Ajax.get(GetMsgCodeBindPhoneUrl, data);
        /**
         * OK
         */
        export type GetSigninRecordResponse = AppResponse<AppListResponse<UserSigninRecordVo>>;
        const GetSigninRecordUrl: AjaxPost<GetSigninRecordResponse, never> = "/users/getSigninRecord";
        /**
         * 获取用户签到记录
         */
        export const GetSigninRecord = () => Ajax.post(GetSigninRecordUrl);
        /**
         * OK
         */
        export type GetSysDictResponse = AppResponse<UserInfoVo>;
        const GetSysDictUrl: AjaxPost<GetSysDictResponse, never> = "/users/getSysDict";
        /**
         * APP获取字典信息
         */
        export const GetSysDict = () => Ajax.post(GetSysDictUrl);
        /**
         * OK
         */
        export type GetUsePersonalPageInfoResponse = AppResponse<UserPersonalInfoVo>;
        /**
         * form
         */
        export type GetUsePersonalPageInfoData = UserSearchByIdForm;
        const GetUsePersonalPageInfoUrl: AjaxPost<GetUsePersonalPageInfoResponse, GetUsePersonalPageInfoData> = "/users/getUsePersonalPageInfo";
        /**
         * 跟据id获取用户个人主页信息
         */
        export const GetUsePersonalPageInfo = (data: GetUsePersonalPageInfoData) => Ajax.post(GetUsePersonalPageInfoUrl, data);
        /**
         * OK
         */
        export type GetUserInfoAndRegisterGetPointNumberByIdResponse = AppResponse<UserInfoDynamicVo>;
        /**
         * form
         */
        export type GetUserInfoAndRegisterGetPointNumberByIdData = UserSearchByIdForm;
        const GetUserInfoAndRegisterGetPointNumberByIdUrl: AjaxPost<GetUserInfoAndRegisterGetPointNumberByIdResponse, GetUserInfoAndRegisterGetPointNumberByIdData> = "/users/getUserInfoAndRegisterGetPointNumberById";
        /**
         * 跟据id获取用户基本信息和注册获取燃豆个数
         */
        export const GetUserInfoAndRegisterGetPointNumberById = (data: GetUserInfoAndRegisterGetPointNumberByIdData) => Ajax.post(GetUserInfoAndRegisterGetPointNumberByIdUrl, data);
        /**
         * OK
         */
        export type GetUserIntiveInfoByIdResponse = AppResponse<UserIntiveInfoVo>;
        /**
         * form
         */
        export type GetUserIntiveInfoByIdData = UserSearchByIdForm;
        const GetUserIntiveInfoByIdUrl: AjaxPost<GetUserIntiveInfoByIdResponse, GetUserIntiveInfoByIdData> = "/users/getUserIntiveInfoById";
        /**
         * 跟据id获取用户个人主页信息
         */
        export const GetUserIntiveInfoById = (data: GetUserIntiveInfoByIdData) => Ajax.post(GetUserIntiveInfoByIdUrl, data);
        /**
         * OK
         */
        export type GetUserlearnstageResponse = AppResponse<AppListResponse<UserLearnstageVo>>;
        const GetUserlearnstageUrl: AjaxPost<GetUserlearnstageResponse, never> = "/users/getUserlearnstage";
        /**
         * 获取学习阶段列表
         */
        export const GetUserlearnstage = () => Ajax.post(GetUserlearnstageUrl);
        /**
         * OK
         */
        export type LoginResponse = AppResponse<UserVo>;
        /**
         * form
         */
        export type LoginData = UserLoginForm;
        const LoginUrl: AjaxPost<LoginResponse, LoginData> = "/users/login";
        /**
         * 用户登录
         */
        export const Login = (data: LoginData) => Ajax.post(LoginUrl, data);
        /**
         * OK
         */
        export type LoginV2Response = AppResponse<UserLoginReturnVo>;
        /**
         * form
         */
        export type LoginV2Data = UserLoginForm;
        const LoginV2Url: AjaxPost<LoginV2Response, LoginV2Data> = "/users/loginV2";
        /**
         * 用户登录V2
         */
        export const LoginV2 = (data: LoginV2Data) => Ajax.post(LoginV2Url, data);
        /**
         * OK
         */
        export type LogoutResponse = AppResponse;
        /**
         * form
         */
        export type LogoutData = UserLogoutForm;
        const LogoutUrl: AjaxPost<LogoutResponse, LogoutData> = "/users/logout";
        /**
         * 用户退出登录
         */
        export const Logout = (data: LogoutData) => Ajax.post(LogoutUrl, data);
        /**
         * OK
         */
        export type QueryFeedBackDetailResponse = AppResponse<UserFeedbackVo>;
        /**
         * form
         */
        export type QueryFeedBackDetailData = FeedBackCommentQueryForm;
        const QueryFeedBackDetailUrl: AjaxPost<QueryFeedBackDetailResponse, QueryFeedBackDetailData> = "/users/queryFeedBackDetail";
        /**
         * 查看意见反馈详情
         */
        export const QueryFeedBackDetail = (data: QueryFeedBackDetailData) => Ajax.post(QueryFeedBackDetailUrl, data);
        /**
         * OK
         */
        export type QueryFeedBackPageListResponse = AppResponse<AppPageListResponse<UserFeedbackVo>>;
        /**
         * form
         */
        export type QueryFeedBackPageListData = UserFeedbackQueryForm;
        const QueryFeedBackPageListUrl: AjaxPost<QueryFeedBackPageListResponse, QueryFeedBackPageListData> = "/users/queryFeedBackPageList";
        /**
         * 查询意见反馈列表(带分页)
         */
        export const QueryFeedBackPageList = (data: QueryFeedBackPageListData) => Ajax.post(QueryFeedBackPageListUrl, data);
        /**
         * OK
         */
        export type QueryInvoiceOneResponse = AppResponse<UserInvoiceVo>;
        const QueryInvoiceOneUrl: AjaxPost<QueryInvoiceOneResponse, never> = "/users/queryInvoiceOne";
        /**
         * 查询增票资质
         */
        export const QueryInvoiceOne = () => Ajax.post(QueryInvoiceOneUrl);
        /**
         * OK
         */
        export type QueryOccupationTreeResponse = AppResponse<AppListResponse<Map<string,object>>>;
        const QueryOccupationTreeUrl: AjaxPost<QueryOccupationTreeResponse, never> = "/users/queryOccupationTree";
        /**
         * 查询职业树
         */
        export const QueryOccupationTree = () => Ajax.post(QueryOccupationTreeUrl);
        /**
         * OK
         */
        export type QueryUserFriendRecordPageListResponse = AppResponse<AppPageListResponse<UserFollowRecordBeanVo>>;
        /**
         * form
         */
        export type QueryUserFriendRecordPageListData = BaseStringExtendBaseForm;
        const QueryUserFriendRecordPageListUrl: AjaxPost<QueryUserFriendRecordPageListResponse, QueryUserFriendRecordPageListData> = "/users/queryUserFriendRecordPageList";
        /**
         * 用户好友记录列表(带分页)
         */
        export const QueryUserFriendRecordPageList = (data: QueryUserFriendRecordPageListData) => Ajax.post(QueryUserFriendRecordPageListUrl, data);
        /**
         * OK
         */
        export type QueryUserPageListResponse = AppResponse<AppMultiplePageListResponse<UserVo>>;
        /**
         * form
         */
        export type QueryUserPageListData = UserQueryForm;
        const QueryUserPageListUrl: AjaxPost<QueryUserPageListResponse, QueryUserPageListData> = "/users/queryUserPageList";
        /**
         * 用户列表(带分页)(粉丝,关注,相关用户搜索)
         */
        export const QueryUserPageList = (data: QueryUserPageListData) => Ajax.post(QueryUserPageListUrl, data);
        /**
         * OK
         */
        export type QueryUserPageListV2Response = AppResponse<AppPageListResponse<UserVo>>;
        /**
         * form
         */
        export type QueryUserPageListV2Data = UserQueryForm;
        const QueryUserPageListV2Url: AjaxPost<QueryUserPageListV2Response, QueryUserPageListV2Data> = "/users/queryUserPageListV2";
        /**
         * 用户列表(带分页)(粉丝,关注,相关用户搜索)
         */
        export const QueryUserPageListV2 = (data: QueryUserPageListV2Data) => Ajax.post(QueryUserPageListV2Url, data);
        /**
         * OK
         */
        export type QueryUserRecommendListResponse = AppResponse<AppPageListResponse<UserRecommendVo>>;
        /**
         * form
         */
        export type QueryUserRecommendListData = UserRecommendQueryForm;
        const QueryUserRecommendListUrl: AjaxPost<QueryUserRecommendListResponse, QueryUserRecommendListData> = "/users/queryUserRecommendList";
        /**
         * 获取用户邀请记录
         */
        export const QueryUserRecommendList = (data: QueryUserRecommendListData) => Ajax.post(QueryUserRecommendListUrl, data);
        /**
         * OK
         */
        export type QueryUserRecordingTimePageListResponse = AppResponse<AppPageListResponse<RecordingTimeBean>>;
        /**
         * form
         */
        export type QueryUserRecordingTimePageListData = UserRecordingTimeQueryForm;
        const QueryUserRecordingTimePageListUrl: AjaxPost<QueryUserRecordingTimePageListResponse, QueryUserRecordingTimePageListData> = "/users/queryUserRecordingTimePageList";
        /**
         * 查询某用户时间轴列表(带分页)
         */
        export const QueryUserRecordingTimePageList = (data: QueryUserRecordingTimePageListData) => Ajax.post(QueryUserRecordingTimePageListUrl, data);
        /**
         * OK
         */
        export type QueryUserRecordingTimePageListV2Response = AppResponse<AppPageListResponse<RecordingTimeBean>>;
        /**
         * form
         */
        export type QueryUserRecordingTimePageListV2Data = UserRecordingTimeQueryForm;
        const QueryUserRecordingTimePageListV2Url: AjaxPost<QueryUserRecordingTimePageListV2Response, QueryUserRecordingTimePageListV2Data> = "/users/queryUserRecordingTimePageListV2";
        /**
         * 查询某用户时间轴列表(带分页)V2
         */
        export const QueryUserRecordingTimePageListV2 = (data: QueryUserRecordingTimePageListV2Data) => Ajax.post(QueryUserRecordingTimePageListV2Url, data);
        /**
         * OK
         */
        export type QueryUserScrollListResponse = AppResponse<AppScrollPageListResponse<UserVo>>;
        /**
         * form
         */
        export type QueryUserScrollListData = UserQueryForm;
        const QueryUserScrollListUrl: AjaxPost<QueryUserScrollListResponse, QueryUserScrollListData> = "/users/queryUserScrollList";
        /**
         * 滚动查询用户列表(带分页)(粉丝,关注,相关用户搜索)
         */
        export const QueryUserScrollList = (data: QueryUserScrollListData) => Ajax.post(QueryUserScrollListUrl, data);
        /**
         * OK
         */
        export type RegisterResponse = AppResponse<UserVo>;
        /**
         * form
         */
        export type RegisterData = UserCreateForm;
        const RegisterUrl: AjaxPost<RegisterResponse, RegisterData> = "/users/register";
        /**
         * 用户注册
         */
        export const Register = (data: RegisterData) => Ajax.post(RegisterUrl, data);
        /**
         * OK
         */
        export type RegisterV2Response = AppResponse<UserLoginReturnVo>;
        /**
         * form
         */
        export type RegisterV2Data = UserCreateForm;
        const RegisterV2Url: AjaxPost<RegisterV2Response, RegisterV2Data> = "/users/registerV2";
        /**
         * 用户注册V2
         */
        export const RegisterV2 = (data: RegisterV2Data) => Ajax.post(RegisterV2Url, data);
        /**
         * OK
         */
        export type ResetPasswordResponse = AppResponse<UserVo>;
        /**
         * form
         */
        export type ResetPasswordData = UserResetPasswordForm;
        const ResetPasswordUrl: AjaxPost<ResetPasswordResponse, ResetPasswordData> = "/users/resetPassword";
        /**
         * 重置密码
         */
        export const ResetPassword = (data: ResetPasswordData) => Ajax.post(ResetPasswordUrl, data);
        /**
         * OK
         */
        export type SetAndroidUserMobileTypeResponse = AppResponse;
        /**
         * form
         */
        export type SetAndroidUserMobileTypeData = UserSetMobileTypeForm;
        const SetAndroidUserMobileTypeUrl: AjaxPost<SetAndroidUserMobileTypeResponse, SetAndroidUserMobileTypeData> = "/users/setAndroidUserMobileType";
        /**
         * 设置用户手机类型(安卓使用)
         */
        export const SetAndroidUserMobileType = (data: SetAndroidUserMobileTypeData) => Ajax.post(SetAndroidUserMobileTypeUrl, data);
        /**
         * OK
         */
        export type SetFeedBackIsSloveResponse = AppResponse;
        /**
         * form
         */
        export type SetFeedBackIsSloveData = UserFeedbackSloveForm;
        const SetFeedBackIsSloveUrl: AjaxPost<SetFeedBackIsSloveResponse, SetFeedBackIsSloveData> = "/users/setFeedBackIsSlove";
        /**
         * 设置意见反馈是否解决
         */
        export const SetFeedBackIsSlove = (data: SetFeedBackIsSloveData) => Ajax.post(SetFeedBackIsSloveUrl, data);
        /**
         * OK
         */
        export type ShieldOrNotUserResponse = AppResponse<UserShieldInfoBean>;
        /**
         * form
         */
        export type ShieldOrNotUserData = UserFollowsForm;
        const ShieldOrNotUserUrl: AjaxPost<ShieldOrNotUserResponse, ShieldOrNotUserData> = "/users/shieldOrNotUser";
        /**
         * 屏蔽/取消屏蔽用户
         */
        export const ShieldOrNotUser = (data: ShieldOrNotUserData) => Ajax.post(ShieldOrNotUserUrl, data);
        /**
         * OK
         */
        export type SigninResponse = AppResponse;
        const SigninUrl: AjaxPost<SigninResponse, never> = "/users/signin";
        /**
         * 用户签到
         */
        export const Signin = () => Ajax.post(SigninUrl);
        /**
         * OK
         */
        export type UnBindAccountResponse = AppResponse;
        /**
         * form
         */
        export type UnBindAccountData = UserUnBindAccountForm;
        const UnBindAccountUrl: AjaxPost<UnBindAccountResponse, UnBindAccountData> = "/users/unBindAccount";
        /**
         * 解除绑定账号
         */
        export const UnBindAccount = (data: UnBindAccountData) => Ajax.post(UnBindAccountUrl, data);
        /**
         * OK
         */
        export type UpdateInvoiceResponse = AppResponse<UserInvoiceVo>;
        /**
         * form
         */
        export type UpdateInvoiceData = UserInvoiceUpdateForm;
        const UpdateInvoiceUrl: AjaxPost<UpdateInvoiceResponse, UpdateInvoiceData> = "/users/updateInvoice";
        /**
         * 修改增票资质
         */
        export const UpdateInvoice = (data: UpdateInvoiceData) => Ajax.post(UpdateInvoiceUrl, data);
        /**
         * OK
         */
        export type UpdateRecordingTimeBeanResponse = AppResponse<UserVo>;
        /**
         * form
         */
        export type UpdateRecordingTimeBeanData = UserRecordingTimeUpdateForm;
        const UpdateRecordingTimeBeanUrl: AjaxPost<UpdateRecordingTimeBeanResponse, UpdateRecordingTimeBeanData> = "/users/updateRecordingTimeBean";
        /**
         * 修改时间轴数据
         */
        export const UpdateRecordingTimeBean = (data: UpdateRecordingTimeBeanData) => Ajax.post(UpdateRecordingTimeBeanUrl, data);
        /**
         * OK
         */
        export type UpdateUserInfoResponse = AppResponse<UserVo>;
        /**
         * form
         */
        export type UpdateUserInfoData = UserUpdateForm;
        const UpdateUserInfoUrl: AjaxPost<UpdateUserInfoResponse, UpdateUserInfoData> = "/users/updateUserInfo";
        /**
         * 修改个人资料
         */
        export const UpdateUserInfo = (data: UpdateUserInfoData) => Ajax.post(UpdateUserInfoUrl, data);
        /**
         * OK
         */
        export type VerifyAccountIsExistResponse = AppResponse<Map<string,object>>;
        export type VerifyAccountIsExistData = {
            /**
             * 账号(如果是手机填手机号，如果是第三方填账户号)
             */
            phone: string;
            /**
             * 验证账号的类型：(1.QQ;2.wx;3.wb;4.手机号)
             */
            accountType: number;
        };
        const VerifyAccountIsExistUrl: AjaxGet<VerifyAccountIsExistResponse, VerifyAccountIsExistData> = "/users/verifyAccountIsExist";
        /**
         * 验证账号是否存在
         */
        export const VerifyAccountIsExist = (data: VerifyAccountIsExistData) => Ajax.get(VerifyAccountIsExistUrl, data);
        /**
         * OK
         */
        export type VerifyMsgCodeResponse = AppResponse<SingleStringVo>;
        export type VerifyMsgCodeData = {
            /**
             * 验证码
             */
            msgCode: string;
            /**
             * 账号(如果是手机填手机号，如果是第三方填账户号)
             */
            phone: string;
            /**
             * 短信验证码类型：1.注册; 2登录；3.忘记密码；4.绑定手机号；5.解绑手机号; 6.修改已绑定手机 旧手机号; 7.修改已绑定手机 新手机号; 8.注销账号
             */
            msgType: number;
        };
        const VerifyMsgCodeUrl: AjaxGet<VerifyMsgCodeResponse, VerifyMsgCodeData> = "/users/verifyMsgCode";
        /**
         * 验证短信验证码是否正确
         */
        export const VerifyMsgCode = (data: VerifyMsgCodeData) => Ajax.get(VerifyMsgCodeUrl, data);
        /**
         * OK
         */
        export type VerifyMsgCodeBindPhoneResponse = AppResponse<SingleStringVo>;
        export type VerifyMsgCodeBindPhoneData = {
            /**
             * 验证码
             */
            msgCode: string;
            /**
             * 账号(如果是手机填手机号，如果是第三方填账户号)
             */
            phone: string;
            /**
             * 短信验证码类型：1.注册; 2登录；3.忘记密码；4.绑定手机号；5.解绑手机号; 6.修改已绑定手机 旧手机号; 7.修改已绑定手机 新手机号; 8.注销账号
             */
            msgType: number;
            /**
             * 三方账户类型(三方账户绑定手机号的时候传 1.QQ;2.wx;3.wb;)
             */
            ownerType?: string;
        };
        const VerifyMsgCodeBindPhoneUrl: AjaxGet<VerifyMsgCodeBindPhoneResponse, VerifyMsgCodeBindPhoneData> = "/users/verifyMsgCodeBindPhone";
        /**
         * 绑定手机号验证短信验证码是否正确
         */
        export const VerifyMsgCodeBindPhone = (data: VerifyMsgCodeBindPhoneData) => Ajax.get(VerifyMsgCodeBindPhoneUrl, data);
        /**
         * OK
         */
        export type VerifyOldPasswordResponse = AppResponse;
        /**
         * form
         */
        export type VerifyOldPasswordData = UserverifyOldPasswordForm;
        const VerifyOldPasswordUrl: AjaxPost<VerifyOldPasswordResponse, VerifyOldPasswordData> = "/users/verifyOldPassword";
        /**
         * 验证旧密码是否正确
         */
        export const VerifyOldPassword = (data: VerifyOldPasswordData) => Ajax.post(VerifyOldPasswordUrl, data);
    }
    /**
     * 动态相关接口
     */
    export namespace Dynamic {
        /**
         * OK
         */
        export type AddResponse = AppResponse;
        /**
         * form
         */
        export type AddData = DynamicCreateForm;
        const AddUrl: AjaxPost<AddResponse, AddData> = "/users/dynamic/add";
        /**
         * 新增
         */
        export const Add = (data: AddData) => Ajax.post(AddUrl, data);
        /**
         * OK
         */
        export type DelDynamicInfoResponse = AppResponse;
        /**
         * form
         */
        export type DelDynamicInfoData = DynamicDelForm;
        const DelDynamicInfoUrl: AjaxPost<DelDynamicInfoResponse, DelDynamicInfoData> = "/users/dynamic/delDynamicInfo";
        /**
         * 删除动态/评论
         */
        export const DelDynamicInfo = (data: DelDynamicInfoData) => Ajax.post(DelDynamicInfoUrl, data);
        /**
         * OK
         */
        export type DynamicCollectResponse = AppResponse;
        /**
         * form
         */
        export type DynamicCollectData = DynamicCollectForm;
        const DynamicCollectUrl: AjaxPost<DynamicCollectResponse, DynamicCollectData> = "/users/dynamic/dynamicCollect";
        /**
         * 动态收藏
         */
        export const DynamicCollect = (data: DynamicCollectData) => Ajax.post(DynamicCollectUrl, data);
        /**
         * OK
         */
        export type DynamicCommentResponse = AppResponse;
        /**
         * form
         */
        export type DynamicCommentData = DynamicCommentCreateForm;
        const DynamicCommentUrl: AjaxPost<DynamicCommentResponse, DynamicCommentData> = "/users/dynamic/dynamicComment";
        /**
         * 动态评论
         */
        export const DynamicComment = (data: DynamicCommentData) => Ajax.post(DynamicCommentUrl, data);
        /**
         * OK
         */
        export type DynamicFeedPointResponse = AppResponse;
        /**
         * form
         */
        export type DynamicFeedPointData = DynamicFeedPointForm;
        const DynamicFeedPointUrl: AjaxPost<DynamicFeedPointResponse, DynamicFeedPointData> = "/users/dynamic/dynamicFeedPoint";
        /**
         * 动态喂豆
         */
        export const DynamicFeedPoint = (data: DynamicFeedPointData) => Ajax.post(DynamicFeedPointUrl, data);
        /**
         * OK
         */
        export type DynamicPraiseResponse = AppResponse;
        /**
         * form
         */
        export type DynamicPraiseData = DynamicPraiseForm;
        const DynamicPraiseUrl: AjaxPost<DynamicPraiseResponse, DynamicPraiseData> = "/users/dynamic/dynamicPraise";
        /**
         * 动态/评论点赞
         */
        export const DynamicPraise = (data: DynamicPraiseData) => Ajax.post(DynamicPraiseUrl, data);
        /**
         * OK
         */
        export type IncreaseDynamicBrowseNumberResponse = AppResponse;
        /**
         * form
         */
        export type IncreaseDynamicBrowseNumberData = DynamicIncreaseBrowseListForm;
        const IncreaseDynamicBrowseNumberUrl: AjaxPost<IncreaseDynamicBrowseNumberResponse, IncreaseDynamicBrowseNumberData> = "/users/dynamic/increaseDynamicBrowseNumber";
        /**
         * 新动态围观量
         */
        export const IncreaseDynamicBrowseNumber = (data: IncreaseDynamicBrowseNumberData) => Ajax.post(IncreaseDynamicBrowseNumberUrl, data);
        /**
         * OK
         */
        export type QueryDynamicCommentListResponse = AppResponse<AppListResponse<Map<string,object>>>;
        /**
         * form
         */
        export type QueryDynamicCommentListData = DynamicCommentQueryForm;
        const QueryDynamicCommentListUrl: AjaxPost<QueryDynamicCommentListResponse, QueryDynamicCommentListData> = "/users/dynamic/queryDynamicCommentList";
        /**
         * 查询列表(不分页)
         */
        export const QueryDynamicCommentList = (data: QueryDynamicCommentListData) => Ajax.post(QueryDynamicCommentListUrl, data);
        /**
         * OK
         */
        export type QueryDynamicCommentPageListResponse = AppResponse<AppPageListResponse<Map<string,object>>>;
        /**
         * form
         */
        export type QueryDynamicCommentPageListData = DynamicCommentQueryForm;
        const QueryDynamicCommentPageListUrl: AjaxPost<QueryDynamicCommentPageListResponse, QueryDynamicCommentPageListData> = "/users/dynamic/queryDynamicCommentPageList";
        /**
         * 查询列表(带分页)
         */
        export const QueryDynamicCommentPageList = (data: QueryDynamicCommentPageListData) => Ajax.post(QueryDynamicCommentPageListUrl, data);
        /**
         * OK
         */
        export type QueryDynamicCommentPageListV2Response = AppResponse<AppPageListResponse<DynamicCommentBeanVo>>;
        /**
         * form
         */
        export type QueryDynamicCommentPageListV2Data = DynamicCommentQueryForm;
        const QueryDynamicCommentPageListV2Url: AjaxPost<QueryDynamicCommentPageListV2Response, QueryDynamicCommentPageListV2Data> = "/users/dynamic/queryDynamicCommentPageListV2";
        /**
         * 查询列表(带分页)
         */
        export const QueryDynamicCommentPageListV2 = (data: QueryDynamicCommentPageListV2Data) => Ajax.post(QueryDynamicCommentPageListV2Url, data);
        /**
         * OK
         */
        export type QueryDynamicDetailsResponse = AppResponse<DynamicBeanVo>;
        /**
         * form
         */
        export type QueryDynamicDetailsData = DynamicCommentQueryForm;
        const QueryDynamicDetailsUrl: AjaxPost<QueryDynamicDetailsResponse, QueryDynamicDetailsData> = "/users/dynamic/queryDynamicDetails";
        /**
         * 动态详情
         */
        export const QueryDynamicDetails = (data: QueryDynamicDetailsData) => Ajax.post(QueryDynamicDetailsUrl, data);
        /**
         * OK
         */
        export type QueryDynamicNoticPageListResponse = AppResponse<AppPageListResponse<DynamicNoticBeanVo>>;
        /**
         * form
         */
        export type QueryDynamicNoticPageListData = DynamicNoticQueryForm;
        const QueryDynamicNoticPageListUrl: AjaxPost<QueryDynamicNoticPageListResponse, QueryDynamicNoticPageListData> = "/users/dynamic/queryDynamicNoticPageList";
        /**
         * 查询动态通知列表(带分页)
         */
        export const QueryDynamicNoticPageList = (data: QueryDynamicNoticPageListData) => Ajax.post(QueryDynamicNoticPageListUrl, data);
        /**
         * OK
         */
        export type QueryDynamicNoticPageListV2Response = AppResponse<AppPageListResponse<DynamicNoticBeanVo>>;
        /**
         * form
         */
        export type QueryDynamicNoticPageListV2Data = DynamicNoticQueryForm;
        const QueryDynamicNoticPageListV2Url: AjaxPost<QueryDynamicNoticPageListV2Response, QueryDynamicNoticPageListV2Data> = "/users/dynamic/queryDynamicNoticPageListV2";
        /**
         * 查询动态通知列表(带分页)
         */
        export const QueryDynamicNoticPageListV2 = (data: QueryDynamicNoticPageListV2Data) => Ajax.post(QueryDynamicNoticPageListV2Url, data);
        /**
         * OK
         */
        export type QueryDynamicPageListResponse = AppResponse<AppMultiplePageListResponse<DynamicBeanVo>>;
        /**
         * form
         */
        export type QueryDynamicPageListData = DynamicQueryForm;
        const QueryDynamicPageListUrl: AjaxPost<QueryDynamicPageListResponse, QueryDynamicPageListData> = "/users/dynamic/queryDynamicPageList";
        /**
         * 查询列表(带分页)
         */
        export const QueryDynamicPageList = (data: QueryDynamicPageListData) => Ajax.post(QueryDynamicPageListUrl, data);
        /**
         * OK
         */
        export type QueryDynamicPageListV2Response = AppResponse<AppPageListResponse<DynamicBeanVo>>;
        /**
         * form
         */
        export type QueryDynamicPageListV2Data = DynamicQueryForm;
        const QueryDynamicPageListV2Url: AjaxPost<QueryDynamicPageListV2Response, QueryDynamicPageListV2Data> = "/users/dynamic/queryDynamicPageListV2";
        /**
         * 查询列表(带分页)
         */
        export const QueryDynamicPageListV2 = (data: QueryDynamicPageListV2Data) => Ajax.post(QueryDynamicPageListV2Url, data);
        /**
         * OK
         */
        export type QueryDynamicScrollListResponse = AppResponse<AppScrollPageListResponse<DynamicBeanVo>>;
        /**
         * form
         */
        export type QueryDynamicScrollListData = DynamicQueryForm;
        const QueryDynamicScrollListUrl: AjaxPost<QueryDynamicScrollListResponse, QueryDynamicScrollListData> = "/users/dynamic/queryDynamicScrollList";
        /**
         * 查询列表(带分页)
         */
        export const QueryDynamicScrollList = (data: QueryDynamicScrollListData) => Ajax.post(QueryDynamicScrollListUrl, data);
        /**
         * OK
         */
        export type QueryNewInfoCountResponse = AppResponse<IntDataVo>;
        /**
         * form
         */
        export type QueryNewInfoCountData = DynamicNewInfoQueryForm;
        const QueryNewInfoCountUrl: AjaxPost<QueryNewInfoCountResponse, QueryNewInfoCountData> = "/users/dynamic/queryNewInfoCount";
        /**
         * 根据时间查询新消息的数量
         */
        export const QueryNewInfoCount = (data: QueryNewInfoCountData) => Ajax.post(QueryNewInfoCountUrl, data);
        /**
         * OK
         */
        export type QueryNoticPointPageListResponse = AppResponse<AppPageListResponse<PointNoticBeanVo>>;
        /**
         * form
         */
        export type QueryNoticPointPageListData = UserPointNoticQueryForm;
        const QueryNoticPointPageListUrl: AjaxPost<QueryNoticPointPageListResponse, QueryNoticPointPageListData> = "/users/dynamic/queryNoticPointPageList";
        /**
         * 查询燃豆助手列表(带分页)
         */
        export const QueryNoticPointPageList = (data: QueryNoticPointPageListData) => Ajax.post(QueryNoticPointPageListUrl, data);
        /**
         * OK
         */
        export type QuerySubCommentListResponse = AppResponse<AppListResponse<DynamicCommentBeanVo>>;
        /**
         * form
         */
        export type QuerySubCommentListData = DynamicCommentQueryForm;
        const QuerySubCommentListUrl: AjaxPost<QuerySubCommentListResponse, QuerySubCommentListData> = "/users/dynamic/querySubCommentList";
        /**
         * 查询子评论列表(不分页)
         */
        export const QuerySubCommentList = (data: QuerySubCommentListData) => Ajax.post(QuerySubCommentListUrl, data);
        /**
         * OK
         */
        export type QuerySubCommentPageListResponse = AppResponse<AppPageListResponse<DynamicCommentBeanVo>>;
        /**
         * form
         */
        export type QuerySubCommentPageListData = DynamicCommentQueryForm;
        const QuerySubCommentPageListUrl: AjaxPost<QuerySubCommentPageListResponse, QuerySubCommentPageListData> = "/users/dynamic/querySubCommentPageList";
        /**
         * 查询子评论列表(分页)
         */
        export const QuerySubCommentPageList = (data: QuerySubCommentPageListData) => Ajax.post(QuerySubCommentPageListUrl, data);
        /**
         * OK
         */
        export type TipOffResponse = AppResponse;
        /**
         * form
         */
        export type TipOffData = TipOffCreateForm;
        const TipOffUrl: AjaxPost<TipOffResponse, TipOffData> = "/users/dynamic/tipOff";
        /**
         * 举报动态/评论
         */
        export const TipOff = (data: TipOffData) => Ajax.post(TipOffUrl, data);
    }
    /**
     * 话题相关接口
     */
    export namespace Topics {
        /**
         * OK
         */
        export type AddResponse = AppResponse;
        /**
         * form
         */
        export type AddData = TopicsCreateForm;
        const AddUrl: AjaxPost<AddResponse, AddData> = "/users/topic/add";
        /**
         * 创建话题
         */
        export const Add = (data: AddData) => Ajax.post(AddUrl, data);
        /**
         * OK
         */
        export type DeleteByAppUserResponse = AppResponse;
        /**
         * form
         */
        export type DeleteByAppUserData = TopicsAppUserDeleteForm;
        const DeleteByAppUserUrl: AjaxPost<DeleteByAppUserResponse, DeleteByAppUserData> = "/users/topic/deleteByAppUser";
        /**
         * App用户删除话题
         */
        export const DeleteByAppUser = (data: DeleteByAppUserData) => Ajax.post(DeleteByAppUserUrl, data);
        /**
         * OK
         */
        export type FollowOrNotTopicsResponse = AppResponse;
        /**
         * form
         */
        export type FollowOrNotTopicsData = TopicsFollowsForm;
        const FollowOrNotTopicsUrl: AjaxPost<FollowOrNotTopicsResponse, FollowOrNotTopicsData> = "/users/topic/followOrNotTopics";
        /**
         * 关注/取消关注话题
         */
        export const FollowOrNotTopics = (data: FollowOrNotTopicsData) => Ajax.post(FollowOrNotTopicsUrl, data);
        /**
         * OK
         */
        export type QueryMyTopicsListResponse = AppResponse<Map<string,Array<TopicsListBeanVo>>>;
        const QueryMyTopicsListUrl: AjaxPost<QueryMyTopicsListResponse, never> = "/users/topic/queryMyTopicsList";
        /**
         * 我发起的话题(登录者本身)
         */
        export const QueryMyTopicsList = () => Ajax.post(QueryMyTopicsListUrl);
        /**
         * OK
         */
        export type QueryTopicsDetailsResponse = AppResponse<TopicsBeanVo>;
        /**
         * form
         */
        export type QueryTopicsDetailsData = TopicsQueryForm;
        const QueryTopicsDetailsUrl: AjaxPost<QueryTopicsDetailsResponse, QueryTopicsDetailsData> = "/users/topic/queryTopicsDetails";
        /**
         * 话题详情
         */
        export const QueryTopicsDetails = (data: QueryTopicsDetailsData) => Ajax.post(QueryTopicsDetailsUrl, data);
        /**
         * OK
         */
        export type QueryTopicsPageListResponse = AppResponse<AppMultiplePageListResponse<TopicsListBeanVo>>;
        /**
         * form
         */
        export type QueryTopicsPageListData = TopicsQueryForm;
        const QueryTopicsPageListUrl: AjaxPost<QueryTopicsPageListResponse, QueryTopicsPageListData> = "/users/topic/queryTopicsPageList";
        /**
         * 查询列表(带分页)
         */
        export const QueryTopicsPageList = (data: QueryTopicsPageListData) => Ajax.post(QueryTopicsPageListUrl, data);
        /**
         * OK
         */
        export type QueryTopicsPageListV2Response = AppResponse<AppPageListResponse<TopicsListBeanVo>>;
        /**
         * form
         */
        export type QueryTopicsPageListV2Data = TopicsQueryForm;
        const QueryTopicsPageListV2Url: AjaxPost<QueryTopicsPageListV2Response, QueryTopicsPageListV2Data> = "/users/topic/queryTopicsPageListV2";
        /**
         * 查询列表(带分页)
         */
        export const QueryTopicsPageListV2 = (data: QueryTopicsPageListV2Data) => Ajax.post(QueryTopicsPageListV2Url, data);
        /**
         * OK
         */
        export type QueryTopicsScrollListResponse = AppResponse<AppScrollPageListResponse<TopicsListBeanVo>>;
        /**
         * form
         */
        export type QueryTopicsScrollListData = TopicsQueryForm;
        const QueryTopicsScrollListUrl: AjaxPost<QueryTopicsScrollListResponse, QueryTopicsScrollListData> = "/users/topic/queryTopicsScrollList";
        /**
         * 滚动查询列表(带分页)
         */
        export const QueryTopicsScrollList = (data: QueryTopicsScrollListData) => Ajax.post(QueryTopicsScrollListUrl, data);
        /**
         * OK
         */
        export type UpdateTopicsResponse = AppResponse;
        /**
         * form
         */
        export type UpdateTopicsData = TopicsUpdateForm;
        const UpdateTopicsUrl: AjaxPost<UpdateTopicsResponse, UpdateTopicsData> = "/users/topic/updateTopics";
        /**
         * 修改话题
         */
        export const UpdateTopics = (data: UpdateTopicsData) => Ajax.post(UpdateTopicsUrl, data);
    }
    /**
     * 订单表
     */
    export type AppShopPointsOrderCreateForm = {
        /**
         * 买家id
         */
        memberId: string;
        /**
         * 支付方式
         */
        paymentId: number;
        /**
         * 字典Type
         */
        sysDicType: string;
    };
    export type AsyncTaskExecutor = any;
    /**
     * BaseStringExtendBaseForm
     */
    export type BaseStringExtendBaseForm = {
        /**
         * 参数
         */
        id?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
    };
    /**
     * BaseStringForm
     */
    export type BaseStringForm = {
        /**
         * 参数
         */
        id: string;
    };
    /**
     * 跟据活动ID和用户id获取对应信息
     */
    export type BindShowQueryForm = {
        /**
         * 活动ID
         */
        activityId?: number;
        /**
         * 邀请人的邀请码
         */
        inviteCode?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户ID
         */
        userId?: string;
    };
    export type BindShowVo = {
        /**
         * 邀请的活动信息
         */
        dressupVo?: UserDressupVo;
        /**
         * 注册时候当前用户邀请人的邀请码
         */
        invitationUserCode?: string;
        /**
         * 邀请人邀请码
         */
        inviterCode?: string;
        /**
         * 邀请人头像
         */
        inviterHeader?: string;
        /**
         * 邀请人名称
         */
        inviterName?: string;
        /**
         * 展示绑定信息 0:以前注册;1:绑定邀请人;2:邀请主力;
         */
        showBindInfo?: number;
    };
    export type BolleanVo = {
        /**
         * 返回结果
         */
        result?: boolean;
    };
    export type DynamicBeanVo = {
        /**
         * 计时Id
         */
        absorbedId?: number;
        /**
         * 发布地址中文名称
         */
        address?: string;
        /**
         * 发布地址编码
         */
        addressCode?: string;
        /**
         * 所在城市是否公开 1是 0否
         */
        addressIsPublic?: number;
        /**
         * 浏览次数
         */
        browseNumber?: number;
        /**
         * 收藏次数
         */
        collectNumber?: number;
        /**
         * 评论次数
         */
        commentNumber?: number;
        /**
         * 内容
         */
        content?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 数据来源 1:老数据;2:圈子发起(app/manager);3:专注模式;4:学霸模式;5:练习专注;6:待办专注;7:练习打卡;8:燃草日历本身;9:目标下发起日志;10:待办清单(个人资料使用);
         */
        createSource?: number;
        /**
         * 当前次数/当前计时时间秒数
         */
        currentTimes?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 动态创建者头像挂件
         */
        dressUpHeadID?: number;
        /**
         * 动态创建者头像
         */
        dynamicCreateHead?: string;
        /**
         * 动态创建者id
         */
        dynamicCreateId?: string;
        /**
         * 动态创建者名称
         */
        dynamicCreateName?: string;
        /**
         * 动态创建者职业Color
         */
        dynamicCreateOccupationColor?: string;
        /**
         * 动态创建者职业Id
         */
        dynamicCreateOccupationId?: number;
        /**
         * 动态创建者职业Name
         */
        dynamicCreateOccupationName?: string;
        /**
         * 喂豆人列表
         */
        dynamicFeedPointList?: DynamicFeedPointBean[];
        /**
         * 喂豆总人数
         */
        feedPointPersonNumber?: number;
        /**
         * 一级评论次数
         */
        firstLevelCommentNumber?: number;
        /**
         * 习惯颜色
         */
        habitColor?: string;
        /**
         * 习惯Id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitName?: string;
        /**
         * 动态首图
         */
        headimage?: string;
        /**
         * 动态主键
         */
        id?: string;
        /**
         * 图片规格
         */
        imageSpecs?: string;
        /**
         * 动态图片或视频列表
         */
        imageslist?: string[];
        /**
         * 当前用户是否已经收藏
         */
        isCollect?: boolean;
        /**
         * 当前用户是否已经喂豆
         */
        isFeedPoint?: boolean;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 当前用户是否已经点赞
         */
        isPraise?: boolean;
        /**
         * 是否私密 0否 1是
         */
        isPrivate?: number;
        /**
         * 热门状态(0:非热门;1:热门) 默认非热门
         */
        ishot?: number;
        /**
         * 列表展示名称
         */
        listShowName?: string;
        /**
         * 会员卡类型 0:非会员(非会员或者成长值没有达到等级);1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 模块 1话题 2日历
         */
        modelType?: number;
        /**
         * 待办名称
         */
        needHandleName?: string;
        /**
         * 获得喂豆的总个数
         */
        pointNumber?: number;
        /**
         * 练习Id
         */
        practiceId?: number;
        /**
         * 练习名称
         */
        practiceName?: string;
        /**
         * 点赞次数
         */
        praiseNumber?: number;
        /**
         * 计时秒数
         */
        secondNumber?: number;
        /**
         * 目标开始的天数
         */
        targetDay?: number;
        /**
         * 目标Id
         */
        targetId?: number;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 专注计时状态 0否 1是
         */
        timeState?: number;
        /**
         * 举报次数
         */
        tipOffNumber?: number;
        /**
         * 标题
         */
        title?: string;
        /**
         * 话题创建者Id
         */
        topicsCreateId?: string;
        /**
         * 话题id
         */
        topicsId?: string;
        /**
         * 话题Title
         */
        topicsTitle?: string;
        /**
         * 总次数/总计时时间秒数
         */
        totalTimes?: string;
        /**
         * 动态类型：1视频 2其他
         */
        type?: number;
        /**
         * 登录用户对该用户(动态创建者)的关注信息
         */
        userFollowInfo?: UserFollowInfoBeanVo;
        /**
         * 权重
         */
        weight?: number;
    };
    /**
     * 收藏动态
     */
    export type DynamicCollectForm = {
        /**
         * 动态ID
         */
        id: string;
        /**
         * 操作类型:1收藏 2取消收藏
         */
        type: number;
        /**
         * 操作用户id
         */
        userId?: string;
    };
    export type DynamicCommentBeanVo = {
        /**
         * 评论创建者职业Color
         */
        commentCreateOccupationColor?: string;
        /**
         * 评论创建者职业Name
         */
        commentCreateOccupationName?: string;
        /**
         * 被评论的评论的Id
         */
        commentId?: string;
        /**
         * 评论次数(只限于一级评论)
         */
        commentNumber?: number;
        /**
         * 评论内容
         */
        content?: string;
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 所属动态内容
         */
        dynamicContent?: string;
        dynamicCreateId?: string;
        /**
         * 所属动态ID
         */
        dynamicId?: string;
        /**
         * 一级评论总条数
         */
        firstLevelTotalCount?: number;
        /**
         * 评论人创建者头像挂件
         */
        formUserDressUpHeadID?: number;
        /**
         * 评论人头像
         */
        formUserHead?: string;
        /**
         * 评论人否是会员 1.是会员 0.不是会员 默认0
         */
        formUserIsMember?: number;
        /**
         * 评论人会员类型 0:非会员;1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        formUserMemberType?: number;
        /**
         * 评论人名称
         */
        formUserName?: string;
        /**
         * 评论人 也是发起人
         */
        formUserid?: string;
        /**
         * 评论主键
         */
        id?: string;
        /**
         * 查询某动态下的列表使用  0非热门 1热门
         */
        isHot?: number;
        /**
         * 当前用户是否已经点赞
         */
        isPraise?: boolean;
        lastCommentDate?: string;
        /**
         * 评论的级别 默认1，其余0
         */
        level?: string;
        /**
         * 一级评论的ID
         */
        level1CommentId?: string;
        /**
         * 模块 1话题 2日历
         */
        modelType?: number;
        /**
         * 点赞次数
         */
        praiseNumber?: number;
        /**
         * 子评论总条数
         */
        subCount?: number;
        /**
         * 专注状态 0否 1是
         */
        timeState?: number;
        /**
         * 举报次数
         */
        tipOffNumber?: number;
        /**
         * 被评论人创建者头像挂件
         */
        toUserDressUpHeadID?: number;
        /**
         * 被评论人头像
         */
        toUserHead?: string;
        /**
         * 被评论人否是会员 1.是会员 0.不是会员 默认0
         */
        toUserIsMember?: number;
        /**
         * 被评论人会员类型 0:非会员;1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        toUserMemberType?: number;
        /**
         * 被评论人名称
         */
        toUserName?: string;
        /**
         * 被评论人
         */
        toUserid?: string;
        /**
         * 动态所属话题主键
         */
        topicsId?: string;
        /**
         * 话题标题
         */
        topicsTitle?: string;
        weight?: number;
    };
    /**
     * 新增动态评论
     */
    export type DynamicCommentCreateForm = {
        /**
         * 所操作评论ID
         */
        commentId?: string;
        /**
         * 评论内容
         */
        content?: string;
        /**
         * 动态Id(必传)
         */
        dynamicId?: string;
        /**
         * 评论人
         */
        formUserid?: string;
        /**
         * 评论的级别 默认1(直接评论动态的)，其余0
         */
        level?: string;
        /**
         * 所操作评论所属一级评论的ID 操作评论的时候传
         */
        level1CommentId?: string;
        /**
         * 动态所属话题主键
         */
        topicsId?: string;
        /**
         * 话题标题
         */
        topicsTitle?: string;
    };
    export type DynamicCommentNoticInfoVo = {
        /**
         * 评论内容
         */
        content?: string;
        /**
         * 评论人名称
         */
        formUserName?: string;
        /**
         * 评论人 也是发起人
         */
        formUserid?: string;
        /**
         * 评论主键
         */
        id?: string;
        /**
         * 评论的级别 默认1，其余0
         */
        level?: string;
        /**
         * 一级评论的ID
         */
        level1CommentId?: string;
        /**
         * 被评论人名称
         */
        toUserName?: string;
        /**
         * 被评论人
         */
        toUserid?: string;
    };
    /**
     * 查询动态评论
     */
    export type DynamicCommentQueryForm = {
        /**
         * 动态ID/一级评论ID
         */
        dynamicId: string;
        orderBy?: string;
        /**
         * 排序方式 支持老版本可以不传默认最新 1:最新 2:最热
         */
        orderType?: number;
        pageNum?: number;
        pageSize?: number;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    /**
     * 新增动态
     */
    export type DynamicCreateForm = {
        /**
         * 计时Id 每次专注都有
         */
        absorbedId?: number;
        /**
         * 发布地址中文名称
         */
        address?: string;
        /**
         * 发布地址编码
         */
        addressCode?: string;
        /**
         * 内容
         */
        content?: string;
        /**
         * 数据来源 1:老数据;2:圈子发起(app/manager);3:专注模式;4:学霸模式;5:练习专注;6:待办专注;7:练习打卡;8:燃草日历本身;9:目标下发起日志;10:待办清单(个人资料使用); 备注：是练习的时候所属习惯可以不传
         */
        createSource?: number;
        /**
         * 动态发起人ID List
         */
        createUserIdList?: string[];
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 动态首图
         */
        headimage?: string;
        /**
         * 图片规格
         */
        imageSpecs?: string;
        /**
         * 图片
         */
        imageslist: string[];
        /**
         * 是否私密 0否 1是
         */
        isPrivate?: number;
        /**
         * 列表展示名称
         */
        listName?: string;
        /**
         * 待办Id
         */
        needHandleId?: number;
        /**
         * 练习Id
         */
        practiceId?: number;
        /**
         * 操作平台 1:APP;2:后台
         */
        source?: number;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 动态标题
         */
        title?: string;
        /**
         * 话题id
         */
        topicsId?: string;
        /**
         * 动态类型：1视频 2其他
         */
        type: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 评论动态Id/评论Id
     */
    export type DynamicDelForm = {
        /**
         * 动态Id/评论Id
         */
        eventId: string;
        /**
         * 1:APP;2:后台
         */
        source?: number;
        /**
         * 举报原因ID
         */
        tipoffreason?: number;
        /**
         * 举报原因内容
         */
        tipoffreasonContent?: string;
        /**
         * 1:动态;2:评论;3:话题(管理员)
         */
        type: number;
        /**
         * 作者本人id
         */
        userId?: string;
    };
    export type DynamicFeedPointBean = {
        /**
         * 被喂豆人ID(动态创建者的ID)
         */
        acceptUserId?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 动态ID
         */
        eventId?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 获得喂豆的个数
         */
        pointNumber?: number;
        /**
         * 喂豆人头像
         */
        userHead?: string;
        /**
         * 喂豆人ID
         */
        userId?: string;
    };
    /**
     * 动态喂豆
     */
    export type DynamicFeedPointForm = {
        /**
         * 动态id
         */
        dynamicId: string;
        /**
         * 喂豆人id
         */
        feedUserId: string;
        /**
         * 喂豆数量
         */
        pointNumber: number;
    };
    export type DynamicIncreaseBrowseForm = {
        /**
         * 浏览次数
         */
        browseNumber?: number;
        /**
         * 所属动态ID
         */
        dynamicId?: string;
    };
    /**
     * 新增动态浏览量
     */
    export type DynamicIncreaseBrowseListForm = {
        /**
         * 动态浏览量
         */
        browseFormList?: DynamicIncreaseBrowseForm[];
    };
    /**
     * App定时请求根据时间获取动态/热门动态的数量
     */
    export type DynamicNewInfoQueryForm = {
        /**
         * 开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        fromDate?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 查询类型(12:App定时请求根据时间获取热门动态数量;13:App定时请求根据时间获取关注动态数量
         */
        searchType?: number;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    export type DynamicNoticBeanVo = {
        /**
         * 接收人Id
         */
        acceptUserId?: string;
        /**
         * 接收人name
         */
        acceptUserName?: string;
        /**
         * 通知别名
         */
        alias?: string;
        /**
         * noticType 是评论/回复评论/点赞的时候
         */
        commentInfo?: DynamicCommentNoticInfoVo;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * noticType 是评论/回复评论/点赞的时候
         */
        dynamicInfo?: DynamicNoticInfoVo;
        /**
         * 动态/评论/话题的标题 内容
         */
        eventContent?: string;
        /**
         * 动态/评论 创建人id
         */
        eventCreateId?: string;
        /**
         * 动态/评论 创建人Name
         */
        eventCreateName?: string;
        /**
         * 动态ID/评论ID/话题的ID
         */
        eventId?: string;
        /**
         * 通知主键ID
         */
        id?: string;
        /**
         * 模块 1圈子 2日志 4用户
         */
        modelType?: number;
        /**
         * 通知的内容 助燃了你的评论/因举报多次被删除
         */
        noticContent?: string;
        /**
         * noticSubType：(目前点赞不发通知)1.话题审核通过;2.话题审核拒绝;3.动态点赞;4.动态评论;5.动态回复;6.动态因举报次数过多被删除;7.评论点赞;8.动态被喂豆;9.用户关注;10.赞回复;11:用户取消关注;; 12 周总结提醒
         */
        noticSubType?: number;
        /**
         * noticType互动通知类型：1.助燃 2评论 3话题
         */
        noticType?: number;
        /**
         * 其他文本内容预留字段 比如：话题拒绝的原因(已有雷同话题)
         */
        othersContent?: string;
        /**
         * 喂豆数量
         */
        pointNumber?: number;
        /**
         * 其他文本内容预留字段2 比如：话题拒绝的备注
         */
        remarksContent?: string;
        /**
         * 发起人head
         */
        sendUserHead?: string;
        /**
         * 发起人Id
         */
        sendUserId?: string;
        /**
         * 发起人name
         */
        sendUserName?: string;
        /**
         * 发起人职业Id
         */
        sendUserOccupation?: number;
        /**
         * 发起人职业颜色
         */
        sendUserOccupationColor?: string;
        /**
         * 发起人职业名称
         */
        sendUserOccupationName?: string;
    };
    export type DynamicNoticInfoVo = {
        /**
         * 内容
         */
        content?: string;
        /**
         * 动态创建者id
         */
        dynamicCreateId?: string;
        /**
         * 动态创建者名称
         */
        dynamicCreateName?: string;
        /**
         * 动态首图
         */
        headimage?: string;
        /**
         * 动态主键
         */
        id?: string;
        /**
         * 标题
         */
        title?: string;
        /**
         * 话题id
         */
        topicsId?: string;
        /**
         * 话题Title
         */
        topicsTitle?: string;
    };
    /**
     * 根据用户ID和通知类型查询动态
     */
    export type DynamicNoticQueryForm = {
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 类型(0:所有;1:助燃;2:喂豆;3:评论;4:粉丝)
         */
        searchType: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 点赞参数
     */
    export type DynamicPraiseForm = {
        /**
         * 所属动态ID
         */
        dynamicId?: string;
        /**
         * 动态或评论Id
         */
        eventId: string;
        /**
         * 操作类型 1点赞 2取消点赞
         */
        operaType?: number;
        /**
         * 1动态 2评论
         */
        type: number;
        /**
         * userId
         */
        userId?: string;
    };
    /**
     * 动态查询
     */
    export type DynamicQueryForm = {
        /**
         * 开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        fromDate?: string;
        /**
         * 是否私密 0否 1是
         */
        isPrivate?: number;
        loginUserId?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 请求次数 第n次请求
         */
        requestTime?: number;
        /**
         * 滚动id
         */
        scrollId?: string;
        /**
         * 下次请求的模块 1:同学习阶段;2:此用户关注话题下的;3:普通权重排序;
         */
        searchModel?: number;
        /**
         * 搜索的内容发起人名称
         */
        searchNameValue?: string;
        /**
         * 搜索时间
         */
        searchTime?: string;
        /**
         * 查询类型(1:热门(权重);2:关注(我关注话题下的+我关注人发起的 时间);3:搜索;4:个人发起的(作者本人));5:某话题下的动态(最热);6:某话题下的动态(最新);7:我参与的;8:个人发起的视频动态(本人);9:某话题下的动态(举报次数最多);10:所有的动态(举报次数最多);11:所有的动态(最新);12:App定时请求根据时间获取热门动态数量; 13:App定时请求根据时间获取关注动态数量;14:个人发起的动态(别人))最热; 15:个人发起的视频动态(别人)); 16:我收藏的动态; 17:个人发起的动态(别人))最新; 18:个人发起的动态(别人))举报次数最多; 19:搜索多个;20:某目标下的动态;
         */
        searchType: number;
        /**
         * 搜索的内容
         */
        searchValue?: string;
        /**
         * 操作平台 1:APP;2:后台
         */
        source?: number;
        /**
         * 目标ID
         */
        targetId?: number;
        /**
         * 结束时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        toDate?: string;
        /**
         * 话题ID
         */
        topicsId?: string;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    export type DynamicSerarchWordHotVo = {
        /**
         * 内容
         */
        content?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 是否有小火苗 1是 0否
         */
        isHot?: number;
        /**
         * 搜索次数
         */
        number?: number;
        /**
         * 1热词 2推荐
         */
        state?: number;
    };
    /**
     * 他人专注场景
     */
    export type EfficiencyAbsorbedSceneForm = {
        /**
         * 他人的用户ID
         */
        otherUserId: string;
        /**
         * 当前查看人/助燃人的用户ID
         */
        seeUid: string;
    };
    /**
     * 他人专注详情信息
     */
    export type EfficiencyAbsorbedSceneVo = {
        /**
         * 他人专注场景助燃个数
         */
        combustNumber?: number;
        /**
         * 他人的装扮头像
         */
        dressUpHead?: string;
        /**
         * 他人专注场景助燃用户头像列表
         */
        efficiencyCombustUserHead?: EfficiencyCombustUserHeadVo[];
        /**
         * 他人的头像
         */
        head?: string;
        /**
         * 是否专注中  0非专注 1专注中
         */
        isAbsorbed?: number;
        /**
         * 他人是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 他人专注开始时间
         */
        otherAbsorbedStartTime?: string;
        /**
         * 专注场景个性装扮
         */
        userDressupVo?: UserDressupVo;
    };
    /**
     * 燃豆兑换目标背景图片
     */
    export type EfficiencyBackgroundImageBuyForm = {
        /**
         * 图片id
         */
        imageId?: number;
        /**
         * 兑换所需燃豆
         */
        needPoints?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 项目背景图片
     */
    export type EfficiencyBackgroundImageCreateForm = {
        /**
         * 图片名称
         */
        imageName?: string;
        /**
         * 图片地址
         */
        imageUrl?: string;
        /**
         * 是否默认 0不是 1是
         */
        isDefault?: number;
        /**
         * Vip专享等级,0:普通用户,1:V1青铜,2:V2秘银,3:V3黄金,4:V4铂金,5:V5钻石,6:V6星辰
         */
        isVip?: number;
        /**
         * 兑换所需燃豆
         */
        needPoints?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 背景图vip标识
         */
        vipExclusive?: string;
    };
    /**
     * 项目背景图片
     */
    export type EfficiencyBackgroundImageQueryForm = {
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id?: number;
        /**
         * 主键id列表
         */
        idList?: number[];
        /**
         * 图片名称
         */
        imageName?: string;
        /**
         * 图片地址
         */
        imageUrl?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 项目背景图片
     */
    export type EfficiencyBackgroundImageUpdateForm = {
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 图片名称
         */
        imageName?: string;
        /**
         * 图片地址
         */
        imageUrl?: string;
        /**
         * 是否默认 0不是 1是
         */
        isDefault?: number;
        /**
         * Vip专享等级,0:普通用户,1:V1青铜,2:V2秘银,3:V3黄金,4:V4铂金,5:V5钻石,6:V6星辰
         */
        isVip?: number;
        /**
         * 所需燃豆数
         */
        needPoints?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 背景图vip标识
         */
        vipExclusive?: string;
    };
    /**
     * 项目背景图片
     */
    export type EfficiencyBackgroundImageVo = {
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 图片名称
         */
        imageName?: string;
        /**
         * 图片地址
         */
        imageUrl?: string;
        /**
         * 是否默认图片 0不是 1是
         */
        isDefault?: number;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 是否已拥有, 0:未拥有 1:已拥有
         */
        isPossess?: number;
        /**
         * Vip专享等级,0:普通用户,1:V1青铜,2:V2秘银,3:V3黄金,4:V4铂金,5:V5钻石,6:V6星辰
         */
        isVip?: number;
        /**
         * VIP燃豆兑换已拥有 0:否 1:是
         */
        isVipExchangePosses?: number;
        /**
         * 会员等级名称
         */
        memberLevelName?: string;
        /**
         * 兑换所需燃豆
         */
        needPoints?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
        /**
         * 背景图vip标识
         */
        vipExclusive?: string;
    };
    /**
     * 系统鸡汤表
     */
    export type EfficiencyChickenSoupQueryForm = {
        /**
         * 所属日期,格式为:yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 鸡汤内容
         */
        diaryContext?: string;
        /**
         * 删除状态
         */
        disable?: number;
        /**
         * id
         */
        id?: number;
        /**
         * 列表
         */
        idList?: number[];
        /**
         * 鸡汤图
         */
        imageUrl?: string;
        /**
         * 月份  1下个月  -1上个月
         */
        month?: number;
        orderBy?: string;
        /**
         * 作者
         */
        origin?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 分享图
         */
        shareImageUrl?: string;
        /**
         * 当前操作用户
         */
        uid?: string;
    };
    /**
     * 系统鸡汤表
     */
    export type EfficiencyChickenSoupVo = {
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 所属日期
         */
        dateOfOwnership?: string;
        /**
         * 日期
         */
        day?: string;
        /**
         * 鸡汤内容
         */
        diaryContext?: string;
        /**
         * 删除状态
         */
        disable?: number;
        /**
         * 动态id
         */
        dynamicId?: string;
        id: number;
        /**
         * 鸡汤图
         */
        imageUrl?: string;
        /**
         * 当前用户是否点赞 0否1是
         */
        ispraise?: number;
        /**
         * 农历
         */
        nonName?: string;
        /**
         * 作者
         */
        origin?: string;
        /**
         * 点赞人头像列表
         */
        praiseHeadList?: string[];
        /**
         * 点赞数
         */
        praiseNum?: number;
        /**
         * 分享图
         */
        shareImageUrl?: string;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
        /**
         * 周几
         */
        weekName?: string;
    };
    /**
     * 他人专注场景助燃用户头像列表
     */
    export type EfficiencyCombustUserHeadVo = {
        /**
         * 助燃数量
         */
        combustNum?: number;
        /**
         * 助燃人装扮头像
         */
        dressUpHead?: string;
        /**
         * 助燃人头像
         */
        head?: string;
        /**
         * 助燃人是否是会员 0:否 1:是
         */
        isMember?: number;
        /**
         * 助燃人用户ID
         */
        uid?: string;
    };
    export type EfficiencyCommentDelCondition = {
        eventId?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        source?: number;
        type?: number;
        userId?: string;
    };
    /**
     * 效率日志评论
     */
    export type EfficiencyCommentJournalCreateForm = {
        /**
         * 被评论的评论id
         */
        commentId?: string;
        /**
         * 评论内容
         */
        content?: string;
        /**
         * 评论人id
         */
        formUserid?: string;
        /**
         * 日志id
         */
        journalId?: string;
        /**
         * 评论级别 一级评论是1 二级评论是0
         */
        level?: string;
        /**
         * 被评论的一级评论id
         */
        level1CommentId?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 被评论人id
         */
        toUserid?: string;
    };
    export type EfficiencyCommentVo = {
        /**
         * 评论创建者职业Color
         */
        commentCreateOccupationColor?: string;
        /**
         * 评论创建者职业Name
         */
        commentCreateOccupationName?: string;
        /**
         * 被评论的评论的Id
         */
        commentId?: string;
        /**
         * 评论次数(只限于一级评论)
         */
        commentNumber?: number;
        /**
         * 二级评论列表
         */
        commentVos?: EfficiencyCommentVo[];
        /**
         * 评论内容
         */
        content?: string;
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 所属动态内容
         */
        dynamicContent?: string;
        /**
         * 评论人头像
         */
        formUserHead?: string;
        /**
         * 评论人名称
         */
        formUserName?: string;
        /**
         * 评论人 也是发起人
         */
        formUserid?: string;
        /**
         * 评论主键
         */
        id?: string;
        /**
         * 查询某动态下的列表使用  0非热门 1热门
         */
        isHot?: number;
        /**
         * 是否点过赞 0否 1是
         */
        isPraise?: number;
        /**
         * 所属动态ID
         */
        journalId?: string;
        /**
         * 评论的级别 默认1，其余0
         */
        level?: string;
        /**
         * 一级评论的ID
         */
        level1CommentId?: string;
        /**
         * 点赞人列表
         */
        praiseInfo?: string[];
        /**
         * 点赞次数
         */
        praiseNumber?: number;
        /**
         * 专注状态 0否1是
         */
        timeState?: number;
        /**
         * 举报次数
         */
        tipOffNumber?: number;
        /**
         * 被评论人头像
         */
        toUserHead?: string;
        /**
         * 被评论人名称
         */
        toUserName?: string;
        /**
         * 被评论人
         */
        toUserid?: string;
        /**
         * 动态所属话题主键
         */
        topicsId?: string;
        /**
         * 话题标题
         */
        topicsTitle?: string;
    };
    /**
     * 日志关联习惯表
     */
    export type EfficiencyCustomaryAssociationVo = {
        /**
         * 习惯颜色
         */
        color?: string;
        /**
         * 习惯名称
         */
        customaryName?: string;
        /**
         * 习惯父类型
         */
        customaryType?: number;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 习惯集合
         */
        efficiencyCustomaryAssociationVoList?: EfficiencyCustomaryAssociationVo[];
        /**
         * 习惯id
         */
        habIt?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 习惯图标
         */
        imageUrl?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 日历集合
     */
    export type EfficiencyDailyDateVo = {
        /**
         * 时间集合yyyy-MM-dd
         */
        dayList?: string[];
        /**
         * 农历集合
         */
        nonList?: string[];
    };
    /**
     * 每日一记表
     */
    export type EfficiencyDailyNotesCreateForm = {
        /**
         * 日记内容
         */
        notesContent?: string;
        /**
         * 记录时间(年月日)
         */
        recordingTime?: string;
        /**
         * 资源集合
         */
        resourcesList?: string[];
        /**
         * 资源类型 1图片 2视频
         */
        resourcesType?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 天气情况
         */
        weatherState?: string;
    };
    /**
     * 每日一记表
     */
    export type EfficiencyDailyNotesUpdateForm = {
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 日记id
         */
        id: number;
        /**
         * 日记内容
         */
        notesContent?: string;
        /**
         * 记录时间(年月日)
         */
        recordingTime?: string;
        /**
         * 资源集合
         */
        resourcesList?: string[];
        /**
         * 资源类型 1图片 2视频
         */
        resourcesType?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 天气情况
         */
        weatherState?: string;
    };
    /**
     * 每日一记表
     */
    export type EfficiencyDailyNotesVo = {
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 日记id
         */
        id: number;
        /**
         * 日记内容
         */
        notesContent?: string;
        /**
         * 记录时间(年月日)
         */
        recordingTime?: string;
        /**
         * 资源集合
         */
        resourceList?: EfficiencyPictureVo[];
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
        /**
         * 天气情况
         */
        weatherState?: string;
    };
    /**
     * vip用户计时数据统计分布数据-常用习惯top10
     */
    export type EfficiencyDistributionCommonHabitDetailVo = {
        /**
         * 习惯颜色
         */
        habitColor?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitName?: string;
        /**
         * 习惯图标
         */
        icon?: string;
        /**
         * 使用时长
         */
        useDuration?: string;
        /**
         * 使用时长秒数
         */
        useSecond?: number;
        /**
         * 使用次数
         */
        useTimes?: number;
    };
    /**
     * vip用户计时数据统计分布数据-常用习惯top10
     */
    export type EfficiencyDistributionCommonHabitVo = {
        /**
         * 使用时长排行
         */
        orderByUseSecondList?: EfficiencyDistributionCommonHabitDetailVo[];
        /**
         * 使用次数排行
         */
        orderByUseTimesList?: EfficiencyDistributionCommonHabitDetailVo[];
    };
    /**
     * vip用户计时数据统计分布数据-使用频率
     */
    export type EfficiencyDistributionFrequencyVo = {
        /**
         * 专注次数
         */
        absorbNums?: number;
        /**
         * 发布动态次数
         */
        dynamicNums?: number;
        /**
         * 待办次数
         */
        needHandleNums?: number;
        /**
         * 练习次数
         */
        practiceNums?: number;
        /**
         * 周计划条数
         */
        weekPlanNums?: number;
    };
    /**
     * vip用户计时数据统计分布数据-从未使用过的习惯
     */
    export type EfficiencyDistributionNeverUseHabitVo = {
        /**
         * 习惯颜色
         */
        habitColor?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitName?: string;
        /**
         * 习惯图标
         */
        icon?: string;
    };
    /**
     * vip用户计时数据统计分布数据-专注走势-详情数据
     */
    export type EfficiencyDistributionTrendDetailVo = {
        /**
         * 对应数据 时长或次数
         */
        data?: string;
        /**
         * 数据所属日期
         */
        dataOfOwnerShip?: string;
        /**
         * 精确数据-时长对应秒数 次数对应次数
         */
        preciseData?: number;
        /**
         * 所属月份
         */
        unit?: number;
    };
    /**
     * vip用户计时数据统计分布数据-专注走势-详情数据
     */
    export type EfficiencyDistributionTrendTypeDataVo = {
        /**
         * 30天数据
         */
        thirtyDayData?: EfficiencyDistributionTrendDetailVo[];
        /**
         * 12月数据
         */
        twelveMonthData?: EfficiencyDistributionTrendDetailVo[];
        /**
         * 12周数据
         */
        twelveWeekData?: EfficiencyDistributionTrendDetailVo[];
    };
    /**
     * vip用户计时数据统计分布数据-专注走势
     */
    export type EfficiencyDistributionTrendVo = {
        /**
         * 专注走势-时长
         */
        durationVo?: EfficiencyDistributionTrendTypeDataVo;
        /**
         * 专注走势-次数
         */
        timesVo?: EfficiencyDistributionTrendTypeDataVo;
    };
    /**
     * vip用户计时数据统计分布数据-计时方式
     */
    export type EfficiencyDistributionTypeVo = {
        /**
         * 计时时长-秒数
         */
        absorbSecond?: number;
        /**
         * 计时时长
         */
        absorbTime?: string;
        /**
         * 专注类型 1代办专注 2学霸模式 3专注模式 4时长练习
         */
        absorbType?: number;
    };
    /**
     * 后记表
     */
    export type EfficiencyEpilogueCreateForm = {
        /**
         * 后记内容
         */
        context?: string;
        /**
         * 日志id
         */
        journalId?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 后记表
     */
    export type EfficiencyEpilogueUpdateForm = {
        /**
         * 后记内容
         */
        context?: string;
        /**
         * 主键id
         */
        id: number;
        /**
         * 日志id
         */
        journalId?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 后记表
     */
    export type EfficiencyEpilogueVo = {
        /**
         * 后记内容
         */
        context?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 日志id
         */
        journalId?: number;
        /**
         * 修改时间
         */
        updateTime?: string;
    };
    /**
     * 目标计划表
     */
    export type EfficiencyGoalPlanCreateForm = {
        /**
         * 计划内容
         */
        planContext?: string;
        /**
         * 计划完成时间,格式为:yyyy-MM-dd
         */
        planEndtime?: string;
        /**
         * 目标id
         */
        targetId?: number;
    };
    /**
     * 目标计划表
     */
    export type EfficiencyGoalPlanUpdateForm = {
        /**
         * 主键id
         */
        id: number;
        /**
         * 计划内容
         */
        planContext?: string;
        /**
         * 计划完成时间,格式为:yyyy-MM-dd
         */
        planEndtime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 目标计划表
     */
    export type EfficiencyGoalPlanVo = {
        /**
         * 创建时间yyyy-MM-dd HH:mm:ss
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 计划内容
         */
        planContext?: string;
        /**
         * 计划完成时间yyyy-MM-dd
         */
        planEndtime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 修改时间yyyy-MM-dd HH:mm:ss
         */
        updateTime?: string;
    };
    export type EfficiencyHabitMergerCreateForm = {
        /**
         * 被合并掉的习惯id
         */
        initialHabitid?: number;
        /**
         * 合并的目标习惯id
         */
        targetHabitid?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    export type EfficiencyHabitsIconVo = {
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 图标集合
         */
        habitsIconVos?: EfficiencyHabitsIconVo[];
        /**
         * 主键id
         */
        id: number;
        /**
         * 图片地址
         */
        imageUrl?: string;
        /**
         * 图片名称
         */
        name?: string;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 父级习惯
         */
        sysHabitsVos?: EfficiencySysHabitsVo[];
        /**
         * 习惯类型
         */
        type?: number;
        /**
         * 修改时间
         */
        updateTime?: string;
    };
    /**
     * 首页背景图及分享
     */
    export type EfficiencyHomePageShareQueryForm = {
        /**
         * 城市id
         */
        cityId?: number;
        /**
         * 主键id
         */
        id?: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 首页目标表
     */
    export type EfficiencyHomePageTargetVo = {
        /**
         * 当前系统时间yyyy-MM-dd HH:mm:ss
         */
        nowDate?: string;
        /**
         * 目标数量
         */
        targeNum?: number;
        /**
         * 是否有已归档的 0否 1是
         */
        targetFile?: number;
        /**
         * 目标集合
         */
        targetVos?: EfficiencyTargetVo[];
    };
    /**
     * 效率首页数据集合类
     */
    export type EfficiencyHomePageVO = {
        /**
         * 天气图标(天气小人)
         */
        backgroundImage?: string;
        /**
         * 当前城市名称
         */
        cityName?: string;
        /**
         * 当前城市气温
         */
        cityTemperature?: number;
        /**
         * 天气情况
         */
        cityWeatherCondition?: string;
        /**
         * 节日/节气
         */
        festival?: string;
        /**
         * 是否需要周总结 0 否 1 是
         */
        isWeekSummary?: number;
        /**
         * 待办数量
         */
        needhandleNum?: number;
        /**
         * 当前系统时间yyyy-MM-dd HH:mm:ss
         */
        nowDate?: string;
        /**
         * 目标数量
         */
        targeNum?: number;
        /**
         * 是否有已归档的 0否 1是
         */
        targetFile?: number;
        /**
         * 目标集合
         */
        targetVos?: EfficiencyTargetVo[];
        /**
         * 计时时间 单位:s
         */
        times?: number;
        /**
         * 计时状态  0未及时  1正计时  2倒计时
         */
        timingState?: number;
        /**
         * 各个时间段的文字状态
         */
        warmhearts?: WarmheartVo[];
        /**
         * 天气图标(天气小人)
         */
        weatherIcon?: string;
        /**
         * 周几
         */
        weekNum?: string;
    };
    export type EfficiencyJournalCommentVo = {
        /**
         * 一级评论对象
         */
        commentBean?: EfficiencyCommentVo;
        /**
         * 页尺寸
         */
        pageNum?: number;
        /**
         * 页尺寸
         */
        pageSize?: number;
        /**
         * 数量
         */
        size?: number;
        /**
         * 二级评论集合
         */
        subListVo?: EfficiencyCommentVo[];
    };
    /**
     * 日历集合信息
     */
    export type EfficiencyNeedhandleCalendarListVo = {
        /**
         * 日历集合信息
         */
        calendarList?: EfficiencyNeedhandleCalendarVo[];
        /**
         * 月份
         */
        month?: number;
    };
    /**
     * 日历信息
     */
    export type EfficiencyNeedhandleCalendarVo = {
        /**
         * 阴历日子
         */
        nonDay?: string;
        /**
         * 阳历日子
         */
        sumDay?: string;
    };
    /**
     * 待办表  重复类型做个字典表
     */
    export type EfficiencyNeedhandleCreateForm = {
        /**
         * 自定义重复周期类型 1.天 2.周 3.月 4.年
         */
        customRepeatCycle?: number;
        /**
         * 所属日期,格式为:yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 关联习惯
         */
        habitId?: number;
        /**
         * 是否是长期重复 0.否 1.是
         */
        longTermRepetition?: number;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复待办周期集合, 每周: 1-7  / 每月: 1-32( 32: 每月最后一天)
         */
        repeatToDoList?: string[];
        /**
         * 重复待办结束时间,格式为:yyyy-MM-dd
         */
        repeatedEndTime?: string;
        /**
         * 重复待办开始时间,格式为:yyyy-MM-dd
         */
        repeatedStartTime?: string;
        /**
         * 重复类型 0不重复 1每天 2工作日 3每周 4 每月 5每年 6自定义
         */
        repeatedType?: number;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 待办标题
         */
        title?: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 删除待办表
     */
    export type EfficiencyNeedhandleDeleteForm = {
        /**
         * 重复待办此日程yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 重复待办删除类型 1.仅限次日程   2.此日程和将来的日程   3.所有日程
         */
        deleteType?: number;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 待办类型 0普通待办 1重复待办 2.虚拟数据 nhid为0时
         */
        needHandleType: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 待办表  重复类型做个字典表
     */
    export type EfficiencyNeedhandleUpdateForm = {
        /**
         * 自定义重复周期类型 1.天 2.周 3.月 4.年
         */
        customRepeatCycle?: number;
        /**
         * 所属日期,格式为:yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 是否禁用 0否1是
         */
        disable?: number;
        /**
         * 关联习惯
         */
        habitId?: number;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 是否是长期重复 0.否 1.是
         */
        longTermRepetition?: number;
        /**
         * 待办父id
         */
        nhId?: number;
        /**
         * 待办备注
         */
        remarks?: string;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复待办周期集合, 每周: 1-7  / 每月: 1-32( 32: 每月最后一天)
         */
        repeatToDoList?: string[];
        /**
         * 重复待办结束时间,格式为:yyyy-MM-dd
         */
        repeatedEndTime?: string;
        /**
         * 重复待办开始时间,格式为:yyyy-MM-dd
         */
        repeatedStartTime?: string;
        /**
         * 重复时间
         */
        repeatedTime?: string;
        /**
         * 重复类型 0不重复 1每天 2工作日 3每周 4 每月 5每年
         */
        repeatedType?: number;
        /**
         * 排序顺序
         */
        sort?: number;
        /**
         * 待办批量修改集合
         */
        sortList?: EfficiencyNeedhandleUpdateForm[];
        /**
         * 完成状态 0未完成 1已完成
         */
        state?: number;
        /**
         * 待办标题
         */
        title?: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 待办表
     */
    export type EfficiencyNeedhandleVo = {
        /**
         * 颜色状态 顺序 红蓝绿黄+无习惯类型(五种)   0为无 1为是
         */
        colorList?: string[];
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 自定义重复周期类型 1.天 2.周 3.月 4.年
         */
        customRepeatCycle?: number;
        /**
         * 用户习惯类型
         */
        customaryType?: number;
        /**
         * 用户习惯颜色
         */
        customaryTypeColor?: string;
        /**
         * 所属日期
         */
        dateOfOwnership?: string;
        /**
         * 是否禁用 0否1是
         */
        disable?: number;
        /**
         * 关联习惯
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitName?: string;
        /**
         * 用户习惯图标
         */
        habitimageUrl?: string;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 是否是长期重复 0.否 1.是
         */
        longTermRepetition?: number;
        /**
         * 待办集合
         */
        needHandleList?: EfficiencyNeedhandleVo[];
        /**
         * 待办父id
         */
        nhId?: number;
        /**
         * 阴历日子
         */
        nonDay?: string;
        /**
         * 待办备注
         */
        remarks?: string;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复待办周期集合, 每周: 1-7  / 每月: 1-32( 32: 每月最后一天)
         */
        repeatToDoList?: string[];
        /**
         * 重复待办结束时间,格式为:yyyy-MM-dd
         */
        repeatedEndTime?: string;
        /**
         * 重复待办开始时间,格式为:yyyy-MM-dd
         */
        repeatedStartTime?: string;
        /**
         * 重复时间
         */
        repeatedTime?: string;
        /**
         * 重复类型 0不重复 1每天 2工作日 3每周 4 每月 5每年 6自定义
         */
        repeatedType?: number;
        /**
         * 待办排序顺序
         */
        sort?: number;
        /**
         * 完成状态 0未完成 1已完成 2没有可完成的当日无待办 /查询上一次剩余待办( 1有0无)
         */
        state?: number;
        /**
         * 阳历日子
         */
        sumDay?: string;
        /**
         * 系统父级习惯
         */
        systemHabitsId?: number;
        /**
         * 待办标题
         */
        title?: string;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
    };
    /**
     * 公开目标信息
     */
    export type EfficiencyOpenTargetVo = {
        /**
         * 距目标日还剩多少天
         */
        dayOfNum?: number;
        /**
         * 总助燃数
         */
        helpNum?: number;
        /**
         * 目标id
         */
        id: number;
        /**
         * 图片数据
         */
        imageList?: EfficiencyTargetImageVo[];
        /**
         * 目标状态 0进行中 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 用户是否是会员 1.是会员 0.不是会员
         */
        isMember?: number;
        /**
         * 最新更新时间
         */
        sortTime?: string;
        /**
         * 目标名称
         */
        targetName: string;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 用户头像
         */
        userHead?: string;
        /**
         * 用户昵称
         */
        userName?: string;
        /**
         * 总浏览数
         */
        viewNum?: number;
    };
    /**
     * 日志日记资源表
     */
    export type EfficiencyPictureVo = {
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 资源类型 1.图片 2.视频
         */
        resourcesType?: number;
        /**
         * 资源地址
         */
        resourcesUrl?: string;
        /**
         * 资源顺序
         */
        sort?: number;
        /**
         * 所属id(日记id/日志id)
         */
        subordinateId?: number;
        /**
         * 所属类型 1日记 2日志
         */
        subordinateType?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
    };
    /**
     * 小练习背景图
     */
    export type EfficiencyPracticeImageVo = {
        /**
         * 创建时间
         */
        createTime?: string;
        id: number;
        /**
         * 图片链接
         */
        imageUrl?: string;
        /**
         * 是否删除 0未删除 1已删除
         */
        isDel?: number;
    };
    /**
     * 计时放弃记录表
     */
    export type EfficiencyRecordingDropTimeCreateForm = {
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 总时长(秒数)
         */
        duration?: number;
        /**
         * 结束时间
         */
        endTime?: string;
        /**
         * 关联习惯
         */
        habitid?: number;
        /**
         * 是否学霸模式  0专注模式 1学霸模式
         */
        isAbsorbed?: number;
        /**
         * 待办id
         */
        nhId?: number;
        /**
         * 0普通待办 1重复待办
         */
        nhType?: number;
        /**
         * 放弃原因
         */
        remarks?: string;
        /**
         * 开始时间
         */
        startTime?: string;
        /**
         * 0倒计时1正计时
         */
        timingMode?: number;
        /**
         * 用户ID
         */
        uid?: string;
    };
    /**
     * 动态中的计时详情
     */
    export type EfficiencyRecordingInfoInDynamicVo = {
        /**
         * 总时长(秒数)
         */
        duration?: number;
        /**
         * 结束时间
         */
        endTime?: string;
        /**
         * 专注度
         */
        focusPercent?: number;
        /**
         * 关联习惯颜色
         */
        habitColor?: string;
        /**
         * 关联习惯名称
         */
        habitName?: string;
        /**
         * 关联习惯
         */
        habitid?: number;
        /**
         * 已完成计时的秒数
         */
        hasFinishSecond?: number;
        /**
         * 已练习时长-h
         */
        hasPracticeHours?: number;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 待办标题
         */
        needHandleTitle?: string;
        /**
         * 学霸模式切出次数
         */
        outTimes?: number;
        /**
         * 学霸模式暂停总时长
         */
        pauseSecond?: number;
        /**
         * 暂停次数
         */
        pauseTimes?: number;
        /**
         * 专注奖励燃豆数
         */
        pointsNum?: number;
        /**
         * 练习总时长-h
         */
        practiceHours?: number;
        /**
         * 练习总时长秒数
         */
        practiceSecond?: number;
        /**
         * 练习标题
         */
        practiceTitle?: string;
        /**
         * 计时标签 3:专注模式;4:学霸模式;5:练习专注;6:待办专注;7:练习打卡
         */
        recordTag?: number;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 开始时间
         */
        startTime?: string;
        /**
         * 用户ID
         */
        uid?: string;
        /**
         * vip奖励专注燃豆数
         */
        vipRewardPercent?: number;
        /**
         * vip奖励专注燃豆数
         */
        vipRewardPointsNum?: number;
    };
    /**
     * 计时表
     */
    export type EfficiencyRecordingTimeCreateForm = {
        /**
         * 城市id
         */
        cityId?: number;
        /**
         * 操作token用于继续和完成计时
         */
        continueToEncryptPlaintext?: string;
        /**
         * 总时长(秒数)
         */
        duration?: number;
        /**
         * 心跳token
         */
        encryptedPlaintext?: string;
        /**
         * 结束时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        endTime?: string;
        /**
         * 计时专注度
         */
        focusPercent?: number;
        /**
         * 关联习惯
         */
        habitid?: number;
        /**
         * 主键id
         */
        id?: number;
        /**
         * 是否专注  0非专注 1专注
         */
        isAbsorbed?: number;
        /**
         * 待办id
         */
        nhId?: number;
        /**
         * 待办类型 0普通待办 1重复待办
         */
        nhType?: number;
        /**
         * 操作类型 1保活2继续
         */
        operationType?: number;
        /**
         * 学霸模式切出次数
         */
        outTimes?: number;
        /**
         * 学霸模式总暂停秒数
         */
        pauseSecond?: number;
        /**
         * 暂停次数
         */
        pauseTimes?: number;
        /**
         * 专注奖励燃豆数
         */
        pointsNum?: number;
        /**
         * 小练习id
         */
        practiceId?: number;
        /**
         * 计时标签类型 3:专注模式;4:学霸模式;5:练习专注;6:待办专注;7:练习打卡
         */
        recordTag?: number;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        startTime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 0倒计时1正计时
         */
        timingMode?: number;
        /**
         * 用户ID
         */
        uid?: string;
    };
    /**
     * 删除时间轴
     */
    export type EfficiencyRecordingTimeDelForm = {
        /**
         * 时间轴Id
         */
        id: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户计时数据柱状图详细数据
     */
    export type EfficiencyRecordingTimeHistogramDetailVo = {
        /**
         * 当天专注具体时长-秒
         */
        absorbSecond?: number;
        /**
         * 所属日期
         */
        dataOfOwnerShip: string;
    };
    /**
     * 用户计时数据柱状图
     */
    export type EfficiencyRecordingTimeHistogramVo = {
        /**
         * 最长专注时间
         */
        absorbTimes: number;
        /**
         * 柱状图渲染数据
         */
        dataList: EfficiencyRecordingTimeHistogramDetailVo[];
    };
    /**
     * 计时表
     */
    export type EfficiencyRecordingTimeQueryForm = {
        /**
         * 地区id
         */
        cityId?: number;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 0榜上 1榜下
         */
        isup?: number;
        /**
         * 榜单类型 1总榜 2日榜 3周榜 4月榜
         */
        listType?: number;
        /**
         * 待办id
         */
        nhId?: number;
        /**
         * 待办类型 0普通待办 1重复待办
         */
        nhType?: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户ID
         */
        uid?: string;
    };
    /**
     * vip用户计时数据统计分布数据
     */
    export type EfficiencyRecordingTimeStaticDistributionVo = {
        /**
         * vip用户计时数据统计分布数据-常用习惯top10
         */
        distributionCommonHabitVo?: EfficiencyDistributionCommonHabitVo;
        /**
         * vip用户计时数据统计分布数据-使用频率
         */
        distributionFrequencyVo?: EfficiencyDistributionFrequencyVo;
        /**
         * vip用户计时数据统计分布数据-从未使用过的习惯
         */
        distributionNeverUseHabitVoList?: EfficiencyDistributionNeverUseHabitVo[];
        /**
         * vip用户计时数据统计分布数据-专注走势
         */
        distributionTrendVo?: EfficiencyDistributionTrendVo;
        /**
         * vip用户计时数据统计分布数据-计时方式
         */
        distributionTypeVoList?: EfficiencyDistributionTypeVo[];
    };
    /**
     * 用户计时数据统计
     */
    export type EfficiencyRecordingTimeStaticVo = {
        /**
         * 当天专注时长
         */
        absorbDuration: string;
        /**
         * 当天专注次数
         */
        absorbTimes: number;
        /**
         * 对应视图的默认时间
         */
        dateList: string[];
        /**
         * 当天放弃次数
         */
        dropTimes: number;
        /**
         * 各习惯明细
         */
        habitDetailVos: EfficiencyRecordingTimeStaticWithHabitDetailVo[];
        /**
         * 当前时间
         */
        now: string;
        /**
         * 分布图数据
         */
        staticWithPercentlVos: EfficiencyRecordingTimeStaticWithPercentVo[];
        /**
         * 累计专注天数
         */
        totalAbsorbDays: number;
        /**
         * 累计专注时长
         */
        totalAbsorbDuration: string;
        /**
         * 累计专注次数
         */
        totalAbsorbTimes: number;
        /**
         * 视图类型 1日视图 2周视图 3月视图
         */
        type: number;
        /**
         * 视图专注次数
         */
        viewAbsorbTimes: number;
        /**
         * 视图专注时长
         */
        viewDuration: string;
    };
    /**
     * 各习惯占比明细
     */
    export type EfficiencyRecordingTimeStaticWithHabitDetailVo = {
        /**
         * 习惯颜色
         */
        color?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitsName?: string;
        /**
         * 习惯占比
         */
        percentOfHabit?: number;
        /**
         * 计时时长
         */
        recordingTime?: string;
    };
    /**
     * 计时比例分布图
     */
    export type EfficiencyRecordingTimeStaticWithPercentVo = {
        /**
         * 习惯颜色
         */
        color?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitsName?: string;
        /**
         * 当前习惯占比在大类中的位置
         */
        index?: number;
        /**
         * 是否有合并 0没有 1有
         */
        isShowName?: number;
        /**
         * 习惯占比
         */
        percentOfHabit?: number;
        /**
         * 计时时长
         */
        recordingTime?: string;
    };
    /**
     * 计时表
     */
    export type EfficiencyRecordingTimeVo = {
        /**
         * 当前专注中人数
         */
        absorbedNumber?: number;
        /**
         * 第几次专注
         */
        absorbedTimes?: number;
        /**
         * 多久保活一次(秒单位)
         */
        cncrypteSecond?: number;
        /**
         * 误差秒数
         */
        compatibleSecond?: number;
        /**
         * 操作token用于继续和完成计时
         */
        continueToEncryptPlaintext?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 是否删除 0否 1是
         */
        disable?: number;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 总时长(秒数)
         */
        duration?: number;
        /**
         * 心跳token
         */
        encryptedPlaintext?: string;
        /**
         * 结束时间
         */
        endTime?: string;
        /**
         * 工具Long
         */
        gongju?: number;
        /**
         * 关联习惯
         */
        habitid?: number;
        /**
         * 已完成计时时间-小时
         */
        hasFinishHours?: number;
        /**
         * 已完成计时时间-秒
         */
        hasFinishSecond?: number;
        /**
         * 用户头像
         */
        head?: string;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 是否专注  0非专注 1专注
         */
        isAbsorbed?: number;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 时长练习是否完成 0未完成 1已完成
         */
        isPracticeFinished?: number;
        /**
         * 会员类型 0:非会员;1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 用户名称
         */
        name?: string;
        /**
         * 待办id
         */
        nhId?: number;
        /**
         * 待办类型 0普通待办 1重复待办
         */
        nhType?: number;
        /**
         * 服务器当前时间
         */
        now?: string;
        /**
         * 专注奖励燃豆数
         */
        pointsNum?: number;
        /**
         * 练习总时长-小时
         */
        practiceHours?: number;
        /**
         * 练习总时长-秒数
         */
        practiceSecond?: number;
        /**
         * 是否记录 0否 1是
         */
        recordState?: number;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 是否奖励燃豆 0否1是
         */
        rewardCord?: number;
        /**
         * 开始时间
         */
        startTime?: string;
        /**
         * 超越用户百分比
         */
        surpassUserPercent?: number;
        /**
         * 暂停时间
         */
        suspendTime?: string;
        /**
         * 是否正在专注计时 0否 1是
         */
        timeState?: number;
        /**
         * 0倒计时1正计时
         */
        timingMode?: number;
        /**
         * 总时长(分钟/小时)
         */
        totalTiming?: string;
        /**
         * 用户ID
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
        /**
         * vip奖励百分比
         */
        vipReward?: string;
    };
    /**
     * 目标表
     */
    export type EfficiencyResponseListVo = {
        /**
         * 背景图id
         */
        backgroundId?: number;
        /**
         * 背景图url
         */
        backgroundUrl?: string;
        /**
         * 当前时间
         */
        currentDateTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否到达目标时间 0没到 1到了
         */
        isArriveTargetTime?: number;
        /**
         * 是否达成 0进行中 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 日志数量
         */
        logNumber?: number;
        /**
         * 正在进行中练习数量
         */
        noFinishPracticeNum?: number;
        /**
         * 练习数量
         */
        practiceNum?: number;
        /**
         * 目标时间yyyy-MM-dd HH:mm:ss
         */
        targeTime?: string;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 年份
         */
        year?: number;
    };
    /**
     * 目标表
     */
    export type EfficiencyResponseVo = {
        /**
         * 目标集合列表-没有目标为空数组
         */
        dataList?: EfficiencyResponseListVo[];
        /**
         * 服务器当前时间
         */
        now?: string;
        /**
         * 本年还剩多少秒
         */
        second?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 本年年份
         */
        year?: number;
    };
    /**
     * 目标表-简单信息
     */
    export type EfficiencySimpleTargetVo = {
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否达成 0进行中 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 目标是否公开 0未公开 1已公开 -1禁止公开
         */
        isOpen?: number;
        /**
         * 目标名称
         */
        targetName?: string;
    };
    /**
     * 系统习惯表
     */
    export type EfficiencySysHabitsVo = {
        /**
         * 颜色
         */
        color?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 习惯名称
         */
        habitsName?: string;
        /**
         * 图标id
         */
        iconId?: number;
        /**
         * 图标url
         */
        iconUrl?: string;
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否默认 0否 1是
         */
        isdefau?: number;
        /**
         * 等级
         */
        level?: number;
        /**
         * 父级id
         */
        parentId?: number;
        /**
         * 排序 从小到大
         */
        sizeSorting?: number;
        /**
         * 习惯集合
         */
        sysHabitsVoList?: EfficiencySysHabitsVo[];
        /**
         * 修改时间
         */
        updateTime?: string;
        /**
         * 与当前用户关系 0为无关系, 1为有关系
         */
        userRelationship?: number;
    };
    /**
     * 目标内容数据
     */
    export type EfficiencyTargetContent = {
        /**
         * 浏览量
         */
        browseVolume?: number;
        /**
         * 习惯颜色
         */
        color?: string;
        /**
         * 评论数
         */
        commentNum?: number;
        /**
         * 文字内容
         */
        content?: string;
        /**
         * 内容类型  1计划 2日志 3 后记 4.每日一记
         */
        contentType?: number;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 习惯名称
         */
        customaryName?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 助燃
         */
        helpNum?: number;
        /**
         * id
         */
        id?: number;
        /**
         * 是否公开的内容 0否 1是
         */
        isOpen?: number;
        /**
         * 后记所属日志id
         */
        journalId?: number;
        /**
         * 后记所属日志时间
         */
        journalTime?: string;
        logTimeConsuming?: number;
        /**
         * 图片集合
         */
        pictureVos?: EfficiencyPictureVo[];
        /**
         * 内容标题
         */
        title?: string;
    };
    /**
     * 目标表
     */
    export type EfficiencyTargetCreateForm = {
        /**
         * 背景图id
         */
        backgroundId?: string;
        /**
         * 习惯集合
         */
        habitsList?: number[];
        /**
         * 是否归档 0否 1是
         */
        isFile?: number;
        /**
         * 计时方式 0倒计时 1正计时
         */
        recordingTimeType?: number;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复日期
         */
        repeatedTime?: string;
        /**
         * 开始时间,格式为:yyyy-MM-dd
         */
        startTime?: string;
        /**
         * 目标时间,格式为:yyyy-MM-dd
         */
        targeTime?: string;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 开启目标-2.0
     */
    export type EfficiencyTargetCreateNewForm = {
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 目标时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        targetTime?: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 目标表
     */
    export type EfficiencyTargetDeleteForm = {
        /**
         * 主键id
         */
        id: number;
        /**
         * 目标名称
         */
        targetName?: string;
    };
    /**
     * 目标详情数据
     */
    export type EfficiencyTargetDetailVo = {
        /**
         * 目标背景图URL
         */
        backgroundUrl: string;
        /**
         * 阅读总数量
         */
        browseNumberSum?: string;
        /**
         * 评论总数量
         */
        commentNumberSum?: string;
        /**
         * 距目标日还剩多少天
         */
        dayOfNum?: number;
        /**
         * 用户头像挂件
         */
        dressUpId?: number;
        /**
         * 进行中目标才返回的未完成练习列表-没有为空数组
         */
        efficiencyTargetPracticeVos?: EfficiencyTargetPracticeVo[];
        /**
         * 结束日
         */
        endTime?: string;
        /**
         * 共经历天数
         */
        experienceDay?: number;
        /**
         * 已完成练习数
         */
        hasFinishedPracticeNum?: number;
        /**
         * 助燃
         */
        helpNum?: number;
        /**
         * 目标id
         */
        id: number;
        /**
         * 目标状态 0进行中 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 是否关注当前用户 1:A关B 2:B关A(A没关B) 3:互相关注
         */
        isFollow?: number;
        /**
         * 是否会员 0不是 1是
         */
        isMember?: number;
        /**
         * 是否当前用户目标 0不是 1是
         */
        isOwner: number;
        /**
         * 未完成练习数
         */
        noFinishPracticeNum?: number;
        /**
         * 点赞总数量
         */
        praiseNumberSum?: string;
        /**
         * 私密动态数
         */
        privateDynamicNum?: number;
        /**
         * 公开动态数
         */
        publicDynamicNum?: number;
        /**
         * 起始日
         */
        startTime?: string;
        /**
         * 目标时间yyyy-MM-dd HH:mm:ss
         */
        targeTime?: string;
        /**
         * 目标名称
         */
        targetName: string;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 用户头像
         */
        userHead?: string;
        /**
         * 用户昵称
         */
        userName?: string;
    };
    /**
     * 目标详情数据
     */
    export type EfficiencyTargetDetailWhenTargetIsFinishedVo = {
        /**
         * 发布动态数
         */
        dynamicNum?: number;
        /**
         * 结束日
         */
        endTime?: string;
        /**
         * 共经历天数
         */
        experienceDay?: number;
        /**
         * 已完成练习数
         */
        hasFinishedPracticeNum?: number;
        /**
         * 目标id
         */
        id: number;
        /**
         * 起始日
         */
        startTime?: string;
        /**
         * 目标背景图
         */
        targetImageUrl?: string;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 总专注时长
         */
        totalRecordingTime?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 用户头像
         */
        userHead?: string;
        /**
         * 用户昵称
         */
        userName?: string;
    };
    /**
     * 修改目标背景
     */
    export type EfficiencyTargetImageUpdateForm = {
        /**
         * 背景图id
         */
        backgroundId?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 背景图图片地址
         */
        imageUrl?: string;
        /**
         * 是否需要校验
         */
        isCheck?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 公开目标展示的动态图片
     */
    export type EfficiencyTargetImageVo = {
        /**
         * 天数
         */
        dayOfNum: number;
        /**
         * 动态id
         */
        dynamicId: string;
        /**
         * 图片地址
         */
        imageUrl: string;
    };
    /**
     * 开启目标页数据
     */
    export type EfficiencyTargetIndexVo = {
        /**
         * 已公开目标合计
         */
        efficiencyTargetVos: EfficiencyTargetVo[];
        /**
         * 是否首次开启目标 0不是 1是
         */
        isFirst: number;
        /**
         * 服务器当前时间
         */
        now: string;
        /**
         * 开启的目标总人数
         */
        targetCount: number;
    };
    /**
     * 日志表
     */
    export type EfficiencyTargetJournalCreateForm = {
        /**
         * 城市名称
         */
        cityName?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 0非开放 1开放
         */
        isOpen?: number;
        /**
         * 日志标题
         */
        journalTitle?: string;
        /**
         * 定位(城市代码)
         */
        location?: number;
        /**
         * 资源集合
         */
        resourcesList?: string[];
        /**
         * 资源类型 1图片 2视频
         */
        resourcesType?: number;
        /**
         * 起始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        startTime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 耗费时长(秒)
         */
        timeConsuming?: number;
        /**
         * 用戶id
         */
        uid?: string;
    };
    /**
     * 日志表
     */
    export type EfficiencyTargetJournalUpdateForm = {
        /**
         * 城市名称
         */
        cityName?: string;
        /**
         * 点赞数量
         */
        fabulousNum?: number;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 0非开放 1开放
         */
        isOpen?: number;
        /**
         * 日志标题
         */
        journalTitle?: string;
        /**
         * 定位(城市代码)
         */
        location?: number;
        /**
         * 资源集合
         */
        resourcesList?: string[];
        /**
         * 资源类型 1图片 2视频
         */
        resourcesType?: number;
        /**
         * 起始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        startTime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 耗费时长(秒)
         */
        timeConsuming?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 日志表
     */
    export type EfficiencyTargetJournalVo = {
        /**
         * 浏览量
         */
        browseVolume?: number;
        /**
         * 城市名称
         */
        cityName?: string;
        /**
         * 习惯颜色
         */
        color?: string;
        /**
         * 评论数量
         */
        commentNum?: number;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 习惯名称
         */
        customaryName?: string;
        /**
         * 天数
         */
        dayNum?: number;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 喂豆人列表
         */
        dynamicFeedPointList?: DynamicFeedPointBean[];
        efficiencyEpilogueVos?: EfficiencyEpilogueVo[];
        /**
         * 资源集合
         */
        efficiencyPictureVos?: EfficiencyPictureVo[];
        /**
         * 后记数量
         */
        epliNum?: number;
        /**
         * es的主键
         */
        esid?: string;
        /**
         * 点赞数量
         */
        fabulousNum?: number;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 用户头像
         */
        head?: string;
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否点过赞  0 否 1 是
         */
        isFabuilous?: number;
        /**
         * 当前用户是否已经喂豆
         */
        isFeedPoint?: boolean;
        /**
         * 0非开放 1开放
         */
        isOpen?: number;
        /**
         * 日志标题
         */
        journalTitle?: string;
        /**
         * 定位(城市代码)
         */
        location?: number;
        /**
         * 用户名称
         */
        name?: string;
        /**
         * 起始时间
         */
        startTime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 目标是否已归档
         */
        targetIsFile?: number;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 耗费时长(秒)
         */
        timeConsuming?: number;
        /**
         * 专注计时 0否1是
         */
        timeState?: number;
        /**
         * 被举报次数
         */
        tipOffNumber?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 小练习打卡反参
     */
    export type EfficiencyTargetPracticeClockVo = {
        /**
         * 练习完成时间
         */
        finishTime?: string;
        /**
         * 习惯图标
         */
        habitIcon?: string;
        /**
         * 已完成次数
         */
        hasFinishTimes?: number;
        /**
         * 小练习id
         */
        id: number;
        /**
         * 练习是否完成 0未完成 1已完成
         */
        isFinish?: number;
        /**
         * 服务器当前时间
         */
        now?: string;
        /**
         * 总练习次数
         */
        practiceTimes?: number;
    };
    /**
     * 小练习
     */
    export type EfficiencyTargetPracticeCreateForm = {
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 背景图id
         */
        imageId?: number;
        /**
         * 练习总时长
         */
        practiceHours?: number;
        /**
         * 练习名称
         */
        practiceName?: string;
        /**
         * 练习次数
         */
        practiceTimes?: number;
        /**
         * 练习类型 1次数 2时长
         */
        practiceType?: number;
        /**
         * 所属目标id
         */
        targetId?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 小练习
     */
    export type EfficiencyTargetPracticeDeleteForm = {
        id: number;
    };
    /**
     * 目标常用练习
     */
    export type EfficiencyTargetPracticeListVo = {
        /**
         * 进行中目标才返回的未完成练习列表-没有为空数组
         */
        efficiencyTargetPracticeVos?: EfficiencyTargetPracticeVo[];
        /**
         * 结束日
         */
        endTime?: string;
        /**
         * 目标id
         */
        id: number;
        /**
         * 目标状态 0进行中 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 是否当前用户目标 0不是 1是
         */
        isOwner: number;
        /**
         * 起始日
         */
        startTime?: string;
        /**
         * 目标时间yyyy-MM-dd HH:mm:ss
         */
        targeTime?: string;
        /**
         * 目标名称
         */
        targetName: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 小练习排序修改
     */
    export type EfficiencyTargetPracticeSortListUpdateForm = {
        /**
         * 排序集合
         */
        sortList: EfficiencyTargetPracticeSortUpdateForm[];
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 小练习排序修改
     */
    export type EfficiencyTargetPracticeSortUpdateForm = {
        /**
         * id
         */
        id: number;
        /**
         * 练习排序
         */
        sort?: number;
    };
    /**
     * 小练习
     */
    export type EfficiencyTargetPracticeUpdateForm = {
        id: number;
        /**
         * 背景图id
         */
        imageId?: number;
    };
    /**
     * 小练习
     */
    export type EfficiencyTargetPracticeVo = {
        /**
         * 常用练习排序
         */
        commonPractice?: number;
        /**
         * 添加时间
         */
        createTime?: string;
        /**
         * 练习完成时间
         */
        finishTime?: string;
        /**
         * 习惯颜色
         */
        habitColor?: string;
        /**
         * 习惯icon链接
         */
        habitIconUrl?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitName?: string;
        /**
         * 已完成计时时间-小时
         */
        hasFinishHours?: number;
        /**
         * 已完成计时时间-秒
         */
        hasFinishSecond?: number;
        /**
         * 已完成次数
         */
        hasFinishTimes?: number;
        id: number;
        /**
         * 背景图id
         */
        imageId?: number;
        /**
         * 背景图url
         */
        imageUrl?: string;
        /**
         * 是否删除 0未删除 1已删除
         */
        isDel?: number;
        /**
         * 练习是否完成 0未完成 1已完成
         */
        isFinish?: number;
        /**
         * 练习总时长-小时
         */
        practiceHours?: number;
        /**
         * 练习名称
         */
        practiceName?: string;
        /**
         * 练习总时长-秒数
         */
        practiceSecond?: number;
        /**
         * 练习次数
         */
        practiceTimes?: number;
        /**
         * 练习类型 1次数 2时长
         */
        practiceType?: number;
        /**
         * 练习排序
         */
        sort?: number;
        /**
         * 所属目标id
         */
        targetId?: number;
        /**
         * 所属目标名称
         */
        targetName?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 目标表
     */
    export type EfficiencyTargetQueryForm = {
        /**
         * 背景图id
         */
        backgroundId?: string;
        /**
         * 背景图url
         */
        backgroundUrl?: string;
        /**
         * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        createTime?: string;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 共经历天数
         */
        experienceDays?: number;
        /**
         * 共专注时长(秒)
         */
        focusTimeNum?: number;
        /**
         * 主键id列表
         */
        idList?: number[];
        /**
         * 区分全年日志和普通目标 0 普通目标 1 全年目标
         */
        identify?: number;
        /**
         * 是否归档 0否 1是
         */
        isFile?: number;
        /**
         * 是否查询所有目标 1是 无值默认查询进行中的目标
         */
        isQueryAll?: number;
        /**
         * 日志数量
         */
        logNumber?: number;
        /**
         * 最新的或者被举报的 0最新 1被举报
         */
        newortip?: number;
        /**
         * 当前年-月
         */
        nowYearMonth?: string;
        /**
         * 上月 -1 /下月1
         */
        num?: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 计时方式 0倒计时 1正计时
         */
        recordingTimeType?: number;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复日期
         */
        repeatedTime?: string;
        /**
         * 主键id
         */
        targetId?: number;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 共培养习惯
         */
        trainingHabits?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 修改目标排序
     */
    export type EfficiencyTargetSortListUpdateForm = {
        /**
         * 排序集合
         */
        sortList?: EfficiencyTargetSortUpdateForm[];
    };
    /**
     * 修改目标排序/练习排序
     */
    export type EfficiencyTargetSortUpdateForm = {
        /**
         * 主键id
         */
        id: number;
        /**
         * 排序
         */
        sort: number;
    };
    /**
     * 目标达成/未达成
     */
    export type EfficiencyTargetStateUpdateForm = {
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否达成 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 目标表
     */
    export type EfficiencyTargetUpdateForm = {
        /**
         * 背景图id
         */
        backgroundId?: string;
        /**
         * 共专注时长(秒)
         */
        focusTimeNum?: number;
        /**
         * 习惯集合
         */
        habitsList?: EfficiencyCustomaryAssociationVo[];
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否归档 0否 1是
         */
        isFile?: number;
        /**
         * 是否二次公开
         */
        isOpen?: number;
        /**
         * 计时方式 0倒计时 1正计时
         */
        recordingTimeType?: number;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复日期
         */
        repeatedTime?: string;
        /**
         * 开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        startTime?: string;
        /**
         * 目标时间,格式为:yyyy-MM-dd
         */
        targeTime?: string;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 修改目标
     */
    export type EfficiencyTargetUpdateNewForm = {
        /**
         * 背景图id
         */
        backgroundId?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 是否达成 0进行中 1已达成 2未达成
         */
        isFile?: number;
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 目标时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        targetTime?: string;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 目标表
     */
    export type EfficiencyTargetVo = {
        /**
         * 背景图id
         */
        backgroundId?: number;
        /**
         * 背景图url
         */
        backgroundUrl?: string;
        /**
         * 浏览量
         */
        browseVolume?: number;
        /**
         * 评论数
         */
        commentNum?: number;
        /**
         * 创建时间yyyy-MM-dd HH:mm:ss
         */
        createTime?: string;
        /**
         * 当前时间
         */
        currentDateTime?: string;
        /**
         * 当前时间
         */
        currentTime?: string;
        /**
         * 剩多少天, 进行了多少天 都从这里取
         */
        dayNum?: number;
        /**
         * 本年还剩多少天
         */
        dayOfYearNum?: number;
        /**
         * 0启用 1禁用
         */
        disable?: number;
        /**
         * 计划列表
         */
        efficiencyGoalPlanVos?: EfficiencyGoalPlanVo[];
        /**
         * 日志集合
         */
        efficiencyTargetJournalVos?: EfficiencyTargetJournalVo[];
        /**
         * 进行中目标才返回的未完成练习列表-没有为空数组
         */
        efficiencyTargetPracticeVos?: EfficiencyTargetPracticeVo[];
        /**
         * 共经历天数
         */
        experienceDays?: number;
        /**
         * 共专注时长(秒)
         */
        focusTimeNum?: number;
        /**
         * 关注信息对象
         */
        followOrNotUser?: UserFollowInfoBeanVo;
        /**
         * 目标关联习惯
         */
        habitNum?: number;
        /**
         * 已完成练习数
         */
        hasFinishedPracticeNum?: number;
        /**
         * 助燃
         */
        helpNum?: number;
        /**
         * 小时数
         */
        hours?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 鉴别目标来源0个人 1系统
         */
        identify?: number;
        /**
         * 是否归档 0否 1是
         */
        isFile?: number;
        /**
         * 是否关注当前用户 0未关注 1已关注 2 互相关注
         */
        isFollow?: number;
        /**
         * 有公开日志  0否 1 是
         */
        isOpen?: number;
        /**
         * 日志数量
         */
        logNumber?: number;
        /**
         * 分钟数
         */
        minute?: number;
        /**
         * 未完成练习数
         */
        noFinishPracticeNum?: number;
        /**
         * 倒计时目标进度百分比
         */
        percentage?: number;
        /**
         * 私密动态数
         */
        privateDynamicNum?: number;
        /**
         * 公开动态数
         */
        publicDynamicNum?: number;
        /**
         * 连续记录天数
         */
        recordDays?: number;
        /**
         * 计时方式 0倒数日 1正数日
         */
        recordingTimeType?: number;
        /**
         * 提醒时间
         */
        reminderTime?: string;
        /**
         * 重复日期
         */
        repeatedTime?: string;
        /**
         * 正计时目标到了目标时间 0 否 1 是
         */
        run1target?: number;
        /**
         * 秒数
         */
        second?: number;
        /**
         * 排序时间 为空时是未公开 不为空代表公开
         */
        sortTime?: string;
        /**
         * 开始时间yyyy-MM-dd HH:mm:ss
         */
        startTime?: string;
        /**
         * 目标时间yyyy-MM-dd HH:mm:ss
         */
        targeTime?: string;
        /**
         * 目标各类数据集合汇总
         */
        targetData?: TargetData[];
        /**
         * 目标名称
         */
        targetName?: string;
        /**
         * 目标集合
         */
        targetVoList?: EfficiencyTargetVo[];
        /**
         * 计时状态 0否 1是
         */
        timeState?: number;
        /**
         * 被举报次数
         */
        tipOffNumber?: number;
        /**
         * 共培养习惯
         */
        trainingHabits?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间yyyy-MM-dd HH:mm:ss
         */
        updateTime?: string;
        /**
         * 用户头像
         */
        userHead?: string;
        /**
         * 用户昵称
         */
        userName?: string;
        /**
         * 年份
         */
        year?: number;
    };
    /**
     * 用户习惯表
     */
    export type EfficiencyUserHabitsCreateForm = {
        /**
         * 习惯名称
         */
        customaryName?: string;
        /**
         * 习惯类型 1.学习 2.工作 3.健康 4.生活
         */
        customaryType?: number;
        /**
         * 是否禁用 0否1是
         */
        disable?: number;
        /**
         * 使用时长
         */
        durationOfUse?: number;
        /**
         * 习惯集合
         */
        efficiencyUserHabitsCreateForm?: EfficiencyUserHabitsCreateForm[];
        /**
         * 专注时长(秒)
         */
        focusTime?: number;
        /**
         * 图标id
         */
        iconId?: number;
        /**
         * 图标url
         */
        imageUrl?: string;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 系统习惯id
         */
        systemHabitsId?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 使用次数
         */
        useFrequency?: number;
    };
    /**
     * 用户习惯表  习惯类型枚举类
     */
    export type EfficiencyUserHabitsDeleteForm = {
        /**
         * 用户习惯主键集合ids
         */
        ids?: number[];
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 用户习惯表  习惯类型枚举类
     */
    export type EfficiencyUserHabitsUpdateForm = {
        /**
         * 习惯名称
         */
        customaryName?: string;
        /**
         * 习惯类型 1.学习 2.工作 3.健康 4.生活
         */
        customaryType?: number;
        /**
         * 是否禁用 0否1是
         */
        disable?: number;
        /**
         * 使用时长
         */
        durationOfUse?: number;
        /**
         * 专注时长(秒)
         */
        focusTime?: number;
        /**
         * 图标id
         */
        iconId?: string;
        /**
         * 主键id
         */
        id: number;
        /**
         * 用户习惯主键集合ids
         */
        ids?: number[];
        /**
         * 图标url
         */
        imageUrl?: string;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 系统习惯id
         */
        systemHabitsId?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改后的习惯
         */
        updateForms?: EfficiencyUserHabitsUpdateForm[];
        /**
         * 使用次数
         */
        useFrequency?: number;
    };
    /**
     * 用户习惯表
     */
    export type EfficiencyUserHabitsVo = {
        /**
         * 颜色
         */
        color?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 习惯名称
         */
        customaryName?: string;
        /**
         * 习惯类型 1.学习 2.工作 3.健康 4.生活
         */
        customaryType?: number;
        /**
         * 是否禁用 0否1是
         */
        disable?: number;
        /**
         * 使用时长
         */
        durationOfUse?: number;
        /**
         * 子习惯集合
         */
        efficiencyUserHabitsVos?: EfficiencyUserHabitsVo[];
        /**
         * 专注时长(秒)
         */
        focusTime?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 图标url
         */
        imageUrl?: string;
        /**
         * 是否不属于用户的系统习惯 0否1是
         */
        isSystem?: number;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 系统习惯id
         */
        systemHabitsId?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间
         */
        updateTime?: string;
        /**
         * 使用次数
         */
        useFrequency?: number;
    };
    /**
     * 周计划表
     */
    export type EfficiencyWeekPlanCreateForm = {
        /**
         * 内容
         */
        context?: string;
        /**
         * 所属日期,格式为:yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 关联习惯id
         */
        habitId?: number;
        /**
         * 计划等级 1 2 3
         */
        planLevel?: number;
        /**
         * 0未完成1已完成
         */
        state?: number;
        /**
         * 0为计划 1为总结
         */
        type?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 周计划总览
     */
    export type EfficiencyWeekPlanOverviewVo = {
        dateofowninship?: string;
        /**
         * 周计划重要数量
         */
        importantNUm?: number;
        /**
         * 月份
         */
        month?: string;
        /**
         * 计划总数
         */
        planNum?: number;
        /**
         * 未完成数量
         */
        stateNum?: number;
        /**
         * 第几周
         */
        week?: string;
        /**
         * 周日期集合
         */
        weekDays?: string[];
        /**
         * 周附属信息
         */
        weekSubsidiary?: string;
        /**
         * 周总结
         */
        weekSummary?: string;
        /**
         * 年份
         */
        year?: number;
    };
    /**
     * 周计划表
     */
    export type EfficiencyWeekPlanQueryForm = {
        /**
         * 所属日期,格式为:yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 主键id
         */
        id?: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 0为计划 1为总结
         */
        type?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 周计划表
     */
    export type EfficiencyWeekPlanUpdateForm = {
        /**
         * 内容
         */
        context?: string;
        /**
         * 所属日期,格式为:yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 0启用1禁用
         */
        disable?: number;
        /**
         * 关联习惯id
         */
        habitId?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 计划等级 1 2 3
         */
        planLevel?: number;
        /**
         * 0未完成1已完成
         */
        state?: number;
        /**
         * 0为计划 1为总结
         */
        type?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 周计划表
     */
    export type EfficiencyWeekPlanVo = {
        /**
         * 习惯颜色
         */
        color?: string;
        /**
         * 内容
         */
        context?: string;
        /**
         * 创建时间yyyy-MM-dd
         */
        createTime?: string;
        /**
         * 习惯类型 1 学习 2 工作 3 健康 4 生活
         */
        customaryType?: number;
        /**
         * 所属日期yyyy-MM-dd
         */
        dateOfOwnership?: string;
        /**
         * 0启用1禁用
         */
        disable?: number;
        /**
         * 关联习惯id
         */
        habitId?: number;
        /**
         * 主键id
         */
        id: number;
        /**
         * 当前日期yyyy-MM-dd
         */
        nowDay?: string;
        /**
         * 计划等级 1 2 3
         */
        planLevel?: number;
        /**
         * 周计划集合
         */
        planList?: EfficiencyWeekPlanVo[];
        /**
         * 0未完成1已完成
         */
        state?: number;
        /**
         * 0为计划 1为总结
         */
        type?: number;
        /**
         * 用户id
         */
        uid?: string;
        /**
         * 修改时间yyyy-MM-dd
         */
        updateTime?: string;
        /**
         * 周日期集合
         */
        weekDayList?: string[];
        /**
         * 第几周
         */
        weekNum?: string;
        /**
         * 附属周信息
         */
        weekNum2?: string;
        /**
         * 周总结内容
         */
        weekSummary?: EfficiencyWeekPlanVo;
        /**
         * 是否展示周总结  0不展示 1展示
         */
        weekSummaryState?: number;
        /**
         * 年月
         */
        yearMonth?: string;
    };
    /**
     * 新增意见反馈评论
     */
    export type FeedBackCommentCreateForm = {
        /**
         * 所操作评论ID,直接评论意见反馈此参数可不传
         */
        commentId?: string;
        /**
         * 评论内容
         */
        content?: string;
        /**
         * 意见反馈创建者id
         */
        feedBackCreateId?: string;
        /**
         * 所属意见反馈的主键
         */
        feedBackId?: number;
        /**
         * 评论人
         */
        formUserid?: string;
        /**
         * 评论的级别 默认1(直接评论的)，其余0
         */
        level?: string;
        /**
         * 被评论人
         */
        toUserid?: string;
    };
    /**
     * 查询意见反馈评论
     */
    export type FeedBackCommentQueryForm = {
        /**
         * 意见反馈ID
         */
        feedBackId: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    export type HotDataVo = {
        /**
         * 动态热门搜索数据
         */
        dynamicHotSearchWord?: DynamicSerarchWordHotVo[];
        /**
         * 动态推荐搜索数据
         */
        dynamicRecommendSearchWord?: DynamicSerarchWordHotVo[];
        /**
         * 商城热门搜索数据
         */
        shopHotSearchWord?: ShopSerarchWordHotVo[];
        /**
         * 商城推荐搜索数据
         */
        shopRecommendSearchWord?: ShopSerarchWordHotVo[];
    };
    /**
     * 聊天继续加好友
     */
    export type IMChatNumberVo = {
        /**
         * 是否加好友 0否 1是
         */
        isAddFriend?: number;
        /**
         * 是否可以继续聊天 0否 1是
         */
        isContinueChat?: number;
    };
    export type ImChatNumberCreateForm = {
        /**
         * 发起方
         */
        uidA?: string;
        /**
         * 接收方
         */
        uidB?: string;
    };
    export type ImDisturbingCreateForm = {
        /**
         * 操作类型(1 免打扰 2 取消免打扰)
         */
        type: number;
        /**
         * 操作人Id
         */
        userIdA?: string;
        /**
         * 被操作用户id
         */
        userIdB: string;
    };
    export type ImFeedbackCreateForm = {
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 处理意见
         */
        dealContent?: string;
        /**
         * 处理状态：0未回复 1已回复
         */
        dealState?: number;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 内容
         */
        detailContent?: string;
        /**
         * 图片
         */
        imageslist?: string[];
        /**
         * 解决状态：0.未设置;1.未解决; 2.已解决; 默认0
         */
        isslove?: number;
        /**
         * 举报原因
         */
        reason?: string;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 反馈状态：0.未处理; 1.紧急; 2.计划; 3.忽略
         */
        state?: number;
        /**
         * 举报人
         */
        uidA?: string;
        /**
         * 被举报人
         */
        uidB?: string;
        /**
         * 更新时间
         */
        updateDate?: string;
    };
    export type IntDataVo = {
        /**
         * 热门动态数量
         */
        data1?: number;
        /**
         * 关注动态数量
         */
        data2?: number;
    };
    export type IntegerVo = {
        /**
         * 返回结果
         */
        data?: number;
    };
    export type LastPullTimeInfo = {
        /**
         * 燃草客服
         */
        customerNotic?: string;
        /**
         * 评论
         */
        dynamicComment?: string;
        /**
         * 喂豆
         */
        dynamicFeedPoint?: string;
        /**
         * 动态互动
         */
        dynamicNotic?: string;
        /**
         * 助燃
         */
        dynamicPraise?: string;
        /**
         * 装备互动
         */
        equipmentNotic?: string;
        /**
         * 粉丝
         */
        fans?: string;
        /**
         * 用户专注状态  0否 1是
         */
        focusedTimingState?: number;
        /**
         * 用户主键ID
         */
        id?: string;
        /**
         * 最新的专注计时开始时间
         */
        newFocusedTimingTime?: string;
        /**
         * 最新的待办带入时间
         */
        newPullNeedhandleTime?: string;
        /**
         * 最新的周计划关闭提醒时间
         */
        newWeekPlanCloseTime?: string;
        /**
         * 官方通知
         */
        officialNotic?: string;
        /**
         * 订单交易
         */
        orderNotic?: string;
        /**
         * 燃豆助手
         */
        pointNotic?: string;
        /**
         * 优惠促销
         */
        promotionNotic?: string;
    };
    export type LastPullTimeInfoVo = {
        /**
         * 燃草客服
         */
        customerNotic?: string;
        /**
         * 评论
         */
        dynamicComment?: string;
        /**
         * 喂豆
         */
        dynamicFeedPoint?: string;
        /**
         * 动态互动
         */
        dynamicNotic?: string;
        /**
         * 助燃
         */
        dynamicPraise?: string;
        /**
         * 装备互动
         */
        equipmentNotic?: string;
        /**
         * 粉丝
         */
        fans?: string;
        /**
         * 用户主键ID
         */
        id?: string;
        /**
         * 官方通知
         */
        officialNotic?: string;
        /**
         * 订单交易
         */
        orderNotic?: string;
        /**
         * 燃豆助手
         */
        pointNotic?: string;
        /**
         * 优惠促销
         */
        promotionNotic?: string;
    };
    export type Link = {
        href?: string;
        templated?: boolean;
    };
    export type ListStringVo = {
        /**
         * 返回结果:当前用户的免打扰列表
         */
        data1?: string[];
        /**
         * 返回结果:获取设置此人为免打扰列表的用户列表
         */
        data2?: string[];
    };
    /**
     * 新增埋点数据
     */
    export type MaidianCreateForm = {
        /**
         * 动作
         */
        action?: string;
        /**
         * 参数
         */
        action_param?: string;
        /**
         * 参数1
         */
        action_param1?: string;
        /**
         * 参数2
         */
        action_param2?: string;
        /**
         * 参数3
         */
        action_param3?: string;
        /**
         * 操作者
         */
        action_user?: string;
        /**
         * app版本
         */
        app_version?: string;
        /**
         * 设备型号
         */
        device_model?: string;
        /**
         * 1圈子 2商城
         */
        modelType?: number;
        /**
         * 系统版本
         */
        system_version?: string;
    };
    export type ModelAndView = {
        empty?: boolean;
        model?: any;
        modelMap?: any;
        reference?: boolean;
        status?: "100 CONTINUE" | "101 SWITCHING_PROTOCOLS" | "102 PROCESSING" | "103 CHECKPOINT" | "200 OK" | "201 CREATED" | "202 ACCEPTED" | "203 NON_AUTHORITATIVE_INFORMATION" | "204 NO_CONTENT" | "205 RESET_CONTENT" | "206 PARTIAL_CONTENT" | "207 MULTI_STATUS" | "208 ALREADY_REPORTED" | "226 IM_USED" | "300 MULTIPLE_CHOICES" | "301 MOVED_PERMANENTLY" | "302 FOUND" | "302 MOVED_TEMPORARILY" | "303 SEE_OTHER" | "304 NOT_MODIFIED" | "305 USE_PROXY" | "307 TEMPORARY_REDIRECT" | "308 PERMANENT_REDIRECT" | "400 BAD_REQUEST" | "401 UNAUTHORIZED" | "402 PAYMENT_REQUIRED" | "403 FORBIDDEN" | "404 NOT_FOUND" | "405 METHOD_NOT_ALLOWED" | "406 NOT_ACCEPTABLE" | "407 PROXY_AUTHENTICATION_REQUIRED" | "408 REQUEST_TIMEOUT" | "409 CONFLICT" | "410 GONE" | "411 LENGTH_REQUIRED" | "412 PRECONDITION_FAILED" | "413 PAYLOAD_TOO_LARGE" | "413 REQUEST_ENTITY_TOO_LARGE" | "414 URI_TOO_LONG" | "414 REQUEST_URI_TOO_LONG" | "415 UNSUPPORTED_MEDIA_TYPE" | "416 REQUESTED_RANGE_NOT_SATISFIABLE" | "417 EXPECTATION_FAILED" | "418 I_AM_A_TEAPOT" | "419 INSUFFICIENT_SPACE_ON_RESOURCE" | "420 METHOD_FAILURE" | "421 DESTINATION_LOCKED" | "422 UNPROCESSABLE_ENTITY" | "423 LOCKED" | "424 FAILED_DEPENDENCY" | "426 UPGRADE_REQUIRED" | "428 PRECONDITION_REQUIRED" | "429 TOO_MANY_REQUESTS" | "431 REQUEST_HEADER_FIELDS_TOO_LARGE" | "451 UNAVAILABLE_FOR_LEGAL_REASONS" | "500 INTERNAL_SERVER_ERROR" | "501 NOT_IMPLEMENTED" | "502 BAD_GATEWAY" | "503 SERVICE_UNAVAILABLE" | "504 GATEWAY_TIMEOUT" | "505 HTTP_VERSION_NOT_SUPPORTED" | "506 VARIANT_ALSO_NEGOTIATES" | "507 INSUFFICIENT_STORAGE" | "508 LOOP_DETECTED" | "509 BANDWIDTH_LIMIT_EXCEEDED" | "510 NOT_EXTENDED" | "511 NETWORK_AUTHENTICATION_REQUIRED";
        view?: View;
        viewName?: string;
    };
    export type MqSysDictVo = {
        /**
         * 对应中文描述
         */
        description?: string;
        /**
         * 主键
         */
        id: number;
        /**
         * 是否有子数据(0默认有,1没有)
         */
        islist?: number;
        /**
         * 是否展示(0默认展示,1不展示)
         */
        isshow?: number;
        /**
         * 键值 子节点设置
         */
        label?: string;
        /**
         * 父节点id 子节点设置 父节点默认0
         */
        parentId?: string;
        /**
         * 排序 子节点在某个父节点的排序
         */
        sort?: number;
        /**
         * 标签英文名 父节点设置
         */
        type?: string;
        /**
         * 键  子节点设置
         */
        value?: string;
    };
    /**
     * 删除待办
     */
    export type NeedhandleDeleteForm = {
        /**
         * 待办id
         */
        id?: number;
        /**
         * 待办类型 0普通待办 1重复待办 2.虚拟数据 nhid为0时
         */
        needHandleType?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    /**
     * 买家地址信息表
     */
    export type OriginShopAddressCreateForm = {
        /**
         * 地址
         */
        address?: string;
        /**
         * 地址标签 1家 2学校 3公司
         */
        addressTag?: number;
        /**
         * 市ID
         */
        cityId?: string;
        /**
         * 1默认收货地址
         */
        isDefault?: number;
        /**
         * 会员ID
         */
        memberId?: number;
        /**
         * 手机电话
         */
        mobPhone?: string;
        /**
         * 省ID
         */
        provinceId?: string;
        /**
         * 区id
         */
        regioId?: string;
        /**
         * 街道id
         */
        rowId?: string;
        /**
         * 会员姓名
         */
        trueName?: string;
    };
    /**
     * 买家地址信息表
     */
    export type OriginShopAddressUpdateForm = {
        /**
         * 地址
         */
        address?: string;
        /**
         * 地址ID
         */
        addressId: number;
        /**
         * 地址标签 1家 2学校 3公司
         */
        addressTag?: number;
        /**
         * 市ID
         */
        cityId?: string;
        /**
         * 1默认收货地址
         */
        isDefault?: number;
        /**
         * 会员ID
         */
        memberId?: number;
        /**
         * 手机电话
         */
        mobPhone?: string;
        /**
         * 省ID
         */
        provinceId?: string;
        /**
         * 地区id
         */
        regioId?: string;
        /**
         * 街道id
         */
        rowId?: string;
        /**
         * 会员姓名
         */
        trueName?: string;
    };
    /**
     * 买家地址信息表
     */
    export type OriginShopAddressVo = {
        address?: string;
        /**
         * 地址详情
         */
        addressDetail?: string;
        /**
         * 地址ID
         */
        addressId: number;
        /**
         * 地址标签 1家 2学校 3公司
         */
        addressTag?: number;
        /**
         * 市ID
         */
        cityId?: string;
        /**
         * 市名称
         */
        cityName?: string;
        /**
         * 1默认收货地址
         */
        isDefault?: number;
        /**
         * 会员ID
         */
        memberId?: string;
        /**
         * 手机电话
         */
        mobPhone?: string;
        /**
         * 省ID
         */
        provinceId?: string;
        /**
         * 省名称
         */
        provinceName?: string;
        /**
         * 区id
         */
        regioId?: string;
        /**
         * 区名称
         */
        regioName?: string;
        /**
         * 街道id
         */
        rowId?: string;
        /**
         * 街道名称
         */
        rowName?: string;
        /**
         * 会员姓名
         */
        trueName?: string;
    };
    /**
     * 购物车数据表
     */
    export type OriginShopCartCreateForm = {
        /**
         * 组合套装ID
         */
        blId?: number;
        /**
         * 买家id
         */
        buyerId?: string;
        /**
         * 收藏id
         */
        collectId?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买商品数量
         */
        goodsNum?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品规格值
         */
        specValNames?: string[];
    };
    /**
     * 购物车数据表
     */
    export type OriginShopCartUpdateForm = {
        /**
         * 买家id
         */
        buyerId?: string;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买商品数量
         */
        goodsNum?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 购物车id
         */
        id: number;
        /**
         * 购物车idList--全选用
         */
        idList?: number[];
        /**
         * 商品规格值
         */
        specValNames?: string[];
        /**
         * 是否勾选
         */
        state?: number;
    };
    /**
     * 购物车数据表
     */
    export type OriginShopCartVo = {
        /**
         * +号是否灰色 0不是 1是
         */
        addIsGrey?: number;
        /**
         * 组合套装ID
         */
        blId?: number;
        /**
         * 买家id
         */
        buyerId?: string;
        /**
         * 商品所属活动
         */
        cartGoodsActivity?: ShopSpuActivityListVo[];
        /**
         * 添加时间
         */
        createTime?: string;
        /**
         * 商品id
         */
        goodsCommonId?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买商品数量
         */
        goodsNum?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品在售价格
         */
        goodsSellPrice?: number;
        /**
         * 购物车id
         */
        id: number;
        /**
         * 商品库存小于10时显示的实时库存数
         */
        isRemaining?: number;
        /**
         * 赠送商品信息
         */
        shopDonationVoList?: ShopDonationVo[];
        /**
         * 商品规格名称
         */
        specValNames?: string[];
        /**
         * 勾选状态 0未勾选 1已勾选
         */
        state?: number;
        /**
         * -号是否灰色 0不是 1是
         */
        subtractIsGrey?: number;
    };
    /**
     * 货品有的属性与属性值
     */
    export type OriginShopGoodsAttrValueVo = {
        /**
         * 属性值id
         */
        attrValueId?: number;
        /**
         * 属性值名称
         */
        attrValueName?: string;
    };
    /**
     * 货品有的属性与属性值
     */
    export type OriginShopGoodsAttrVo = {
        /**
         * 属性id
         */
        attrId?: number;
        /**
         * 属性名称
         */
        attrName?: string;
        /**
         * 属性值
         */
        shopGoodsAttrValueVos?: OriginShopGoodsAttrValueVo[];
    };
    /**
     * spu详情价格
     */
    export type OriginShopGoodsCommonShowPriceVo = {
        /**
         * 限购数
         */
        limitNum?: number;
        /**
         * 最高价
         */
        maxPrice?: number;
        /**
         * 最低价
         */
        minPrice?: number;
    };
    /**
     * 商品图片
     */
    export type OriginShopGoodsImagesVo = {
        /**
         * 颜色规格值id
         */
        colorId?: number;
        /**
         * 商品公共内容id
         */
        goodsCommonid?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品图片id
         */
        goodsImageId: number;
        /**
         * 默认主题，1是，0否
         */
        isDefault?: number;
    };
    /**
     * 货品所有规格与商品信息
     */
    export type OriginShopGoodsSpecAndItemInfoVo = {
        /**
         * 默认展示图片
         */
        defaultShowImage?: string;
        /**
         * 商品主图
         */
        itemMainImages?: OriginShopGoodsImagesVo[];
        /**
         * 货品价格区间
         */
        originPriceVo?: OriginShopGoodsCommonShowPriceVo;
        /**
         * 货品规格信息
         */
        shopGoodsSpecVos?: OriginShopGoodsSpecVo[];
        /**
         * 商品信息
         */
        shopItemCommonVos?: OriginShopItemCommonVo[];
        /**
         * 货品库存
         */
        spuStoragy?: number;
    };
    /**
     * 货品有的规格值
     */
    export type OriginShopGoodsSpecValVo = {
        /**
         * 规格图片
         */
        colorImage?: string;
        /**
         * 规格值id
         */
        specValId?: number;
        /**
         * 规格值名称
         */
        specValName?: string;
    };
    /**
     * 货品有的规格
     */
    export type OriginShopGoodsSpecVo = {
        /**
         * 规格对应的规格值
         */
        shopGoodsSpecValVos?: OriginShopGoodsSpecValVo[];
        /**
         * 规格id
         */
        specId?: number;
        /**
         * 规格名称
         */
        specName?: string;
    };
    /**
     * 买家发票信息表
     */
    export type OriginShopInvoiceCreateForm = {
        /**
         * 单位名称
         */
        companyName?: string;
        /**
         * 详细地址
         */
        invAddrDetail?: string;
        /**
         * 市
         */
        invRecCity?: number;
        /**
         * 收票人省份
         */
        invRecProvince?: number;
        /**
         * 区
         */
        invRecRegion?: number;
        /**
         * 纳税人识别号
         */
        invoiceCode?: string;
        /**
         * 发票内容[普通发票] 1商品明细 2商品类别
         */
        invoiceContent?: number;
        /**
         * 发票抬头[普通发票] 1个人 2公司
         */
        invoiceTitle?: number;
        /**
         * 1普通发票2增值税发票
         */
        invoiceType?: number;
        /**
         * 会员ID
         */
        memberId?: string;
        /**
         * 订单id
         */
        orderId: number;
        /**
         * 收票人手机号
         */
        recMobphone?: string;
        /**
         * 收票人姓名
         */
        recName?: string;
        /**
         * 注册地址
         */
        regAddr?: string;
        /**
         * 银行帐户
         */
        regBaccount?: string;
        /**
         * 开户银行
         */
        regBname?: string;
        /**
         * 注册电话
         */
        regPhone?: string;
    };
    /**
     * 商品基本信息
     */
    export type OriginShopItemCommonVo = {
        /**
         * 颜色规格id
         */
        colorId?: number;
        goodsSellPrice?: number;
        goodsStorage?: number;
        goodsXianGouPrice?: number;
        itemId?: number;
        limitNum?: number;
        shopItemSpecVos?: OriginShopItemSpecVo[];
    };
    /**
     * 商品有的规格与规格值
     */
    export type OriginShopItemSpecVo = {
        /**
         * 规格id
         */
        specId?: number;
        /**
         * 规格名称
         */
        specName?: string;
        /**
         * 规格值id
         */
        specValId?: number;
        /**
         * 规格值名称
         */
        specValName?: string;
    };
    /**
     * 订单信息扩展表
     */
    export type OriginShopOrderCommonVo = {
        /**
         * 发货地址ID
         */
        addressId?: number;
        /**
         * 发货备注
         */
        deliverExplain?: string;
        /**
         * 卖家是否已评价买家
         */
        evalsellerState?: string;
        /**
         * 卖家评价买家的时间
         */
        evalsellerTime?: number;
        /**
         * 评价时间
         */
        evaluationTime?: string;
        /**
         * 发票id
         */
        invoiceInfo?: number;
        /**
         * 订单索引id
         */
        orderCommonId: number;
        /**
         * 订单留言
         */
        orderMessage?: string;
        /**
         * 订单赠送积分(燃豆)
         */
        orderPointscount?: number;
        /**
         * 预计发货时间
         */
        prepareShippingTime?: string;
        /**
         * 促销信息备注
         */
        promotionInfo?: string;
        /**
         * 买家详细地址
         */
        reciverAddressDetail?: string;
        /**
         * 收货人地址是否默认 1为默认
         */
        reciverAddressIsDefault?: number;
        /**
         * 收货人地址标签  1家 2学校 3公司
         */
        reciverAddressTag?: number;
        /**
         * 收货人姓名
         */
        reciverName?: string;
        /**
         * 收货人联系方式
         */
        reciverPhone?: string;
        /**
         * 配送公司ID
         */
        shippingExpressId?: number;
        /**
         * 配送公司名称
         */
        shippingExpressName?: string;
        /**
         * 配送时间(发货时间)
         */
        shippingTime?: string;
        /**
         * 优惠券id
         */
        ticketId?: number;
        /**
         * 代金券面额
         */
        ticketPrice?: number;
    };
    /**
     * 订单商品参数
     */
    export type OriginShopOrderGoodsCreateForm = {
        /**
         * 商品活动信息
         */
        activityGoodsInfo?: UtilVo;
        /**
         * 下单时燃豆可获得数量
         */
        canGetPoints?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买商品数量
         */
        goodsNum?: number;
        /**
         * 商品原价
         */
        goodsOriginPrice?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 下单时燃豆可抵扣的百分比
         */
        pointsDeduction?: number;
        /**
         * 商品规格名称
         */
        specValNames?: string[];
    };
    /**
     * 订单商品表
     */
    export type OriginShopOrderGoodsVo = {
        /**
         * 买家ID
         */
        buyerId?: string;
        /**
         * 下单时燃豆可获得数量
         */
        canGetPoints?: number;
        /**
         * 评价id
         */
        evaluationId?: string;
        /**
         * 评价状态 0未评价 1已评价
         */
        evaluationState?: number;
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 商品原价
         */
        goodsOriginPrice?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 购买商品的规格值列表
         */
        goodsSpecValList?: string[];
        /**
         * 1默认2团购商品3限时折扣商品4组合套装5赠品
         */
        goodsType?: string;
        /**
         * 订单商品表索引id
         */
        id: number;
        /**
         * 订单id
         */
        orderId?: number;
        /**
         * 下单时燃豆可抵扣的百分比
         */
        pointsDeduction?: number;
        /**
         * 促销活动ID（团购ID/限时折扣ID/优惠套装ID）与goods_type搭配使用
         */
        promotionsId?: number;
        /**
         * 物流状态:1为待发货,2为待收货,3为未收到,4为已收货
         */
        refundGoodsState?: number;
        /**
         * 退款单id
         */
        refundId?: number;
        /**
         * 单个商品的退款情况，0是无退款,1退款中，2退款成功，3退款失败, 4退款关闭, 5审核中
         */
        refundState?: number;
    };
    /**
     * 订单表
     */
    export type OriginShopOrderVo = {
        /**
         * 发货后自动确认收货时间
         */
        autoConfirmTime?: string;
        /**
         * 订单生成时间
         */
        createTime?: string;
        /**
         * 燃豆抵扣金额
         */
        deductionAmount?: number;
        /**
         * 退款信息--为空说明无退款
         */
        detailVo?: ShopOrderRefundInfoInDetailVo;
        /**
         * 评价状态 0未评价，1已评价
         */
        evaluationState?: number;
        /**
         * 订单完成时间(确认收货时间)
         */
        finishedTime?: string;
        /**
         * 商品总价格
         */
        goodsAmount?: number;
        /**
         * 详情页是否有确认收货按钮, 0没有, 1有
         */
        hasConfirmGoodsButton?: number;
        /**
         * 详情页是否有立即评价按钮, 0没有, 1有
         */
        hasEvaluateButton?: number;
        /**
         * 详情页是否查看物流按钮, 0没有, 1有
         */
        hasViewExpressButton?: number;
        /**
         * 买家电子邮箱
         */
        memberEmail?: string;
        /**
         * 买家id
         */
        memberId?: number;
        /**
         * 买家姓名
         */
        memberName?: string;
        /**
         * 当前时间
         */
        now?: string;
        /**
         * 订单总价格
         */
        orderAmount?: number;
        orderCommonId?: number;
        /**
         * 订单商品总件数
         */
        orderGoodsTotalNum?: number;
        /**
         * 订单索引id
         */
        orderId: number;
        /**
         * 订单编号
         */
        orderNo?: string;
        /**
         * 订单超时时间-单位小时
         */
        orderOverTime?: number;
        /**
         * 订单状态：0(已取消)1(默认):未付款;2:已付款;3:已发货;4:已收货;5:超时未支付;6:交易关闭
         */
        orderState?: number;
        /**
         * 支付单号
         */
        payId?: number;
        /**
         * 支付(付款)时间
         */
        payTime?: string;
        /**
         * 支付方式
         */
        paymentId?: number;
        /**
         * 下单时燃豆价格
         */
        pointsPrice?: number;
        /**
         * 满减金额
         */
        reduceFee?: number;
        /**
         * 退款金额
         */
        refundAmount?: number;
        /**
         * 退款状态:0是无退款,1退款中，2退款成功，3退款失败
         */
        refundState?: number;
        /**
         * 物流单号
         */
        shippingCode?: string;
        /**
         * 运费
         */
        shippingFee?: number;
        /**
         * 满减信息
         */
        shopFullReduceVo?: ShopFullReduceVo;
        /**
         * 订单公共信息
         */
        shopOrderCommonVo?: OriginShopOrderCommonVo;
        /**
         * 订单商品信息
         */
        shopOrderGoodsVos?: OriginShopOrderGoodsVo[];
        /**
         * 使用燃豆数量
         */
        usePointsNum?: number;
    };
    /**
     * 退款表
     */
    export type OriginShopRefundVo = {
        /**
         * 买家ID
         */
        buyerId?: string;
        /**
         * 申请原因（重新做表）
         */
        buyerMessage?: string;
        /**
         * 申请凭证列表
         */
        buyerMessageImageList?: string[];
        /**
         * 买家会员名
         */
        buyerName?: string;
        /**
         * 买家联系电话
         */
        buyerPhone?: string;
        /**
         * 买家退货说明
         */
        buyerRefundInfo?: string;
        /**
         * 审核时间
         */
        checkTime?: string;
        /**
         * 添加时间
         */
        createTime?: string;
        /**
         * 收货延迟时间,默认为0
         */
        delayTime?: number;
        /**
         * 物流公司编号
         */
        expressId?: number;
        /**
         * 物流公司名称
         */
        expressName?: string;
        /**
         * 商品ID,全部退款是0
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 购买商品的规格值列表
         */
        goodsSpecValList?: string[];
        /**
         * 物流状态:1为待发货,2为待收货,3为未收到,4为已收货,默认为1
         */
        goodsState?: number;
        /**
         * 退款id
         */
        id: number;
        /**
         * 物流单号
         */
        invoiceNo?: string;
        /**
         * 审核通过后，发货截至天数（减审核时间算剩余）
         */
        limitDay?: number;
        /**
         * 订单商品ID,全部退款是0
         */
        orderGoodsId?: number;
        /**
         * 订单商品类型:1默认2团购商品3限时折扣商品4组合套装
         */
        orderGoodsType?: number;
        /**
         * 订单ID
         */
        orderId?: number;
        /**
         * 订单锁定类型:1为不用锁定,2为需要锁定,默认为1
         */
        orderLock?: number;
        /**
         * 订单编号
         */
        orderNo?: string;
        /**
         * 下单选择的支付方式
         */
        paymentId?: number;
        /**
         * 收货备注
         */
        receiveMessage?: string;
        /**
         * 收货时间,默认为0
         */
        receiveTime?: number;
        /**
         * 退款金额
         */
        refundAmount?: number;
        /**
         * 退款单号
         */
        refundNo?: string;
        /**
         * 退款状态:1退款中 2退款成功 3退款失败 4退款关闭
         */
        refundOrderState?: number;
        /**
         * 退还燃豆数量
         */
        refundPointsNum?: number;
        /**
         * 申请状态:1待审核,2同意,3拒绝,默认为1
         */
        refundState?: number;
        /**
         * 申请类型:1为退款,2为退货退款,默认为1
         */
        refundType?: number;
        /**
         * 申请未通过原因
         */
        refuseMessage?: string;
        /**
         * 退货类型:1为不用退货,2为需要退货,默认为1
         */
        returnType?: number;
        /**
         * 发货时间,默认为0
         */
        shipTime?: string;
    };
    export type PointNoticBeanVo = {
        /**
         * 通知别名
         */
        alias?: string;
        /**
         * 通知的内容 :您已连续签到7天，继续加油哦~
         */
        content?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 通知主键ID
         */
        id?: string;
        /**
         * 动态ID/评论ID/话题ID/订单的ID/商品的ID
         */
        jumpId?: string;
        /**
         * 跳转参数
         */
        jumpParams?: string;
        /**
         * 跳转类型
         */
        jumpType?: string;
        /**
         * noticType燃豆通知类型(燃豆记录图标 PointIcon)：操作类型:1.首次登录;2.签到;3.正计时;4.倒计时;5.装备评价;6.退款返还抵扣燃豆(收支明细使用包含[1.超时未支付;2.退整单;3.退单个商品]);7.动态获得喂豆;8.赞助燃草;9.绑定手机号;10.补充资料;11.邀请好友;12.给他人喂豆;13.燃豆抵扣;14.退款超时未支付返还抵扣燃豆;15.订单退款退整单返还抵扣燃豆;16.订单退款退单个商品返还抵扣燃豆;17.修改用户名;18.系统奖励;
         */
        noticType?: number;
        /**
         * 操作类型 1获得 2消费
         */
        operaType?: number;
        /**
         * 燃豆数量
         */
        pointNumber?: number;
        /**
         * 消息发送者
         */
        senderUserId?: string;
        /**
         * 通知的标题：签到领
         */
        title?: string;
    };
    /**
     * 点赞信息
     */
    export type PraiseInformationForm = {
        /**
         *  1 点赞 2 取消点赞
         */
        operation?: number;
        /**
         * 被点赞的id
         */
        recipientId?: string;
        /**
         * 1 日志 2 评论
         */
        type?: number;
        /**
         * 用户id
         */
        uid?: string;
    };
    export type RecordingTimeBean = {
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 当日计时总秒数
         */
        currentDayTotalSecondNumber?: number;
        /**
         * 当前次数/当前计时时间小时数
         */
        currentTimes?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 习惯颜色
         */
        habitColor?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 习惯名称
         */
        habitName?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 是否是老数据 0不是 1是
         */
        isOldData?: number;
        /**
         * 是否私密 0不是 1是
         */
        isPrivate?: number;
        /**
         * 列表展示名称
         */
        listShowName?: string;
        /**
         * 待办id
         */
        nhId?: number;
        /**
         * 待办名称
         */
        nhName?: string;
        /**
         * 小练习id
         */
        practiceId?: number;
        /**
         * 对应计时表id
         */
        recordingTimeId?: number;
        /**
         * 当次计时秒数
         */
        secondNumber?: number;
        /**
         * 列表展示计时时长
         */
        showTime?: string;
        /**
         * 目标id
         */
        targetId?: number;
        /**
         * 总次数/总计时时间小时数
         */
        totalTimes?: string;
        /**
         * 专注类型-标签类型 3:专注模式;4:学霸模式;5:练习专注;6:待办专注;7:练习打卡;10:待办清单(个人资料使用)
         */
        type?: number;
        /**
         * 更新时间
         */
        updateDate?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 活动商品信息
     */
    export type ShopActiviteItemInfoVo = {
        /**
         * 活动类型 1为限时抢购, 2为预售
         */
        activityType?: number;
        /**
         * 活动结束时间
         */
        endTime?: string;
        /**
         * 预售商品的发货时间
         */
        preShippingTime?: string;
        /**
         * 活动开始时间
         */
        startTime?: string;
    };
    /**
     * spu活动
     */
    export type ShopActivityRedisVo = {
        /**
         * 折扣活动
         */
        shopDiscountGoodsRedisVo?: ShopDiscountGoodsRedisVo;
        /**
         * 满减活动
         */
        shopFullReduceGoodsRedisVo?: ShopFullReduceGoodsRedisVo;
    };
    /**
     * 活动表
     */
    export type ShopActivityVo = {
        /**
         * 活动名称
         */
        activityName?: string;
        /**
         * 是否可分享 0:不可以；1：可以
         */
        canShare?: number;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 描述
         */
        description?: string;
        /**
         * html数据
         */
        htmlText?: string;
        /**
         * 分享图标
         */
        iconImage?: string;
        id: number;
        /**
         * json数据
         */
        jsonText?: string;
    };
    /**
     * 新增买家地址信息表
     */
    export type ShopAddressCreateForm = {
        /**
         * 地址
         */
        address?: string;
        /**
         * 地址标签 1家 2学校 3公司
         */
        addressTag?: number;
        /**
         * 市ID
         */
        cityId?: string;
        /**
         * 1默认收货地址
         */
        isDefault?: number;
        /**
         * 会员ID
         */
        memberId?: string;
        /**
         * 手机电话
         */
        mobPhone?: string;
        /**
         * 省ID
         */
        provinceId?: string;
        /**
         * 地区内容
         */
        regioId?: string;
        /**
         * 街道id
         */
        rowId?: string;
        /**
         * 座机电话
         */
        telPhone?: string;
        /**
         * 会员姓名
         */
        trueName?: string;
    };
    /**
     * 删除买家地址信息表
     */
    export type ShopAddressDeleteForm = {
        /**
         * 地址ID
         */
        addressId: number;
    };
    /**
     * 买家地址信息表
     */
    export type ShopAddressVo = {
        /**
         * 地址
         */
        address?: string;
        /**
         * 地址详情
         */
        addressDetail?: string;
        /**
         * 地址ID
         */
        addressId: number;
        /**
         * 地址标签 1家 2学校 3公司
         */
        addressTag?: number;
        /**
         * 市ID
         */
        cityId?: string;
        /**
         * 1默认收货地址
         */
        isDefault?: number;
        /**
         * 会员ID
         */
        memberId?: string;
        /**
         * 手机电话
         */
        mobPhone?: string;
        /**
         * 省ID
         */
        provinceId?: string;
        /**
         * 区id
         */
        regioId?: string;
        /**
         * 街道id
         */
        rowId?: string;
        /**
         * 座机电话
         */
        telPhone?: string;
        /**
         * 会员姓名
         */
        trueName: string;
        type?: string;
    };
    export type ShopAppClassEsParam = {
        classImage?: string;
        className?: string;
        id?: number;
    };
    /**
     * 分类基础信息
     */
    export type ShopAppClassViewBaseVo = {
        /**
         * 分类图片
         */
        classImage?: string;
        /**
         * 类目名称
         */
        className: string;
        id: number;
        /**
         * 分类级别
         */
        level?: number;
        /**
         * 上级类目id 顶级默认为0
         */
        parentId: number;
        /**
         * 已关联spu数量
         */
        relatedSpuCount: number;
        /**
         * 排序
         */
        sort?: number;
    };
    /**
     * app前台类目表
     */
    export type ShopAppClassVo = {
        /**
         * 分类图片
         */
        classImage?: string;
        /**
         * 类目名称
         */
        className?: string;
        id: number;
        /**
         * 分类级别
         */
        level?: number;
        /**
         * 上级类目id 顶级默认为0
         */
        parentId?: number;
        /**
         * 分类下的目录
         */
        shopAppClassBaseVos?: ShopAppClassViewBaseVo[];
        /**
         * 是否展示在app首页 0不展示 1展示
         */
        showInIndex?: number;
        /**
         * 排序
         */
        sort?: number;
    };
    export type ShopAppUpdateVo = {
        /**
         * 下载地址
         */
        downloadUrl?: string;
        id: number;
        /**
         * 是否强制更新 0否 1是
         */
        isCompelUpdate?: number;
        /**
         * 是否可用 0不可用 1可用
         */
        isUsable?: number;
        /**
         * app类型 1安卓 2ios
         */
        type?: number;
        /**
         * 升级说明
         */
        updateRemark?: string;
        /**
         * 升级时间
         */
        updateTime?: string;
        /**
         * 版本号
         */
        version?: string;
    };
    export type ShopAppVersionUpdateVo = {
        /**
         * app版本是否需要更新
         */
        isUpdate: number;
        /**
         * 版本更新信息
         */
        shopAppUpdateVo?: ShopAppUpdateVo;
    };
    /**
     * 买家发票信息表
     */
    export type ShopApplyInvoicePreVo = {
        /**
         * 是否有增值税发票资质 0没有 1有
         */
        canApplySpecialInvoice: number;
        /**
         * 收票人手机号
         */
        recMobphone?: string;
        /**
         * 增值税发票信息 canApplySpecialInvoice为1时不为空
         */
        shopSpecialInvoiceVo?: ShopSpecialInvoiceVo;
    };
    export type ShopAreaQueryForm = {
        /**
         * 地区深度，从1开始
         */
        areaDeep?: number;
        /**
         * 索引ID
         */
        areaId?: string;
        /**
         * 索引ID列表
         */
        areaIdList?: string[];
        /**
         * 地区首字母
         */
        areaIndexWord?: string;
        /**
         * 地区名称
         */
        areaName?: string;
        /**
         * 地区父ID
         */
        areaParentId?: string;
        /**
         * 地区父ID列表
         */
        areaParentIdList?: string[];
        /**
         * 排序
         */
        areaSort?: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
    };
    export type ShopAreaVo = {
        /**
         * 地区深度，从1开始
         */
        areaDeep?: number;
        /**
         * 索引ID
         */
        areaId: string;
        /**
         * 地区首字母
         */
        areaIndexWord: string;
        /**
         * 地区名称
         */
        areaName: string;
        /**
         * 地区父ID
         */
        areaParentId?: string;
        /**
         * 排序
         */
        areaSort?: number;
    };
    /**
     * app轮播图
     */
    export type ShopBannerImageVo = {
        /**
         * 0:失效，1：生效
         */
        activite?: number;
        /**
         * 描述
         */
        description?: string;
        id: number;
        /**
         * 图片链接
         */
        image?: string;
        /**
         * 跳转参数
         */
        jumpArguments?: string;
        /**
         * 跳转类型
         */
        jumpType?: string;
        /**
         * 排序
         */
        sort?: number;
        type?: string;
    };
    export type ShopBrandEsParam = {
        brandId?: number;
        brandName?: string;
        brandPic?: string;
    };
    /**
     * 品牌表
     */
    export type ShopBrandVo = {
        /**
         * 品牌申请，0为申请中，1为通过，默认为1，申请功能是会员使用，系统后台默认为1
         */
        brandApply?: number;
        /**
         * 类别名称
         */
        brandClass?: string;
        /**
         * 索引ID
         */
        brandId: number;
        /**
         * 品牌名称
         */
        brandName?: string;
        /**
         * 图片
         */
        brandPic?: string;
        /**
         * 推荐，0为否，1为是，默认为0
         */
        brandRecommend?: number;
        /**
         * 排序
         */
        brandSort?: number;
        /**
         * 所属分类id
         */
        classId?: number;
        /**
         * 店铺ID
         */
        storeId?: number;
    };
    /**
     * 苹果回调反参
     */
    export type ShopCallBackOfIphoneVo = {
        /**
         * 票据信息
         */
        certCode?: string;
    };
    /**
     * 可评价订单商品参数
     */
    export type ShopCanEvalOrderGoodsVo = {
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品id
         */
        goodsId: number;
        /**
         * 商品图片
         */
        goodsImage: string;
        /**
         * 商品名称
         */
        goodsName: string;
        /**
         * 购买商品的规格值列表
         */
        goodsSpecValList?: string[];
        /**
         * 订单id
         */
        orderId: number;
        /**
         * 评价可获得的燃豆数量
         */
        pointsNum: number;
    };
    /**
     * 可评价订单商品-按spu分组
     */
    export type ShopCanEvalOrderSpuVo = {
        /**
         * 货品下的可评价商品
         */
        shopOrderGoodsVos: OriginShopOrderGoodsVo[];
        /**
         * 货品id
         */
        spuId: number;
    };
    /**
     * 可评价订单商品-按spu分组
     */
    export type ShopCanEvalOrderSpuWhenSuccessVo = {
        /**
         * 货品下的可评价商品
         */
        shopCanEvalOrderGoodsVos: ShopCanEvalOrderGoodsVo[];
        /**
         * 货品id
         */
        spuId: number;
    };
    /**
     * 购物车收藏
     */
    export type ShopCartCollectCreateForm = {
        shopCartCollectForms?: ShopCartCollectForm[];
    };
    /**
     * 购物车种草
     */
    export type ShopCartCollectForm = {
        /**
         * 购物车ID
         */
        cartId?: number;
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品ID
         */
        goodsId?: number;
    };
    /**
     * 删除购物车
     */
    export type ShopCartDeleteForm = {
        idList?: number[];
    };
    /**
     * 购物车数据表
     */
    export type ShopCartGoodsVo = {
        /**
         * 有效商品信息
         */
        effectVos?: ShopCartSpuVo[];
        /**
         * 失效商品信息
         */
        noEffectVos?: ShopCartNoEffectGoodsVo[];
    };
    /**
     * 购物车商品失效数据
     */
    export type ShopCartNoEffectGoodsVo = {
        /**
         * 买家id
         */
        buyerId?: string;
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品限购价格
         */
        goodsXianGouPrice?: number;
        /**
         * 购物车id
         */
        id: number;
        /**
         * 失效类型，1下架，2售罄
         */
        isEffectType?: number;
        /**
         * 商品限购数量
         */
        limitNum?: number;
        /**
         * 失效原因
         */
        remark?: string;
    };
    /**
     * 购物车总价计算
     */
    export type ShopCartPriceVo = {
        /**
         * 购物车价格
         */
        cartPrice?: number;
        /**
         * 满减价格
         */
        reducePrice?: number;
    };
    /**
     * 购物车数据表
     */
    export type ShopCartSpuVo = {
        /**
         * 有效商品信息
         */
        goodsCommonId?: number;
        /**
         * spu下的有效商品
         */
        originShopCartVos?: OriginShopCartVo[];
    };
    /**
     * 修改购物车后返回金额信息
     */
    export type ShopCartUpdateVo = {
        /**
         * +号是否灰色 0不是 1是
         */
        addIsGrey?: number;
        /**
         * 合并的购物车id
         */
        cartId?: number;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 商品原价
         */
        goodsPrice?: number;
        /**
         * 商品在售价
         */
        goodsSellPrice?: number;
        /**
         * 是否合并操作，1是，0不是
         */
        isMerge?: number;
        /**
         * 商品所属活动
         */
        shopActivityRedisVo?: ShopActivityRedisVo;
        /**
         * 赠品信息
         */
        shopDonationVoList?: ShopDonationVo[];
        /**
         * 勾选状态 0未勾选 1已勾选
         */
        state?: number;
        /**
         * -号是否灰色 0不是 1是
         */
        subtractIsGrey?: number;
    };
    /**
     * 折扣活动商品表
     */
    export type ShopDiscountGoodsRedisVo = {
        /**
         * 折扣活动id
         */
        discountId?: number;
        /**
         * 折扣活动名称
         */
        discountName?: string;
        /**
         * 折扣价格
         */
        discountPrice?: number;
        /**
         * 活动结束时间
         */
        endTime?: string;
        id: number;
        /**
         * 限购数 0为不限购
         */
        limitNum?: number;
        /**
         * 商品id 为0时为通用sku
         */
        skuId?: number;
        /**
         * 货品id
         */
        spuId?: number;
        /**
         * 活动开始时间
         */
        startTime?: string;
        /**
         * 状态，0-取消 1-正常
         */
        state?: number;
        /**
         * 活动横幅链接
         */
        streamerUrl?: string;
    };
    /**
     * 商城首页活动列表
     */
    export type ShopDiscountIndexVo = {
        /**
         * 折扣活动商品
         */
        discountGoodsList?: ShopGoodsCommonViewInAppVo[];
        /**
         * 折扣活动名称
         */
        discountName?: string;
        /**
         * 折扣活动说明
         */
        discountRemark?: string;
        /**
         * 结束时间
         */
        endTime?: string;
        /**
         * 活动id
         */
        id: number;
        /**
         * 当前时间
         */
        now?: string;
        /**
         * 开始时间
         */
        startTime?: string;
    };
    /**
     * 购买商品赠品
     */
    export type ShopDonationVo = {
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 赠送数量
         */
        goodsNum?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
    };
    /**
     * 评论列表图数量
     */
    export type ShopEvalImageNumVo = {
        /**
         * 商品id(SKU)
         */
        imageNum: number;
    };
    /**
     * 商城评价参数
     */
    export type ShopEvaluateCreateForm = {
        /**
         * 评价内容
         */
        evalContent?: string;
        /**
         * 商品id列表
         */
        evalGoodsIds?: number[];
        /**
         * 订单id
         */
        evalOrderId?: number;
        /**
         * 评价图片
         */
        evaluateImages?: string[];
        /**
         * spu id
         */
        goodsCommonId?: number;
        /**
         * 是否匿名 0不匿名 1匿名
         */
        isAnonymity?: number;
    };
    /**
     * 评论点赞参数
     */
    export type ShopEvaluateGoodsCreateGiveLikeForm = {
        /**
         * 评论id
         */
        level1CommentId?: string;
        /**
         * 点赞人id
         */
        userId?: string;
    };
    /**
     * 信誉评价表
     */
    export type ShopEvaluateGoodsDetailResponseVo = {
        /**
         * 掌柜回复
         */
        adminReply?: string;
        /**
         * 评论内容
         */
        content?: string;
        /**
         * 评论时间
         */
        createDate?: string;
        /**
         * 评论图片
         */
        evalImages?: string[];
        /**
         * 评论人头像
         */
        formUserHead?: string;
        /**
         * 评论人id
         */
        formUserId?: string;
        /**
         * 评论人名称
         */
        formUserName?: string;
        /**
         * 评论人头像装扮id
         */
        fromUserDressId?: number;
        /**
         * 评论人是否会员
         */
        fromUserIsMember?: number;
        /**
         * spuId
         */
        goodsCommonId?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品规格值
         */
        goodsSpecName?: string[];
        /**
         * 是否点赞 0没有 1有
         */
        isGiveLike?: number;
        /**
         * 评论回复列表
         */
        replyVos?: ShopEvaluateGoodsReplyResponseVo[];
        /**
         * 评论id
         */
        uuid?: string;
    };
    /**
     * 评价分页数据
     */
    export type ShopEvaluateGoodsPageListResponseVo = {
        /**
         * 掌柜回复
         */
        adminReply?: string;
        /**
         * 颜色规格名称
         */
        colorSpecName?: string;
        /**
         * 评论次数
         */
        commentNumber?: number;
        /**
         * 评论内容
         */
        content?: string;
        /**
         * 评论时间
         */
        createDate?: string;
        /**
         * 评论图片
         */
        evalImages?: string[];
        /**
         * 评论人头像
         */
        formUserHead?: string;
        /**
         * 评论人id
         */
        formUserId?: string;
        /**
         * 评论人名称
         */
        formUserName?: string;
        /**
         * 评论人头像装扮id
         */
        fromUserDressId?: number;
        /**
         * 评论人是否会员 0不是 1是
         */
        fromUserIdMember?: number;
        /**
         * 评论人职业
         */
        fromUserProfession?: string;
        /**
         * 商品规格值
         */
        goodsSpecName?: string[];
        /**
         * 是否点赞 0没有 1有
         */
        isGiveLike?: number;
        /**
         * 点赞次数
         */
        praiseNumber?: number;
        /**
         * 举报次数
         */
        tipOffNumber?: number;
        /**
         * 评论id
         */
        uuid?: string;
        /**
         * 浏览次数
         */
        viewNumber?: number;
    };
    /**
     * 信誉评价表
     */
    export type ShopEvaluateGoodsReplyResponseVo = {
        /**
         * 回复内容
         */
        content?: string;
        /**
         * 回复图片
         */
        evalImages?: string[];
        /**
         * 评论人头像挂件id
         */
        formUserDressId?: number;
        /**
         * 评论人头像
         */
        formUserHead?: string;
        /**
         * 评论人
         */
        formUserId?: string;
        /**
         * 评论人名称
         */
        formUserName?: string;
        /**
         * 评论人是否会员
         */
        fromUserIsMember?: number;
        /**
         * 是否直接回复评论 0不是 1是
         */
        isOriginEval?: number;
        /**
         * 一级评论id
         */
        level1CommentId?: string;
        /**
         * 被评论人id
         */
        toUserId?: string;
        /**
         * 被评论人名称
         */
        toUserName?: string;
        /**
         * 评论id
         */
        uuid?: string;
    };
    /**
     * 评论成功返回值
     */
    export type ShopEvaluateGoodsSuccessResponseVo = {
        /**
         * 返燃豆数量
         */
        pointsNUm: number;
        /**
         * 评论id
         */
        uuid: string;
    };
    /**
     * 评论浏览
     */
    export type ShopEvaluateGoodsViewForm = {
        /**
         * 评论id
         */
        level1CommentIds?: string[];
    };
    /**
     * 信誉评价表
     */
    export type ShopEvaluateReplyForm = {
        /**
         * 评论ID -- 直接回复评论时为空
         */
        evalId?: string;
        /**
         * 顶层评论id
         */
        level1CommentId?: string;
        /**
         * 回复内容
         */
        replyContent?: string;
        /**
         * 回复图片
         */
        replyImages?: string[];
        /**
         * 被回复人id
         */
        replyMemberId?: string;
    };
    /**
     * 快递行程列表
     */
    export type ShopExpressRouteEntityVo = {
        /**
         * 状态
         */
        deliverystatus?: string;
        /**
         * 到达地区
         */
        status?: string;
        /**
         * 更新时间
         */
        time?: string;
    };
    /**
     * 买家收藏表
     */
    export type ShopFavoritesCreateForm = {
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品ID
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 用户ID
         */
        memberId?: string;
    };
    /**
     * 买家收藏表
     */
    export type ShopFavoritesDeleteForm = {
        /**
         * 收藏id
         */
        idList: number[];
    };
    /**
     * 买家收藏表
     */
    export type ShopFavoritesVo = {
        /**
         * 收藏时间
         */
        createTime?: string;
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品ID
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品售价
         */
        goodsPrice?: number;
        id: number;
        /**
         * 会员ID
         */
        memberId?: string;
        /**
         * 0取消收藏 1已收藏
         */
        state?: number;
    };
    /**
     * 满减活动商品redis数据
     */
    export type ShopFullReduceGoodsRedisVo = {
        /**
         * 活动结束时间
         */
        endTime?: string;
        /**
         * 满减id
         */
        fullReduceId?: number;
        /**
         * 满减名称
         */
        fullReduceName?: string;
        id: number;
        /**
         * 是否上不封顶 0不是 1是
         */
        isLoop?: number;
        shopFullReduceSpecVos?: ShopFullReduceSpecVo[];
        /**
         * 商品id
         */
        skuId?: number;
        /**
         * 货品id
         */
        spuId?: number;
        /**
         * 活动开始时间
         */
        startTime?: string;
        /**
         * 状态，0-取消 1-正常
         */
        state?: number;
        /**
         * 满减条件 1满金额 2满件数
         */
        type?: number;
    };
    /**
     * 满减规格
     */
    export type ShopFullReduceSpecVo = {
        /**
         * 不支持包邮地区编码
         */
        excludePostageAreaCode?: string;
        /**
         * 满几件
         */
        fullNum?: number;
        /**
         * 满金额
         */
        fullPrice?: number;
        /**
         * 满减活动id
         */
        fullReduceId?: number;
        id: number;
        /**
         * 是否有赠品 0没有 1有
         */
        isGift?: number;
        /**
         * 是否包邮 0不包 1包
         */
        isPostage?: number;
        /**
         * 优惠等级
         */
        level?: number;
        /**
         * 打几折
         */
        reduceDiscount?: number;
        /**
         * 减金额
         */
        reducePrice?: number;
        /**
         * 赠品列表
         */
        shopPostageGiftVos?: ShopPostageGiftVo[];
        /**
         * 满件规则 1减钱 2打折 3无
         */
        specType?: number;
    };
    /**
     * 满减活动参数
     */
    export type ShopFullReduceVo = {
        /**
         * 满减折扣
         */
        discount?: string;
        /**
         * 是否全店满减 0不是 1是
         */
        isStoreFullReduce?: number;
        /**
         * 满减要求件数
         */
        needFullReduceNum?: number;
        /**
         * 满减要求金额
         */
        needFullReducePrice?: number;
        /**
         * 满减减的金额
         */
        reducePrice?: number;
    };
    /**
     * spu详情
     */
    export type ShopGoodsCommonDetailVo = {
        /**
         * 品牌id
         */
        brandId?: number;
        /**
         * 品牌名称
         */
        brandName?: string;
        /**
         * 是否被收藏 - 0未收藏 1已收藏
         */
        collectState?: number;
        /**
         * 发货地址
         */
        deliverAddress?: string;
        /**
         * 默认展示的评论信息
         */
        evaluateGoodsInfo?: ShopEvaluateGoodsPageListResponseVo[];
        /**
         * 评论个数
         */
        evaluateNum?: number;
        /**
         * 收藏数量
         */
        goodsCollect?: number;
        /**
         * 商品公共表id
         */
        goodsCommonid: number;
        /**
         * 商品主图
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 已售数量
         */
        goodsSalenum?: number;
        /**
         * 商家条形码编号
         */
        goodsSerial?: string;
        /**
         * 运费 0为免运费
         */
        goodsShippingFee?: number;
        /**
         * 商品广告词
         */
        goodsSlogan?: string;
        /**
         * 货品库存
         */
        goodsStorage?: number;
        /**
         * 是否下架商品 不为空说明已下架-直接展示
         */
        isDownMessage?: string;
        /**
         * 是否预售商品 0不是 1是
         */
        isPreSell?: number;
        /**
         * 主规格图
         */
        mainSpecImages?: string[];
        /**
         * 原价
         */
        originPrice?: OriginShopGoodsCommonShowPriceVo;
        /**
         * 购买商品可获积分数
         */
        points?: number;
        /**
         * 燃豆可抵折扣百分比
         */
        pointsDeduction?: number;
        /**
         * 预售商品发货时间
         */
        preDeliverTime?: string;
        /**
         * 货品下的属性与属性值对应值
         */
        shopGoodsAttrVos?: OriginShopGoodsAttrVo[];
        /**
         * 商品服务信息
         */
        shopGoodsServiceListVos?: ShopGoodsServiceListVo[];
        /**
         * 主规格列表
         */
        shopGoodsSpecVo?: ShopGoodsCommonViewSpecVo;
        /**
         * spu下只有一个sku 默认选中
         */
        shopItemSpecEnhanceVo?: ShopItemSpecEnhanceVo;
        /**
         * 活动信息
         */
        shopSpuActivityVo?: ShopSpuActivityVo;
        /**
         * 详情展示轮播图
         */
        spuBannerImages?: string[];
    };
    /**
     * 搜索页商品列表
     */
    export type ShopGoodsCommonViewEsGoodsListVo = {
        /**
         * 分类列表
         */
        shopAppClassEsParams?: ShopAppClassEsParam[];
        /**
         * 品牌列表
         */
        shopBrandEsParams?: ShopBrandEsParam[];
        /**
         * spu列表
         */
        shopItemList?: ShopGoodsCommonViewItemInEsVo[];
    };
    /**
     * app展示货品列表
     */
    export type ShopGoodsCommonViewInAppVo = {
        /**
         * spu id
         */
        goodsCommonid?: number;
        /**
         * spu主图
         */
        goodsImage?: string;
        /**
         * 商品名称（+规格名称）
         */
        goodsName?: string;
        /**
         * 货品一口价
         */
        goodsPrice?: number;
        /**
         * 已售出数量
         */
        goodsSaleNum?: number;
    };
    /**
     * ES商品返回数据
     */
    export type ShopGoodsCommonViewItemInEsVo = {
        /**
         * spuId
         */
        goodsId?: number;
        /**
         * spu主图
         */
        goodsImage?: string;
        /**
         * spu名称
         */
        goodsName?: string;
        /**
         * spu总售量
         */
        goodsSalenum?: number;
        /**
         * 商品售价
         */
        goodsSellPrice?: number;
    };
    /**
     * 商品有的规格与规格值
     */
    export type ShopGoodsCommonViewItemSpecVo = {
        /**
         * 规格id
         */
        specId?: number;
        /**
         * 规格名称
         */
        specName?: string;
        /**
         * 规格值id
         */
        specValId?: number;
        /**
         * 规格值名称
         */
        specValName?: string;
    };
    /**
     * 货品有的规格值
     */
    export type ShopGoodsCommonViewSpecValVo = {
        /**
         * 规格图片
         */
        colorImage?: string;
        /**
         * 规格值id
         */
        specValId?: number;
        /**
         * 规格值名称
         */
        specValName?: string;
    };
    /**
     * 货品有的规格
     */
    export type ShopGoodsCommonViewSpecVo = {
        /**
         * 规格对应的规格值
         */
        shopGoodsSpecValVos?: ShopGoodsCommonViewSpecValVo[];
        /**
         * 规格id
         */
        specId?: number;
        /**
         * 规格名称
         */
        specName?: string;
    };
    /**
     * 商城首页信息
     */
    export type ShopGoodsIndexVo = {
        /**
         * 首页轮播图
         */
        bannerImageVos?: ShopBannerImageVo[];
        /**
         * 首页分类列表
         */
        indexClassVos?: ShopBannerImageVo[];
        /**
         * 首页通知信息列表
         */
        indexNoticeVos?: ShopBannerImageVo[];
        /**
         * banner图是否滚动 0不滚动 1滚动
         */
        isBannerScroll?: number;
        /**
         * banner滚动间隔时间
         */
        scrollSecond?: number;
    };
    /**
     * 评论成功返回值
     */
    export type ShopGoodsListWhenEvelSuccessResponseVo = {
        /**
         * 待评价订单商品列表 -- 为空时展示推荐列表
         */
        canEvalList?: ShopCanEvalOrderSpuWhenSuccessVo[];
        /**
         * 推荐商品列表
         */
        recommendGoodsList?: ShopGoodsCommonViewItemInEsVo[];
    };
    /**
     * 商品详情服务表
     */
    export type ShopGoodsServiceListVo = {
        /**
         * 服务内容
         */
        serviceContentList?: ShopGoodsServiceVo[];
        /**
         * 服务类型
         */
        serviceType?: string;
    };
    /**
     * 商品详情服务表
     */
    export type ShopGoodsServiceVo = {
        /**
         * 服务内容
         */
        content?: string;
        /**
         * 服务内容解释
         */
        contentExplain?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 服务图标
         */
        icon?: string;
        id: number;
        /**
         * 排序
         */
        sort?: number;
    };
    /**
     * spu默认发货地址与运费
     */
    export type ShopGoodsShipAndDeliverAddressVo = {
        /**
         * 发货地址
         */
        deliverAddress?: string;
        /**
         * 运费
         */
        shippingFee?: number;
    };
    /**
     * 我的商城数据聚合
     */
    export type ShopIndexMyShopStaticVo = {
        /**
         * 动态互动数量
         */
        dynamicNum?: number;
        /**
         * 计时柱状图数据
         */
        histogramVo?: EfficiencyRecordingTimeHistogramVo;
        /**
         * banner图是否滚动 0不滚动 1滚动
         */
        isBannerScroll?: number;
        /**
         * 我的页面轮播图
         */
        myBannerImage?: ShopBannerImageVo[];
        /**
         * 购物车数量
         */
        myCartNum?: number;
        /**
         * 收藏数量
         */
        myFavouriteNum?: number;
        /**
         * 待评价数量
         */
        preEvaluateNum?: number;
        /**
         * 待付款数量
         */
        prePayNum?: number;
        /**
         * 待收货数量
         */
        preTakeGoodsNum?: number;
        /**
         * banner滚动间隔时间
         */
        scrollSecond?: number;
        /**
         * 通知是否显示红点 在totalPushNum为0时判断此字段(大于0显示红点否则不显示)
         */
        totalNoticeNum?: number;
        /**
         * 显示的推送的数量
         */
        totalPushNum?: number;
    };
    /**
     * 苹果内购票据
     */
    export type ShopIphoneCertForm = {
        /**
         * 票据信息
         */
        certificateCode?: string;
    };
    /**
     * 商品详情底部样式返回参数
     */
    export type ShopItemExtraDetailVo = {
        /**
         * 商品统一详情
         */
        globalBody?: string;
        /**
         * 商品内容
         */
        goodsBody?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品上次编辑时间
         */
        lastModifyTime?: string;
    };
    /**
     * 商品表
     */
    export type ShopItemSpecEnhanceVo = {
        /**
         * 商品id(SKU)
         */
        itemId: number;
        /**
         * spu下只有一个sku 默认选中
         */
        shopItemSpecVos?: ShopGoodsCommonViewItemSpecVo[];
    };
    /**
     * 我的收藏列表
     */
    export type ShopMyFavoritesListVo = {
        /**
         * 商品收藏数
         */
        goodsCollectNum?: number;
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品ID
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品售卖价
         */
        goodsSellPrice?: number;
        /**
         * 商品库存数
         */
        goodsStoragy?: number;
        /**
         * 收藏id
         */
        id?: number;
        /**
         * 失效类型，1下架，2售罄, 3失效
         */
        notEffectType?: number;
        /**
         * 比收藏时降价数额
         */
        reducePrice?: number;
    };
    /**
     * 我的收藏失效商品
     */
    export type ShopMyFavoritesNotEffectGoodsVo = {
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 收藏id
         */
        id?: number;
        /**
         * 失效类型，1下架，2售罄, 3失效
         */
        notEffectType?: number;
    };
    /**
     * 我的退款商品
     */
    export type ShopMyRefundGoodsVo = {
        /**
         * 商品ID,全部退款是0
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 购买商品的规格值列表
         */
        goodsSpecValList?: string[];
    };
    /**
     * 我的退款记录
     */
    export type ShopMyRefundVo = {
        /**
         * 申请原因（重新做表）
         */
        buyerMessage?: string;
        /**
         * 申请凭证列表
         */
        buyerMessageImageList?: string[];
        /**
         * 买家退货说明
         */
        buyerRefundInfo?: string;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 退款id
         */
        id: number;
        /**
         * 是否整单退款 1是 2不是
         */
        isAllRefundOrder?: number;
        /**
         * 订单ID
         */
        orderId?: number;
        /**
         * 订单状态：1未支付取消订单，2已支付取消订单，3超时未支付
         */
        orderState?: number;
        /**
         * 退款单号
         */
        refundNo?: string;
        /**
         * 退款单退款状态: 1退款中，2退款成功，3退款失败, 4退款关闭
         */
        refundOrderState?: number;
        /**
         * 退还燃豆数量
         */
        refundPointsNum?: number;
        /**
         * 申请状态:1待审核,2同意,3拒绝,默认为1
         */
        refundState?: number;
        /**
         * 申请类型:1为退款,2为退货退款,默认为1
         */
        refundType?: number;
        /**
         * 退货类型:1为不用退货,2为需要退货,默认为1
         */
        returnType?: number;
        /**
         * 退款商品信息
         */
        shopMyRefundGoodsVoList?: ShopMyRefundGoodsVo[];
    };
    /**
     * 商城通知列表参数
     */
    export type ShopNoticeEveryCountVo = {
        /**
         * 最新一条通知信息
         */
        defaultOneContent?: string;
        /**
         * 上次查看后新通知的数量
         */
        newNoticeNum?: number;
        /**
         * 最新通知时间
         */
        newNoticeTime?: string;
        /**
         * 通知图标
         */
        noticeIcon?: string;
        /**
         * 通知标题
         */
        noticeTitle?: string;
        /**
         * 通知类型 1装备互动 2优惠促销 3订单交易 4官方通知 5燃豆助手 6燃草客服 7评论 8助燃 9喂豆 10粉丝
         */
        type?: number;
    };
    /**
     * 商城互动通知列表查询参数
     */
    export type ShopNoticeListQueryForm = {
        /**
         * 通知类型 1装备互动 2优惠促销 3订单交易 4官方通知
         */
        noticeType: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 商城互动列表返回参数
     */
    export type ShopNoticeVo = {
        /**
         * 通知时间
         */
        createTime?: string;
        /**
         * 通知商品图片
         */
        goodsImage?: string;
        /**
         * 跳转参数
         */
        jumpParams?: string;
        /**
         * 跳转类型
         */
        jumpType?: string;
        /**
         * 通知内容
         */
        noticeContent?: string;
        /**
         * 通知标题
         */
        noticeTitle?: string;
        /**
         * 通知具体内容json字符串
         */
        noticejson?: string;
        /**
         * 主键id
         */
        uuid?: string;
    };
    /**
     * 买家订单地址历史
     */
    export type ShopOrderAddressHistoryVo = {
        /**
         * 主键
         */
        id: number;
        /**
         * 订单使用过的地址
         */
        orderAddressDetail?: string;
        /**
         * 订单id
         */
        orderId?: number;
        /**
         * 收货人姓名
         */
        orderReciverName?: string;
        /**
         * 收货人联系方式
         */
        orderReciverPhone?: string;
        /**
         * 1用户修改 2后台修改
         */
        type?: number;
        /**
         * 修改时间
         */
        updateTime?: string;
    };
    /**
     * 订单表
     */
    export type ShopOrderAddressUpdateForm = {
        /**
         * 订单索引id
         */
        addressId: number;
        /**
         * 订单索引id
         */
        orderId: number;
    };
    /**
     * 订单表
     */
    export type ShopOrderAppCreateForm = {
        /**
         * 下单地址id
         */
        addressId?: number;
        /**
         * 燃豆抵扣金额
         */
        deductionAmount: number;
        /**
         * 商品总价格
         */
        goodsAmount: number;
        /**
         * 买家电子邮箱
         */
        memberEmail: string;
        /**
         * 买家id
         */
        memberId: string;
        /**
         * 买家姓名
         */
        memberName: string;
        /**
         * 订单总价格
         */
        orderAmount: number;
        /**
         * 再支付订单id
         */
        orderId?: number;
        /**
         * 订单备注
         */
        orderMessage?: string;
        /**
         * 订单中的商品信息
         */
        originShopOrderGoodsCreateForms: OriginShopOrderGoodsCreateForm[];
        /**
         * 支付方式
         */
        paymentId: number;
        /**
         * 是否使用燃豆抵扣，0不适用, 1使用
         */
        pointsIsUsed: number;
        /**
         * 运费
         */
        shippingFee: number;
        /**
         * 下单地址
         */
        shopAddressCreateForm?: ShopAddressCreateForm;
    };
    /**
     * 订单表
     */
    export type ShopOrderAppPayParamsVo = {
        /**
         * 应用ID
         */
        appId?: string;
        /**
         * 商户号
         */
        mchId?: string;
        /**
         * 随机字符串
         */
        nonceStr?: string;
        /**
         * 订单id
         */
        orderId: number;
        /**
         * 支付方式
         */
        paymentId: number;
        /**
         * 预支付交易会话ID
         */
        prepayId?: string;
        /**
         * 签名
         */
        sign?: string;
        /**
         * 时间戳
         */
        timestamp?: string;
        /**
         * 调起支付宝需要的参数
         */
        zhifubaoSecretKey?: string;
    };
    /**
     * 取消订单参数
     */
    export type ShopOrderCancelForm = {
        /**
         * 订单id
         */
        orderId?: number;
        /**
         * 取消原因
         */
        reason: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 订单信息扩展表
     */
    export type ShopOrderCommonVo = {
        /**
         * 发货地址ID
         */
        addressId?: number;
        /**
         * 发货备注
         */
        deliverExplain?: string;
        /**
         * 卖家是否已评价买家
         */
        evalsellerState?: string;
        /**
         * 卖家评价买家的时间
         */
        evalsellerTime?: number;
        /**
         * 评价时间
         */
        evaluationTime?: string;
        /**
         * 发票id
         */
        invoiceInfo?: number;
        /**
         * 订单索引id
         */
        orderCommonId: number;
        /**
         * 订单留言
         */
        orderMessage?: string;
        /**
         * 订单赠送积分(燃豆)
         */
        orderPointscount?: number;
        /**
         * 预计发货时间
         */
        prepareShippingTime?: string;
        /**
         * 促销信息备注
         */
        promotionInfo?: string;
        /**
         * 买家详细地址
         */
        reciverAddressDetail?: string;
        /**
         * 收货人地址是否默认 1为默认
         */
        reciverAddressIsDefault?: number;
        /**
         * 收货人地址标签  1家 2学校 3公司
         */
        reciverAddressTag?: number;
        /**
         * 市
         */
        reciverCityCode?: string;
        /**
         * 市名称
         */
        reciverCityName?: string;
        /**
         * 买家门牌号
         */
        reciverHouseNumber?: string;
        /**
         * 收货人姓名
         */
        reciverName?: string;
        /**
         * 收货人联系方式
         */
        reciverPhone?: string;
        /**
         * 省
         */
        reciverProvinceCode?: string;
        /**
         * 省名称
         */
        reciverProvinceName?: string;
        /**
         * 区
         */
        reciverRegioCode?: string;
        /**
         * 区名称
         */
        reciverRegioName?: string;
        /**
         * 街道
         */
        reciverRowCode?: string;
        /**
         * 街道名称
         */
        reciverRowName?: string;
        /**
         * 配送公司ID
         */
        shippingExpressId?: number;
        /**
         * 配送公司名称
         */
        shippingExpressName?: string;
        /**
         * 配送时间(发货时间)
         */
        shippingTime?: string;
        /**
         * 订单历史收货地址-长度为0说明未修改过地址
         */
        shopOrderAddressHistoryVoList?: ShopOrderAddressHistoryVo[];
        /**
         * 优惠券id
         */
        ticketId?: number;
        /**
         * 代金券面额
         */
        ticketPrice?: number;
    };
    /**
     * 确认收货参数
     */
    export type ShopOrderConfirmGoodsForm = {
        /**
         * 订单id
         */
        orderId?: number;
    };
    /**
     * 订单再支付
     */
    export type ShopOrderContinuePayForm = {
        /**
         * 订单id
         */
        orderId?: number;
    };
    /**
     * 创建订单前置参数获取
     */
    export type ShopOrderCreatePreForm = {
        /**
         * 用户选择最后一级地区的code值
         */
        addressAreaCode?: string;
        /**
         * 切换或是新增的地址id
         */
        addressId?: number;
        /**
         * 购买的商品信息
         */
        shopOrderGoodsBaseForms?: ShopOrderGoodsBaseForm[];
    };
    /**
     * 取消删除订单
     */
    export type ShopOrderDeleteForm = {
        /**
         * 订单id
         */
        orderId?: number;
        /**
         * 用户id
         */
        userId?: number;
    };
    /**
     * 订单物流信息
     */
    export type ShopOrderExpressVo = {
        /**
         * 快递员 或 快递站(没有则为空)
         */
        courier?: string;
        /**
         * 快递员电话 (没有则为空)
         */
        courierPhone?: string;
        /**
         * 物流状态
         */
        deliveryStatus?: string;
        /**
         * 快递公司名称
         */
        expName?: string;
        /**
         * 快递公司电话
         */
        expPhone?: string;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 是否展示物流公司信息 0不展示 1展示
         */
        isShow?: number;
        /**
         * 是否签收,1.是否签收
         */
        issign?: string;
        /**
         * 快递行程
         */
        list?: ShopExpressRouteEntityVo[];
        /**
         * 快递公司LOGO
         */
        logo?: string;
        /**
         * 快递单号
         */
        number?: string;
        /**
         * 收货地址
         */
        receiverAddress?: string;
    };
    /**
     * 满减活动参数
     */
    export type ShopOrderFullReduceForAppRealVo = {
        /**
         * 满减优惠总金额
         */
        reducePrice?: number;
        /**
         * 促销详情
         */
        shopSpuActivityDetailVos?: ShopOrderSpuActivityDetailVo[];
    };
    /**
     * 订单商品基本
     */
    export type ShopOrderGoodsBaseForm = {
        /**
         * 商品所属活动
         */
        activityGoodsInfo?: UtilVo;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买商品数量
         */
        goodsNum?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品规格名称
         */
        specValNames?: string[];
    };
    export type ShopOrderGoodsBaseVo = {
        /**
         * 商品所属活动
         */
        activityGoodsInfo?: ShopActiviteItemInfoVo;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买商品数量
         */
        goodsNum?: number;
        /**
         * 商品原价
         */
        goodsOriginPrice?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品条形码
         */
        goodsSerial?: string;
        /**
         * 商品库存
         */
        goodsStorage?: number;
        /**
         * 是否预售商品 0不是 1是
         */
        isPreSell?: number;
        /**
         * 失效类型，1下架，2售罄
         */
        notEffectType?: number;
        /**
         * 可得积分
         */
        points?: number;
        /**
         * 燃豆可抵比例
         */
        pointsDeduction?: number;
        /**
         * 失效原因
         */
        remark?: string;
        /**
         * 商品规格名称
         */
        specValNames?: string[];
        /**
         * spu id
         */
        spuId?: number;
    };
    /**
     * 商品是否可退款
     */
    export type ShopOrderGoodsCanRefundQueryForm = {
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 订单id
         */
        orderId?: number;
    };
    /**
     * 订单商品表
     */
    export type ShopOrderGoodsVo = {
        /**
         * 买家ID
         */
        buyerId?: string;
        /**
         * 下单时燃豆可获得数量
         */
        canGetPoints?: number;
        /**
         * 评价id
         */
        evaluationId?: string;
        /**
         * 评价状态 0未评价 1已评价
         */
        evaluationState?: number;
        /**
         * 货品id
         */
        goodsCommonId?: number;
        /**
         * 商品id
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 商品原价
         */
        goodsOriginPrice?: number;
        /**
         * 商品价格
         */
        goodsPrice?: number;
        /**
         * 商品条码
         */
        goodsSerial?: string;
        /**
         * 购买商品的规格值列表
         */
        goodsSpecValList?: string[];
        /**
         * 1默认2团购商品3限时折扣商品4组合套装5赠品
         */
        goodsType?: string;
        /**
         * 订单商品表索引id
         */
        id: number;
        /**
         * 订单id
         */
        orderId?: number;
        /**
         * 下单时燃豆可抵扣的百分比
         */
        pointsDeduction?: number;
        /**
         * 促销活动ID（团购ID/限时折扣ID/优惠套装ID）与goods_type搭配使用
         */
        promotionsId?: number;
        /**
         * 退款单id
         */
        refundId?: number;
        /**
         * 退款状态:0是无退款,1退款中，2退款成功，3退款失败, 4退款关闭
         */
        refundState?: number;
    };
    /**
     * 买家发票信息表
     */
    export type ShopOrderInvoiceVo = {
        /**
         * 单位名称
         */
        companyName?: string;
        /**
         * 索引id
         */
        id: number;
        /**
         * 发票内容[普通发票] 1商品明细 2商品类别
         */
        invoiceContent?: number;
        /**
         * 发票抬头[普通发票]  1个人 2公司
         */
        invoiceTitle?: number;
        /**
         * 1普通发票2增值税发票
         */
        invoiceType: number;
        /**
         * 下单时间
         */
        orderCreateTime: string;
        /**
         * 订单号
         */
        orderNo: string;
        /**
         * 收票人手机号
         */
        recMobphone?: string;
        /**
         * 收票人姓名
         */
        recName?: string;
    };
    /**
     * 订单参数
     */
    export type ShopOrderParamVo = {
        /**
         * 燃豆抵扣金额
         */
        deductionAmount?: number;
        /**
         * 商品总价格
         */
        goodsAmount?: number;
        /**
         * 购买件数
         */
        goodsNum?: number;
        /**
         * 燃豆是否已实名认证，0没有，1有
         */
        isPointAuthorization?: number;
        /**
         * 订单总价格
         */
        orderAmount?: number;
        /**
         * 可抵燃豆数量
         */
        pointsNum?: number;
        /**
         * 存在预售商品显示的预计发货时间
         */
        preGoodsMessage?: string;
        /**
         * 用户默认地址
         */
        shopAddressVo?: OriginShopAddressVo;
        /**
         * 满减信息
         */
        shopOrderFullReduceForAppVos?: ShopOrderFullReduceForAppRealVo;
        /**
         * 订单商品信息
         */
        shopOrderGoodsBaseVos?: ShopOrderGoodsBaseVo[];
        /**
         * 订单运费
         */
        shopShippingFeeVo?: ShopShippingFeeVo;
        /**
         * 弹框信息
         */
        warnMessage?: string;
    };
    /**
     * 订单支付表
     */
    export type ShopOrderPayVo = {
        /**
         * 支付宝支付签名-再支付用
         */
        alipaySign?: string;
        /**
         * 买家ID
         */
        buyerId?: string;
        /**
         * 创建时间
         */
        createTime?: string;
        /**
         * 第三方订单号
         */
        outTradeNo?: string;
        payId: number;
        /**
         * 支付单号
         */
        payNo?: string;
        /**
         * 0默认未支付1已支付(只有第三方支付接口通知到时才会更改此状态)
         */
        payState?: number;
        /**
         * 修改时间
         */
        updateTime?: string;
    };
    /**
     * 订单表
     */
    export type ShopOrderRefundInfoInDetailVo = {
        /**
         * 退款单id
         */
        refundId?: number;
        /**
         * 退款单号
         */
        refundNo?: string;
        /**
         * 退款时间
         */
        refundTime?: string;
    };
    /**
     * spu活动详情
     */
    export type ShopOrderSpuActivityDetailVo = {
        /**
         * 活动类型
         */
        activityKeyName?: string;
        /**
         * 活动
         */
        shopSpuActivityListVos?: ShopOrderSpuActivityVo[];
    };
    /**
     * 下单商品有的活动
     */
    export type ShopOrderSpuActivityVo = {
        /**
         * 活动总名称
         */
        activityName?: string;
        /**
         * 补充字段
         */
        remark?: string;
        /**
         * 活动类型 1燃豆抵扣 2满减 3限购 4预售 5包邮
         */
        type?: number;
    };
    /**
     * 订单表
     */
    export type ShopOrderVo = {
        /**
         * 退款后可使用的燃豆数量
         */
        canUsePointsNum?: number;
        /**
         * 取消订单原因
         */
        cancelMessage?: string;
        /**
         * 订单生成时间
         */
        createTime?: string;
        /**
         * 燃豆抵扣金额
         */
        deductionAmount?: number;
        /**
         * 延迟时间,默认为0
         */
        delayTime?: number;
        /**
         * 评价状态 0未评价，1已评价
         */
        evaluationState?: number;
        /**
         * 订单完成时间(确认收货时间)
         */
        finishedTime?: string;
        /**
         * 商品总价格
         */
        goodsAmount?: number;
        /**
         * 是否被删除 0未删除 1已删除
         */
        isDel?: number;
        /**
         * 买家电子邮箱
         */
        memberEmail?: string;
        /**
         * 买家id
         */
        memberId?: string;
        /**
         * 买家姓名
         */
        memberName?: string;
        /**
         * 订单总价格
         */
        orderAmount?: number;
        orderCommonId?: number;
        /**
         * 订单索引id
         */
        orderId: number;
        /**
         * 订单编号
         */
        orderNo?: string;
        /**
         * 订单超时时间-单位小时
         */
        orderOverTime?: number;
        /**
         * 订单状态：0(已取消)1(默认):未付款;2:已付款;3:已发货;4:已收货;
         */
        orderState?: number;
        /**
         * 订单类型(1商城订单 2燃豆订单)
         */
        orderType?: number;
        /**
         * 支付单号
         */
        payId?: number;
        /**
         * 支付(付款)时间
         */
        payTime?: string;
        /**
         * 支付方式
         */
        paymentId?: number;
        /**
         * 下单时燃豆价格
         */
        pointsPrice?: number;
        /**
         * 满包邮参数
         */
        postageParam?: string;
        /**
         * 满减金额
         */
        reduceFee?: number;
        /**
         * 满减参数
         */
        reduceParam?: string;
        /**
         * 退款金额
         */
        refundAmount?: number;
        /**
         * 退款状态:0是无退款,1退款中，2退款成功，3退款失败
         */
        refundState?: number;
        /**
         * 物流单号
         */
        shippingCode?: string;
        /**
         * 运费
         */
        shippingFee?: number;
        /**
         * 订单公共信息
         */
        shopOrderCommonVo?: ShopOrderCommonVo;
        /**
         * 订单商品信息
         */
        shopOrderGoodsVos?: ShopOrderGoodsVo[];
        /**
         * 订单支付信息
         */
        shopOrderPayVo?: ShopOrderPayVo;
        /**
         * 标记等级(发货优先级)
         */
        signLevel?: number;
        /**
         * 使用燃豆数量
         */
        usePointsNum?: number;
    };
    /**
     * 包邮活动赠品表
     */
    export type ShopPostageGiftVo = {
        id: number;
        /**
         * 包邮id
         */
        postageId?: number;
        /**
         * 赠品别名
         */
        skuAliasName?: string;
        /**
         * 商品id
         */
        skuId?: number;
    };
    /**
     * 退款申请参数
     */
    export type ShopRefundApplyForm = {
        /**
         * 申请原因
         */
        buyerMessage?: string;
        /**
         * 申请凭证图片列表
         */
        buyerMessageImageList?: string[];
        /**
         * 买家退货说明
         */
        buyerRefundInfo?: string;
        /**
         * 商品ID,全部退款是0
         */
        goodsId?: number;
        /**
         * 订单ID
         */
        orderId?: number;
        /**
         * 退款个数
         */
        refundNum?: number;
        /**
         * 申请类型:1为退款,2为退货退款 -- 商品id不为0时此字段不能为空
         */
        refundType?: number;
        /**
         * 货物状态:1为未收到货,2为已收到货
         */
        returnType?: number;
    };
    /**
     * 退款申请参数
     */
    export type ShopRefundApplyUpdateForm = {
        /**
         * 申请原因
         */
        buyerMessage?: string;
        /**
         * 申请凭证图片列表
         */
        buyerMessageImageList?: string[];
        /**
         * 买家退货说明
         */
        buyerRefundInfo?: string;
        /**
         * 退款单id
         */
        id?: number;
        /**
         * 订单id
         */
        orderId?: number;
        /**
         * 退款个数
         */
        refundNum?: number;
        /**
         * 货物状态:1为未收到货,2为已收到货
         */
        returnType?: number;
    };
    /**
     * 取消退货申请
     */
    export type ShopRefundCancelForm = {
        /**
         * 退款单id
         */
        id?: number;
        /**
         * 订单id
         */
        orderId?: number;
    };
    /**
     * 填写物流时的退款详情
     */
    export type ShopRefundDetailInExpressVo = {
        /**
         * 结束时间
         */
        endTime?: string;
        /**
         * 当前时间
         */
        now?: string;
        /**
         * 收货人详细地址
         */
        receiveAddress?: string;
        /**
         * 收货人姓名
         */
        receiveName?: string;
        /**
         * 收货人联系方式
         */
        receivePhone?: string;
    };
    /**
     * 退款表
     */
    export type ShopRefundDetailTypeVo = {
        /**
         * 退款详情跳转页面 1退款未审核 2退款审核通过-填写物流 3物流已发出-待审核
         */
        toPage: number;
    };
    /**
     * 退款表
     */
    export type ShopRefundGoodsForm = {
        /**
         * 联系电话
         */
        buyerPhone?: string;
        /**
         * 买家退货说明
         */
        buyerRefundInfo?: string;
        /**
         * 退款单id
         */
        id: number;
        /**
         * 物流单号
         */
        invoiceNo?: string;
    };
    /**
     * 查询退款商品退款价格返回值
     */
    export type ShopRefundGoodsPriceVo = {
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 购买数量
         */
        goodsNum?: number;
        /**
         * 退款燃豆数量
         */
        refundPointsNum: number;
        /**
         * 退款价格
         */
        refundPrice: number;
        /**
         * 商品规格名称
         */
        specValNames?: string[];
    };
    /**
     * 退款申请参数
     */
    export type ShopRefundPriceQueryForm = {
        /**
         * 商品ID
         */
        goodsId?: number;
        /**
         * 订单ID
         */
        orderId?: number;
        /**
         * 退款数量
         */
        refundNum?: number;
    };
    /**
     * 退款原因
     */
    export type ShopRefundReasonVo = {
        /**
         * 已收到货退款原因
         */
        hasReceivedRefundReason: string[];
        /**
         * 未收到货退款原因
         */
        noReceivedRefundReason: string[];
    };
    /**
     * 退款表
     */
    export type ShopRefundVo = {
        /**
         * 买家ID
         */
        buyerId?: string;
        /**
         * 申请原因（重新做表）
         */
        buyerMessage?: string;
        /**
         * 申请凭证图片，逗号隔开
         */
        buyerMessageImage?: string;
        /**
         * 买家会员名
         */
        buyerName?: string;
        /**
         * 买家联系电话
         */
        buyerPhone?: string;
        /**
         * 买家退货说明
         */
        buyerRefundInfo?: string;
        /**
         * 审核时间
         */
        checkTime?: string;
        /**
         * 佣金比例
         */
        commisRate?: number;
        /**
         * 添加时间
         */
        createTime?: string;
        /**
         * 收货延迟时间,默认为0
         */
        delayTime?: number;
        /**
         * 物流公司编号
         */
        expressId?: number;
        /**
         * 物流公司名称
         */
        expressName?: string;
        /**
         * 商品ID,全部退款是0
         */
        goodsId?: number;
        /**
         * 商品图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 商品数量
         */
        goodsNum?: number;
        /**
         * 物流状态:1为待发货,2为待收货,3为未收到,4为已收货,默认为1
         */
        goodsState?: number;
        /**
         * 记录ID
         */
        id: number;
        /**
         * 物流单号
         */
        invoiceNo?: string;
        /**
         * 审核通过后，发货截至天数（减审核时间算剩余）
         */
        limitDay?: number;
        /**
         * 订单商品ID,全部退款是0
         */
        orderGoodsId?: number;
        /**
         * 订单商品类型:1默认2团购商品3限时折扣商品4组合套装
         */
        orderGoodsType?: number;
        /**
         * 订单ID
         */
        orderId?: number;
        /**
         * 订单锁定类型:1为不用锁定,2为需要锁定,默认为1
         */
        orderLock?: number;
        /**
         * 订单编号
         */
        orderNo?: string;
        /**
         * 收货备注
         */
        receiveMessage?: string;
        /**
         * 收货时间,默认为0
         */
        receiveTime?: number;
        /**
         * 退款金额
         */
        refundAmount?: number;
        /**
         * 申请编号
         */
        refundNo?: string;
        /**
         * 退款状态:1退款中 2退款成功 3退款失败
         */
        refundOrderState?: number;
        /**
         * 退还燃豆数量
         */
        refundPointsNum?: number;
        /**
         * 申请状态:1待审核,2同意,3拒绝,默认为1
         */
        refundState?: number;
        /**
         * 申请类型:1为退款,2为退货退款,默认为1
         */
        refundType?: number;
        /**
         * 申请未通过原因
         */
        refuseMessage?: string;
        /**
         * 申请未通过原因备注
         */
        refuseMessageRemark?: string;
        /**
         * 退货类型:1为不用退货,2为需要退货,默认为1
         */
        returnType?: number;
        /**
         * 买家发货时间
         */
        shipTime?: string;
    };
    export type ShopSearchNameListResponse = {
        searchNames?: string;
        type?: number;
    };
    /**
     * 发送邮箱参数
     */
    export type ShopSendEmailForm = {
        /**
         * 发送邮箱地址
         */
        toEmail?: string;
    };
    export type ShopSerarchWordHotVo = {
        /**
         * 内容
         */
        content?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 是否有小火苗 1是 0否
         */
        isHot?: number;
        /**
         * 搜索次数
         */
        number?: number;
        /**
         * 1热词 2推荐
         */
        state?: number;
    };
    /**
     * 满减活动参数
     */
    export type ShopShippingFeeVo = {
        /**
         * 满包邮名称-前台用
         */
        postageName?: string;
        /**
         * 运费 为0时 包邮
         */
        shippingFee?: number;
    };
    /**
     * 增值说发票信息表
     */
    export type ShopSpecialInvoiceVo = {
        /**
         * 单位名称
         */
        companyName?: string;
        /**
         * 纳税人识别号
         */
        invoiceCode?: string;
        /**
         * 注册地址
         */
        regAddr?: string;
        /**
         * 银行帐户
         */
        regBaccount?: string;
        /**
         * 开户银行
         */
        regBname?: string;
        /**
         * 注册电话
         */
        regPhone?: string;
    };
    /**
     * spu活动详情
     */
    export type ShopSpuActivityDetailVo = {
        /**
         * 活动类型
         */
        activityKeyName?: string;
        /**
         * 活动
         */
        shopSpuActivityListVos?: ShopSpuActivityListVo[];
    };
    /**
     * spu活动详情
     */
    export type ShopSpuActivityListVo = {
        /**
         * 活动总名称
         */
        activityName?: string;
        /**
         * 活动类型 1燃豆抵扣 2满减 3限购 4预售 5包邮 6满包邮
         */
        type?: number;
    };
    /**
     * spu活动详情
     */
    export type ShopSpuActivityVo = {
        /**
         * 横幅地址
         */
        activityPicUrl?: string;
        /**
         * 折扣价
         */
        discountPrice?: OriginShopGoodsCommonShowPriceVo;
        /**
         * 活动结束时间
         */
        endTime?: string;
        /**
         * 当前时间
         */
        now?: string;
        /**
         * 活动信息
         */
        shopSpuActivityListVos?: ShopSpuActivityListVo[];
    };
    /**
     * 猜你喜欢内容
     */
    export type ShopUserLikeGoodsVo = {
        /**
         * 展示字样
         */
        content?: string;
        /**
         * 商品列表
         */
        shopGoodsCommonViewInAppVos?: ShopGoodsCommonViewInAppVo[];
    };
    /**
     * 查询燃豆记录
     */
    export type ShopUserPointsQueryForm = {
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 操作类型 1获得 2消费
         */
        type?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户操作燃豆记录表
     */
    export type ShopUserPointsVo = {
        /**
         * 操作时间
         */
        createTime?: string;
        id: number;
        /**
         * 消费描述
         */
        oprDes?: string;
        /**
         * 操作类型:1.首次登录;2.签到;3.正计时;4.倒计时;5.装备评价;6.退款返还抵扣燃豆;7.动态获得喂豆;8.赞助燃草;9.绑定手机号;10.补充资料;11.邀请好友;12.给他人喂豆;13.燃豆抵扣;
         */
        oprType?: number;
        /**
         * 操作燃豆的途径id （订单，工具等）
         */
        outId?: number;
        /**
         * 获得或消费燃豆的数量
         */
        pointsNum?: number;
        /**
         * 操作类型 1获得 2消费
         */
        type?: number;
        /**
         * 用户id
         */
        userId?: string;
        /**
         * vip用户加成燃豆个数
         */
        vipPointsNum?: number;
        /**
         * vip用户加成燃豆百分比
         */
        vipPointsPercentage?: string;
    };
    /**
     * 生成会员订单参数
     */
    export type ShopVipOrderCreateForm = {
        /**
         * 是否自动续费 0不是 1是
         */
        isAutoContinue?: number;
        /**
         * 订单金额
         */
        orderAmount?: number;
        /**
         * 支付方式id 苹果支付为3
         */
        paymentId?: number;
        /**
         * 苹果内购唯一交易号
         */
        transactionId?: string;
        /**
         * 下单人id-iphone支付用
         */
        userId?: string;
        /**
         * 会员卡类型 1月卡 2季卡 3年卡 4邀请卡
         */
        vipType?: number;
    };
    export type SingleStringVo = {
        /**
         * 返回结果
         */
        data?: string;
    };
    /**
     * 目标展示数据
     */
    export type TargetData = {
        /**
         * 日
         */
        day?: number;
        /**
         * 日志后记集合
         */
        efficiencyEpilogueVos?: EfficiencyEpilogueVo[];
        /**
         * 计划集合
         */
        efficiencyGoalPlanVos?: EfficiencyGoalPlanVo[];
        /**
         * 日志集合
         */
        efficiencyTargetJournalVos?: EfficiencyTargetJournalVo[];
        /**
         * 目标集合
         */
        efficiencyTargetVos?: EfficiencyTargetVo[];
        /**
         * 专注时长
         */
        focusTime?: number;
        /**
         * 日志数量
         */
        journalNum?: number;
        /**
         * 月份
         */
        month?: number;
        /**
         * 月级数据
         */
        targetData?: TargetData[];
        /**
         * 年份
         */
        year?: number;
    };
    /**
     * 举报动态/评论
     */
    export type TipOffCreateForm = {
        /**
         * 举报事件ID 动态/评论 id
         */
        eventid: string;
        /**
         * 举报原因
         */
        tipoffreason: number;
        /**
         * 举报类型 1动态 2评论 3日志 4日志评论 5日志项目(目标)
         */
        type: number;
        /**
         * 举报人Id
         */
        userid?: string;
    };
    /**
     * App用户删除话题
     */
    export type TopicsAppUserDeleteForm = {
        /**
         * 话题ID
         */
        id: string;
        /**
         * 操作平台 1:APP;2:后台
         */
        source?: number;
        /**
         * 操作用户ID
         */
        userId?: string;
    };
    export type TopicsBeanVo = {
        /**
         * 围观人数
         */
        browseNumber?: number;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 话题创建者头像挂件
         */
        dressUpHeadID?: number;
        /**
         * 包含动态的数量
         */
        dynamicNumber?: number;
        /**
         * 头图
         */
        headimage?: string;
        /**
         * 话题主键
         */
        id?: string;
        /**
         * 简介
         */
        introduce?: string;
        /**
         * 当前用户是否已经关注此话题
         */
        isFollow?: boolean;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 热门状态(0:非热门;1:热门) 默认非热门
         */
        ishot?: number;
        /**
         * 参与人数
         */
        joinUserNumber?: number;
        /**
         * 会员类型 0:非会员;1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 拒绝原因名称
         */
        rejectReasonContent?: string;
        /**
         * 拒绝原因ID
         */
        rejectReasonId?: string;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 话题评分 Manager创建话题时使用
         */
        score?: number;
        /**
         * 审核状态(0:审核中;1:已通过;2:未通过) 默认审核中
         */
        state?: number;
        /**
         * 专注状态 0否1是
         */
        timeState?: number;
        /**
         * 标题
         */
        title?: string;
        /**
         * 话题创建者头像
         */
        topicCreateHead?: string;
        /**
         * 话题创建者id
         */
        topicCreateId?: string;
        /**
         * 话题创建者名称
         */
        topicCreateName?: string;
        /**
         * 话题列表内容展示文本
         */
        topicListContentCreateDate?: string;
        /**
         * 话题列表内容展示首图
         */
        topicListContentImage?: string;
        /**
         * 话题列表内容展示文本
         */
        topicListContentText?: string;
        /**
         * 话题列表内容展示文本
         */
        topicListContentUserName?: string;
        /**
         * 更新时间
         */
        updateDate?: string;
        /**
         * 权重
         */
        weight?: number;
    };
    /**
     * 新增话题
     */
    export type TopicsCreateForm = {
        /**
         * 话题发起人ID List
         */
        createUserIdList?: string[];
        /**
         * 头图
         */
        headimage?: string;
        /**
         * 简介
         */
        introduce?: string;
        /**
         * 话题评分 Manager创建话题时使用
         */
        score?: number;
        /**
         * 操作平台 1:APP;2:后台
         */
        source?: number;
        /**
         * 标题
         */
        title: string;
        /**
         * 话题发起人ID
         */
        userId?: string;
    };
    /**
     * 话题 关注/取消关注
     */
    export type TopicsFollowsForm = {
        /**
         * 话题Id
         */
        eventId: string;
        /**
         * 操作类型(1关注 2取消关注)
         */
        type: number;
        /**
         * 关注人Id
         */
        userId?: string;
    };
    export type TopicsListBeanVo = {
        /**
         * 围观人数
         */
        browseNumber?: number;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 动态创建者头像挂件
         */
        dressUpHeadID?: number;
        /**
         * 包含动态的数量
         */
        dynamicNumber?: number;
        /**
         * 头图
         */
        headimage?: string;
        /**
         * 话题主键
         */
        id?: string;
        /**
         * 是否是新话题(0:否;1:是)
         */
        isNew?: number;
        /**
         * 置顶状态(0:非置顶;1:置顶) 默认非置顶
         */
        isTop?: number;
        /**
         * 热门状态(0:非热门;1:热门) 默认非热门
         */
        ishot?: number;
        /**
         * 参与人数
         */
        joinUserNumber?: number;
        /**
         * 拒绝原因名称
         */
        rejectReasonContent?: string;
        /**
         * 拒绝原因ID
         */
        rejectReasonId?: string;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 话题评分 Manager创建话题时使用
         */
        score?: number;
        /**
         * 审核状态(0:审核中;1:已通过;2:未通过) 默认审核中
         */
        state?: number;
        /**
         * 标题
         */
        title?: string;
        /**
         * 话题创建者头像
         */
        topicCreateHead?: string;
        /**
         * 话题创建者id
         */
        topicCreateId?: string;
        /**
         * 话题创建者名称
         */
        topicCreateName?: string;
        /**
         * 话题列表内容展示文本 时间
         */
        topicListContentCreateDate?: string;
        /**
         * 话题列表内容展示首图
         */
        topicListContentImage?: string;
        /**
         * 话题列表内容展示文本
         */
        topicListContentText?: string;
        /**
         * 话题列表内容展示文本
         */
        topicListContentUserName?: string;
    };
    /**
     * 话题查询
     */
    export type TopicsQueryForm = {
        /**
         * 开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        fromDate?: string;
        /**
         * 查询的信息是否包含已经删除的 true包含 false不包含
         */
        isContainDelInfo?: boolean;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 滚动id
         */
        scrollId?: string;
        /**
         * 搜索的内容发起人名称
         */
        searchNameValue?: string;
        /**
         * 查询类型(1:热门;2:关注;3:搜索;4:最新;5:我参与的;6:所有待审核;7:所有未通过;8:某用户发起的话题(已通过);9:已删除的话题(默认最新)默认最新;10:已删除的话题(最热);11:搜索多个;12:某用户发起的(传对应的话题状态 包含审核通过，拒绝，通过的);13:按照评分排序;
         */
        searchType: number;
        /**
         * 搜索的内容Title
         */
        searchValue?: string;
        /**
         * 结束时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        toDate?: string;
        /**
         * 话题状态 0:审核中;1:已审核;2:未通过
         */
        topicState?: number;
        /**
         * 话题ID
         */
        topicsId?: string;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    /**
     * 话题更新
     */
    export type TopicsUpdateForm = {
        /**
         * 头图
         */
        headimage?: string;
        /**
         * 话题主键
         */
        id: string;
        /**
         * 简介
         */
        introduce?: string;
        /**
         * 话题评分 Manager创建话题时使用
         */
        score?: number;
        /**
         * 操作平台 1:APP;2:后台
         */
        source?: number;
        /**
         * 标题
         */
        title?: string;
        /**
         * 话题发起人ID/修改评分时候操作用户的id
         */
        userId?: string;
    };
    /**
     * 用户评分
     */
    export type UserAppScoreForm = {
        /**
         * 平台系统 1：IOS 2:Android
         */
        equipmentSystem?: number;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    /**
     * 绑定账号参数
     */
    export type UserBindAccountForm = {
        /**
         * 用户ID
         */
        id?: string;
        /**
         * 第一已绑定手机号机会是否已经使用过 0否 1是
         */
        isbindphone?: number;
        /**
         * 短信验证码
         */
        msgCode?: string;
        /**
         * 账户号(1.QQ;2.wx;3.wb;分别对应的账户号)
         */
        phone?: string;
        /**
         * 用户创建类型:1.QQ;2.wx;3.wb;7.Apple
         */
        type?: number;
    };
    /**
     * 更换绑定手机号
     */
    export type UserChangePhoneForm = {
        /**
         * 用户ID
         */
        id?: string;
        /**
         * 短信验证码
         */
        msgCode?: string;
        /**
         * 账户号(1.手机号；4.QQ;5.wx;6.wb分别对应的账户号)
         */
        phone?: string;
    };
    /**
     * 新增用户form
     */
    export type UserCreateForm = {
        /**
         * 活动ID
         */
        activityId?: number;
        /**
         * 头像
         */
        head?: string;
        /**
         * 邀请人的邀请码
         */
        inviteCode?: string;
        /**
         * 请求手机类型:1.oppo;2.vivo;3.小米;4.华为 默认0 未知机型 统一极光推送
         */
        mobileType?: number;
        /**
         * 短信验证码手机号注册时必须传
         */
        msgCode?: string;
        /**
         * 账户名称 三方账户号分别对应的账户名称 手机号默认还是手机号)
         */
        name?: string;
        /**
         * 第三方账户号
         */
        openId?: string;
        /**
         * 手机号(现注册必填项)
         */
        phone: string;
        /**
         * 推送registration_id
         */
        pushRegistrationId?: string;
        /**
         * type=5.通过推荐手机号注册时必填 推荐人ID
         */
        recommenderId?: string;
        /**
         * 用户类型(用户创建注册类型（授权平台）:1.QQ+手机号;2.wx+手机号;3.wb+手机号;4.phone手机号(普通邀请/活动邀请都属于此类型);5.通过推荐手机号注册(此业务已经删除);7.Apple;) 填写对应的数字
         */
        type: number;
    };
    /**
     * 用户自定义tabBar模块表
     */
    export type UserCustomTabbarQueryForm = {
        /**
         * 用户自定义tabbar串,逗号分隔
         */
        customTabbar?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: number;
        /**
         * 个性装扮图标父parentId
         */
        iconParentId?: number;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 主键ID列表
         */
        idList?: number[];
        /**
         * 是否自定义tabbar,0:否, 1:是
         */
        isCustom?: number;
        /**
         * 自定义tabbar名称
         */
        name?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户自定义tabBar模块表
     */
    export type UserCustomTabbarVo = {
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 用户自定义tabbar串,逗号分隔
         */
        customTabbar?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: number;
        /**
         * 个性装扮图标父parentId
         */
        iconParentId?: number;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 是否自定义tabbar,0:否, 1:是
         */
        isCustom?: number;
        /**
         * tabbar名称
         */
        name?: string;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 用户选择的tabbar集合list
         */
        tabbarList?: number[];
        /**
         * 修改时间
         */
        updateDate?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    export type UserDressupExchangeForm = {
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 去除自定义专注场景 , 1: 去除自定义场景
         */
        isCustom?: number;
        /**
         * 模块类型:1.图标;2.表情包;3.白噪音;4.头像挂件;5.专注场景;6:体验卡;7.小组件
         */
        modelType: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    export type UserDressupIconDetailVo = {
        /**
         * 未选中模块tabbar的url
         */
        commonUrl?: string;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 是否是默认(0:否,1:是)
         */
        isDefault?: number;
        /**
         * 模块类型:1.图标;2.表情包;3.白噪音;4.头像挂件;5.专注场景;6.体验卡;7.小组件
         */
        modelType?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 所属模块ID
         */
        parentId?: number;
        /**
         * 选中模块tabbar的url
         */
        tabbarUrl?: string;
    };
    export type UserDressupIconVo = {
        /**
         * 图标详情背景url
         */
        iconBackgroundUrl?: string;
        /**
         * 图标正常字体颜色
         */
        iconCommonColor?: string;
        /**
         * 选中图标字体颜色
         */
        iconSelectColor?: string;
        /**
         * 图标详情集合
         */
        userDressupIconDetailVoList?: UserDressupIconDetailVo[];
    };
    export type UserDressupVo = {
        /**
         * 某用户在该活动下邀请了多少人
         */
        activityIntiveUserNumber?: number;
        /**
         * 未选中模块tabbar的url
         */
        commonUrl?: string;
        /**
         * 表情包详情压缩url/专注场景压缩url
         */
        compressedUrl?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 体验卡的有效天数
         */
        dayNumber?: number;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 表情包对应app本地数据的唯一标识,不能修改
         */
        emoticonUnique?: string;
        /**
         * 表情包详情页图片url
         */
        emoticonUrl?: string;
        /**
         * 白噪音/专注场景文件大小,单位:M
         */
        fileSize?: number;
        /**
         * 头像挂件url
         */
        headportraitUrl?: string;
        /**
         * 图标详情背景url
         */
        iconBackgroundUrl?: string;
        /**
         * 图标正常字体颜色
         */
        iconCommonColor?: string;
        /**
         * 图标选中字体颜色
         */
        iconSelectColor?: string;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 邀请解锁结束时间
         */
        intiveEnddate?: string;
        /**
         * 邀请解锁开始时间
         */
        intiveStartdate?: string;
        /**
         * 是否自定义 0:否 1:是
         */
        isCustom?: number;
        /**
         * 是否自定义专注场景(0:否;1:是)
         */
        isCustomFocusScene?: number;
        /**
         * 是否默认(0:否 ,1:是)
         */
        isDefault?: number;
        /**
         * 是否免费 0:否, 1:是
         */
        isFree?: number;
        /**
         * 是否邀请解锁
         */
        isInviteunlock?: number;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 是否播放白噪音,默认是0:播放 1:不播放
         */
        isPlayWhitenoise?: number;
        /**
         * 是否燃豆兑换/VIP免费试用,0 否 , 1 是
         */
        isPointexchange?: number;
        /**
         * 是否已拥有(0:未拥有 1:已拥有)
         */
        isPossess?: number;
        /**
         * 是否重复享有(0:否;1:是)
         */
        isRepeat?: number;
        /**
         * 是否正在使用(0:未使用 1:正在使用中)
         */
        isUse?: number;
        /**
         * Vip专享等级,0:普通用户,1:V1青铜,2:V2秘银,3:V3黄金,4:V4铂金,5:V5钻石,6:V6星辰
         */
        isVip?: number;
        /**
         * VIP燃豆兑换已拥有 0:否 1:是
         */
        isVipExchangePosses?: number;
        /**
         * 最后一次的专注场景更新时间戳
         */
        lastModifyTime?: number;
        /**
         * 会员等级名称
         */
        memberLevelName?: string;
        /**
         * 小组件详情url
         */
        miniComponentUrl?: string;
        /**
         * 模块类型:1.图标;2.表情包;3.白噪音;4.头像挂件;5.专注场景;6.体验卡;7.小组件
         */
        modelType?: number;
        /**
         * 白噪音url
         */
        musicUrl?: string;
        /**
         * 名称
         */
        name?: string;
        /**
         * 所属模块ID
         */
        parentId?: number;
        /**
         * 所需燃豆的个数
         */
        pointNumber?: number;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 选中模块tabbar的url
         */
        tabbarUrl?: string;
        /**
         * 更新时间
         */
        updateDate?: string;
        /**
         * 个性装扮列表图片地址url
         */
        url?: string;
        /**
         * 所需邀请的人的个数
         */
        userNumber?: number;
        /**
         * Vip专享/VIP免费使用
         */
        vipExclusiveFree?: string;
        /**
         * 白噪音背景图颜色
         */
        whitenoiseColor?: string;
    };
    /**
     * 用户个性装扮 表情包已拥有记录表
     */
    export type UserEmoticonQueryForm = {
        /**
         * 获取类型 1: 燃豆兑换, 2: 邀请
         */
        gainType?: number;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 主键ID列表
         */
        idList?: number[];
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 装扮模块主键ID
         */
        subId?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户过期提醒
     */
    export type UserExpireTipBeanVo = {
        id?: string;
        /**
         * 移动版本(1:iOS;2:Android;)
         */
        systemType?: number;
        /**
         * 数据类型type(1:5日后到期;2:明日到期;3:到期后第一天;4:到期后第二天;(暂时不要))
         */
        type?: number;
        /**
         * 用户ID
         */
        userId?: string;
    };
    /**
     * 新增反馈
     */
    export type UserFeedbackCreateForm = {
        /**
         * app版本 1.1.2
         */
        appVersion?: string;
        /**
         * 内容
         */
        content?: string;
        /**
         * 手机型号 MI 9/iPhone 6 Plus
         */
        deviceVersion?: string;
        /**
         * 设备平台
         */
        equipmentPlatform?: number;
        /**
         * 图片
         */
        imageslist?: string[];
        /**
         * 设备平台 android/iOS
         */
        mobileSystemName?: string;
        /**
         * 手机型号
         */
        phoneModel?: string;
        /**
         * 用户id
         */
        userId?: string;
        /**
         * 微信号或者邮箱
         */
        wxEmail?: string;
    };
    /**
     * 查询反馈
     */
    export type UserFeedbackQueryForm = {
        /**
         * app版本 1.1.2
         */
        appVersion?: string;
        /**
         * 内容
         */
        content?: string;
        /**
         * 手机型号 MI 9/iPhone 6 Plus
         */
        deviceVersion?: string;
        /**
         * 设备平台 android/iOS
         */
        mobileSystemName?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 反馈状态：0.未处理; 1.紧急; 2.计划; 3.忽略
         */
        state?: number;
        /**
         * 用户Id
         */
        userId?: string;
        /**
         * 用户名
         */
        userName?: string;
    };
    /**
     * 修改反馈解决状态
     */
    export type UserFeedbackSloveForm = {
        /**
         * 反馈表主键
         */
        id: number;
        /**
         * 1.未解决; 2.已解决;
         */
        isSlove?: number;
        /**
         * 用户ID
         */
        userId?: string;
    };
    export type UserFeedbackVo = {
        /**
         * app版本
         */
        appVersion?: string;
        /**
         * 评论的条数
         */
        commentNumber?: number;
        /**
         * 内容
         */
        content?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 处理意见
         */
        dealContent?: string;
        /**
         * 处理状态：0未回复 1已回复
         */
        dealState?: number;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 手机型号
         */
        deviceVersion?: string;
        /**
         * 反馈表主键
         */
        id: number;
        /**
         * 图片
         */
        image?: string[];
        /**
         * 是否包含未读消息 0不包含 1包含
         */
        includeUnread?: number;
        /**
         * 是否是管理员回复：0否 1是
         */
        isAdmin?: number;
        /**
         * 解决状态：0.未设置;1.未解决; 2.已解决; 默认0
         */
        isSlove?: number;
        /**
         * 设备平台
         */
        mobileSystemName?: string;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 反馈状态：0.未处理; 1.紧急; 2.计划; 3.忽略
         */
        state?: number;
        /**
         * 更新时间
         */
        updateDate?: string;
        /**
         * 用户id
         */
        userId?: string;
        /**
         * 用户名
         */
        userName?: string;
        /**
         * 微信号或者邮箱
         */
        wxEmail?: string;
    };
    /**
     * 用户个性装扮 专注场景已拥有记录表
     */
    export type UserFocussceneCreateForm = {
        /**
         * 获取类型 1: 燃豆兑换, 2: 邀请 ,3: 自定义场景
         */
        gainType?: number;
        /**
         * 自定义专注场景图,0: 竖屏 ,1: 横屏, 默认是 0:竖屏
         */
        isScreen?: number;
        /**
         * 装扮模块主键ID
         */
        subId?: number;
        /**
         * 自定义专注场景url
         */
        url?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户个性装扮 专注场景已拥有记录表
     */
    export type UserFocussceneVo = {
        /**
         * 获取类型 1: 燃豆兑换, 2: 邀请,3:自定义场景
         */
        gainType?: number;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 自定义专注场景图,0: 竖屏 ,1: 横屏, 默认是 0:竖屏
         */
        isScreen?: number;
        /**
         * 装扮模块主键ID
         */
        subId?: number;
        /**
         * 自定义用户场景url
         */
        url?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户关注信息
     */
    export type UserFollowInfoBeanVo = {
        /**
         * 关注的时间
         */
        createDate?: string;
        delFlag?: string;
        /**
         * 关注类型(1:A关B 2:B关A(A没关B) 3:互相关注)
         */
        flowType?: number;
        id?: string;
        /**
         * 用户ID A
         */
        userIdA?: string;
        /**
         * 用户ID B
         */
        userIdB?: string;
    };
    /**
     * 用户关注记录信息
     */
    export type UserFollowRecordBeanVo = {
        /**
         * 关注的时间
         */
        createDate?: string;
        delFlag?: string;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 关注类型(1:A关B 2:B关A(A没关B) 3:互相关注)
         */
        flowType?: number;
        /**
         * 用户IDB 头像
         */
        head?: string;
        id?: string;
        /**
         * 用户IDB 名称
         */
        name?: string;
        /**
         * 用户ID A
         */
        userIdA?: string;
        /**
         * 用户ID B
         */
        userIdB?: string;
    };
    /**
     * 用户 关注/取消关注
     */
    export type UserFollowsForm = {
        /**
         * 被操作用户id
         */
        eventId: string;
        /**
         * 操作类型(1关注/屏蔽 2取消关注/取消屏蔽)
         */
        type: number;
        /**
         * 操作人Id
         */
        userId?: string;
    };
    /**
     * 忘记密码
     */
    export type UserForgetPasswordForm = {
        /**
         * 密码
         */
        password?: string;
        /**
         * 手机号
         */
        phone?: string;
    };
    export type UserInfoBean = {
        addressCode?: string;
        addressIsPublic?: number;
        addressName?: string;
        autograph?: string;
        /**
         * 生日
         */
        brithday?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        delFlag?: string;
        dressUpHeadID?: number;
        /**
         * 用户计时总时长 格式化之后的时间
         */
        durationNum?: string;
        /**
         * 用户计时总时长 格式化之前的秒数
         */
        durationNumBoforeFormats?: number;
        /**
         * 成长值
         */
        growthValue?: number;
        head?: string;
        id?: string;
        inviteCode?: string;
        invoice?: number;
        /**
         * 是否是受限用户 0 否 1是
         */
        isLimit?: number;
        isMember?: number;
        isScoreAppAndroid?: number;
        isScoreAppIos?: number;
        isUpdateName?: number;
        isbindphone?: number;
        isinformation?: number;
        /**
         * 最后登录时间
         */
        lastLoginDate?: string;
        /**
         * 最后拉取时间
         */
        lastPullTimeInfo?: LastPullTimeInfo;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 学习阶段名称
         */
        learnstageName?: string;
        level?: number;
        /**
         * 所属持有会员卡类型: 0:非会员;1:月卡;2:季卡;3:年卡;4:月续;5:季续;6:年续;7.体验卡(邀请获得是N天的,具体关联活动ID);8.高级vip赠体验卡;
         */
        memberCardType?: number;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        memberType?: number;
        /**
         * 请求客户端机型:iPhone 8 Plus,iOS 13.3.1
         */
        mobileSystem?: string;
        /**
         * 手机系统名称：iOS/android
         */
        mobileSystemName?: string;
        /**
         * 请求手机类型:1.oppo;2.vivo;3.小米;4.华为 默认0 未知机型 统一极光推送
         */
        mobileType?: number;
        name?: string;
        occupation?: number;
        occupationColor?: string;
        occupationName?: string;
        password?: string;
        phone?: string;
        /**
         * 燃豆个数
         */
        pointNumber?: number;
        /**
         * 燃值
         */
        praiseNumber?: number;
        /**
         * 推送registration_id
         */
        pushRegistrationId?: string;
        realName?: number;
        recommenderId?: string;
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        remarks?: string;
        sex?: string;
        state?: number;
        /**
         * 更新时间
         */
        updateDate?: string;
    };
    /**
     * 用户动态补充信息
     */
    export type UserInfoDynamicVo = {
        /**
         * 所在城市编码
         */
        addressCode?: string;
        /**
         * 所在城市是否公开 1是 0否 默认0
         */
        addressIsPublic?: number;
        /**
         * 所在城市名称
         */
        addressName?: string;
        /**
         * 个性签名
         */
        autograph?: string;
        /**
         * 粉丝个数
         */
        beUserFollowNumber?: number;
        createDate?: string;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 用户计时总时长
         */
        durationNum?: string;
        /**
         * 关注用户个数
         */
        followUserNumber?: number;
        /**
         * 头像
         */
        head?: string;
        /**
         * 主键
         */
        id: string;
        /**
         * 资质状态 0.无; 1.审核中; 2.审核通过; 3.审核拒绝
         */
        invoice?: number;
        /**
         * 该用户的是否专注状态 0否 1是
         */
        isAbsorbedState?: number;
        /**
         * 是否是受限用户 0 否 1是
         */
        isLimit?: number;
        /**
         * 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 用户今天是否已经签到
         */
        isSignin?: boolean;
        /**
         * 第一已绑定手机号机会是否已使用 0否 1是
         */
        isbindphone?: number;
        lastLoginDate?: string;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 学习阶段名称
         */
        learnstageName?: string;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        /**
         * 会员卡类型 0:非会员(非会员或者成长值没有达到等级);1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 最后登录设备
         */
        mobileSystem?: string;
        /**
         * 名称
         */
        name?: string;
        /**
         * 职业id
         */
        occupation?: number;
        /**
         * 职业颜色
         */
        occupationColor?: string;
        /**
         * 职业名称
         */
        occupationName?: string;
        /**
         * 用户积分个数
         */
        pointNumber?: number;
        /**
         * 燃值
         */
        praiseNumber?: number;
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        /**
         * 注册获取燃豆个数
         */
        registerGetPointNumber?: number;
        /**
         * 性别
         */
        sex?: string;
        /**
         * 户所绑定的三方账户信息 用户类型授权平台（1.qq/2.wechat/3.weibo ）
         */
        thirdPartyTypeList?: number[];
        /**
         * 专注状态 0否1是
         */
        timeState?: number;
        /**
         * 登录用户对该用户的关注信息
         */
        userFollowInfo?: UserFollowInfoBeanVo;
    };
    /**
     * 用户基本补充信息
     */
    export type UserInfoVo = {
        /**
         * 活动邀请用户界面
         */
        activityInvitationUserPageCircleH5?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 默认习惯
         */
        defaultHabit?: MqSysDictVo;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 动态喂豆字典
         */
        dynamicFeedPointNumber?: Value[];
        /**
         * 设备平台
         */
        equipmentPlatform?: Value[];
        /**
         * 最后拉取关注话题时间
         */
        followTopicLastPullTime?: string;
        /**
         * 单个获取燃豆个数
         */
        getPointNumber?: Value[];
        /**
         * 热门城市
         */
        hotCity?: Value[];
        /**
         * 最后拉取热门话题时间
         */
        hotTopicLastPullTime?: string;
        /**
         * 主键
         */
        id: string;
        /**
         * 邀请用户界面
         */
        invitationUserPageCircleH5?: string;
        /**
         * 邀请用户规则界面
         */
        invitationUserRulesCircleH5?: string;
        /**
         * 邀请人邀请码
         */
        inviterCode?: string;
        /**
         * 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * isScoreAppAndroid  第一次评分安卓机会是否已使用 0否 1是
         */
        isScoreAppAndroid?: number;
        /**
         * isScoreAppIos  第一次评分IOS机会是否已使用 0否 1是
         */
        isScoreAppIos?: number;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 学习阶段名称
         */
        learnstageName?: string;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        /**
         * 会员卡类型 0:非会员(非会员或者成长值没有达到等级);1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 燃豆记录图标
         */
        pointIcon?: MqSysDictVo[];
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        /**
         * 性别
         */
        sex?: MqSysDictVo[];
        /**
         * 签到关键天数
         */
        signDay?: MqSysDictVo[];
        /**
         * 赞助我们
         */
        supportWe?: MqSysDictVo[];
        /**
         * 专注状态 0否1是
         */
        timeState?: number;
        /**
         * 举报理由
         */
        tipOffReason?: Value[];
        /**
         * 聊天举报理由
         */
        tipOffReasonIM?: Value[];
        /**
         * 用户操作获取燃豆数量(注册,登录,完善用户资料,邀请好友,评价商品,兑换皮肤，买装备)
         */
        userOperaGetPointNumber?: MqSysDictVo[];
    };
    /**
     * 用户邀请所需信息
     */
    export type UserIntiveInfoVo = {
        /**
         * 个性签名
         */
        autograph?: string;
        /**
         * 活动文案
         */
        content?: string;
        /**
         * 头像
         */
        head?: string;
        /**
         * 主键
         */
        id: string;
        /**
         * 注册邀请人获取燃豆个数
         */
        intiveRegisterGetPointNumber?: number;
        /**
         * 邀请码
         */
        inviteCode?: string;
        /**
         * 名称
         */
        name?: string;
        /**
         * 注册新用户获取燃豆个数
         */
        registerGetPointNumber?: number;
        /**
         * 性别
         */
        sex?: string;
        /**
         * 活动图片
         */
        url?: string;
    };
    /**
     * 用户资质审核
     */
    export type UserInvoiceCreateForm = {
        /**
         * 开户行名称
         */
        bankName?: string;
        /**
         * 开户行账号
         */
        bankNumber?: string;
        /**
         * 单位名称
         */
        companyName?: string;
        /**
         * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 注册地址
         */
        registAddress?: string;
        /**
         * 注册电话
         */
        registPhone?: string;
        /**
         * 描述
         */
        remarks?: string;
        /**
         * 资质状态 0.无; 1.审核中; 2.审核通过
         */
        state?: number;
        /**
         * 纳税人识别码
         */
        taxpayerId?: string;
        /**
         * 更新时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        updateDate?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 删除增票资质
     */
    export type UserInvoiceDeleteForm = {
        /**
         * 增票资质主键id
         */
        invoiceId?: number;
    };
    /**
     * 修改资质审核
     */
    export type UserInvoiceUpdateForm = {
        /**
         * 开户行名称
         */
        bankName?: string;
        /**
         * 开户行账号
         */
        bankNumber?: string;
        /**
         * 单位名称
         */
        companyName?: string;
        /**
         * 创建时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 增票资质主键ID
         */
        id: number;
        /**
         * 注册地址
         */
        registAddress?: string;
        /**
         * 注册电话
         */
        registPhone?: string;
        /**
         * 描述
         */
        remarks?: string;
        /**
         * 资质状态 0.无; 1.审核中; 2.审核通过
         */
        state?: number;
        /**
         * 纳税人识别码
         */
        taxpayerId?: string;
        /**
         * 更新时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        updateDate?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    export type UserInvoiceVo = {
        /**
         * 开户行名称
         */
        bankName?: string;
        /**
         * 开户行账号
         */
        bankNumber?: string;
        /**
         * 单位名称
         */
        companyName?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 增票资质主键ID
         */
        id: number;
        /**
         * 注册地址
         */
        registAddress?: string;
        /**
         * 注册电话
         */
        registPhone?: string;
        /**
         * 资质状态 0.无; 1.审核中; 2.审核通过
         */
        state?: number;
        /**
         * 纳税人识别码
         */
        taxpayerId?: string;
        /**
         * 用户id
         */
        userId?: string;
    };
    export type UserLearnstageVo = {
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 主键
         */
        id: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 排序
         */
        sort?: number;
        /**
         * 状态
         */
        state?: number;
        /**
         * 更新时间
         */
        updateDate?: string;
    };
    /**
     * 用户登录form
     */
    export type UserLoginForm = {
        /**
         * 设备id
         */
        deviceId?: string;
        /**
         * 设备名称
         */
        deviceName?: string;
        /**
         * 用户登录类型:1.QQ;2.wx;3.wb;4.本机手机号；5.othersPhone+pwd；6.othersPhone+msg;7.Apple
         */
        loginType?: number;
        /**
         * 请求手机类型:1.oppo;2.vivo;3.小米;4.华为 默认0 未知机型 统一极光推送
         */
        mobileType?: number;
        /**
         * 手机号登录时必须传
         */
        msgCode?: string;
        /**
         * 其他手机号登录时必须传
         */
        password?: string;
        /**
         * 账户号(用户创建注册类型（授权平台）:1.QQ;2.wx;3.wb;4.phone手机号;7.Apple 手机号默认还是手机号，第三方分别对应的账户Id)
         */
        phone: string;
        /**
         * 推送registration_id
         */
        pushRegistrationId?: string;
    };
    export type UserLoginReturnVo = {
        /**
         * 所在城市编码
         */
        addressCode?: string;
        /**
         * 所在城市是否公开 1是 0否 默认0
         */
        addressIsPublic?: number;
        /**
         * 所在城市名称
         */
        addressName?: string;
        /**
         * 个性签名
         */
        autograph?: string;
        /**
         * 生日,格式为:yyyy-MM-dd
         */
        brithday?: string;
        createDate?: string;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 邀请的活动信息
         */
        dressupVo?: UserDressupVo;
        /**
         * 头像
         */
        head?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 邀请人邀请码
         */
        inviterCode?: string;
        /**
         * 邀请人头像
         */
        inviterHeader?: string;
        /**
         * 邀请人名称
         */
        inviterName?: string;
        /**
         * 增值税发票 0.无; 1.审核中; 2.审核通过
         */
        invoice?: number;
        /**
         * 用户注册的时候使用 如果走的是绑定并登录的流程则App不做头像移动操作
         */
        isRegister?: boolean;
        /**
         * isScoreAppAndroid  第一次评分安卓机会是否已使用 0否 1是
         */
        isScoreAppAndroid?: number;
        /**
         * isScoreAppIos  第一次评分IOS机会是否已使用 0否 1是
         */
        isScoreAppIos?: number;
        /**
         * 是否有密码 0无 1有
         */
        isSettingPassword?: number;
        /**
         * 第一次修改用户名机会是否已使用 0否 1是
         */
        isUpdateName?: number;
        /**
         * 第一已绑定手机号机会是否已经使用过 0否 1是
         */
        isbindphone?: number;
        /**
         * 第一已补充资料机会是否已经使用过 0否 1是
         */
        isinformation?: number;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 学习阶段名称
         */
        learnstageName?: string;
        /**
         * 用户等级 默认小白用户1
         */
        level?: number;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        /**
         * 会员类型 0:非会员;1:月卡;2:季卡;3:年卡;4:月续;5:季续;6:年续;7.体验卡(邀请获得是N天的,具体关联活动ID);8.高级vip赠体验卡;
         */
        memberType?: number;
        /**
         * 请求手机类型:1.oppo;2.vivo;3.小米;4.华为 默认0 未知机型 统一极光推送
         */
        mobileType?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 手机号
         */
        phone?: string;
        /**
         * 燃豆个数
         */
        pointNumber?: number;
        /**
         * 燃值
         */
        praiseNumber?: number;
        /**
         * 实名认证 0未实名 1已实名
         */
        realName?: number;
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        /**
         * 性别
         */
        sex?: string;
        /**
         * 展示绑定信息 0:以前注册;1:绑定邀请人;2:邀请助力;
         */
        showBindInfo?: number;
        /**
         * 用户状态 默认正常1
         */
        state?: number;
        /**
         * token
         */
        token?: string;
    };
    /**
     * 用户退出登录
     */
    export type UserLogoutForm = {
        /**
         * 设备id
         */
        deviceId?: string;
        /**
         * 用户ID
         */
        id?: string;
    };
    /**
     * 用户会员到期查询
     */
    export type UserMemberExpireTipQueryForm = {
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 移动版本(1:iOS;2:Android;)
         */
        systemType: number;
        /**
         * 用户Id
         */
        userId?: string;
    };
    export type UserMemberOngoingActivitiesVo = {
        /**
         * 正在进行中的活动
         */
        dressupVoList?: UserDressupVo[];
        /**
         * 用户邀请码
         */
        inviteCode?: string;
        /**
         * 是否填写邀请码 0否 1是
         */
        isInvitationCode?: number;
        /**
         * 已邀请的用户的个数
         */
        userNumber?: number;
    };
    export type UserMemberPackageAndPrivilegeListVo = {
        /**
         * 会员套餐
         */
        packageVoList?: UserMemberPackageVo[];
        /**
         * 会员特权
         */
        privilegeList?: UserMemberPrivilegeVo[];
        /**
         * 会员信息
         */
        userInfoBean?: UserInfoBean;
    };
    export type UserMemberPackageVo = {
        /**
         * 平均每月的价格
         */
        averagePriceMonth?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 主键
         */
        id: number;
        /**
         * 图片
         */
        image?: string;
        /**
         * 套餐名称
         */
        name?: string;
        /**
         * 原价
         */
        originalPrice?: number;
        /**
         * 现价
         */
        presentPrice?: number;
        /**
         * 优惠的价格
         */
        reducePrice?: string;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 类型(1.月卡;2.季卡;3.年卡;)
         */
        type?: number;
        /**
         * 更新时间
         */
        updateDate?: string;
    };
    export type UserMemberPrivilegeVo = {
        /**
         * 享有的个数
         */
        enjoyNumber?: number;
        /**
         * 图标
         */
        icon?: string;
        /**
         * 主键
         */
        id: number;
        /**
         * 级别 一级是1 其余是2
         */
        level?: number;
        /**
         * 会员等级:(1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;)
         */
        memberLevel?: string;
        /**
         * 会员等级:(1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;)
         */
        memberLevelList?: string[];
        /**
         * 名称
         */
        name?: string;
        /**
         * 所属类型:1.共有;2.独有
         */
        ownType?: number;
        /**
         * 父级ID
         */
        parentId?: number;
        /**
         * 特权描述
         */
        privilegeDescribe?: string;
        /**
         * 特权说明
         */
        privilegeExplain?: string;
        /**
         * 特权名称
         */
        privilegeName?: string;
        /**
         * 备注
         */
        remarks?: string;
    };
    export type UserMemberRecordQueryForm = {
        /**
         * 活动的id(关联装扮表主键id)
         */
        activityId?: number;
        /**
         * 有效时间
         */
        dayNumber?: number;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 结束时间
         */
        endDate?: string;
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 主键ID列表
         */
        idList?: number[];
        /**
         * 会员类型:(1.月卡;2.季卡;3.年卡;4.月续;5.季续;6.年续;7.体验卡(邀请获得是N天的,具体关联活动ID);8.高级vip赠体验卡;)
         */
        memberType?: number;
        /**
         * 操作类型1.单次支付;2.续费;3.邀请获得;4.vip赠送;
         */
        operaType?: number;
        orderBy?: string;
        /**
         * 订单ID
         */
        orderId?: number;
        pageNum?: number;
        pageSize?: number;
        /**
         * 支付类型1.iPhone端 苹果支付;2.安卓端 支付宝支付;3.安卓端 微信支付;4.邀请活动奖励;5.高级vip赠送;
         */
        payType?: number;
        /**
         * 金额
         */
        price?: number;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 生效时间
         */
        startDate?: string;
        /**
         * 用户ID
         */
        userId?: string;
    };
    export type UserMemberRecordVo = {
        /**
         * 活动的id(关联装扮表主键id)
         */
        activityId?: number;
        /**
         * 有效时间
         */
        dayNumber?: number;
        /**
         * 结束时间,格式为:yyyy-MM-dd
         */
        endDate?: string;
        /**
         * 主键ID
         */
        id: number;
        /**
         * 会员卡类型 0:非会员(非会员或者成长值没有达到等级);1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 操作类型1.单次支付;2.续费;3.邀请获得;4.vip赠送;
         */
        operaType?: number;
        /**
         * 订单ID
         */
        orderId?: number;
        /**
         * 支付类型1.iPhone端 苹果支付;2.安卓端 支付宝支付;3.安卓端 微信支付;4.邀请活动奖励;5.高级vip赠送;
         */
        payType?: number;
        /**
         * 金额
         */
        price?: number;
        /**
         * 备注
         */
        remarks?: string;
        /**
         * 生效时间,格式为:yyyy-MM-dd
         */
        startDate?: string;
        /**
         * 该记录的状态 默认0正常 1已退款
         */
        state?: number;
        /**
         * 用户ID
         */
        userId?: string;
    };
    export type UserOwnDressUpVo = {
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 头像
         */
        head?: string;
        /**
         * 用户ID
         */
        id?: string;
        /**
         * 用户邀请码
         */
        inviteCode?: string;
        /**
         * 用户是否是会员 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        /**
         * 会员卡类型 0:非会员(非会员或者成长值没有达到等级);1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 正在使用的个性装扮
         */
        userInUseDressupList?: UserDressupVo[];
    };
    /**
     * 用户个人主页信息
     */
    export type UserPersonalInfoVo = {
        /**
         * 所在城市编码
         */
        addressCode?: string;
        /**
         * 所在城市是否公开 1是 0否 默认0
         */
        addressIsPublic?: number;
        /**
         * 所在城市名称
         */
        addressName?: string;
        /**
         * 个性签名
         */
        autograph?: string;
        /**
         * 粉丝个数
         */
        beUserFollowNumber?: number;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 用户计时总时长
         */
        durationNum?: string;
        /**
         * 关注用户个数
         */
        followUserNumber?: number;
        /**
         * 头像
         */
        head?: string;
        /**
         * 主键
         */
        id: string;
        /**
         * 该用户的是否专注状态 0否 1是
         */
        isAbsorbedState?: number;
        /**
         * 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 学习阶段名称
         */
        learnstageName?: string;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        /**
         * 会员类型 0:非会员;1:月卡;2:季卡;3:年卡;4:月续;5:季续;6:年续;7.体验卡(邀请获得是N天的,具体关联活动ID);8.高级vip赠体验卡;
         */
        memberType?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 用户积分个数
         */
        pointNumber?: number;
        /**
         * 燃值
         */
        praiseNumber?: number;
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        /**
         * 性别
         */
        sex?: string;
        /**
         * 登录用户对该用户的关注信息
         */
        userFollowInfo?: UserFollowInfoBeanVo;
    };
    /**
     * 查询燃豆助手
     */
    export type UserPointNoticQueryForm = {
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 查询用户信息
     */
    export type UserQueryForm = {
        /**
         * 动态ID
         */
        dynamicId?: string;
        /**
         * 用户创建开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        fromDate?: string;
        /**
         * 最后登录开始时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        fromLastLoginDate?: string;
        /**
         * 用户IDList
         */
        idList?: string[];
        /**
         * 用户是否是会员 1.是会员 0.不是会员
         */
        isMember?: number;
        /**
         * 是否分页 不分页传1，分页默认空
         */
        isPage?: number;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 最后登录设备 Android iOS
         */
        mobileSystem?: string;
        orderBy?: string;
        /**
         * 排序类型(1:燃值;2:创建时间;3:最后登录时间;4:专注时长;5:燃豆个数;)此参数只在管理界面App用户列表使用
         */
        orderType?: number;
        pageNum?: number;
        pageSize?: number;
        /**
         * 滚动id
         */
        scrollId?: string;
        /**
         * 查询类型(1:粉丝列表;2:关注列表;3:关注搜索列表;4:用户搜索列表;5.管理界面App用户列;6.搜索多个;7.某用户所屏蔽用户列表;8.动态喂豆人列表;9.mamager受限用户列表;10:跟据List<userId>查询用户信息)
         */
        searchType?: number;
        /**
         * 搜索的内容
         */
        searchValue?: string;
        /**
         * 性别 性别 1男 2女
         */
        sex?: string;
        /**
         * 用户创建结束时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        toDate?: string;
        /**
         * 最后登录结束时间,格式为:yyyy-MM-dd HH:mm:ss
         */
        toLastLoginDate?: string;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    /**
     * 邀请人
     */
    export type UserRecommendQueryForm = {
        /**
         * 被邀请人ID
         */
        beuserId?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        /**
         * 邀请表主键
         */
        id?: number;
        /**
         * 邀请表主键列表
         */
        idList?: number[];
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 有效状态:针对重复邀请活动是否算此记录(0:无效;1:有效;)
         */
        state?: number;
        /**
         * 邀请人ID
         */
        userId?: string;
    };
    export type UserRecommendVo = {
        /**
         * 邀请人头像
         */
        beuserHead?: string;
        /**
         * 被邀请人ID
         */
        beuserId?: string;
        /**
         * 邀请人Name
         */
        beuserName?: string;
        /**
         * 创建时间
         */
        createDate?: string;
        /**
         * 邀请表主键
         */
        id: number;
        /**
         * 有效状态:针对重复邀请活动是否算此记录(0:无效;1:有效;)
         */
        state?: number;
    };
    /**
     * 查询用户时间轴信息
     */
    export type UserRecordingTimeQueryForm = {
        /**
         * 传相应事件类型对应的ID
         */
        eventId?: number;
        /**
         * 事件类型:1.待办;2.目标;3.练习;4.学霸模式;5.专注模式;
         */
        eventType?: number;
        /**
         * 开始时间,格式为:yyyy-MM-dd
         */
        fromTime?: string;
        /**
         * 习惯id
         */
        habitId?: number;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 查询用户ID
         */
        searchId?: string;
        /**
         * 操作平台 1:APP;2:后台
         */
        source?: number;
        /**
         * 结束时间,格式为:yyyy-MM-dd
         */
        toTime?: string;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    /**
     * 修改用户时间轴数据
     */
    export type UserRecordingTimeUpdateForm = {
        /**
         * 习惯id
         */
        habitId?: number;
        /**
         * 主键
         */
        id?: string;
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 用户ID
         */
        userId?: string;
    };
    /**
     * 重置密码
     */
    export type UserResetPasswordForm = {
        /**
         * 密码
         */
        password?: string;
        /**
         * 用户Id
         */
        userId?: string;
    };
    /**
     * 用户跟据id查询Form
     */
    export type UserSearchByIdForm = {
        /**
         * 活动的Id(邀请的时候使用)
         */
        avtivityId?: number;
        /**
         * 邀请码
         */
        inviteCode?: string;
        /**
         * 是否需要校验权限：是app请求且请求查询的用户不是登录者本人 true是 false否 验证用户权限时使用
         */
        isNeedVerifiyAuthority?: boolean;
        /**
         * 搜索的Id
         */
        searchId?: string;
        /**
         * 当前用户ID
         */
        userId?: string;
    };
    /**
     * 填写邀请码
     */
    export type UserSetInviteCodeForm = {
        /**
         * 活动ID
         */
        activityId?: number;
        /**
         * 用户Id
         */
        id?: string;
        /**
         * 邀请码
         */
        inviteCode?: string;
    };
    /**
     * 设置用户机型
     */
    export type UserSetMobileTypeForm = {
        /**
         * 用户Id
         */
        id?: string;
        /**
         * 请求手机类型:1.oppo;2.vivo;3.小米;4.华为 默认0 未知机型 统一极光推送
         */
        mobileType?: number;
        /**
         * 推送registration_id
         */
        pushRegistrationId?: string;
    };
    /**
     * 用户屏蔽信息
     */
    export type UserShieldInfoBean = {
        /**
         * 操作的时间
         */
        createDate?: string;
        /**
         * 删除标识(0正常 1已经删除)
         */
        delFlag?: string;
        id?: string;
        /**
         * 用户ID A
         */
        userIdA?: string;
        /**
         * 用户ID B
         */
        userIdB?: string;
    };
    export type UserSigninRecordVo = {
        /**
         * 连续签到天数
         */
        continudateDayNumber?: number;
        /**
         * 签到记录表主键
         */
        id: number;
        /**
         * 获得积分个数
         */
        pointNumber?: number;
        /**
         * 不是关键天数是0，是关键天数存对应的值 7，14，21，30
         */
        ruleValue?: number;
        /**
         * 签到日期
         */
        signinDate?: string;
    };
    /**
     * 解绑三方账号参数
     */
    export type UserUnBindAccountForm = {
        /**
         * 用户Id
         */
        id?: string;
        /**
         * 绑定的用户类型(用户创建注册类型（授权平台）:1.QQ;2.wx;3.wb;7.Apple) 填写对应的数字
         */
        type: number;
    };
    /**
     * 修改用户信息
     */
    export type UserUpdateForm = {
        /**
         * 所在城市编码
         */
        addressCode?: string;
        /**
         * 所在城市是否公开 1是 0否 默认0
         */
        addressIsPublic?: number;
        /**
         * 所在城市名称
         */
        addressName?: string;
        /**
         * 个性签名
         */
        autograph?: string;
        /**
         * 生日,格式为:yyyy-MM-dd
         */
        brithday?: string;
        /**
         * 头像
         */
        head?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 学习阶段
         */
        learnstage?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 职业
         */
        occupation?: number;
        /**
         * 手机号
         */
        phone?: string;
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        /**
         * 性别传从字典中获取的对应的Code
         */
        sex?: string;
    };
    /**
     * 用户选择tabBar模块表
     */
    export type UserUseCustomTabbarForm = {
        /**
         * 用户选择的tabbar集合list
         */
        tabbarList?: number[];
        /**
         * 用户id
         */
        userId?: string;
    };
    /**
     * 用户个性装扮 使用中记录表
     */
    export type UserUseRecordQueryForm = {
        /**
         * 主键ID
         */
        id?: number;
        /**
         * 主键ID列表
         */
        idList?: number[];
        /**
         * 模块类型:1.图标;2.表情包;3.白噪音;4.头像挂件;5.专注场景;6.体验卡;7.小组件
         */
        modelType?: number;
        /**
         * 模块类型集合:1.图标;2.表情包;3.白噪音;4.头像挂件;5.专注场景;6.体验卡;7.小组件
         */
        modelTypeList?: number[];
        orderBy?: string;
        pageNum?: number;
        pageSize?: number;
        /**
         * 装扮模块主键ID
         */
        subId?: number;
        /**
         * 用户id
         */
        userId?: string;
    };
    export type UserVo = {
        /**
         * 所在城市编码
         */
        addressCode?: string;
        /**
         * 所在城市是否公开 1是 0否 默认0
         */
        addressIsPublic?: number;
        /**
         * 所在城市名称
         */
        addressName?: string;
        /**
         * 个性签名
         */
        autograph?: string;
        /**
         * 粉丝个数
         */
        beUserFollowNumber?: number;
        /**
         * 生日,格式为:yyyy-MM-dd
         */
        brithday?: string;
        createDate?: string;
        /**
         * 创建动态的个数
         */
        createDynamicNumber?: number;
        /**
         * 装扮头像
         */
        dressUpHeadID?: number;
        /**
         * 邀请的活动信息
         */
        dressupVo?: UserDressupVo;
        /**
         * 用户计时总时长
         */
        durationNum?: string;
        /**
         * 关注用户List(关注类型 1:A关B 2:B关A(A没关B) 3:互相关注)
         */
        followUserList?: UserFollowInfoBeanVo[];
        /**
         * 关注用户个数
         */
        followUserNumber?: number;
        /**
         * 头像
         */
        head?: string;
        /**
         * 主键
         */
        id?: string;
        /**
         * 注册时候当前用户邀请人的邀请码
         */
        invitationUserCode?: string;
        /**
         * 邀请人邀请码
         */
        inviterCode?: string;
        /**
         * 邀请人头像
         */
        inviterHeader?: string;
        /**
         * 邀请人名称
         */
        inviterName?: string;
        /**
         * 增值税发票 0.无; 1.审核中; 2.审核通过
         */
        invoice?: number;
        /**
         * 是否是受限用户 0 否 1是
         */
        isLimit?: number;
        /**
         * 1.是会员 0.不是会员 默认0
         */
        isMember?: number;
        /**
         * 用户注册的时候使用 如果走的是绑定并登录的流程则App不做头像移动操作
         */
        isRegister?: boolean;
        /**
         * isScoreAppAndroid  第一次评分安卓机会是否已使用 0否 1是
         */
        isScoreAppAndroid?: number;
        /**
         * isScoreAppIos  第一次评分IOS机会是否已使用 0否 1是
         */
        isScoreAppIos?: number;
        /**
         * 是否有密码 0无 1有
         */
        isSettingPassword?: number;
        /**
         * 第一次修改用户名机会是否已使用 0否 1是
         */
        isUpdateName?: number;
        /**
         * 第一已绑定手机号机会是否已经使用过 0否 1是
         */
        isbindphone?: number;
        /**
         * 第一已补充资料机会是否已经使用过 0否 1是
         */
        isinformation?: number;
        lastLoginDate?: string;
        /**
         * 最后拉取时间
         */
        lastPullTimeInfo?: LastPullTimeInfoVo;
        /**
         * 学习阶段 默认0:未设置
         */
        learnstage?: number;
        /**
         * 学习阶段名称
         */
        learnstageName?: string;
        /**
         * 用户等级 默认小白用户1
         */
        level?: number;
        /**
         * 会员结束时间
         */
        memberEndDate?: string;
        /**
         * 会员卡类型 0:非会员(非会员或者成长值没有达到等级);1.青铜;2.秘银;3.黄金;4.铂金;5.钻石;6.星辰;
         */
        memberType?: number;
        /**
         * 最后登录设备 Android iOS
         */
        mobileSystem?: string;
        /**
         * 请求手机类型:1.oppo;2.vivo;3.小米;4.华为 默认0 未知机型 统一极光推送
         */
        mobileType?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 职业id
         */
        occupation?: number;
        /**
         * 职业颜色
         */
        occupationColor?: string;
        /**
         * 职业名称
         */
        occupationName?: string;
        /**
         * 手机号
         */
        phone?: string;
        /**
         * 燃豆个数
         */
        pointNumber?: number;
        /**
         * 燃值
         */
        praiseNumber?: number;
        /**
         * 推送registration_id
         */
        pushRegistrationId?: string;
        /**
         * 实名认证 0未实名 1已实名
         */
        realName?: number;
        /**
         * 时间轴是否公开 1是公开 0否不是公开 默认1公开
         */
        recordTimeIsPublic?: number;
        /**
         * 性别
         */
        sex?: string;
        /**
         * type=7 某用户所屏蔽用户列表 屏蔽主键ID
         */
        shieldId?: string;
        /**
         * 展示绑定信息 0:以前注册;1:绑定邀请人;2:邀请主力;
         */
        showBindInfo?: number;
        /**
         * 用户状态 默认正常1
         */
        state?: number;
        /**
         * 专注状态0否1是
         */
        timeState?: number;
        /**
         * token
         */
        token?: string;
        /**
         * 登录用户对该用户的关注信息
         */
        userFollowInfo?: UserFollowInfoBeanVo;
    };
    /**
     * 验证旧密码是否正确
     */
    export type UserverifyOldPasswordForm = {
        /**
         * 用户ID
         */
        id?: string;
        /**
         * 密码
         */
        password?: string;
    };
    /**
     * 商品所在活动信息
     */
    export type UtilVo = {
        /**
         * 活动说明
         */
        activityExplain?: string;
        /**
         * 活动id
         */
        activityId?: number;
        /**
         * 活动名称
         */
        activityName?: string;
        /**
         * 活动价格
         */
        activityPrice?: number;
        /**
         * 活动状态，0-取消 1-正常
         */
        activityState?: number;
        /**
         * 活动标题
         */
        activityTitle?: string;
        /**
         * 活动类型 1为限时抢购, 2为预售
         */
        activityType?: number;
        /**
         * 结束时间
         */
        endTime?: string;
        /**
         * 商品编号
         */
        goodsId?: number;
        /**
         * 商品主图片
         */
        goodsImage?: string;
        /**
         * 商品名称
         */
        goodsName?: string;
        /**
         * 店铺价格
         */
        goodsPrice?: number;
        /**
         * 商品库存
         */
        goodsStoragy?: number;
        /**
         * 限购数量
         */
        maxLimit?: number;
        /**
         * 起步购买数量
         */
        minLimit?: number;
        /**
         * 详情页展示燃豆可抵金额信息-为空不展示此标签
         */
        pointsLabelMessage?: string;
        /**
         * 预售商品的发货时间
         */
        preShippingTime?: string;
        /**
         * 活动信息
         */
        shopActivityRedisVo?: ShopActivityRedisVo;
        /**
         * 开始时间
         */
        startTime?: string;
        /**
         * 活动商品总数
         */
        totalNum?: number;
        /**
         * 推荐标志 0-未推荐 1-已推荐
         */
        xianshiRecommend?: number;
    };
    export type Value = {
        desc?: string;
        label?: string;
        value?: string;
        valueId?: number;
        valueName?: string;
    };
    export type View = {
        contentType?: string;
    };
    export type WarmheartVo = {
        context?: string;
        name?: string;
        type?: number;
    };
}