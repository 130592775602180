import { Ajax } from "../../internal";


export interface DownloadItem {
  scheme: string;
  url: string;
}

export interface DownloadConfig {
  ios: DownloadItem;
  android: DownloadItem;
}
interface RemoteItem {
  url: string,
  version: string,
  date: string,
  remark: string
}

interface RemoteConfig {
  ios: RemoteItem[],
  android: RemoteItem[]
}
// this.iosAppUrl = "FireweedMqt://?action=download-manifest&url=" + encodeURIComponent("https://download.fireweed.fun/" + con!.ios[0].url);
// this.iosDownUrl = "itms-apps://itunes.apple.com/app/id1471608685?mt=8"
// this.androidAppUrl = "fireweed://shortcut/"
// this.androidDownUrl = "http://download.fireweed.fun/" + con!.android[0].url
export async function getDownLoadConfig(): Promise<DownloadConfig> {
  let res: RemoteConfig;
  if (isProd()) {
    res = await Ajax.get("https://download.fireweed.fun/appVersion/config.json?time=" + Date.now());
  } else {
    res = await Ajax.get("https://download.fireweed.fun/devAppVersion/config.json?time=" + Date.now());
  }

  return {
    ios: getIos(res),
    android: getAndroid(res)
  }
  // this.iosAppUrl = "FireweedMqt://?action=download-manifest&url=" + encodeURIComponent("https://download.fireweed.fun/" + con!.ios[0].url);
  // this.iosDownUrl = "itms-apps://itunes.apple.com/app/id1471608685?mt=8"
  // this.androidAppUrl = "fireweed://shortcut/"
  // this.androidDownUrl = "http://download.fireweed.fun/" + con!.android[0].url

}
function isProd() {
  return location.hostname == "m.fireweed.cn";
}

function getIos(config: RemoteConfig): DownloadItem {
  return {
    scheme: "FireweedMqt://",//"?action=download-manifest&url=" + encodeURIComponent("https://download.fireweed.fun/" + config.ios[0].url),
    url: "itms-apps://itunes.apple.com/app/id1471608685?mt=8"
  } as DownloadItem
}

function getAndroid(config: RemoteConfig): DownloadItem {
  return {
    scheme: "fireweed://shortcut/",
    url: "http://download.fireweed.fun/" + config.android[0].url
  } as DownloadItem
}



