import { VueComponent, Component, CreateElement, Ajax, Prop } from "internal";
import { DownloadConfig, getDownLoadConfig } from "./config"
export type DownloadPageProps = {
    isSmall?: boolean
};

@Component
export class DownloadPage extends VueComponent<DownloadPageProps>{

    private list: any = {};
    private shareImg: boolean = false;

    @Prop({ default: false }) isSmall: boolean;

    get isProd() {
        return location.hostname == "m.fireweed.cn"
    }

    private render(h: CreateElement) {
        return (
            <div class="download-wrap">
                <div class="pic5" v-show={!this.isSmall}>
                    <img src={require('@/assets/invent-img/pic4-top.png')}></img>
                </div>
                <div class="icons">
                    <div>
                        <a onClick={this.downLoadIos}>
                            <span class="ios">iOS</span>
                        </a>
                        <a onClick={this.downLoadAndroid}>
                            <span class="android">Android</span>
                        </a>
                    </div>
                    <p class="pic5-info">如果点击无法下载安装，请选择在系统默认浏览器中打开(ios是Safari)</p>
                </div>
                <div class="download-share" v-show={this.shareImg} onClick={() => this.shareImg = false}>
                    <img src={require('@/assets/invent-img/share.png')}></img>
                </div>
            </div>
        )
    }

    // private iosDownUrl: any = ''
    // private iosAppUrl: any = ''
    // private androidDownUrl: any = ''
    // private androidAppUrl: any = ''

    private config: DownloadConfig = {} as any;

    private async downVersion() {
        this.config = await getDownLoadConfig();
        // let res;
        // if (location.hostname == "m.fireweed.cn") {
        //     res = await Ajax.get("https://download.fireweed.fun/appVersion/config.json?time=" + Date.now());
        // } else {
        //     res = await Ajax.get("https://download.fireweed.fun/devAppVersion/config.json?time=" + Date.now());
        // }
        // let con: any
        // con = res

        // // this.iosDownUrl = "itms-services://?action=download-manifest&url=" + encodeURIComponent("https://download.fireweed.fun/" + con!.ios[0].url);

        // this.iosAppUrl = "FireweedMqt://?action=download-manifest&url=" + encodeURIComponent("https://download.fireweed.fun/" + con!.ios[0].url);
        // this.iosDownUrl = "itms-apps://itunes.apple.com/app/id1471608685?mt=8"
        // this.androidAppUrl = "fireweed://shortcut/"
        // this.androidDownUrl = "http://download.fireweed.fun/" + con!.android[0].url
    }

    private downLoadIos(event: any) {
        // @ts-ignore
        if (_hmt) {
            // @ts-ignore
            _hmt.push(['_trackEvent', '下载页面', '下载ios'])
        }
        if (this.isWeiXin) {
            this.shareImg = true
        } else {
            window.location.href = this.config.ios.scheme;
            setTimeout(() => {
                let hidden = window.document.hidden
                if (typeof hidden == "undefined" || hidden == false) {
                    //App store下载地址
                    window.location.href = this.config.ios.url
                }
            }, 1000);
            // if (this.isProd) {
            //     window.location.href = this.iosDownUrl
            // } else {
            //     window.location.href = this.iosAppUrl;
            //     setTimeout(() => {
            //         let hidden = window.document.hidden
            //         if (typeof hidden == "undefined" || hidden == false) {
            //             //App store下载地址
            //             window.location.href = this.iosDownUrl
            //         }
            //     }, 20);
            // }
        }
    }



    private downLoadAndroid(event: any) {
        // @ts-ignore
        if (_hmt) {
            // @ts-ignore
            _hmt.push(['_trackEvent', '下载页面', '下载安卓'])
        }
        if (this.isWeiXin) {
            this.shareImg = true
        } else {
            window.location.href = this.config.android.scheme;
            setTimeout(() => {
                let hidden = window.document.hidden
                if (typeof hidden == "undefined" || hidden == false) {
                    //App store下载地址
                    window.location.href = this.config.android.url
                }
            }, 1000);
            // window.location.href = this.androidAppUrl
            // setTimeout(() => {
            //     let hidden = window.document.hidden
            //     if (typeof hidden == "undefined" || hidden == false) {
            //         //App store下载地址
            //         window.location.href = this.androidDownUrl
            //     }
            // }, 20);
        }
    }


    private get isWeiXin() {
        var u = navigator.userAgent;
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1 || (ua.indexOf('qq') !== -1 && u.indexOf('MQQBrowser') == -1) || ua.indexOf('dingtalk') !== -1) {
            return true;
        }
        return false;
    }

    private mounted() {
        document.body.className += " total"
    }

    private created() {
        this.downVersion();
        // var u = navigator.userAgent;
        // if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && this.isProd) {
        //     window.location.href = "itms-apps://itunes.apple.com/app/id1471608685?mt=8"
        // }
    }

}